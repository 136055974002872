import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addRmOpInfoApi,
  rmModifyOpInfoApi,
  rmOpInfoApi,
} from "components/api/room";
import RoomOptModifyInfoView from "components/view/room/opt/modify";
import { rmOpProps } from "interface/room";
import i18n from "locales/i18n";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const RoomOptModifyInfoPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname, state, search } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const memType = pathname.includes("manager") ? "manager" : "admin";

  const query = useQuery({
    queryKey: ["rmOpInfo", memType],
    queryFn: () => {
      return rmOpInfoApi({
        rmNo: state.rmNo,
        opRgSeq: search.replace("?opRgSeq=", ""),
        langCd: i18n.language,
      });
    },
    enabled: !!search.replace("?opRgSeq=", ""),
  });

  const rmOpDtl = query?.data?.body?.rmOpDtl;
  const addInfList = query?.data?.body?.addInfList;

  const [info, setInfo] = useState<rmOpProps>({
    rmNo: state?.rmNo ?? "",
    langCd: i18n.language,
    opRgSeq: search?.replace("?opRgSeq=", "") ?? 0,
    brkfInYn: "",
    latChkOutTi: "",
    uyn: "",
  });

  const [opAddInfo, setOpAddInfo] = useState({});
  const [opAddLngInf, setOpAddLngInf] = useState<any>({});

  const handleAddInfoChange = (name: string, value: string) => {
    let names = name.split("_");

    setOpAddInfo({
      langCd: names[1],
      [names[0]]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;
    if (name && value) {
      setInfo({
        ...info,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const rmOpModifyMutation = useMutation(rmModifyOpInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "수정 완료하였습니다.",
              handleClick() {
                dispatch(setMessage({ message: null }));

                setInfo({
                  rmNo: state?.rmNo ?? "",
                  langCd: i18n.language,
                  opRgSeq: search?.replace("?opRgSeq=", "") ?? "",
                  brkfInYn: "",
                  latChkOutTi: "",
                  uyn: "",
                });
              },
            })
          );
          queryClient.invalidateQueries({
            queryKey: ["rmOpInfo"],
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = (type: string) => {
    let params = {
      rmNo: info.rmNo,
      opRgSeq: info.opRgSeq,
    } as any;
    if (type === "default") {
      params = {
        ...params,
        ...info,
        latChkOutTi: info?.latChkOutTi.replace(":", ""),
      };

      dispatch(
        setMessage({
          message: "객실옵션 기본정보를 수정하시겠습니까?",
          isConfirm: true,
          isCancel: true,
          handleClick() {
            rmOpModifyMutation.mutate(params);
          },
        })
      );
      return;
    }
    if (type === "rmOpNm") {
      params = {
        ...params,
        ...opAddInfo,
      };

      dispatch(
        setMessage({
          message: `객실옵션 ${
            params?.langCd === "kr"
              ? "한글"
              : params?.langCd === "us"
              ? "영문"
              : ""
          }명을 수정하시겠습니까?`,
          isConfirm: true,
          isCancel: true,
          handleClick() {
            rmOpModifyMutation.mutate(params);
          },
        })
      );
      return;
    }
    if (type === "rmOpDesc") {
      params = {
        ...params,
        ...opAddInfo,
      };

      dispatch(
        setMessage({
          message: `객실옵션${
            params?.langCd === "kr"
              ? "한글"
              : params?.langCd === "us"
              ? "영문"
              : ""
          }설명을 수정하시겠습니까?`,
          isConfirm: true,
          isCancel: true,
          handleClick() {
            rmOpModifyMutation.mutate(params);
          },
        })
      );
      return;
    }
  };

  const handleAddLngSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;

    if (name && value) {
      setOpAddLngInf({
        ...opAddLngInf,
        langCd: value,
      });
    }
  };

  const handleAddLngInfChange = (name: string, value: string) => {
    setOpAddLngInf({
      ...opAddLngInf,
      [name.split("_")[0]]: value,
    });
  };

  const handleAddInfClose = () => {
    setIsOpen(false);
    setOpAddLngInf({});
  };

  const addInfMutation = useMutation(addRmOpInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `객실옵션추가정보를 추가 완료하였습니다.`,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                queryClient.invalidateQueries({
                  queryKey: ["rmOpInfo", memType],
                });
                setIsOpen(false);
                // window.location.reload();
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleAddInfo = () => {
    dispatch(
      setMessage({
        message: `객실옵션을 추가 하시겠습니까?`,
        isConfirm: true,
        isCancel: true,
        handleClick() {
          const params = {
            lodGrpNo: rmOpDtl?.lodGrpNo,
            lodNo: rmOpDtl?.lodNo,
            rmNo: info.rmNo,
            rgSeq: Number(info.opRgSeq),
            rmOpNm: opAddLngInf.rmOpNm,
            rmOpDesc: opAddLngInf.rmOpDesc,
            langCd: opAddLngInf.langCd === "en" ? "us" : opAddLngInf.langCd,
          };
          addInfMutation.mutate(params);
        },
      })
    );
  };

  useEffect(() => {
    setOpAddInfo({});
  }, []);

  return (
    <RoomOptModifyInfoView
      info={info}
      rmOpDtl={rmOpDtl}
      addInfList={addInfList}
      opAddInfo={opAddInfo}
      opAddLngInf={opAddLngInf}
      memType={memType}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      addLoading={addInfMutation.isLoading}
      isLoading={rmOpModifyMutation.isLoading}
      handleAddInfoChange={handleAddInfoChange}
      handleSelect={handleSelect}
      handleModify={handleModify}
      handleAddLngSelect={handleAddLngSelect}
      handleAddInfo={handleAddInfo}
      handleAddLngInfChange={handleAddLngInfChange}
      handleAddInfClose={handleAddInfClose}
    />
  );
};
export default RoomOptModifyInfoPage;
