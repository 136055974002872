import axios, { InternalAxiosRequestConfig } from "axios";
import { ResponseProps } from "interface";

type IProps = {
  formData?: FormData;
  uploadFiles?: FormData;
  imgTypCd?: string;
  lodNo: string;
};

const baseUrl = `/app/comn/upload/image`;

axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    // 요청 오류가 있는 작업 수행
    return Promise.reject(error);
  }
);

const imgUploadApi = async (params: IProps) => {
  const { formData, lodNo } = params;

  const headers: any = {
    "Content-Type": "multipart/form-data; charset=UTF-8",
  };

  try {
    axios.defaults.withCredentials = true;
    const response = await axios({
      method: "post",
      url: `${baseUrl}/${lodNo}`,

      data: formData,
      headers,
    });

    const res: ResponseProps = response.data;

    const {
      header: { code, message },
      body,
    } = response.data;

    if (code === -200) {
      alert(message);
      sessionStorage.removeItem("login");
      window.location.replace("/");
    }

    return res;
  } catch (error) {
    console.error(error);
  }
};

export default imgUploadApi;
