import FormSearchBtn from "components/button/formSearch";
import SubHeader from "components/layout/header/subHeader";
import Select from "components/select";
import LodGrdSelect from "components/select/room/lodGrdSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import LodDefaultList from "components/tableList/lod/default";
import { trace } from "console";
import { lodDefaultSearchProps } from "interface/lod";
import { lodGrpStatus } from "util/option";

type IProps = {
  isLoading: boolean;
  searchInfo: lodDefaultSearchProps;
  memType: "admin" | "manager";
  defaultInfoList: [];
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleReset(): void;
  handleSearch(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const LodDefaultListView = ({
  memType,
  searchInfo,
  isLoading,
  defaultInfoList,
  handleChange,
  handleReset,
  handleSelect,
  handleSearch,
}: IProps) => {
  return (
    <>
      <div className="search-form">
        <SubHeader title="숙소기본정보" add />

        <div className="table-form">
          <table>
            <tbody>
              <tr>
                <th>숙소그룹번호</th>
                <td>
                  <LodGrdSelect
                    option={searchInfo.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소그룹명</th>
                <td>
                  <input
                    type="text"
                    value=""
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소번호</th>
                <td>
                  <LodNoSelect
                    option={searchInfo.lodNo}
                    params={searchInfo.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소명</th>
                <td>
                  <input
                    type="text"
                    value=""
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소등급</th>
                <td>
                  <LodGrdSelect
                    option={searchInfo?.lodGrpCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소상태</th>
                <td>
                  <Select
                    id=""
                    name="uYn"
                    options={lodGrpStatus}
                    onSelect={() => {}}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            disabled={false}
            handleReset={handleReset}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <LodDefaultList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default LodDefaultListView;
