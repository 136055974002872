import PayList from "components/view/home/payList";

const AdminHomePage = () => {
  return (
    <div className="home-wrap">
      <div className="home day-list">
        <PayList payInfo={{} as any} lodNo={""} />
      </div>
    </div>
  );
};

export default AdminHomePage;
