import { useMutation } from "@tanstack/react-query";
import { signupApi } from "components/api/member";
import Step4View from "components/view/signup/step4";
import { passProps } from "interface/member";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const Step4Page = () => {
  const { pathname, state: certEncParam } = useLocation();

  const dispath = useDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [info, setInfo] = useState<passProps>({
    pwd: "",
    check_pwd: "",
  });

  const handleChange = (target: { name: string; value: string }) => {
    const { name, value } = target;

    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const signupMutation = useMutation(signupApi, {
    onSuccess(data, context) {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          setIsOpen(!isOpen);
        }
        if (code === -1) {
          dispath(setMessage({ message }));
        }
      }
    },
    onError(error, variables, context) {
      console.error("error", error, variables, context);
      // dispath(setMessage({ message: "회원가입 실패" }));
    },
  });

  const handleSignup = () => {
    const params = {
      certEncParam: decodeURIComponent(certEncParam.split("=")[1]),
      lgnPwd: info.pwd,
      prvList: [
        {
          prvGrpCd: "A01",
          prvSeq: "1",
          required: true,
          prvAgYn: "Y",
        },
        {
          prvGrpCd: "B01",
          prvSeq: "2",
          required: true,
          prvAgYn: "Y",
        },
        {
          prvGrpCd: "C01",
          prvSeq: "3",
          required: true,
          prvAgYn: "Y",
        },
        {
          prvGrpCd: "D01",
          prvSeq: "4",
          required: true,
          prvAgYn: "Y",
        },
      ],
    };

    const url = pathname.includes("admin")
      ? "admin/join/adminCertJoin"
      : "manager/join/manCertJoin";
    signupMutation.mutate({ data: params, url });
  };

  return (
    <Step4View
      info={info}
      isOpen={isOpen}
      isLoading={signupMutation.isLoading}
      handleCloseModal={handleCloseModal}
      handleChange={handleChange}
      handleSignup={handleSignup}
    />
  );
};

export default Step4Page;
