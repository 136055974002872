import { lodGrpHead } from "util/option";
import { IlodGrpList, lodGrpSearchProps } from "interface/lod";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import Modal from "components/modal/modal";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  isLoadingSelector,
  setIsLoading,
  setIsSearch,
} from "store/commonSlice";
import NotData from "../notData";
import { useSelector } from "react-redux";
import { isSearchSelector } from "store/memberSlice";
import { pageNavProps } from "interface";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: lodGrpSearchProps;
};

type IListProps = {
  searchInfo: lodGrpSearchProps;
  memType: "admin" | "manager";
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search, state, pathname } = useLocation();
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const handleStatus = () => {};

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  // useEffect(() => {
  //   if (isSearch) {
  //     const params = {
  //       lodGrpNo: searchInfo?.lodGrpNo || state?.lodGrpNo,
  //       lodNo: searchInfo?.lodNo || state?.lodNo,
  //       // lodGrpCd: "",
  //       lodNm: searchInfo?.lodNm || state?.lodNm,
  //       rmNo: searchInfo?.rmNo || state?.rmNo,
  //       rmNm: searchInfo?.rmNm || state?.rmNm,
  //       rmTypCd: searchInfo?.rmTypCd || state?.rmTypCd,
  //       rmOlkCd: searchInfo?.rmOlkCd || state?.rmOlkCd,
  //       maxPerCt: searchInfo?.maxPerCt || state?.maxPerCt,
  //       minPerCt: searchInfo?.minPerCt || state?.minPerCt,
  //       uyn: searchInfo?.uyn || state?.uyn,
  //       pageNo: page || state?.pageNo,
  //     };

  //     rmListMutation.mutate(params);
  //   }
  // }, [isSearch, searchInfo, search]);
  const pageRows = 10;

  return (
    <>
      {list &&
        list?.map((li: IlodGrpList, index: number) => {
          return (
            <tr>
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              <td>
                <button
                  onClick={() => {
                    navigate(`?lodGrpNo=${li.lodGrpNo}`);
                  }}
                >
                  {li.lodGrpNo}
                </button>
              </td>
              <td>{li.lodGrpNm}</td>
              <td>{li.lodGrpCntyCd}</td>
              <td>{li.lodGrpAddr1}</td>
              <td>{li.lodGrpAddr2}</td>
              <td>
                <span
                  className={li.uYn === "Y" ? "uyn" : ""}
                  // onClick={() => setIsOpen(true)}
                >
                  {li.uYn === "Y" ? "정상" : "미 정상"}
                </span>
              </td>
            </tr>
          );
        })}
      <Modal
        title="상태 변경"
        isOpen={false}
        cnlText="취소"
        isLoading={false}
        disabled={false}
        handleCloseModal={() => {
          // setInfo({
          //   isOpen: false,
          //   rmNo: "",
          //   uyn: "",
          // });
        }}
        onClick={handleStatus}
      >
        <div>숙소상태를 변경 하시겠습니까?</div>
      </Modal>
    </>
  );
};

const LodGrpList = ({ memType, searchInfo }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSelector);

  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = lodGrpHead.map((head) => {
    return head;
  });

  const [No, lodGrpNm, lodGrpCd, lodNm, ...res] = headList;

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                {headList.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {
                <Suspense>
                  <List
                    searchInfo={searchInfo}
                    memType={memType}
                    setPageNav={setPageNav}
                  />
                </Suspense>
              }
            </tbody>
          </table>
        </div>
        <NotData
          isLoading={isLoading}
          isNotData={!pageNav?.totalRows}
          isSuccess={!!pageNav}
          styles=""
          txt="Loading ..."
        />
      </div>
      {/* <AgGrid rowData={rowData} columnDefs={columnDefs} pagination={true} /> */}
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav?.totalPage} />
      )}
    </div>
  );
};

export default LodGrpList;
