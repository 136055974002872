import { loginProps } from "interface/member";
import "./login.scss";
import LoadingButton from "components/button/loadingButton";

type IProps = {
  info: loginProps;
  showPw: boolean;
  isLoading: boolean;
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void;
  handleShowPW(): void;
  handleLogin(): void;
};

const LoginView = ({
  info,
  showPw,
  isLoading,
  handleChange,
  handleKeyDown,
  handleShowPW,
  handleLogin,
}: IProps) => {
  return (
    <>
      <div className="card-form">
        <h2>로그인</h2>
        <div className="flex justify-center gap-x-2 w-full mx-auto mb-5">
          <div className="flex gap-x-5">
            <input
              type="radio"
              id="admin"
              name="memType"
              onChange={(e) => handleChange(e.target)}
            />
            <label htmlFor="admin">관리자</label>
            <input
              type="radio"
              id="manager"
              name="memType"
              defaultChecked
              onChange={(e) => handleChange(e.target)}
            />
            <label htmlFor="manager">매니저</label>
          </div>
          {/* <RadioButton
            id="admin"
            name="memType"
            label="관리자"
            onChange={(target) => handleChange(target)}
          />
          <RadioButton
            id="manager"
            name="memType"
            label="매니저"
            defaultChecked
            onChange={(target) => handleChange(target)}
          /> */}
        </div>
        <div>
          <input
            type="text"
            id="id"
            name="emlId"
            onChange={(e) => handleChange(e.currentTarget)}
            onKeyDown={handleKeyDown}
            className="mb-8"
          />
          <label className={info?.emlId ? "on" : ""}>아이디</label>
        </div>
        <div>
          <input
            type={showPw ? "text" : "password"}
            id="pass"
            name="lgnPwd"
            onChange={(e) => handleChange(e.currentTarget)}
            onKeyDown={handleKeyDown}
          />
          <label className={info.lgnPwd ? "on" : ""}>비밀번호</label>
          <span
            className={`icon-pw ${showPw ? "on" : ""}`}
            onClick={handleShowPW}
          />
        </div>

        {/* <span className="id-remember">
          <input type="checkbox" id="id-remember" />
          <label htmlFor="id-remember">아이디 저장</label>
        </span> */}
        <LoadingButton
          txt={"로그인"}
          styles="btn-login"
          isLoading={isLoading}
          disabled={!(info.emlId && info.lgnPwd) || isLoading}
          onClick={handleLogin}
        />

        {/* <span className="member-list">
          <Link to="/signup">회원가입</Link>
          <Link to="">아이디 찾기</Link>
          <Link to="">비밀번호 찾기</Link>
        </span> */}
      </div>
    </>
  );
};

export default LoginView;
