import Button from "components/button/createButtonWrap";
import SubHeader from "components/layout/header/subHeader";
import RadioEditor from "components/radio/radioEditor";
import RadioContent from "components/radio/radiocontent";
import LodGrdSelect from "components/select/room/lodGrdSelect";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import { lodDefaultInfoProps } from "interface/lod";
import { useLocation } from "react-router-dom";
import { lngLabel } from "util/option";

type IProps = {
  info: lodDefaultInfoProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleCreate(): void;
};

const LodDefaultModifyView = ({
  info,
  handleChange,
  handleSelect,
  handleCreate,
}: IProps) => {
  const { pathname, search, state } = useLocation();
  return (
    <div className="main-cnt">
      <SubHeader
        title="숙소기본정보 수정"
        back
        url={state?.url}
        state={state?.searchInfo}
      />

      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹명</th>
              <td>
                <LodGrpSelect
                  option={info?.lodGrpNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
              <th>숙소등급</th>
              <td>
                <LodGrdSelect
                  option={info?.lodGrpCd}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th>숙소명</th>
              <td colSpan={3}>
                <RadioContent
                  options={lngLabel}
                  name={"lodNo"}
                  onChange={(target) => handleChange("lodNo", target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>공지사항</th>
              <td colSpan={3}>
                <RadioEditor
                  options={lngLabel}
                  name={"noti"}
                  info={{}}
                  onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
            <tr>
              <th>운영정보</th>
              <td colSpan={3}>
                <RadioEditor
                  options={lngLabel}
                  name={"operate"}
                  info={{}}
                  onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
            <tr>
              <th>인원추가정보</th>
              <td colSpan={3}>
                <RadioEditor
                  options={lngLabel}
                  name={"people"}
                  info={{}}
                  onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
            <tr>
              <th>투숙고객혜택정보</th>
              <td colSpan={3}>
                <RadioEditor
                  options={lngLabel}
                  name={"guest"}
                  info={{}}
                  onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
            <tr>
              <th>부대시설정보</th>
              <td colSpan={3}>
                <RadioEditor
                  options={lngLabel}
                  name={"facilit"}
                  info={{}}
                  onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
            <tr>
              <th>조식정보</th>
              <td colSpan={3}>
                <RadioEditor
                  options={lngLabel}
                  name={"breakfast"}
                  info={{}}
                  onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
            <tr>
              <th>취사시설정보</th>
              <td colSpan={3}>
                <RadioEditor
                  options={lngLabel}
                  name={"cook"}
                  info={{}}
                  onChange={(name, value) => handleChange(name, value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Button
        txt="수정"
        isLoading={false}
        disabled={false}
        onClick={handleCreate}
      />
    </div>
  );
};

export default LodDefaultModifyView;
