import Button from "components/button/createButtonWrap";
import CheckButton from "components/checkbox/checkbtn";
import SubHeader from "components/layout/header/subHeader";
import Select from "components/select";
import LodChlSelect from "components/select/room/lodChlSelect";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNmSelect from "components/select/room/lodNmSelect";
import RmNmSelect from "components/select/room/rmNmSelect";
import RmOpSelect from "components/select/room/rmOpSelect";
import { optionProps } from "interface";
import { rmPrdCreateProps } from "interface/room";
import i18n from "locales/i18n";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { rmListSelector, rmOpListSelector } from "store/roomSlice";
import { inCom } from "util/common";
import { currenciesOptions, dayOptions, lngLabel } from "util/option";

type IProps = {
  info: rmPrdCreateProps;
  memType: "admin" | "manager";
  isLoading: boolean;
  handleChange(
    target: (EventTarget & HTMLInputElement) | HTMLTextAreaElement
  ): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleCreate(): void;
};

const RmPdCreateView = ({
  info,
  memType,
  isLoading,
  handleChange,
  handleSelect,
  handleCreate,
}: IProps) => {
  const lng = i18n.language;
  const txtRef = useRef<HTMLDivElement>(null);

  let rmList = useSelector(rmListSelector);
  let rmInfo = rmList && rmList.find((li) => li.rmNo === info.rmNo);
  const opList = useSelector(rmOpListSelector);

  const [opInfo, setOpInfo] = useState<any>(null);

  useEffect(() => {
    if (opList) {
      setOpInfo(
        opList &&
          info?.rmOpSeq &&
          opList.find((li) => {
            return li.rgSeq === Number(info?.rmOpSeq);
          })
      );
    }
    return () => {
      setOpInfo(null);
    };
  }, [opList, info?.rmOpSeq]);

  // const opInfo =
  //   opList &&
  //   info?.rmOpSeq &&
  //   opList.find((li) => {
  //     return li.rgSeq === Number(info?.rmOpSeq);
  //   });

  const isAble = Object.values(info).some((value) => {
    return value === "" || value === "NNNNNNN";
  });

  useEffect(() => {
    if (txtRef.current) {
      txtRef.current.innerHTML = opInfo?.rmOpDesc ?? "";
      // txtRef.current.innerHTML = "<p>객실 옵션 설명ㄹ</p>";
      // txtRef.current.style.height = txtRef.current.scrollHeight + "px";
    }
  }, [txtRef.current, opInfo]);

  return (
    <div className="main-cnt">
      <SubHeader title="객실상품 생성" dot />

      <div className="table-form">
        <table>
          <tbody>
            {memType === "admin" && (
              <tr>
                <th>숙소그룹명</th>
                <td>
                  <LodGrpSelect
                    option={info.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th data-dot>숙소명</th>
                <td>
                  <LodNmSelect
                    disabled={!info.lodGrpNo}
                    params={info.lodGrpNo}
                    option={info.lodNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
            )}
            {memType === "manager" && (
              <tr>
                <th>숙소그룹명</th>
                <td>
                  <input type="text" value={info?.lodGrpNm} readOnly />
                </td>
                <th>숙소명</th>
                <td>
                  <input type="text" value={info?.lodNm} readOnly />
                </td>
              </tr>
            )}
            <tr>
              <th data-dot>객실명</th>
              <td>
                <RmNmSelect
                  disabled={!info.lodNo}
                  params={info.lodNo}
                  option={info.rmNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th>객실타입</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmInfo?.rmTypNm ?? ""}
                  readOnly
                />
              </td>
              <th>객실전망</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmInfo?.rmOlkNm ?? ""}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th data-dot>객실상품채널</th>
              <td>
                <LodChlSelect
                  option={info.lodChlCd}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th data-dot>객실옵명</th>
              <td>
                <RmOpSelect
                  disabled={!info.rmNo}
                  params={info.rmNo}
                  option={opInfo?.rgSeq ?? ""}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th>객실옵션 설명</th>
              <td colSpan={3}>
                <div ref={txtRef} className="txt" />

                {/* <textarea
                  name=""
                  id=""
                  ref={txtRef}
                  disabled
                  defaultValue={opInfo?.rmOpDesc ?? ""}
                /> */}
              </td>
            </tr>
            {/* <tr>
              <th>객실옵션명</th>
              <td colSpan={3}>
                <RadioContent
                  options={lngLabel}
                  info={{ langCd: opInfo?.langCd, value: opInfo?.rmOpNm }}
                  inpDisabled={true}
                  rdoDisabled={false}
                  name={"rmPdNm"}
                  onChange={(target) => handleChange(target)}
                />
              </td>
            </tr>
            <tr>
              <th>객실옵션설명</th>
              <td colSpan={3}>
                <RadioContent
                  options={lngLabel}
                  name={"rmPdDesc"}
                  contStyle=""
                  inpDisabled={true}
                  rdoDisabled={false}
                  info={{ langCd: opInfo?.langCd, value: opInfo?.rmOpDesc }}
                  onChange={(target) => handleChange(target)}
                />
              </td>
            </tr> */}
            <tr>
              <th>객실상품 조식포함</th>
              <td>
                <input
                  type="text"
                  value={opInfo?.brkfInYn === "Y" ? "포함" : "미포함"}
                  readOnly
                />
              </td>
              <th>객실상품 레이트 체크아웃시간</th>
              <td>
                <input
                  type="text"
                  value={
                    opInfo?.latChkOutTi?.replace(
                      /\B(?=(\d{2})+(?!\d))/g,
                      ":"
                    ) ?? "11:00"
                  }
                  readOnly
                />
              </td>
            </tr>
            {/* <tr>
              <th>객실상품 특가할인</th>
              <td colSpan={1}>
                <div className="flex items-center justify-between gap-x-2 max-w-[25rem] pe-2">
                  <input
                    type="text"
                    className="unit"
                    name="dscPri"
                    maxLength={2}
                    // value={info.dscPri}
                    onChange={(e) => {
                      const isNum = !/[^0-9]/.test(e.currentTarget.value);
                      if (isNum || !e.currentTarget.value) {
                        handleChange && handleChange(e.currentTarget);
                      } else {
                        return;
                      }
                    }}
                  />
                  <span>%</span>
                </div>
              </td>
            </tr> */}

            <tr>
              <th data-dot>객실상품 시작일자</th>
              <td>
                <input
                  type="date"
                  id="date"
                  max="2100-12-31"
                  name="rmPdSDt"
                  value={info.rmPdSDt}
                  onChange={(e) => handleChange(e.currentTarget)}
                />
              </td>
              <th data-dot>객실상품 종료일자</th>
              <td>
                <input
                  type="date"
                  id="date"
                  max="2100-12-31"
                  name="rmPdEDt"
                  value={info.rmPdEDt}
                  onChange={(e) => handleChange(e.currentTarget)}
                />
              </td>
            </tr>
            <tr>
              <th data-dot>객실상품 적용요일</th>
              <td>
                <div className="flex max-w-[25rem]">
                  <CheckButton
                    id={"all"}
                    name="rmPdAplyDow"
                    checked={info.rmPdAplyDow === "YYYYYYY"}
                    label={"전체 선택"}
                    labelStles="w-max"
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                  {dayOptions.map((day, index) => (
                    <CheckButton
                      key={day.value}
                      id={day.value + ""}
                      name="rmPdAplyDow"
                      checked={info.rmPdAplyDow[index] === "Y"}
                      label={day[lng as keyof optionProps]}
                      onChange={(e) => handleChange(e.currentTarget)}
                    />
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <th data-dot>객실상품 생성수량</th>
              <td>
                <input
                  type="text"
                  name="rmPdCt"
                  value={
                    info.rmPdCt +
                    "".replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                  }
                  onChange={(e) => {
                    const value = e.currentTarget.value.replaceAll(",", "");
                    const isNum = !/[^0-9]/.test(value);
                    if (isNum || !e.currentTarget.value) {
                      handleChange(e.currentTarget);
                    } else {
                      return;
                    }
                  }}
                />
              </td>
              <th data-dot>객실상품 금액</th>
              <td>
                <div className="flex max-w-[25rem] gap-x-2">
                  <span className="max-w-[12.5rem]">
                    <Select
                      id={"currCd"}
                      name={"currCd"}
                      options={currenciesOptions}
                      disabled
                      onSelect={(target) => handleSelect(target)}
                    />
                  </span>
                  <input
                    type="text"
                    name="rmPdSellPri"
                    value={info.rmPdSellPri.replace(
                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                      ","
                    )}
                    onChange={(e) => {
                      const value = e.currentTarget.value.replaceAll(",", "");
                      const isNum = !/[^0-9]/.test(value);
                      if (isNum || !e.currentTarget.value) {
                        handleChange(e.currentTarget);
                      } else {
                        return;
                      }
                    }}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button
        isLoading={isLoading}
        disabled={isAble || isLoading}
        onClick={handleCreate}
      />
    </div>
  );
};

export default RmPdCreateView;
