import { useQuery } from "@tanstack/react-query";
import { lodGrpListApi } from "components/api/member";
import Select from "components/select";
import { IlodGrpList } from "interface/member";
import { useDispatch } from "react-redux";
import { lodGrdCodeOptions } from "util/option";

type IProps = {
  option: string;
  onSelect(target: EventTarget & HTMLLIElement): void;
};

const LodGrdSelect = ({ option, onSelect }: IProps) => {
  const dispatch = useDispatch();

  const { data } = useQuery(["lodGrpList"], lodGrpListApi);
  const options = data?.body.lodGrpList.map((li: IlodGrpList) => {
    const option = {
      value: li.lodGrpNo,
      kr: li.lodGrpNm,
      en: li.lodGrpNm,
    };
    return option;
  });

  return (
    <Select
      id="lodGrpCd"
      name="lodGrpCd"
      option={option}
      options={[...lodGrdCodeOptions, ...options]}
      onSelect={onSelect}
    />
  );
};

export default LodGrdSelect;
