import { useMutation } from "@tanstack/react-query";
import { termCreateApi } from "components/api/term";
import TermCreateView from "components/view/terms/create";
import { prvProps } from "interface/term";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

const TermCreatePage = () => {
  const dispatch = useDispatch();
  const langCd = i18n.language;
  const [prv, setPrv] = useState<prvProps>({
    prvCd: "",
    langCd: langCd,
    prvNm: "",
    prvDesc: "",
    essYn: "Y",
    aplyYn: "Y",
    aplySDt: "",
    aplyEDt: "",
    trmCn: "",
    verNm: "",
  });

  const handleChange = (name: string, value: string) => {
    setPrv({
      ...prv,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, lng, value } = target.dataset;
    if (name && value) {
      setPrv({
        ...prv,
        [name]: value,
      });
    }
  };

  const termMutation = useMutation(termCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "성공적으로 약관을 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    termMutation.mutate({
      ...prv,
      aplySDt: prv.aplySDt.replaceAll("-", ""),
      aplyEDt: prv.aplyEDt.replaceAll("-", ""),
    });
  };

  return (
    <TermCreateView
      isLoading={termMutation.isLoading}
      prv={prv}
      handleChange={handleChange}
      handleCreate={handleCreate}
      handleSelect={handleSelect}
    />
  );
};

export default TermCreatePage;
