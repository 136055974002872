import Editor from "components/editor";
import SubHeader from "components/layout/header/subHeader";
import LoadingButton from "components/button/loadingButton";
import Select from "components/select";
import { aplyYnOptions, essYnOption, langOptions } from "util/option";
import "./term.scss";
import { prvProps } from "interface/term";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsSearch } from "store/commonSlice";

type IProps = {
  isLoading: boolean;
  prv: prvProps;
  handleChange(name: string, value: string): void;
  handleCreate(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const TermCreateView = ({
  isLoading,
  prv,
  handleChange,
  handleCreate,
  handleSelect,
}: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAble = !!(
    prv.aplyEDt &&
    prv.aplySDt &&
    prv.aplyYn &&
    prv.essYn &&
    prv.langCd &&
    prv.prvCd &&
    prv.prvNm &&
    prv.trmCn
  );

  return (
    <>
      <div className="search-form">
        <SubHeader title="약관 등록" dot />

        <div className="table-form">
          <table>
            <tbody>
              <tr>
                <th data-dot>약관명</th>
                <td>
                  <input
                    type="text"
                    name="prvNm"
                    value={prv.prvNm}
                    onChange={(e) => handleChange("prvNm", e.target.value)}
                  />
                </td>
                <th>약관버전명</th>
                <td>
                  <input
                    type="text"
                    name="verNm"
                    value={prv.verNm}
                    onChange={(e) =>
                      handleChange("verNm", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th data-dot>약관코드</th>
                <td>
                  <input
                    type="text"
                    name="prvCd"
                    value={prv.prvCd}
                    onChange={(e) => handleChange("prvCd", e.target.value)}
                  />
                </td>
                <th data-dot>언어</th>
                <td>
                  <Select
                    id={"langCd"}
                    name={"langCd"}
                    option={prv.langCd}
                    options={langOptions}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th data-dot>필수여부</th>
                <td>
                  <Select
                    id={"essYn"}
                    name={"essYn"}
                    options={essYnOption}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th data-dot>적용여부</th>
                <td>
                  <Select
                    id={"aplyYn"}
                    name={"aplyYn"}
                    options={aplyYnOptions}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>

              <tr>
                <th data-dot>적용시작일</th>
                <td>
                  <input
                    type="date"
                    value={prv.aplySDt}
                    onChange={(e) =>
                      handleChange("aplySDt", e.currentTarget.value)
                    }
                  />
                </td>
                <th data-dot>적용종료일</th>
                <td>
                  <input
                    type="date"
                    value={prv.aplyEDt}
                    onChange={(e) =>
                      handleChange("aplyEDt", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th data-dot>약관설명</th>
                <td colSpan={3}>
                  <textarea
                    name="prvDesc"
                    id=""
                    value={prv.prvDesc}
                    onChange={(e) =>
                      handleChange("prvDesc", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <label htmlFor="" className="px-4 pb-4 text-base" data-dot>
            약관 내용
          </label>
          <Editor id="trmCn" content={""} handleChange={handleChange} />
        </div>
        <div className="btn-form-wrap">
          <LoadingButton
            txt="약관등록"
            disabled={!isAble}
            isLoading={isLoading}
            styles={"btn-search"}
            onClick={handleCreate}
          />
          <button
            className="btn-reset"
            onClick={() => {
              navigate(-1);
              dispatch(setIsSearch(true));
            }}
          >
            뒤로가기
          </button>
        </div>
      </div>
    </>
  );
};

export default TermCreateView;
