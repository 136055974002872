import { useMutation } from "@tanstack/react-query";
import { promCreateApi } from "components/api/promotion";
import PromCreateView from "components/view/promotion/create";
import { promProps } from "interface/promotion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

const PromCreatePage = () => {
  const dispatch = useDispatch();
  const [promInfo, setPromInfo] = useState<promProps>({
    promCd: "",
    influNm: "",
    influEml: "",
    influCnty: "대한민국",
    influLangCd: "",
    influMobCntyNo: "+82",
    influMobNo: "",
  });

  const handleChange = (name: string, value: string) => {
    setPromInfo({
      ...promInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;
    if (name === "mobCntyCd") {
      setPromInfo({
        ...promInfo,
        influMobCntyNo: value + "",
      });
    }
    if (name === "country") {
      setPromInfo({
        ...promInfo,
        influCnty: value + "",
      });
    }
  };
  const promMutation = useMutation(promCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    promMutation.mutate(promInfo);
  };

  return (
    <PromCreateView
      isLoading={false}
      promInfo={promInfo}
      handleSelect={handleSelect}
      handleChange={handleChange}
      handleCreate={handleCreate}
    />
  );
};

export default PromCreatePage;
