import { useEffect, useState } from "react";
import LodGrpCreateView from "components/view/lod/group/create";
import { useMutation } from "@tanstack/react-query";

const LodGrpCreatePage = () => {
  const [info, setInfo] = useState({
    mobCd: "+82",
    mobNo: "",
    email: "",
  });

  const [lodAddInfo, setLodAddInfo] = useState<any>({});

  const handleChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { value, lng } = target.dataset;
    setInfo({
      ...info,
      mobCd: value ?? "+82",
    });
  };

  const handleAddInfoChange = (name: string, value: string) => {
    const names = name.split("_");
    const langCd = names[1];

    setLodAddInfo({
      ...lodAddInfo,
      [langCd]: {
        ...lodAddInfo[langCd],
        langCd,
        [names[0]]: value,
      },
    });
  };

  // const lodGrpMutation = useMutation()

  const handleCreate = () => {};

  return (
    <LodGrpCreateView
      isLoading={false}
      info={info}
      handleChange={handleChange}
      handleCreate={handleCreate}
      handleSelect={handleSelect}
      handleAddInfoChange={handleAddInfoChange}
    />
  );
};

export default LodGrpCreatePage;
