import { useLocation } from "react-router-dom";

type IProps = {};

const Step2View = ({}: IProps) => {
  const { pathname, state } = useLocation();
  return (
    <div className="no-logged-wrap">
      <h2>
        {pathname.includes("admin") ? "관리자" : "매니저"} 회원 비밀번호
        인증메일 안내
      </h2>
      <h3>
        가입 승인 요청을 완료하시려면
        <br />
        24시간 이내에
        <br />
        <strong>{state?.emlId}</strong>
        으로 <br />
        전송된 메일을 통해 인증해 주시기 바랍니다.
        <br />
      </h3>
    </div>
  );
};

export default Step2View;
