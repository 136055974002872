import "./loading.scss";

type IProps = {
  type?: "small" | "middle" | "lds-wrap";
  height?: string;
  bg?: string;
  top?: string;
};

export default function Loading({ type = "lds-wrap", bg = "#fff" }: IProps) {
  return (
    <div className={`bg-${bg} ${type}`}>
      <div className={`lds-spinner`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
