import RmPdListView from "components/view/room/prd/list";
import { rmPdSearchProps } from "interface/room";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch, setMessage } from "store/commonSlice";
import { afterDay } from "util/common";

const RmPdListPage = () => {
  const { pathname, state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  let lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
      : "";

  const { sDt, eDt } = afterDay(14);

  const [searchInfo, setSearchinfo] = useState<rmPdSearchProps>({
    lodGrpNo: state?.lodGrpNo || lodGrpInfo?.lodGrpNo,
    lodGrpNm: state?.lodGrpNm || lodGrpInfo?.lodGrpNm,
    lodNo: state?.lodNo || localStorage.getItem("sltLodNo"),
    lodNm: state?.lodNm || lodGrpInfo?.lodNm,
    langCd: i18n.language,
    rmNo: state?.rmNo ?? "",
    lodGrdCd: state?.lodGrdCd ?? "",
    rmNm: state?.rmNm ?? "",
    rmTypCd: state?.rmTypCd ?? "",
    rmOlkCd: state?.rmOlkCd ?? "",
    maxPerCtMin: state?.maxPerCtMin ?? "",
    maxPerCtMax: state?.maxPerCtMax ?? "",
    rmPdNo: state?.rmPdNo ?? "",
    lodChlCd: state?.lodChlCd ?? "",
    rmOpNm: state?.rmOpNm ?? "",
    sellSDt: state?.sellSDt ?? "",
    sellEDt: state?.sellEDt ?? "",
    sellYn: state?.selllYn ?? "",
  });

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;

    setSearchinfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, lng, value } = target.dataset;
    if (name && value) {
      if (name === "lodGrpNo") {
        setSearchinfo({
          ...searchInfo,
          lodNo: "",
          rmNo: "",
          rmOlkCd: "",
          rmTypCd: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      if (name === "lodNo") {
        setSearchinfo({
          ...searchInfo,
          rmNo: "",
          rmOlkCd: "",
          rmTypCd: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      setSearchinfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleSearch = () => {
    // dispatch(
    //   setMessage({
    //     message: `기본 조회기간은 14일입니다. / 다른 일자로 조회하시려면 조회일자를 선택하여 주세요. / 조회하시겠습니까?`,
    //     isCancel: true,
    //     isConfirm: true,
    //     handleClick() {
    //       dispatch(setIsLoading(true));
    //       dispatch(setIsSearch(true));
    //       navigate(`?page=1`);
    //       dispatch(setMessage({ message: null }));
    //     },
    //   })
    // );
    if (!(searchInfo?.sellSDt && searchInfo.sellEDt)) {
      dispatch(
        setMessage({
          message: "조회시작일자와 조회종료일자를 선택하여 주세요",
          isConfirm: true,
          handleClick() {
            dispatch(setMessage({ message: null }));
          },
        })
      );
      return;
    }
    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));
    navigate(`?page=1`);
  };
  const handleReset = () => {
    setSearchinfo({
      lodGrpNo: lodGrpInfo?.lodGrpNo,
      lodGrpNm: lodGrpInfo?.lodGrpNm,
      lodNo: localStorage.getItem("sltLodNo") ?? "",
      lodNm: lodGrpInfo?.lodNm,
      lodGrdCd: "",
      langCd: i18n.language,
      rmNo: "",
      rmNm: "",
      rmTypCd: "",
      rmOlkCd: "",
      maxPerCtMin: "",
      maxPerCtMax: "",
      rmPdNo: "",
      lodChlCd: "",
      rmOpNm: "",
      sellSDt: "",
      sellEDt: "",
      sellYn: "Y",
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };

  return (
    <RmPdListView
      memType={memType}
      searchInfo={searchInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleSearch={handleSearch}
      handleReset={handleReset}
    />
  );
};

export default RmPdListPage;
