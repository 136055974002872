import { IadmList, searchMemberListProps } from "interface/member";
import RadioButton from "components/radio/radiobtn";
import { authOptions, memStatus } from "util/option";
import FormSearchBtn from "components/button/formSearch";
import PhoneSelect from "components/select/phone";
import Select from "components/select";
import SubHeader from "components/layout/header/subHeader";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMemNo } from "store/memberSlice";
import LodNoSelect from "components/select/room/lodNoSelect";
import AdminList from "components/tableList/member/adminList";
import ManagerList from "components/tableList/member/managerList";
import UserList from "components/tableList/member/userListHead";
import { useSelector } from "react-redux";
import { isLoadingSelector, memTypeSelector } from "store/commonSlice";
import { regNum } from "util/common";
import { useLocation } from "react-router-dom";

type IProps = {
  searchInfo: Partial<searchMemberListProps>;
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleSearch(): void;
  handleReset(): void;
};

const MemberListView = ({
  searchInfo,
  handleChange,
  handleSearch,
  handleSelect,
  handleReset,
}: IProps) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const memType = pathname.includes("admin")
    ? "admin"
    : pathname.includes("manager")
    ? "manager"
    : "";

  const memtype =
    searchInfo.type === "admin"
      ? "관리자"
      : searchInfo.type === "manager"
      ? "매니저"
      : "회원";

  useEffect(() => {
    dispatch(setMemNo(""));
  }, []);

  return (
    <>
      <div className="search-form">
        <SubHeader title={memtype} />

        <div className="table-form">
          <table>
            <tbody>
              <tr>
                <th>회원 타입 </th>
                <td colSpan={3}>
                  <div className="flex gap-4">
                    {memType === "admin" && (
                      <RadioButton
                        id="admin"
                        name="memType"
                        label="관리자"
                        // disable
                        checked={searchInfo.type === "admin"}
                        onChange={(target) => handleChange(target)}
                      />
                    )}

                    <RadioButton
                      id="manager"
                      name="memType"
                      label="매니저"
                      // disable
                      checked={searchInfo.type === "manager"}
                      onChange={(target) => handleChange(target)}
                    />
                    <RadioButton
                      id="ub"
                      name="memType"
                      label="유버"
                      disable
                      checked={false}
                      onChange={(target) => handleChange(target)}
                    />
                    <RadioButton
                      id="user"
                      name="memType"
                      label="사용자"
                      // disable
                      checked={searchInfo.type === "user"}
                      onChange={(target) => handleChange(target)}
                    />
                  </div>
                </td>
              </tr>

              {searchInfo.type === "admin" && (
                <tr>
                  <th>관리자 권한</th>
                  <td>
                    <Select
                      id={"authGrpCod"}
                      name={"authGrpCod"}
                      option={searchInfo.authGrpCod}
                      options={authOptions}
                      onSelect={handleSelect}
                    />
                  </td>
                  <th>{memtype} 회원상태</th>
                  <td colSpan={1}>
                    <Select
                      option={searchInfo.memStatus}
                      options={memStatus}
                      onSelect={handleSelect}
                      id={"memStatus"}
                      name={"memStatus"}
                    />
                  </td>
                </tr>
              )}

              {/* {searchInfo.type === "manager" && (
                <tr>
                  <th>숙소그룹명</th>

                  <td>
                    <input type="text" value={searchInfo?.lodGrpNm} readOnly />
                  </td>
                  <th>숙소명</th>
                  <td>
                    <LodNoSelect
                      disabled={!searchInfo?.lodGrpNo}
                      params={searchInfo?.lodGrpNo ?? ""}
                      option={searchInfo?.lodNo ?? ""}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                </tr>
              )} */}
              {searchInfo.type === "user" && (
                <tr>
                  <th>회원가입조회일자</th>
                  <td>
                    <input
                      type="date"
                      name="joiReqDtti"
                      value={searchInfo.joiReqDtti}
                      onChange={(e) => handleChange(e.target)}
                    />
                  </td>
                  <th>회원탈퇴조회일자</th>
                  <td>
                    <input
                      type="date"
                      name="soDtti"
                      value={searchInfo.soDtti}
                      onChange={(e) => handleChange(e.target)}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <th>{memtype} 국가번호</th>
                <td>
                  <PhoneSelect
                    handleSelect={handleSelect}
                    mobNo={""}
                    mobCd={searchInfo.mobCntyCd}
                  />
                </td>
                <th>{memtype} 전화번호</th>
                <td>
                  <input
                    type="text"
                    name="mobNo"
                    placeholder="숫자만 입력"
                    maxLength={11}
                    value={searchInfo?.mobNo}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      if (regNum.test(value)) {
                        handleChange(e.currentTarget);
                      } else {
                        return;
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>{memtype} 이메일</th>
                <td>
                  <input
                    type="text"
                    name="emlId"
                    value={searchInfo?.emlId}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
                {searchInfo.type === "manager" ||
                  (searchInfo.type === "user" && (
                    <>
                      <th>{memtype} 회원상태</th>
                      <td colSpan={1}>
                        <Select
                          option={searchInfo.memStatus}
                          options={memStatus}
                          onSelect={handleSelect}
                          id={"memStatus"}
                          name={"memStatus"}
                        />
                      </td>
                    </>
                  ))}
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            disabled={false}
            handleSearch={handleSearch}
            handleReset={handleReset}
          />
        </div>
      </div>

      {searchInfo?.type === "admin" && <AdminList searchInfo={searchInfo} />}
      {searchInfo?.type === "manager" && (
        <ManagerList searchInfo={searchInfo} />
      )}
      {searchInfo?.type === "user" && <UserList isLoading={isLoading} />}
    </>
  );
};

export default MemberListView;
