import { IPayInfo } from "interface/home";
import { Suspense, useState } from "react";
import { homePayInfoHead } from "util/option";
import { useMutation } from "@tanstack/react-query";
import { homeManagerDayPayInfoApi } from "components/api/home";
import { useDispatch } from "react-redux";
import { isLoadingSelector, setIsLoading, setMessage } from "store/commonSlice";
import { IoReload } from "react-icons/io5";
import NotData from "components/tableList/notData";
import { useSelector } from "react-redux";
import { dateFormat, inCom } from "util/common";

type IProps = {
  lodNo: string;
  payInfo: IPayInfo;
};

const PayInfo = ({ payInfo }: { payInfo: IPayInfo }) => {
  return (
    <tbody>
      <tr>
        <td>{dateFormat(payInfo?.resvDt ?? "")}</td>
        <td>{payInfo?.resvCt}</td>
        <td>{inCom(payInfo?.resvPayAmt ?? "")}</td>
        <td>{inCom(payInfo?.resvCnlAmt ?? "")}</td>
        <td>{inCom(payInfo?.resvCalAmt ?? "")}</td>
      </tr>
    </tbody>
  );
};

const PayInfoList = ({ payInfo, lodNo }: IProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  const now = new Date();

  const [info, setInfo] = useState<IPayInfo | null>(null);
  const payInfoMutation = useMutation(homeManagerDayPayInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setInfo(body?.dayPayInfo);
          dispatch(setIsLoading(false));
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });
  const handleReload = () => {
    dispatch(setIsLoading(true));
    const date = now.toISOString().split("T")[0].replaceAll("-", "");
    const params = {
      lodNo,
      date,
    };
    payInfoMutation.mutate(params);
  };

  return (
    <div className="relative pb-[1.25rem]">
      <div className="home title">
        <h4>오늘 예약 현황</h4>
        <button onClick={handleReload}>
          <IoReload
            size={28}
            style={{ cursor: "pointer", padding: 2 }}
            // className={payInfoMutation.isLoading ? "icon-rotate" : ""}
          />
        </button>
      </div>
      <table>
        <thead>
          <tr>
            {homePayInfoHead.map((bk) => (
              <th key={bk}>{bk}</th>
            ))}
          </tr>
        </thead>
        <Suspense>
          <PayInfo payInfo={info || payInfo} />
        </Suspense>
      </table>
      <NotData
        isLoading={isLoading}
        isNotData={payInfoMutation.data?.body?.dayPayInfo === null}
        isSuccess={payInfoMutation.isSuccess}
        type="middle"
        txt="Loading ..."
      />
    </div>
  );
};

export default PayInfoList;
