import RoomOptionView from "components/view/room/opt/list";
import { rmOptSearchProps } from "interface/room";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch, setMessage } from "store/commonSlice";

const RoomOptPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  let lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") ?? "")
      : {};

  const [searchInfo, setSearchInfo] = useState<rmOptSearchProps>({
    lodGrpNo: state?.lodGrpNo || lodGrpInfo?.lodGrpNo,
    lodGrpNm: state?.lodGrpNm || lodGrpInfo?.lodGrpNm,
    lodNo: (state?.lodNo || localStorage.getItem("sltLodNo")) ?? "",
    lodNm: state?.lodNm || lodGrpInfo?.lodNm,
    langCd: i18n.language,
    lodGrpCd: state?.lodGrpCd ?? "",
    rmNo: state?.rmNo ?? "",
    rmNm: state?.rmNm ?? "",
    rmOpNm: state?.rmOpNm ?? "",
    opRgSeq: state?.opRgSeq ?? "",
    brkfInYn: state?.brkfInYn ?? "",
    latChkOutTi: state?.latChkOutTi ?? "",
    uyn: state?.uyn ?? "",
  });

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;
    setSearchInfo({
      ...searchInfo,
      [name]: name === "latChkOutTi" ? value?.replace(":", "") : value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;
    if (name && value) {
      if (name === "lodGrpNo") {
        setSearchInfo({
          ...searchInfo,
          lodNo: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: lodGrpInfo?.lodGrpNo,
      lodGrpNm: lodGrpInfo?.lodGrpNm,
      lodNo: localStorage.getItem("sltLodNo") ?? "",
      langCd: i18n.language,
      lodGrpCd: "",
      lodNm: "",
      rmNo: "",
      rmNm: "",
      rmOpNm: "",
      opRgSeq: "",
      brkfInYn: "",
      latChkOutTi: "",
      uyn: "",
      pageNo: 1,
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };

  const handleSearch = () => {
    if (!searchInfo.lodGrpNo) {
      dispatch(
        setMessage({
          message: "숙소그룹번호를 선택하여 주세요",
          isConfirm: true,
          handleClick() {
            dispatch(setMessage({ message: null }));
          },
        })
      );
      return;
    }
    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));
    navigate(`?page=1`);
  };

  return (
    <RoomOptionView
      memType={memType}
      searchInfo={searchInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleReset={handleReset}
      handleSearch={handleSearch}
    />
  );
};

export default RoomOptPage;
