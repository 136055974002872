import FormSearchBtn from "components/button/formSearch";
import SubHeader from "components/layout/header/subHeader";
import Select from "components/select";
import LodChlSelect from "components/select/room/lodChlSelect";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNmSelect from "components/select/room/lodNmSelect";
import RmNoSelect from "components/select/room/rmNoSlect";
import RmOlkSelect from "components/select/room/rmOlkSelect";
import RmTypeSelect from "components/select/room/rmTypSelect";
import RoomBookList from "components/tableList/room/resv";
import { rmResvSearchProps } from "interface/room";
import { regNum } from "util/common";
import { dateTypOptions, lodGrpStatus } from "util/option";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmResvSearchProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
  handleSearch(): void;
};

const RoomBookListView = ({
  memType,
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
  handleSearch,
}: IProps) => {
  return (
    <>
      <div className="search-form">
        <SubHeader title="객실예약" dot />
        <div className="table-form">
          <table>
            <tbody>
              {memType === "admin" && (
                <tr>
                  <th>숙소그룹번호</th>
                  <td>
                    <LodGrpSelect
                      option={searchInfo.lodGrpNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소그룹명</th>
                  <td>
                    <input
                      type="text"
                      value={searchInfo.lodGrpNm}
                      onChange={(e) =>
                        handleChange("lodGrpNm", e.currentTarget.value)
                      }
                    />
                  </td>
                </tr>
              )}
              {memType === "admin" && (
                <tr>
                  <th>숙소번호</th>
                  <td>
                    <LodNmSelect
                      disabled={!searchInfo.lodGrpNo}
                      params={searchInfo.lodGrpNo}
                      option={searchInfo.lodNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소명</th>
                  <td>
                    <input
                      type="text"
                      value={searchInfo.lodNm}
                      onChange={(e) =>
                        handleChange("lodNm", e.currentTarget.value)
                      }
                    />
                  </td>
                </tr>
              )}
              {/* {memType === "admin" && (
                <tr>
                  <th>숙소등급</th>
                  <td>
                    <LodChlSelect
                      option={searchInfo?.lodChlSeq + ""}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                </tr>
              )} */}
              <tr>
                <th>객실번호</th>
                <td>
                  <RmNoSelect
                    disabled={!searchInfo.lodNo}
                    params={searchInfo.lodNo}
                    option={searchInfo.rmNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>객실명</th>
                <td>
                  <input
                    type="text"
                    value={searchInfo.rmNm}
                    onChange={(e) =>
                      handleChange("rmNm", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>객실타입</th>
                <td>
                  <RmTypeSelect
                    option={searchInfo.rmTypCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>객실전망</th>
                <td>
                  <RmOlkSelect
                    option={searchInfo.rmOlkCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th>객실 최대인원수 조회최소값</th>
                <td>
                  <input
                    type="text"
                    name="maxPerCtMin"
                    value={searchInfo.maxPerCtMin}
                    onChange={(e) => {
                      if (regNum.test(e.currentTarget.value)) {
                        handleChange("maxPerCtMin", e.currentTarget.value);
                      } else {
                        return;
                      }
                    }}
                  />
                </td>
                <th>객실 최대인원수 조회최대값</th>
                <td>
                  <input
                    type="text"
                    name="maxPerCtMax"
                    value={searchInfo.maxPerCtMax}
                    onChange={(e) => {
                      if (regNum.test(e.currentTarget.value)) {
                        handleChange("maxPerCtMax", e.currentTarget.value);
                      } else {
                        return;
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>객실상품번호</th>
                <td>
                  <input
                    type="text"
                    value={searchInfo?.rmPdNo}
                    onChange={(e) =>
                      handleChange("rmPdNo", e.currentTarget.value)
                    }
                  />
                  {/* <Select
                    id={"rmOpNo"}
                    name={"rmOpNo"}
                    options={lodGrpStatus}
                    onSelect={(target) => handleSelect(target)}
                  /> */}
                </td>
                <th>객실상품채널</th>
                <td>
                  <LodChlSelect
                    option={searchInfo.lodChlCd + ""}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>

              <tr>
                <th>객실 프로모션번호</th>
                <td>
                  <input
                    type="text"
                    value={searchInfo?.promCd}
                    onChange={(e) =>
                      handleChange("promCd", e.currentTarget.value)
                    }
                  />
                  {/* <Select
                    id={"promCd"}
                    name={"promCd"}
                    options={lodGrpStatus}
                    onSelect={(target) => handleSelect(target)}
                  /> */}
                </td>
                <th>객실 프로모션명</th>
                <td>
                  <input
                    type="text"
                    value={searchInfo?.promNm}
                    onChange={(e) =>
                      handleChange("promNM", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약번호</th>
                <td>
                  <input
                    type="text"
                    name="rmResvNo"
                    value={searchInfo?.rmResvNo}
                    onChange={(e) =>
                      handleChange("rmResvNo", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 회원이름</th>
                <td>
                  <input
                    type="text"
                    value={searchInfo?.memFstnm}
                    onChange={(e) =>
                      handleChange("memFstnm", e.currentTarget.value)
                    }
                  />
                </td>
                <th>객실예약 회원전화번호</th>
                <td>
                  <input
                    type="text"
                    value={searchInfo?.memMobNo}
                    onChange={(e) =>
                      handleChange("memMobNo", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 고객이름</th>
                <td>
                  <input
                    type="text"
                    value={searchInfo?.resvCstFstnm}
                    onChange={(e) =>
                      handleChange("resvCstFstnm", e.currentTarget.value)
                    }
                  />
                </td>
                <th>객실예약 고객전화번호</th>
                <td>
                  <input
                    type="text"
                    value={searchInfo?.resvCstMblNo}
                    onChange={(e) =>
                      handleChange("resvCstMblNo", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>조회기준일자</th>
                <td>
                  <Select
                    id={""}
                    name={"dateTyp"}
                    option={searchInfo?.dateTyp}
                    options={dateTypOptions.slice(0, 2)}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th data-dot>조회시작일자</th>
                <td>
                  <input
                    type="date"
                    id="date"
                    max="2100-12-31"
                    value={searchInfo?.sellSDt || searchInfo?.resvSDt}
                    onChange={(e) => handleChange("SDt", e.currentTarget.value)}
                  />
                </td>
                <th data-dot>조회종료일자</th>
                <td>
                  <input
                    type="date"
                    id="date"
                    max="2100-12-31"
                    value={searchInfo?.sellEDt || searchInfo?.resvEDt}
                    onChange={(e) => handleChange("EDt", e.currentTarget.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            handleSearch={handleSearch}
            handleReset={handleReset}
          />
        </div>
      </div>
      <RoomBookList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default RoomBookListView;
