import RoomCuttentView from "components/view/room/crr/list";
import { rmCrrProps } from "interface/room";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";

const RoomCurrentPage = () => {
  const { pathname } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  const dispatch = useDispatch();

  let lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
      : "";
  const [searchInfo, setSearchInfo] = useState<
    Omit<rmCrrProps, "sellSDt" | "sellEDt">
  >({
    lodGrpNo: lodGrpInfo?.lodGrpNo,
    lodGrpNm: lodGrpInfo?.lodGrpNm,
    lodNm: lodGrpInfo?.lodNm,
    lodGrpCd: "",
    lodNo: localStorage.getItem("sltLodNo") ?? "",
    sellYn: "A",
  });

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;

    if (name && value) {
      setSearchInfo({
        ...searchInfo,
        [name]: value,
      });
    }
  };

  const handleSearch = () => {
    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));
  };

  const handleReset = () => {
    setSearchInfo({
      ...searchInfo,
      lodGrpNo: "",
      lodGrpCd: "",
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };

  useEffect(() => {
    dispatch(setIsSearch(true));

    return () => {
      dispatch(setIsSearch(false));
    };
  }, []);

  return (
    <RoomCuttentView
      searchInfo={searchInfo}
      memType={memType}
      handleSelect={handleSelect}
      handleSearch={handleSearch}
      handleReset={handleReset}
    />
  );
};

export default RoomCurrentPage;
