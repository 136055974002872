import { useEffect, useState } from "react";
import { loginProps } from "interface/member";
import { regEmail, regId } from "util/common";
import { loginApi } from "components/api/member";
import { useNavigate } from "react-router-dom";
import { setMemType, setMessage } from "store/commonSlice";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import LoginView from "components/view/login";

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPw, setShowPw] = useState(false);
  const [info, setInfo] = useState<loginProps>({
    emlId: "",
    lgnPwd: "",
    memType: "manager",
  });

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value, id } = target;

    if (name === "memType") {
      setInfo({
        emlId: "",
        lgnPwd: "",
        [name]: id,
      });
      dispatch(setMemType(id));
      return;
    }
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleShowPW = () => {
    setShowPw(!showPw);
  };

  // const lodGrpListMutation = useMutation({
  //   mutationKey: ["lodGrpList"],
  //   mutationFn: lodGrpListApi,
  //   onSuccess(data) {
  //     if (data) {
  //       const {
  //         header: { code, message },
  //         body: { lodGrpList },
  //       } = data;

  //       if (code === 0) {
  //         localStorage.setItem("lodGrpInfo", JSON.stringify(lodGrpList[0]));
  //       }
  //       if (code === -1) {
  //       }
  //     }
  //   },
  // });

  const loginMutation = useMutation(loginApi, {
    onSuccess: (data) => {
      if (data) {
        const {
          header: { code, dtlCode, message },
          body,
        } = data;
        if (code === 0) {
          const login = {
            value: "isLogin",
            expiry: new Date().getTime() + 1000 * 60 * 30,
          };

          body?.manInf &&
            localStorage.setItem("manInf", JSON.stringify(body?.manInf));
          sessionStorage.setItem("login", JSON.stringify(login));
          dispatch(setMemType(info.memType));
          navigate(`/${info.memType}`);
        }

        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleLogin = async () => {
    if (!regEmail.test(info.emlId)) {
      dispatch(
        setMessage({
          title: "오류",
          message: "정확한 형식의 아이디를 입력하세요",
        })
      );
      return;
    }

    if (!regId.test(info.lgnPwd)) {
      dispatch(
        setMessage({
          title: "오류",
          message: "정확한 형식의 비밀번호를 입력하세요",
        })
      );
      return;
    }

    loginMutation.mutate(info);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (info.emlId && info.lgnPwd && e.key === "Enter") {
      handleLogin();
    }
    return;
  };

  useEffect(() => {
    sessionStorage.removeItem("login");
    localStorage.removeItem("lodGrpInfo");
    localStorage.removeItem("manInf");
    localStorage.removeItem("sltLodNo");
  }, []);

  return (
    <LoginView
      info={info}
      showPw={showPw}
      isLoading={loginMutation.isLoading}
      handleChange={handleChange}
      handleKeyDown={handleKeyDown}
      handleShowPW={handleShowPW}
      handleLogin={handleLogin}
    />
  );
};

export default LoginPage;
