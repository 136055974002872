type IProps = {
  disabled?: boolean;
  onClick?(): void;
  isLoading: boolean;
  txt?: string;
  styles?: string;
};

const LoadingButton = ({
  disabled,
  onClick,
  isLoading = true,
  txt,
  styles = "btn-red",
}: IProps) => {
  return (
    <button className={styles} disabled={disabled} onClick={onClick}>
      {isLoading ? <i className="fa fa-spinner fa-pulse fa-fw"></i> : txt}
    </button>
  );
};

export default LoadingButton;
