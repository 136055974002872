import SubHeader from "components/layout/header/subHeader";
import { IrmResvInfo } from "interface/room";
import i18n from "locales/i18n";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { dateFormat, getDay, inCom } from "util/common";

type IProps = {
  rmResvCnlDtl: IrmResvInfo;
};

const RoomBookCancelInfoView = ({ rmResvCnlDtl }: IProps) => {
  const { state } = useLocation();
  const txtRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (txtRef.current) {
      txtRef.current.innerHTML = rmResvCnlDtl?.rmOpDesc ?? "";
    }
  }, []);

  const perCt =
    rmResvCnlDtl?.adltPerCt || rmResvCnlDtl?.chldPerCt
      ? rmResvCnlDtl?.adltPerCt + rmResvCnlDtl?.chldPerCt
      : "0";

  const resvNm =
    rmResvCnlDtl?.memSurnm && rmResvCnlDtl?.memFstnm
      ? rmResvCnlDtl?.memSurnm + rmResvCnlDtl?.memFstnm
      : "";

  const cstNm =
    rmResvCnlDtl?.resvCstSurnm && rmResvCnlDtl?.resvCstFstnm
      ? rmResvCnlDtl?.resvCstSurnm + rmResvCnlDtl?.resvCstFstnm
      : "";

  const payAmt = inCom(rmResvCnlDtl?.payAmt ?? "") ?? "";
  const refAmt = inCom(rmResvCnlDtl?.refAmt ?? "") ?? "";

  return (
    <>
      <div className="main-cnt">
        <SubHeader
          title="객실예약취소 상세"
          close
          url={state?.url}
          state={state?.searchInfo}
        />
        <div className="table-form">
          <table>
            <tbody>
              <tr>
                <th>숙소그룹명</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.lodGrpNm ?? ""}
                    readOnly
                  />
                </td>
                <th>숙소명</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.lodNm ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실명</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.rmNm ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실타입</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.rmTypNm ?? ""}
                    readOnly
                  />
                </td>
                <th>객실전망</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.rmOlkNm ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실상품번호</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.rmResvNo ?? ""}
                    readOnly
                  />
                </td>
                <th>객실상품채널</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.lodChlNm ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실상품명</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.rmOpNm ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실상품설명</th>
                <td colSpan={3}>
                  <textarea name="" readOnly ref={txtRef} />
                </td>
              </tr>
              <tr>
                <th>객실상품 조식포함</th>
                <td>
                  <input
                    type="text"
                    defaultValue={
                      rmResvCnlDtl?.brkfInYn === "Y" ? "포함" : "미포함"
                    }
                    readOnly
                  />
                </td>
                <th>객실상품 레이트 체크아웃시간</th>
                <td>
                  <input
                    type="text"
                    defaultValue={
                      rmResvCnlDtl?.latChkOutTi?.replace(
                        /\B(?=(\d{2})+(?!\d))/g,
                        ":"
                      ) ?? ""
                    }
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실상품 특가할인</th>
                <td>
                  <input type="text" defaultValue={""} readOnly />
                </td>
              </tr>
              <tr>
                <th>객실상품 일자</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.sellDt ?? ""}
                    readOnly
                  />
                </td>
                <th>객실상품 요일 </th>
                <td>
                  <input
                    type="text"
                    defaultValue={getDay(
                      rmResvCnlDtl?.sellDt ?? "",
                      i18n.language
                    )}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실상품 금액</th>
                <td>
                  <input
                    type="text"
                    defaultValue={inCom(rmResvCnlDtl?.rmSellPri ?? "")}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>프로모션번호</th>
                <td>
                  <input type="text" defaultValue={""} readOnly />
                </td>
                <th>프로모션명</th>
                <td>
                  <input type="text" defaultValue={""} readOnly />
                </td>
              </tr>
              <tr>
                <th>프로모션 금액 </th>
                <td>
                  <input type="text" defaultValue={""} readOnly />
                </td>
              </tr>
              <tr>
                <th>객실예약 번호</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.rmResvNo ?? ""}
                    readOnly
                  />
                </td>
                {/* <th>객실예약 순번번호</th>
                <td>
                  <input type="text" defaultValue={""} readOnly />
                </td> */}
              </tr>
              <tr>
                <th>객실예약 시작일자</th>
                <td>
                  <input
                    type="text"
                    defaultValue={dateFormat(
                      rmResvCnlDtl?.resvSDt?.slice(0, 8) ?? ""
                    )}
                    readOnly
                  />
                </td>
                <th>객실예약 종료일자</th>
                <td>
                  <input
                    type="text"
                    defaultValue={dateFormat(
                      rmResvCnlDtl?.resvEDt?.slice(0, 8) ?? ""
                    )}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 일수</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.resvDayCt ?? ""}
                    readOnly
                  />
                </td>
                <th>객실예약 수량</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.resvRmCt ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 전체인원수</th>
                <td>
                  <input type="text" defaultValue={perCt} readOnly />
                </td>
                <th>객실예약 성인인원수</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.adltPerCt ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 아동인원수</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.chldPerCt ?? ""}
                    readOnly
                  />
                </td>
                <th>객실예약 무료인원수</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.basPerCt}
                    readOnly
                  />
                </td>
              </tr>

              {/* <tr>
                <th>객실예약이메일 발송일시</th>
                <td>
                  <input type="text" readOnly />
                </td>
                <th>객실예약팩스 발송일시</th>
                <td>
                  <input type="text" readOnly />
                </td>
              </tr> */}
              <tr>
                <th>객실예약 회원이름</th>
                <td>
                  <input type="text" defaultValue={resvNm} readOnly />
                </td>
                <th>객실예약 회원전화번호</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.resvCstMblNo ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 고객이름</th>
                <td>
                  <input type="text" defaultValue={cstNm} readOnly />
                </td>
                <th>객실예약 고객전화번호</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.resvCstMblNo ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 고객이메일</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvCnlDtl?.resvCstEml ?? ""}
                    readOnly
                  />
                </td>
                {/* <th>객실예약 취소</th>
                <td>
                  <button>예약취소</button>
                </td> */}
              </tr>
              <tr>
                <th>정산금액</th>
                <td>
                  <input type="text" defaultValue={payAmt} readOnly />
                </td>
              </tr>
              <tr>
                <th>객실예약 요청사항</th>
                <td colSpan={3}>
                  <textarea
                    name=""
                    id=""
                    defaultValue={rmResvCnlDtl?.reqCn ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 결제취소정보</th>
                <td colSpan={3}>
                  <fieldset disabled={true} className="pay-inf">
                    <div>
                      <span>결제취소일시</span>
                      <span>
                        {dateFormat(rmResvCnlDtl?.cnlDtti?.slice(0, 8))}{" "}
                        {rmResvCnlDtl?.cnlDtti?.slice(8, 10)}:
                        {rmResvCnlDtl?.cnlDtti?.slice(10, 12)}:
                        {rmResvCnlDtl?.cnlDtti?.slice(12, 14)}
                      </span>
                    </div>
                    <div>
                      <span>결제취소등록일련번호</span>
                      <span>{rmResvCnlDtl?.cnlRgSeq}</span>
                    </div>
                    <div>
                      <span>환불유형</span>
                      <span>
                        {rmResvCnlDtl?.refTyp == "T"
                          ? "전체취소"
                          : rmResvCnlDtl?.refTyp == "P"
                          ? "부분취소"
                          : ""}
                      </span>
                    </div>
                    <div>
                      <span>결제금액</span>
                      <span>{payAmt}</span>
                    </div>
                    <div>
                      <span>환불금액</span>
                      <span>{refAmt}</span>
                    </div>
                    <div>
                      <span>잔여금액</span>
                      <span>{inCom(rmResvCnlDtl?.balAmt ?? "") ?? ""}</span>
                    </div>
                    <div>
                      <span>통화코드</span>
                      <span>{rmResvCnlDtl?.currCd}</span>
                    </div>
                  </fieldset>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RoomBookCancelInfoView;
