import { createPortal } from "react-dom";
import "../modal.scss";
import { ReactNode, useEffect } from "react";

type IProps = {
  children: ReactNode;
  styles?: string;
  isOpen: boolean;
  isOutClose?: boolean;
  handleCloseModal(): void;
};

export default function DefaultModal({
  children,
  handleCloseModal,
  styles = "max-w-[30rem] w-full",
  isOpen,
  isOutClose = true
}: IProps) {
  useEffect(() => {
    // const pageY = window.pageXOffset;

    if (isOpen) {
      document.body.style.overflowY = "hidden";

      // document.body.style.pointerEvents = "none";
      // document.body.style.overflowY = "hidden";
      // document.body.style.position = "fixed";
      // document.body.style.width = "100%";
      // document.body.style.height = "100%";
      // document.body.style.top = `-${pageY}`;
      // document.body.style.bottom = "0";
      // document.body.style.left = "0";
      // document.body.style.right = "0";
    }

    return () => {
      document.body.style.removeProperty("overflow");
      // document.body.style.removeProperty("pointer-events");
      // document.body.style.removeProperty("position");
      // document.body.style.removeProperty("width");
      // document.body.style.removeProperty("height");

      // document.body.style.removeProperty("top");
      // document.body.style.removeProperty("bottom");
      // document.body.style.removeProperty("left");
      // document.body.style.removeProperty("right");

      // window.scrollTo(0, pageY);
    };
  }, [isOpen]);
  return (
    <>
      {isOpen &&
        createPortal(
          <section className={"modal-outer"} onClick={() => {
            if(isOutClose) {
              handleCloseModal()
              return 
            }
          }}>
            <div
              className={`${styles} modal-wrap`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {children}
            </div>
          </section>,
          document.body
        )}
    </>
  );
}
