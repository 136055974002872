// import Home from "./home.json";
// import Lod from "./lodgment.json";
// import Room from "./room.json";
// import Setting from "./setting.json";
// import Member from "./member.json";
// import Comm from "./comm.json";
// import Alert from "./alert.json";
import Layout from "./layout.json";

const en = {
  // ...Home,
  // ...Lod,
  // ...Room,
  // ...Setting,
  // ...Member,
  // ...Alert,
  // ...Comm,
  ...Layout,
};

export default en;
