import { useMutation, useQuery } from "@tanstack/react-query";
import { lodListApi } from "components/api/comn";
import Select from "components/select";
import { optionProps } from "interface";
import { useEffect, useState } from "react";

type IProps = {
  option: string;
  params: string;
  disabled?: boolean;
  onSelect(target: EventTarget & HTMLLIElement): void;
};

export interface lodList {
  lodGrpNm: string;
  lodGrpNo: string;
  lodNm: string;
  lodNo: string;
}

const LodNoSelect = ({ params, disabled, option, onSelect }: IProps) => {
  const [options, setOptions] = useState<optionProps[]>([
    { value: "A", kr: "선택하세요", en: "select" },
  ]);

  const listMutation = useMutation({
    mutationKey: ["lodList"],
    mutationFn: lodListApi,
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const options = body.lodList.map((li: lodList) => {
            const option = {
              value: li.lodNo,
              kr: li.lodNo,
              en: li.lodNo,
            };
            return option;
          });

          setOptions([...options]);
        }
        if (code === -1) {
          setOptions([]);
        }
      }
    },
  });

  useEffect(() => {
    if (!!params) {
      listMutation.mutate(params);
    }
    if (!params) {
      setOptions([{ value: "A", kr: "선택하세요", en: "select" }]);
    }
  }, [params]);

  return (
    <Select
      wrapStyle="flex-1 w-full h-[2.75rem]"
      id="lodNo"
      name="lodNo"
      disabled={disabled}
      option={option}
      options={[{ value: "A", kr: "선택하세요", en: "select" }, ...options]}
      onSelect={onSelect}
    />
  );
};

export default LodNoSelect;
