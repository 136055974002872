import FormSearchBtn from "components/button/formSearch";
import SubHeader from "components/layout/header/subHeader";
import Select from "components/select";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import RoomOptionList from "components/tableList/room/option";
import { rmOptSearchProps } from "interface/room";
import {
  brkfInYnOptions,
  lastTimeOptions,
  lodGrdCodeOptions,
  lodGrpStatus,
} from "util/option";
import RmNoSelect from "components/select/room/rmNoSlect";
import RmOpSelect from "components/select/room/rmOpSelect";
import LodNmSelect from "components/select/room/lodNmSelect";
import LodNoSelect from "components/select/room/lodNoSelect";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmOptSearchProps;
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
  handleSearch(): void;
};

const RoomOptView = ({
  memType,
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
  handleSearch,
}: IProps) => {
  return (
    <>
      <div className="search-form">
        <SubHeader title="객실옵션" add />

        <div className="table-form">
          <table>
            <tbody>
              {memType === "admin" && (
                <tr>
                  <th>숙소그룹번호</th>
                  <td>
                    <LodGrpSelect
                      option={searchInfo.lodGrpNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소그룹명</th>
                  <td>
                    <input
                      type="text"
                      name="lodGrpNm"
                      value={searchInfo?.lodGrpNm}
                      onChange={(e) => handleChange(e.currentTarget)}
                    />
                  </td>
                </tr>
              )}
              {memType === "admin" && (
                <tr>
                  <th>숙소번호</th>
                  <td>
                    <LodNoSelect
                      disabled={!searchInfo.lodGrpNo}
                      params={searchInfo.lodGrpNo}
                      option={searchInfo.lodNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소명</th>
                  <td>
                    <input
                      type="text"
                      name="lodNm"
                      value={searchInfo?.lodNm}
                      onChange={(e) => handleChange(e.currentTarget)}
                    />
                  </td>
                </tr>
              )}
              {/* {memType === "admin" && (
                <tr>
                  <th>숙소등급</th>
                  <td>
                    <Select
                      id="lodGrpCd"
                      name="lodGrpCd"
                      options={lodGrdCodeOptions}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                </tr>
              )} */}

              {/* <tr>
                <th>객실옵션번호</th>
                <td>
                  <input
                    type="text"
                    name="opRgSeq"
                    value={searchInfo?.opRgSeq}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
              </tr> */}
              <tr>
                <th>객실옵션 조식포함</th>
                <td>
                  <Select
                    id="brkfInYn"
                    name="brkfInYn"
                    option={searchInfo.brkfInYn}
                    options={brkfInYnOptions}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>객실옵션 레이트 체크아웃시간</th>
                <td>
                  <Select
                    id="latChkOutTi"
                    name="latChkOutTi"
                    option={searchInfo.latChkOutTi}
                    options={lastTimeOptions}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th>상태</th>
                <td>
                  <Select
                    id="uyn"
                    name="uyn"
                    option={searchInfo.uyn}
                    options={lodGrpStatus}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            disabled={false}
            handleReset={handleReset}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <RoomOptionList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default RoomOptView;
