export type headType = {
  kr: string;
  en: string;
};

export const HomeBKHistory = [
  "월",
  "예약건수",
  "결제금액",
  "취소금액",
  "정산금액",
];

export const homePayInfoHead = [
  "예약날짜",
  "예약건수",
  "결제금액",
  "취소금액",
  "정산금액",
];

export const homeChkInfoHead = [
  "고객명",
  "고객연락처",
  "상품명",
  "체크인 / 체크아웃",
  "숙박일수",
];

export const adminListHead = [
  "번호",
  "회원번호",
  "권한",
  "영문이름",
  "영문성",
  "이메일",
  "전화번호",
  "상태",
];

export const managerListHead = [
  "번호",
  "회원번호",
  "숙소명",
  "영문이름",
  "영문성",
  "이메일",
  "전화번호",
  "상태",
];

export const userListHead = [
  "번호",
  "회원번호",
  "등급",
  "영문이름",
  "영문성",
  "이메일",
  "전화번호",
  "상태",
];
export const lodGrpListHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소그룹 주소국가",
  "숙소그룹 주소1",
  "숙소그룹주소2",
  "상태",
];

export const lodListHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소번호",
  "숙소등급",
  "숙소명",
  "숙소 주소국가",
  "숙소 주소1",
  "숙소 주소2",
  "숙소 판매금액구분",
  "숙소 계약시작일자",
  "숙소 계약종료일자",
  "상태",
];

export const lodDefaultListHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소번호",
  "숙소등급",
  "숙소명",
  "상태",
];

export const lodProvideListHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소번호",
  "숙소등급",
  "숙소명",
  "상태",
];

export const rmListHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소번호",
  // "숙소등급",
  "숙소명",
  "객실 번호",
  "객실 명",
  "객실 타입",
  "객실 전망",
  "객실 최대인원수",
  "상태",
];

export const roomOpListHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소번호",
  // "숙소등급",
  "숙소명",
  "객실번호",
  "객실옵션번호",
  "객실옵션명",
  "객실옵션 조식포함",
  "객실옵션 레이트 체크아웃시간",
  "상태",
];

export const manRmPdListHead = [
  "번호",
  // "숙소등급",
  "객실번호",
  "객실명",
  "객실상품번호",
  "객실상품채널",
  "객실상품명",
  "객실상품 일자",
  "객실상품 요일",
  "객실상품 생성수량",
  "객실 상품 판매금액",
  "상태",
];

export const admRmPdListHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소번호",
  // "숙소등급",
  "숙소명",
  "객실번호",
  "객실명",
  "객실상품번호",
  "객실타입",
  "객실전망",
  "객실상품채널",
  "객실상품명",
  "객실상품 조식포함",
  "객실상품 레이트 체크아웃시간",
  "객실상품 특가할인",
  "객실상품 일자",
  "객실상품 요일",
  "객실상품 생성수량",
  "객실상품 판매수량",
  "객실상품 판매금액",
  "상태",
];

export const roomHstrHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소번호",
  "숙소명",
  "객실번호",
  "객실명",
  "객실 옵션명",
  "객실상품 일자",
  "객실상품 요일",
  "객실상품 생성수량",
  "객실상품 판매중수량",
  "객실상품 판매중지수량",
  "객실상품 예약수량",
  "객실상품 판매금액",
  "접속자 번호",
  "메모",
  "이력상태 유형",
  "상태",
];

export const rmBkListHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소번호",
  "숙소명",
  // "숙소등급",
  "객실번호",
  "객실명",
  "객실타입",
  "객실전망",
  "객실 최대인원수",
  "객실상품번호",
  "객실상품 채널",
  "객실상품명",
  "객실상품일자",
  "객실상품 예약일자",
  "객실예약 번호",
  "객실예약 회원이름",
  "객실예약 회원전호번호",
  "객실예약 고객이름",
  "객실예약 고객전화번호",
  "객실상품 판매금액",
];

export const rmBkCnlListHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소번호",
  "숙소명",
  // "숙소등급",
  "객실번호",
  "객실명",
  "객실타입",
  "객실전망",
  // "객실최대인원수",
  "객실상품번호",
  "객실상품 채널",
  "객실상품명",
  "객실상품일자",
  "객실상품 예약일자 / 객실상품예약취소일자",
  "객실예약 번호",
  "객실예약 회원이름",
  "객실예약 회원전호번호",
  "객실예약 고객이름",
  "객실예약 고객전화번호",
  "객실상품금액 / 결제취소금액",
];

export const lodGrpHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소그룹 주소국가",
  "숙소그룹 주소1",
  "숙소그룹 주소2",
  "상태",
];

export const lodHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소번호",
  "숙소등급",
  "숙소명",
  "숙소 주소국가",
  "숙소 주소1",
  "숙소 주소2",
  "숙소 판매금액구분",
  "숙소 계약시작일자",
  "숙소 계약종료일자",
  "상태",
];

export const lodDefaultHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소번호",
  "숙소명",
  "숙소등급",
  "상태",
];

export const lodChlHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소번호",
  "숙소명",
  "숙소등급",
  "숙소채널명",
  "적용 시작일자",
  "적용 종료일자",
  "등록일시",
  "상태",
];

export const lodPrdHead = ["번호", "숙소그룹명", "숙소명", "숙소등급", "상태"];

export const lodRfdHead = [
  "번호",
  "숙소그룹번호",
  "숙소그룹명",
  "숙소번호",
  "숙소명",
  "숙소등급",
  "숙소활불수수료종류",
  // "기본수수료종료",
  // "적용요일",
  "등록일시",
  "상태",
];

export const promnHead = [
  "번호",
  "프로모션코드",
  "인플루언서명",
  "인플루언서 이메일",
  "인플루언서 언어코드",
  "최초생성일",
];

export const calcPromHead = [
  "번호",
  "프로모션코드",
  "객실예약번호",
  "예약취소",
  "최초생성일시",
  "최종수정일시",
];

export const termOptionHead = [
  "번호",
  "약관명",
  "약관코드",
  "약관설명",
  "버전",
  "버전명",
  "적용시작일",
  "적용종료일",
  "필수여부",
  "적용여부",
];

// export const adminListHead = [
//   { kr: "번호", en: "" },
//   { kr: "회원번호", en: "" },
//   { kr: "권한", en: "" },
//   { kr: "영문 이름", en: "" },
//   { kr: "영문 성", en: "" },
//   { kr: "이메일", en: "" },
//   { kr: "전화번호", en: "" },
//   { kr: "상태", en: "" },
// ];

// export const managerListHead = [
//   { kr: "번호", en: "" },
//   { kr: "회원번호", en: "" },
//   { kr: "숙소명", en: "" },
//   { kr: "영문 이름", en: "" },
//   { kr: "영문 성", en: "" },
//   { kr: "이메일", en: "" },
//   { kr: "전화번호", en: "" },
//   { kr: "상태", en: "" },
// ];

// export const userListHead = [
//   { kr: "번호", en: "" },
//   { kr: "회원번호", en: "" },
//   { kr: "등급", en: "" },
//   { kr: "영문 이름", en: "" },
//   { kr: "영문 성", en: "" },
//   { kr: "이메일", en: "" },
//   { kr: "전화번호", en: "" },
//   { kr: "상태", en: "" },
// ];

export const dateTypOptions = [
  {
    kr: "객실상품일자",
    en: "",
    value: "rmPdDt",
  },
  {
    kr: "객실상품예약일자",
    en: "",
    value: "rmResvDt",
  },
  {
    kr: "객실상품예약취소일자",
    en: "",
    value: "rmCnlResvDt",
  },
];

export const authOptions = [
  {
    kr: "선택하세요",
    value: "all",
    en: "Choose",
  },

  {
    kr: "소유자",
    value: "OTAOWENER01",
    en: "Owner",
  },
  {
    kr: "관리자",
    value: "OTAMAN01",
    en: "Manager",
  },
  {
    kr: "개발자",
    value: "OTADEV01",
    en: "Developer",
  },
  {
    kr: "리포터",
    value: "OTATRP01",
    en: "Reporter",
  },
  {
    kr: "시스템관리자",
    value: "SYSADMIN",
    en: "System administrator",
  },
];

export const lngLabel = [
  {
    id: "kr",
    label: "KOR",
  },
  {
    id: "us",
    label: "ENG",
  },
  // {
  //   id: "ja",
  //   label: "JP",
  // },
];

export const userTypOptions = [
  {
    kr: "관리자",
    value: "admin",
    en: "Admin",
  },
  {
    kr: "매니저",
    value: "manager",
    en: "Manager",
  },
];
export const memStatus = [
  {
    kr: "전체",
    value: "A",
    en: "All",
  },
  { kr: "정상", value: "D", en: "Normal" },
  { kr: "신청", value: "R", en: "Applacation" },
  { kr: "탈퇴", value: "S", en: "Drop" },
];

export const memSts = [
  { kr: "정상", value: "Y", en: "Normal" },
  { kr: "신청", value: "N", en: "Applacation" },
];

export const freePerCtOptios = [
  { kr: "1", value: "1", en: "1" },
  { kr: "2", value: "2", en: "2" },
  { kr: "3", value: "3", en: "3" },
  { kr: "4", value: "4", en: "4" },
  { kr: "5", value: "5", en: "5" },
];

export const lodGrpStatus = [
  {
    kr: "전체",
    value: "A",
    en: "All",
  },
  {
    kr: "사용",
    value: "Y",
    en: "USE",
  },
  {
    kr: "미사용",
    value: "N",
    en: "NOT",
  },
];

export const lodRfdStatus = [
  {
    kr: "숙소환불수수료율기본",
    value: "",
    en: "",
  },
  {
    kr: "숙소환불수수료율기본메시지",
    value: "",
    en: "",
  },
  {
    kr: "숙소환불수수료율옵션",
    value: "",
    en: "",
  },
  {
    kr: "수소환불수수료율옵션메시지",
    value: "",
    en: "",
  },
];

export const rmPrdStatus = [
  {
    kr: "전체",
    value: "A",
    en: "All",
  },
  {
    kr: "판매",
    value: "Y",
    en: "SELL",
  },
  {
    kr: "중지",
    value: "N",
    en: "STOP",
  },
  // { kr: "마감", value: "D", en: "END" },
];

export const rmResvCnlStateus = [
  {
    kr: "전체",
    value: "A",
    en: "All",
  },
  {
    kr: "결제무료취소",
    value: "F",
    en: "Free",
  },
  {
    kr: "결제취소수수료",
    value: "C",
    en: "CancelFee",
  },
];

export const uYnOptions = [
  {
    kr: "사용",
    value: "Y",
    en: "USE",
  },
  {
    kr: "미사용",
    value: "N",
    en: "NOT",
  },
];

export const aplyYnOptions = [
  {
    kr: "적용",
    value: "Y",
    en: "USE",
  },
  {
    kr: "미 적용",
    value: "N",
    en: "NOT",
  },
];

export const essYnOption = [
  {
    kr: "필수",
    value: "Y",
    en: "USE",
  },
  {
    kr: "선택",
    value: "N",
    en: "NOT",
  },
];

export const sllPayOption = [
  {
    kr: "판매가",
    value: "sell",
    en: "sellPay",
  },
  {
    kr: "",
    value: "",
    en: "",
  },
];

export const lodGradeOptions = [
  {
    kr: "HOTEL",
    value: "HOTEL",
    en: "HOTEL",
  },
];

export const brkfInYnOptions = [
  {
    kr: "전체",
    value: "A",
    en: "Select",
  },
  {
    kr: "미포함",
    value: "N",
    en: "Not",
  },
  {
    kr: "포함",
    value: "Y",
    en: "Include",
  },
];

export const lodGrdCodeOptions = [
  {
    kr: "선택하세요",
    value: "A",
    en: "Select",
  },
  {
    kr: "S1",
    value: "S1",
    en: "S1",
  },
  {
    kr: "S2",
    value: "S2",
    en: "S2",
  },
  {
    kr: "S3",
    value: "S3",
    en: "S3",
  },
  {
    kr: "S4",
    value: "S4",
    en: "S4",
  },

  {
    kr: "S5",
    value: "S5",
    en: "S5",
  },
];

export const lodSellAmdvcOptions = [
  {
    kr: "선택하세요",
    value: "A",
    en: "Select",
  },
  {
    kr: "판매가",
    value: "01",
    en: "Sale price",
  },
  {
    kr: "입금가",
    value: "02",
    en: "Deposit price",
  },
];

export const rmSellYnOption = [
  {
    kr: "선택하세요",
    value: "A",
    en: "Select",
  },
  {
    kr: "판매",
    value: "Y",
    en: "Sell",
  },
  {
    kr: "미 판매",
    value: "N",
    en: "Not Sell",
  },
];

export const lastTimeOptions = [
  { kr: "선택하세요", value: "A", en: "Select" },
  {
    kr: "9:00",
    value: "9:00",
  },
  {
    kr: "10:00",
    value: "10:00",
  },
  {
    kr: "11:00",
    value: "11:00",
  },
  {
    kr: "12:00",
    value: "12:00",
  },
  {
    kr: "13:00",
    value: "13:00",
  },
  {
    kr: "14:00",
    value: "14:00",
  },
  {
    kr: "15:00",
    value: "15:00",
  },
  {
    kr: "16:00",
    value: "16:00",
  },
  {
    kr: "17:00",
    value: "17:00",
  },
  {
    kr: "18:00",
    value: "18:00",
  },
  {
    kr: "19:00",
    value: "19:00",
  },
  {
    kr: "20:00",
    value: "20:00",
  },
];

export const salePriceOptions = [
  {
    kr: "전체",
    value: "all",
  },
  {
    kr: "판매가",
    value: "sale",
  },
  {
    kr: "입금가",
    value: "deposit",
  },
];

export const bankOptions = [
  {
    kr: "신한은행",
    value: "Shinhan ",
  },
  {
    kr: "국민은행",
    value: "kb",
  },
  {
    kr: "우리은행",
    value: "woori",
  },
  {
    kr: "하나은행",
    value: "hana",
  },

  {
    kr: "한국시티은행",
    value: "citi",
  },
  {
    kr: "제일은행",
    value: "sc",
  },
  {
    kr: "기업은행",
    value: "ibk",
  },
  {
    kr: "농협",
    value: "nonghyup",
  },
  {
    kr: "수협",
    value: "suhyup",
  },
  {
    kr: "한국산업은행",
    value: "kdb",
  },
  {
    kr: "한국수출입은행",
    value: "krreaexim",
  },
];

export const moneyOptions = [
  {
    kr: "KRW",
    value: "KRW",
  },
  {
    kr: "USD",
    value: "USD",
  },
  {
    kr: "CNY",
    value: "CNY",
  },
];

export const facilityOptions = [
  {
    label1: "사우나",
    label2: "수영장",
  },
  {
    label1: "루프탑수영장",
    label2: "실내수영장",
  },
  {
    label1: "야외수영장",
    label2: "어린이수영장",
  },
  {
    label1: "워터파크",
    label2: "키즈존",
  },
  {
    label1: "썰매장",
    label2: "온천",
  },
  {
    label1: "BBQ",
    label2: "레스토랑",
  },
  {
    label1: "라운지",
    label2: "피트니스",
  },
  {
    label1: "공용샤워실",
    label2: "식당",
  },
  {
    label1: "세탁기",
    label2: "건조기",
  },
  {
    label1: "탈수기",
    label2: "공용PC",
  },
  {
    label1: "탈수기",
    label2: "공용PC",
  },
  {
    label1: "골프장",
    label2: "편의점",
  },
  {
    label1: "엘레베이터",
    label2: "주차장",
  },
  {
    label1: "카페",
    label2: "취사기능",
  },
  {
    label1: "마사지",
    label2: "바",
  },
  {
    label1: "노래방",
    label2: "",
  },
];

export const roomFacilityOptions = [
  {
    label1: "넷플리스",
    label2: "파티룸",
  },
  {
    label1: "객실스파",
    label2: "미니바",
  },
  {
    label1: "안마의자",
    label2: "공기청정기",
  },
  {
    label1: "스타일러",
    label2: "와이파이",
  },
  {
    label1: "에어컨",
    label2: "객실샤워실",
  },
  {
    label1: "욕실용품",
    label2: "냉장고",
  },
  {
    label1: "욕조",
    label2: "드라이기",
  },
  {
    label1: "디리미",
    label2: "전기밥솥",
  },
  {
    label1: "TV",
    label2: "주방",
  },
  {
    label1: "금고",
    label2: "전기주전자",
  },
  {
    label1: "커피머신",
    label2: "",
  },
];

export const otherFacilityOptions = [
  {
    label1: "조식포함",
    label2: "무료주차",
  },
  {
    label1: "패밀리룸",
    label2: "반려견동물",
  },
  {
    label1: "객실내취사",
    label2: "객실내흡연",
  },
  {
    label1: "픽업기능",
    label2: "캠프파이어",
  },
  {
    label1: "짐보관가능",
    label2: "발렛파킹",
  },
  {
    label1: "프린터사용",
    label2: "카드결제",
  },
  {
    label1: "금고",
    label2: "전기주전자",
  },
  {
    label1: "커피머신",
    label2: "",
  },
];

export const feeTypeOptions = [
  {
    kr: "기본수수료",
    value: "default",
  },
  {
    kr: "성수기수수료",
    value: "season",
  },
];

export const channelOptions = [
  {
    kr: "All",
    value: "all",
  },
  {
    kr: "UBE",
    value: "ube",
  },
  {
    kr: "UBA",
    value: "uba",
  },
];

export const rateOptions = [
  {
    kr: "5",
    value: "5",
  },
  {
    kr: "10",
    value: "10",
  },
  {
    kr: "20",
    value: "20",
  },
];

export const langOptions = [
  {
    kr: "한국어",
    value: "kr",
    en: "KOR",
    events: "all",
  },
  {
    value: "en",
    kr: "영문",
    en: "ENG",
    events: "all",
  },
  // {
  //   value: "jp",
  //   kr: "일본어",
  //   en: "JP",
  //   event: "all",
  // },
  // {
  //   value: "chn",
  //   kr: "CHN",
  //   en: "CHN",
  // },
];

export const currenciesOptions = [
  {
    kr: "₩",
    value: "krw",
    langCd: "kr",
    en: "₩",
  },
  {
    kr: "$",
    value: "usd",
    langCd: "us",
    en: "$",
  },
  {
    kr: "¥",
    value: "chy",
    langCd: "chn",
    en: "¥",
  },
  {
    kr: "¥",
    value: "jpy",
    langCd: "jp",
    en: "¥",
  },
];

export const dayOptions = [
  {
    kr: "월",
    value: "0",
    en: "MON",
  },
  {
    kr: "화",
    value: "1",
    en: "TUE",
  },
  {
    kr: "수",
    value: "2",
    en: "WED",
  },
  {
    kr: "목",
    value: "3",
    en: "THU",
  },
  {
    kr: "금",
    value: "4",
    en: "FRI",
  },
  {
    kr: "토",
    value: "5",
    en: "SAT",
  },
  {
    kr: "일",
    value: "6",
    en: "SUN",
  },
];
