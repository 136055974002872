import Select from "components/select";
import { dayOptions, rmPrdStatus } from "util/option";
import { inCom, useCalendar } from "util/common";
import "./calendar.scss";
import { useEffect, useRef, useState } from "react";
import Modal from "components/modal/modal";
import { crrList, IrmPdStaList, rmPdStatusProps } from "interface/room";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  rmHstListApi,
  rmPdSellCtModifyApi,
  rmPdSellPriModifyApi,
  rmPdStatusModifyApi,
} from "components/api/room";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

import { setIsSearch } from "store/roomSlice";
import i18n from "locales/i18n";
import LoadingButton from "components/button/loadingButton";
import { optionProps } from "interface";

type IlodChlListProps = {
  sellDt: string;
  lodChlNm?: string | null;
  lodChlCd: string | null;
  rmPdList: crrList[] | null;
  isPass: boolean;
  date: string;
  onClick(params: Partial<rmPdStatusProps> & { isOpen: boolean }): void;
};

const RmPdListCard = ({
  sellDt,
  lodChlCd,
  lodChlNm,
  rmPdList,
  isPass,
  date,
  onClick,
}: IlodChlListProps) => {
  const dispatch = useDispatch();
  const lodGrpInfo = localStorage.getItem("lodGrpInfo");

  const [info, setInfo] = useState({
    rmNo: "",
    opRgSeq: -1,
  });

  const [hstList, setHstList] = useState<any[]>([]);
  // const [pageNo, setPageNo] = useState(1);

  const pageRef = useRef(1);

  const rmHstMutation = useMutation(rmHstListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const rmPdHistList = body?.rmPdHistList || ([] as any[]);
          setHstList([...hstList, ...rmPdHistList]);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleHtrList = ({
    rmNo,
    opRgSeq,
  }: {
    rmNo: string;
    opRgSeq: number;
  }) => {
    setInfo({
      rmNo,
      opRgSeq,
    });

    const params = {
      lodGrpNo: JSON.parse(lodGrpInfo ?? "")?.lodGrpNo ?? "",
      lodChlCd: lodChlCd ?? "",
      rmNo,
      opRgSeq: opRgSeq ?? 0,
      rmOpNm: "",
      sellSDt: date,
      sellEDt: date,
      pageNo: 1,
    };

    rmHstMutation.mutate(params);
  };

  const handleMore = () => {
    const params = {
      lodGrpNo: JSON.parse(lodGrpInfo ?? "")?.lodGrpNo ?? "",
      lodChlCd: lodChlCd ?? "",
      rmNo: info?.rmNo,
      opRgSeq: info.opRgSeq ?? 0,
      rmOpNm: "",
      sellSDt: date,
      sellEDt: date,
      pageNo: pageRef.current,
    };

    rmHstMutation.mutate(params);
  };

  return (
    <>
      <ul className={lodChlCd ? "card" : "hidden"}>
        {/* <li
        className="lod-chl"
        // data-name={"상품 채널 :"}
      >
        {lodChlNm}
      </li> */}
        {rmPdList &&
          rmPdList.length !== 0 &&
          rmPdList?.map((li: crrList, index: number) => {
            return (
              <ul key={index}>
                <li>
                  <button
                    className={`status ${
                      li.sellStaCd === "S01" && !isPass
                        ? li.sellCt > li.sellDoneCt
                          ? "bg-green-350 text-white-0"
                          : "bg-gray-200 text-black-900"
                        : isPass
                        ? "bg-gray-200 text-black-900"
                        : "bg-red-400 text-white-0"
                    }`}
                    onClick={() => {
                      onClick({
                        sellYn: li.sellStaCd,
                        tarSellYn: li.sellStaCd,
                        rmNo: li.rmNo,
                        sellDt,
                        opRgSeq: li.opRgSeq,
                        sellPri: undefined,
                        sellCt: undefined,
                        isOpen: true,
                      });
                    }}
                  >
                    {li.sellCt === li.sellDoneCt
                      ? "완료"
                      : isPass
                      ? "마감"
                      : li.sellStaNm}
                  </button>
                  <span>{lodChlNm}</span>
                </li>
                <li>
                  <div className={li.rmNm ? "block" : "hidden"} data-name="">
                    {li.rmNm}
                  </div>
                  <button
                    className={li.sellPri ? `block  price` : "hidden"}
                    data-name="₩"
                    onClick={() => {
                      onClick({
                        isOpen: true,
                        sellPri: li?.sellPri + "",
                        sellDt,
                        opRgSeq: li?.opRgSeq,
                        rmNo: li?.rmNo,
                        sellYn: undefined,
                        tarSellYn: undefined,
                        sellCt: undefined,
                      });
                    }}
                  >
                    {li.sellPri
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                  </button>
                  <div className="cnt">
                    <button
                      onClick={() => {
                        onClick({
                          isOpen: true,
                          sellPri: undefined,
                          sellDt,
                          opRgSeq: li?.opRgSeq,
                          rmNo: li?.rmNo,
                          sellYn: undefined,
                          tarSellYn: undefined,
                          sellCt: li.sellCt,
                          lodChlCd: lodChlCd ?? "",
                        });
                      }}
                    >
                      생성 :
                      <span>
                        {li.sellCt
                          .toString()
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    </button>
                    <span>
                      판매 :{" "}
                      <span className="text-blue-500 ">
                        {li.sellDoneCt
                          .toString()
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    </span>
                  </div>
                </li>

                <li
                  className="text-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleHtrList({
                      rmNo: li.rmNo,
                      opRgSeq: li.opRgSeq,
                    });
                  }}
                >
                  상품이력 확인
                </li>
              </ul>
            );
          })}
      </ul>
      {info.rmNo && (
        <Modal
          title={"상품 이력"}
          isOpen={true}
          btnText={""}
          styles="max-w-[98%] w-full sm:max-w-full"
          handleCloseModal={() => {
            setInfo({
              rmNo: "",
              opRgSeq: -1,
            });
            setHstList([]);
          }}
          onClick={() => {}}
        >
          <div>
            <table className="md-table">
              <thead>
                <tr>
                  <th>번호</th>
                  <th>객실 상품번호</th>
                  {/* <th>객실명</th> */}
                  <th>객실 옵션명</th>
                  {/* <th>객실상품 일자</th> */}
                  {/* <th>객실상품 요일</th> */}
                  <th>객실상품 생성수량</th>
                  <th>객실상품 판매중수량</th>
                  <th>객실상품 판매중지수량</th>
                  <th>객실상품 예약수량</th>
                  <th>객실상품 판매금액</th>
                  <th>접속자 번호</th>
                  <th className="min-w-[15rem]">메모</th>
                  <th>이력 상태유형</th>
                </tr>
              </thead>
              <tbody>
                {hstList.length === 0 && (
                  <tr>
                    <td colSpan={11}>
                      <span className="w-full text-center font-bold text-lg">
                        검색결과가 없습니다.
                      </span>
                    </td>
                  </tr>
                )}
                {hstList.map((li: any, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{li?.rmNo}</td>
                      {/* <td>{li?.rmNm}</td> */}
                      <td>{li?.rmOpNm}</td>
                      {/* <td>{dateFormat(li?.sellDt ?? "")}</td> */}
                      {/* <td>{getDay(li?.sellDt, i18n.language)}</td> */}
                      <td>{li?.rmCt}</td>
                      <td>{li?.sellYCt}</td>
                      <td>{li?.sellNCt}</td>
                      <td>{li?.resvCt}</td>
                      <td>{inCom(li?.rmSellAmt ?? "")}</td>
                      <td>{li?.acsrNo}</td>
                      <td>
                        <span className="min-w-[15rem]">{li?.memo}</span>
                      </td>
                      <td>
                        <span data-sts={li?.histStaTyp}>
                          {li?.histStaTyp === "C"
                            ? "생성"
                            : li?.histStaTyp === "D"
                            ? "삭제"
                            : "수정"}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {rmHstMutation?.data?.body?.pageNav?.totalPage > 1 && (
            <div className="pt-4 flex justify-center">
              <LoadingButton
                styles="btn-sm"
                txt="더보기"
                isLoading={rmHstMutation.isLoading}
                onClick={() => {
                  pageRef.current = pageRef.current + 1;
                  handleMore();
                }}
              />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

type IProps = {
  now: Date;
  date: {
    sellSDt: string;
    sellEDt: string;
  };
  rmPdStaList: IrmPdStaList[];
  isLoading: boolean;
  handleNext(): void;
  handlePrev(): void;
  handleCurr(): void;
};

const Calendar = ({
  now,
  date,
  rmPdStaList,
  isLoading,
  handleNext,
  handlePrev,
  handleCurr,
}: IProps) => {
  const lng = i18n.language;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { year, month, monthDate } = useCalendar({ now });

  const [info, setInfo] = useState<
    Partial<rmPdStatusProps> & { isOpen: boolean }
  >({
    isOpen: false,
    sellPri: undefined,
    sellCt: undefined,
    rmNo: "",
    sellDt: undefined,
    sellYn: undefined,
    tarSellYn: undefined,
    opRgSeq: 0,
    rmCt: "",
    lodNo: undefined,
    lodChlCd: undefined,
  });

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng } = target.dataset;

    if (name && value) {
      setInfo({
        ...info,
        [name]: value,
      });
    }
  };

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;
    setInfo({
      ...info,
      [name]: value.replaceAll(",", ""),
    });
  };

  const handleSelectCard = (params: rmPdStatusProps & { isOpen: boolean }) => {
    setInfo({
      ...info,
      sellPri: params.sellPri,
      sellCt: params.sellCt,
      sellYn: params.sellYn,
      tarSellYn: params.tarSellYn || undefined,
      sellDt: params.sellDt,
      rmNo: params.rmNo,
      opRgSeq: params.opRgSeq,
      isOpen: params.isOpen,
      lodChlCd: params.lodChlCd,
      rmCt: params?.sellCt,
    });
  };

  // 가격 수정
  const sellPriMutation = useMutation(rmPdSellPriModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `객실상품 가격 수정이 완료되었습니다./${message}`,
              isConfirm: true,
              handleClick() {
                setInfo({
                  sellPri: undefined,
                  sellCt: undefined,
                  rmNo: "",
                  sellDt: undefined,
                  sellYn: undefined,
                  tarSellYn: undefined,
                  opRgSeq: 0,
                  isOpen: false,
                });
                dispatch(setMessage({ message: null }));
                queryClient.invalidateQueries({ queryKey: ["crrList"] });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModifySellPri = () => {
    const paranms = {
      rmNo: info?.rmNo,
      opRgSeq: info?.opRgSeq,
      sellDt: info?.sellDt,
      sellPri: info?.sellPri,
    };
    dispatch(
      setMessage({
        message: "객실상품 가격을 수정하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick() {
          sellPriMutation.mutate(paranms);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  //상품 수량 변경
  const sellCtMutation = useMutation(rmPdSellCtModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `객실상품 수량이 정상적으로 수정되었습니다./${message}`,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                setInfo({
                  ...info,
                  sellCt: undefined,
                  rmNo: undefined,
                  isOpen: false,
                });
                queryClient.invalidateQueries({ queryKey: ["crrList"] });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModifyrmCnt = () => {
    const params = {
      lodNo: localStorage.getItem("lodNo") ?? "",
      lodChlCd: info?.lodChlCd,
      rmNo: info?.rmNo,
      sellDt: info?.sellDt,
      opRgSeq: info?.opRgSeq,
      rmCt: Number(info?.rmCt),
    };
    dispatch(
      setMessage({
        message: "객실상품 수량을 변경하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          sellCtMutation.mutate(params);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  //상품 상태 변경
  const rmPdStatusMutation = useMutation(rmPdStatusModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setInfo({
            sellPri: undefined,
            sellCt: undefined,
            rmNo: "",
            sellDt: undefined,
            sellYn: undefined,
            tarSellYn: undefined,
            isOpen: false,
            opRgSeq: 0,
          });
          dispatch(
            setMessage({
              message: `성공적으로 변경 되었습니다./${message}`,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                dispatch(setIsSearch(true));
                queryClient.invalidateQueries({ queryKey: ["crrList"] });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModifyStatus = () => {
    const params = {
      rmNo: info?.rmNo,
      opRgSeq: info?.opRgSeq,
      sellDt: info?.sellDt,
      tarSellYn: info.tarSellYn === "S01" ? "Y" : "N",
      sellYn: info.sellYn === "S01" ? "Y" : "N",
    };

    dispatch(
      setMessage({
        message: "객실상품 상태를 변경하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          rmPdStatusMutation.mutate(params);
        },
      })
    );
  };

  const isVisable =
    Number(
      new Date().toISOString().split("T")[0].replaceAll("-", "").slice(0, 6)
    ) >=
    Number(now.toISOString().split("T")[0].replaceAll("-", "").slice(0, 6));

  return (
    <div className="calendar">
      <div className="calendar-title">
        <button className="prev" onClick={handlePrev} />
        <span>
          {year} . {month}
        </span>
        <button className="next" onClick={handleNext} />

        {!isVisable && <button onClick={handleCurr}>Back</button>}
      </div>

      <div className="calendar-cont">
        <div className="flex w-full ">
          {dayOptions.map((day) => (
            <span className="day" key={day.value}>
              {day[lng as keyof optionProps]}
            </span>
          ))}
        </div>
        <div className="flex flex-wrap w-full ">
          {monthDate.map((week, index) => {
            const rmPd = rmPdStaList.find((rmPd: IrmPdStaList) => {
              return rmPd.sellDt === week.dateSt;
            });

            let lodChlList = rmPd?.lodChlList ? rmPd?.lodChlList[0] : null;

            return (
              <div
                key={index}
                className={`date`}
                data-islast={week.isLast}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                {week.date}

                {lodChlList?.lodChlCd && (
                  <>
                    <RmPdListCard
                      lodChlCd={lodChlList?.lodChlCd}
                      lodChlNm={lodChlList.lodChlNm}
                      rmPdList={lodChlList?.rmPdList ?? []}
                      sellDt={week.dateSt ?? ""}
                      isPass={week.isLast}
                      onClick={handleSelectCard}
                      date={
                        year +
                        "" +
                        ("00" + month).slice(-2) +
                        ("00" + week.date).slice(-2)
                      }
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        title={"객실상품 가격 수정"}
        btnText="수정"
        isOpen={info?.sellPri !== undefined && info.isOpen}
        handleCloseModal={() => {
          setInfo({
            ...info,
            sellPri: undefined,
          });
        }}
        disabled={!info?.sellPri}
        onClick={handleModifySellPri}
      >
        <div className="pt-4">
          <label htmlFor="" className="w-full text-left text-base pb-2 ">
            상품금액
          </label>
          <input
            type="text"
            name="sellPri"
            value={(info.sellPri ?? "")
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
            onChange={(e) => {
              handleChange(e.target);
            }}
          />
        </div>
      </Modal>

      <Modal
        title={"객실상품 수량 변경"}
        isOpen={info?.sellCt !== undefined && info.isOpen}
        handleCloseModal={() =>
          setInfo({
            ...info,
            sellCt: undefined,
            rmNo: undefined,
          })
        }
        disabled={!info?.sellCt}
        onClick={handleModifyrmCnt}
      >
        <div className="pt-4">
          <label htmlFor="" className="w-full text-left text-base pb-2">
            생성 수량
          </label>
          <input
            type="number"
            name="rmCt"
            min="1"
            max="10000"
            value={info.rmCt ?? ""}
            onChange={(e) => {
              handleChange(e.target);
            }}
          />
        </div>
      </Modal>

      <Modal
        title={"객실상품 상태 변경"}
        isOpen={info?.sellYn !== undefined && info.isOpen}
        handleCloseModal={() => {
          setInfo({
            ...info,
            sellYn: undefined,
            rmNo: undefined,
          });
        }}
        onClick={handleModifyStatus}
      >
        <div className="pt-4">
          <label htmlFor="" className="w-full text-base text-left pb-2">
            상품 판매 상태
          </label>

          <Select
            id="sellYn"
            name="sellYn"
            option={info.sellYn}
            options={rmPdStatus}
            onSelect={(target) => {
              handleSelect(target);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Calendar;

const rmPdStatus = [
  {
    kr: "판매",
    value: "S01",
    en: "SELL",
  },
  {
    kr: "중지",
    value: "S02",
    en: "STOP",
  },
];
