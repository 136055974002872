import { useMutation } from "@tanstack/react-query";
import { lodDefaultCreateApi } from "components/api/lod";
import LodDefaultInfoView from "components/view/lod/default/info";
import { lodDefaultInfoProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

const LodDefaultInfoPage = () => {
  const dispatch = useDispatch();

  const [info, setInfo] = useState<lodDefaultInfoProps>({
    lodGrpNm: "",
    lodGrpNo: "",
    lodNo: "",
    lodGrpCd: "",
    lodNm: "",
    lodNotiCn: "",
    lodOprCn: "",
    lodCstAdCn: "",
    lodCstFvrCn: "",
    lodAddFacCn: "",
    lodBrkfCn: "",
    lodCokCn: "",
  });

  const handleChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng } = target.dataset;
  };

  const defaultInfoMutation = useMutation(lodDefaultCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    defaultInfoMutation.mutate(info);
  };

  return (
    <LodDefaultInfoView
      info={info}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleCreate={handleCreate}
    />
  );
};

export default LodDefaultInfoPage;
