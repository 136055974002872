import { useMutation } from "@tanstack/react-query";
import { rmPdDtlApi } from "components/api/room";
import RmPdInfoView from "components/view/room/prd/info";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const RmPdInfoPage = () => {
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();

  const memType = pathname.includes("admin") ? "admin" : "manager";

  const rmPdInfoMutation = useMutation(rmPdDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  useEffect(() => {
    rmPdInfoMutation.mutate({ rmPdNo: search?.replace("?rmPdNo=", "") });
  }, [search]);

  const rmOpNmList = rmPdInfoMutation?.data?.body?.rmOpNmList || [];
  const rmPdInf = rmPdInfoMutation?.data?.body?.rmPdInf || {};

  return (
    <>
      <RmPdInfoView
        memType={memType}
        rmPdInfo={rmPdInf}
        rmOpNmList={rmOpNmList}
      />
    </>
  );
};

export default RmPdInfoPage;
