import "./checkbox.scss";

type IProps = {
  label: string;
  id: string;
  disabled?: boolean;
  checked?: boolean;
  labelStyles?: string;
  ref?: React.MutableRefObject<null>;
  handleCheckbox(e: React.ChangeEvent<HTMLInputElement>): void;
};

const CheckBox = ({
  label,
  id,
  disabled,
  checked,
  labelStyles,
  // ref,
  handleCheckbox,
}: IProps) => {
  return (
    <span className="checkbox-wrap">
      <input
        type="checkbox"
        // ref={ref}
        id={id}
        checked={checked}
        // defaultChecked={checked}
        disabled={disabled}
        onChange={handleCheckbox}
      />
      <label htmlFor={id} className={labelStyles}>
        {label}
      </label>
    </span>
  );
};

export default CheckBox;
