import { useQuery } from "@tanstack/react-query";
import Select from "components/select";

type IProps = {
  option: string;
  onSelect(target: EventTarget & HTMLLIElement): void;
};

const LodChlSelect = ({ option, onSelect }: IProps) => {
  // const { data } = useQuery(["lodGrpList"], lodGrpListApi);

  // const options = data?.body.lodGrpList.map((li: IlodGrpList) => {
  //   const option = {
  //     value: li.lodGrpNo,
  //     kr: li.lodGrpNm,
  //     en: li.lodGrpNm,
  //   };
  //   return option;
  // });

  const options = [
    {
      kr: "UBS",
      value: "UBS",
      en: "UBS",
    },
    // {
    //   kr: "USA",
    //   value: "USA",
    //   en: "USA",
    // },
  ];

  return (
    <Select
      // wrapStyle="flex-1 w-full "
      id="lodChlCd"
      name="lodChlCd"
      option={option}
      options={[{ value: "A", kr: "선택하세요", en: "select" }, ...options]}
      onSelect={onSelect}
    />
  );
};

export default LodChlSelect;
