import { useMutation } from "@tanstack/react-query";
import { termListAPi } from "components/api/term";
import { pageNavProps } from "interface";
import { IprivList } from "interface/term";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  isLoadingSelector,
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import { dateFormat } from "util/common";
import { termOptionHead } from "util/option";

type IProps = {
  searchInfo: {
    langCd: string;
    userType: string;
  };
};

type IListProps = {
  searchInfo: {
    langCd: string;
    userType: string;
  };
  setPageNav: Dispatch<SetStateAction<number>>;
};
const List = ({ searchInfo, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const [info, setInfo] = useState({
    isOpen: false,
    rmNo: "",
    uyn: "",
  });
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  const trmListMutation = useMutation(termListAPi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setList(body?.prvList);
          setPageNav(body?.prvList?.length ?? 0);
          dispatch(setIsSearch(false));
          dispatch(setIsLoading(false));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  useEffect(() => {
    if (isSearch) {
      trmListMutation.mutate({
        userType: searchInfo.userType,
        langCd: searchInfo.langCd,
      });
    }
  }, [isSearch, searchInfo, search]);

  //   const pageRows = trmListMutation.data?.body?.pageNav?.pageRows;

  return (
    <>
      {list &&
        list.map((li: IprivList, index) => {
          return (
            <tr
              key={index}
              onClick={() => {
                navigate(`info?prvCd=${li.prvCd}`, {
                  state: {
                    langCd: searchInfo.langCd,
                  },
                });
              }}
            >
              {/* <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td> */}
              <td>{index}</td>
              <td>{li?.prvNm}</td>
              <td>{li.prvCd}</td>
              <td>{li.prvDesc ?? "-"}</td>
              <td>{li.rgSeq}</td>
              <td>{li.verNm}</td>
              <td>{dateFormat(li.aplySDt ?? "")}</td>
              <td>{dateFormat(li.aplyEDt ?? "")}</td>
              <td>{li.essYn === "Y" ? "필수" : "선택"}</td>
              <td>젹용</td>
            </tr>
          );
        })}
    </>
  );
};

const TermList = ({ searchInfo }: IProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const [pageNav, setPageNav] = useState<number>(0);

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav || 0}</b>개
            </span>
          </h3>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                {termOptionHead.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <Suspense>
                <List searchInfo={searchInfo} setPageNav={setPageNav} />
              </Suspense>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TermList;
