import Header from "./header";
import "./layout.scss";
import GBN from "./gbn";
import { useSelector } from "react-redux";
import {
  messageSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import { useDispatch } from "react-redux";
import NotiModal from "components/modal/notiModal";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";

const Layout = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const loginObject = sessionStorage.getItem("login");
  const isLogin = (loginObject && JSON.parse(loginObject).value) || null;

  const {
    title,
    canclBntText,
    cnfrmBtnText,
    message,
    isCancel,
    isConfirm,
    handleCancel,
    handleClick,
  } = useSelector(messageSelector);

  const handleCloseModal = () => {
  dispatch(setMessage({ message: null }));
  };

  useEffect(() => {
    dispatch(setIsSearch(false));
    dispatch(setIsLoading(false));
  }, [pathname]);

  const msg = message?.split("/");
  return (
    <>
      <Header />
      {isLogin && <GBN />}
      <section
        className={
          isLogin ? "mt-[4rem] ms-[12.55rem] " : "mt-[4rem] mx-auto py-[2.5rem]"
        }
      >
        <Outlet />

        <NotiModal
          title={title}
          isOpen={!!message}
          canclBntText={canclBntText}
          cnfrmBtnText={cnfrmBtnText}
          isCancel={isCancel}
          isConfirm={isConfirm}
          handleCloseModal={handleCloseModal}
          handleClick={handleClick}
          handleCancel={handleCancel}
        >
          <div>
            {msg?.map((msg) => (
              <span key={msg}>{msg}</span>
            ))}
          </div>
        </NotiModal>
      </section>
    </>
  );
};

export default Layout;
