import LodGroupInfoview from "components/view/lod/group/info";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

const LodGroupInfoPage = () => {
  // const lodgInfo = useQuery(["lodgInfo"]);
  const query = useQuery({
    queryKey: [""],
    queryFn: () => {},
  });

  return <LodGroupInfoview lodGrpInfo={{}} />;
};

export default LodGroupInfoPage;
