export const countryCode = [
  {
    kr: "대한민국",
    en: "Korea",
    phone: "82",
    code: "KOR",
  },
  {
    kr: "가나",
    en: "Ghana",
    code: "GHA",
    phone: "233",
  },
  {
    kr: "가봉",
    en: "Gabon",
    phone: "241",
    code: "GAB",
  },
  { kr: "가이아나", en: "Guyana", phone: "592", code: "GUY" },
  { kr: "감비아", en: "Gambia", phone: "220", code: "	GMB" },

  { kr: "괌", en: "Guam", phone: "1671", code: "GUM" },
  { kr: "과들루프", en: "Guadeloupe", phone: "590", code: "GLP" },
  { kr: "과테말라", en: "Guatemala", phone: "502", code: "GTM" },
  { kr: "그레나다", en: "Grenada", phone: "1473", code: "GRD" },
  { kr: "그리스", en: "Greece", phone: "30", code: "	GRC" },
  { kr: "그린란드", en: "Greenland", phone: "299", code: "GRL" },
  { kr: "기니", en: "Guinea", phone: "224", code: "GIN" },
  { kr: "기니비사우", en: "Guinea-Bissau", phone: "245", code: "GNB" },
  { kr: "나미비아", en: "Namibia", phone: "264", code: "NAM" },
  { kr: "나우루", en: "Nauru", phone: "674", code: "NRU" },
  { kr: "나이지리아", en: "Nigeria", phone: "234", code: "NGA" },
  { kr: "남수단", en: "South Sudan", phone: "211", code: "SSD" },
  {
    kr: "남아프리카 공화국",
    en: "South Africa",
    phone: "27",
    code: "ZAF",
  },
  { kr: "네덜란드", en: "the Netherlands", phone: "31", code: "NLD" },
  { kr: "네팔", en: "Nepal", phone: "977", code: "NPL" },
  { kr: "노르웨이", en: "Norway", phone: "47", code: "NOR" },
  { kr: "누벨칼레도니", en: "New Caledonia", phone: "687", code: "	NCL" },
  { kr: " 뉴질랜드", en: "New Zealand", phone: "64", code: "NZL" },
  { kr: "니우에", en: "Niue", phone: "683", code: "NIU" },
  { kr: "니제르", en: "Niger", phone: "227", code: "NER" },
  { kr: "니카라과", en: "Nicaragua", phone: "505", code: "NIC" },
  // 대한민국	Republic of korea	82	KR	KOR
  { kr: "덴마크", en: "Denmark", phone: "45", code: "DNK" },
  {
    kr: "도미니카 공화국",
    en: "Dominican R.",
    phone: "1809",
    code: "DOM",
  },
  { kr: "도미니카 연방", en: "	Dominica", phone: "1767", code: "DMA" },
  { kr: "독일", en: "Germany", phone: "49", code: "DEU" },
  { kr: "동티모르", en: "East Timor", phone: "	670", code: "TLS" },
  { kr: "라오스", en: "Laos", phone: "856", code: "LAO" },
  { kr: "라이베리아", en: "Liberia", phone: "231", code: "LBR" },
  { kr: "라트비아", en: "Latvia", phone: "371", code: "LVA" },
  { kr: "러시아", en: "Russia", phone: "7", code: "RUS" },
  { kr: "레바논", en: "Lebanon", phone: "961", code: "LBN" },
  { kr: "레소토", en: "Lesotho", phone: "266", code: "LSO" },
  { kr: "루마니아", en: "Romania", phone: "40", code: "ROU" },
  { kr: "룩셈부르크", en: "Luxembourg", phone: "352", code: "LUX" },
  { kr: "르완다", en: "Rwanda", phone: "250", code: "RWA" },
  { kr: "리비아", en: "Libya", phone: "218", code: "LBY" },
  { kr: "리투아니아", en: "Lithuania", phone: "370", code: "LTU" },
  { kr: "리히텐슈타인", en: "Liechtenstein", phone: "423", code: "LIE" },
  { kr: "마다가스카르", en: "Madagascar", phone: "261", code: "MDG" },
  { kr: "마르티니크", en: "Martinique", phone: "596", code: "MTQ" },
  { kr: "마셜 제도", en: "Marshall Islands", phone: "692", code: "MHL" },
  { kr: "마요트", en: "Mayotte", phone: "269", code: "MYT" },
  { kr: "마카오", en: "Macao", phone: "853", code: "MAC" },
  { kr: "말라위", en: "Malawi", phone: "265", code: "MWI" },
  { kr: "말레이시아", en: "Malaysia", phone: "60", code: "MYS" },
  { kr: "말리", en: "Mali", phone: "223", code: "MLI" },
  { kr: "멕시코", en: "Mexico", phone: "52", code: "MEX" },
  { kr: "모나코", en: "Monaco", phone: "377", code: "MCO" },
  { kr: "모로코", en: "Morocco", phone: "212", code: "MAR" },
  { kr: "모리셔스", en: "Mauritius", phone: "230", code: "MUS" },
  { kr: "모리타니", en: "Mauritania", phone: "222", code: "MRT" },
  { kr: "모잠비크", en: "Mozambique", phone: "258", code: "MOZ" },
  { kr: "몬테네그로", en: "Montenegro", phone: "382", code: "MNE" },
  { kr: "몬트세랫", en: "Montserrat", phone: "1664", code: "MSR" },
  { kr: "몰도바", en: "Maldova", phone: "373", code: "MDA" },
  { kr: "몰디브", en: "Maldives", phone: "960", code: "MDV" },
  { kr: "몰타", en: "Malta", phone: "356", code: "MLT" },
  { kr: "몽골", en: "Mongolia", phone: "976", code: "MNG" },
  {
    kr: "미국",
    en: "	United States of America",
    phone: "1",
    code: "USA",
  },
  { kr: "미얀마 버마", en: "Myanmar", phone: "95", code: "MMR" },
  {
    kr: "미크로네시아 연방",
    en: "Micronesia",
    phone: "691",
    code: "FSM",
  },
  { kr: "바누아투", en: "Vanuatu", phone: "678", code: "VUT" },
  { kr: "바레인", en: "Bahrain", phone: "973", code: "BHR" },
  { kr: "바베이도스", en: "Barbados", phone: "1246", code: "BRB" },
  { kr: "바티칸 시국", en: "Vatican", phone: "379", code: "VAT" },
  { kr: "바하마", en: "Bahamas", phone: "1242", code: "BHS" },
  { kr: "방글라데시", en: "Bangladesh", phone: "880", code: "BGD" },
  { kr: "버뮤다", en: "Bermuda", phone: "1441", code: "BMU" },
  { kr: "베냉", en: "Benin", phone: "229", code: "BEN" },
  { kr: "베네수엘라", en: "Venezuela", phone: "58", code: "VEN" },
  { kr: "베트남", en: "Vietnam", phone: "84", code: "VNM" },
  { kr: "벨기에", en: "Belgium", phone: "32", code: "BEL" },
  { kr: "벨라루스", en: "Belarus", phone: "375", code: "BLR" },
  { kr: "벨리즈", en: "Belize", phone: "501", code: "BLZ" },
  {
    kr: "보스니아 헤르체고비나",
    en: "Bosnia and Herzegovina",
    phone: "387",
    code: "BIH",
  },
  { kr: "보츠와나", en: "Botswana", phone: "267", code: "BWA" },
  { kr: "볼리비아", en: "Bolivia", phone: "591", code: "BOL" },
  { kr: "부룬디", en: "Burundi", phone: "257", code: "BDI" },
  { kr: "부르키나파소", en: "Burkina Faso", phone: "226", code: "BFA" },
  { kr: "부탄", en: "Bhutan", phone: "975", code: "BTN" },
  { kr: "북마케도니아", en: "Macedonia", phone: "389", code: "MKD" },
  { kr: "불가리아", en: "Bulgaria", phone: "359", code: "BGR" },
  { kr: "브라질", en: "Brazil", phone: "55", code: "BRA" },
  { kr: "브루나이", en: "Brunei", phone: "673", code: "BRN" },
  { kr: "사모아", en: "Samoa", phone: "685", code: "WSM" },
  {
    kr: "사우디아라비아",
    en: "Saudi Arabia",
    phone: "966",
    code: "SAU",
  },
  { kr: "산마리노", en: "San Marino", phone: "378", code: "SMR" },
  {
    kr: "생피에르 미클롱",
    en: "Saint Pierre and Miquelon",
    phone: "508",
    code: "SPM",
  },
  { kr: "서사하라", en: "Western Sahara", phone: "212", code: "ESH" },
  { kr: "세네갈", en: "Senegal", phone: "221", code: "SEN" },
  { kr: "세르비아", en: "Serbia", phone: "381", code: "SRB" },
  { kr: "세이셸", en: "Seychelles", phone: "248", code: "SYC" },
  { kr: "세인트루시아", en: "Saint Lucia", phone: "1758", code: "LCA" },
  { kr: "세인트헬레나", en: "Saint Helena", phone: "290", code: "SHN" },
  { kr: "소말리아", en: "Somalia", phone: "252", code: "SOM" },
  {
    kr: "솔로몬 제도",
    en: "Solomon Islands",
    phone: "677",
    code: "SLB",
  },
  { kr: "수단", en: "Sudan", phone: "249", code: "SDN" },
  { kr: "수리남", en: "Surikr", phone: "597", code: "SUR" },
  { kr: "스리랑카", en: "Sri Lanka", phone: "94", code: "LKA" },
  { kr: "스웨덴", en: "Sweden", phone: "46", code: "SWE" },
  { kr: "스위스", en: "Switzerland", phone: "41", code: "CHE" },
  { kr: "스페인", en: "Spain", phone: "34", code: "ESP" },
  { kr: "슬로바키아", en: "Slovakia", phone: "421", code: "SVK" },
  { kr: "슬로베니아", en: "Slovenia", phone: "386", code: "SVN" },
  { kr: "시리아", en: "Syria", phone: "963", code: "SYR" },
  { kr: "시에라리온", en: "Sierra Leone", phone: "232", code: "SLE" },
  { kr: "신트마르턴", en: "Sint Maarten", phone: "1721", code: "SXM" },
  { kr: "싱가포르", en: "Singapore", phone: "65", code: "SGP" },
  {
    kr: "아랍에미리트",
    en: "United Arab Emirates",
    phone: "971",
    code: "ARE",
  },
  { kr: "아루바", en: "Aruba", phone: "297", code: "ABW" },
  { kr: "아르메니아", en: "Armenia", phone: "374", code: "ARM" },
  { kr: "아르헨티나", en: "Argentina", phone: "54", code: "ARG" },
  {
    kr: "아메리칸사모아",
    en: "American Samoa",
    phone: "1684",
    code: "ASM",
  },
  { kr: "아이슬란드", en: "Iceland", phone: "354", code: "ISL" },
  { kr: "아이티", en: "Haiti", phone: "509", code: "HTI" },
  { kr: "아일랜드", en: "Ireland", phone: "353", code: "IRL" },
  { kr: "아제르바이잔", en: "Azerbaijan", phone: "994", code: "AZE" },
  { kr: "아프가니스탄", en: "Afghanistan", phone: "93", code: "AFG" },
  { kr: "안도라", en: "Andorra", phone: "376", code: "AND" },
  { kr: "알바니아", en: "Albania", phone: "355", code: "ALB" },
  { kr: "알제리", en: "Algeria", phone: "213", code: "DZA" },
  { kr: "앙골라", en: "Angola", phone: "244", code: "AGO" },
  {
    kr: "앤티가 바부다",
    en: "Antigua and Barbuda",
    phone: "1268",
    code: "ATG",
  },
  { kr: "앵귈라", en: "Anguilla", phone: "1264", code: "AIA" },
  { kr: "에리트레아", en: "Eritrea", phone: "291", code: "ERI" },
  { kr: "에스와티니", en: "Eswatini", phone: "268", code: "SWZ" },
  { kr: "에스토니아", en: "Estonia", phone: "372", code: "EST" },
  { kr: "에콰도르", en: "Ecuador", phone: "593", code: "ECU" },
  { kr: "에티오피아", en: "Ethiopia", phone: "251", code: "ETH" },
  { kr: "엘살바도르", en: "El Salvador", phone: "503", code: "SLV" },
  { kr: "영국", en: "United Kingdom", phone: "44", code: "GBR" },
  { kr: "예멘", en: "Yemen", phone: "967", code: "YEM" },
  { kr: "오만", en: "Oman", phone: "968", code: "OMN" },
  { kr: "오스트레일리아", en: "Australia", phone: "61", code: "AUS" },
  { kr: "오스트리아", en: "Austria", phone: "43", code: "AUT" },
  { kr: "온두라스", en: "Honduras", phone: "504", code: "HND" },
  { kr: "요르단", en: "Jordan", phone: "962", code: "JOR" },
  { kr: "우간다", en: "Uganda", phone: "256", code: "UGA" },
  { kr: "우루과이", en: "Uruguay", phone: "598", code: "URY" },
  { kr: "우즈베키스탄", en: "Uzbekistan", phone: "998", code: "UZB" },
  { kr: "우크라이나", en: "Ukraine", phone: "380", code: "UKR" },
  { kr: "이라크", en: "Iraq", phone: "964", code: "IRQ" },
  { kr: "이란", en: "Iran", phone: "98", code: "IRN" },
  { kr: "이스라엘", en: "Israel", phone: "972", code: "ISR" },
  { kr: "이집트", en: "Egypt", phone: "20", code: "EGY" },
  { kr: "이탈리아", en: "Italy", phone: "39", code: "ITA" },
  { kr: "인도", en: "India", phone: "91", code: "IND" },
  { kr: "인도네시아", en: "Indonesia", phone: "62", code: "IDN" },
  { kr: "일본", en: "Japan", phone: "81", code: "JPN" },
  { kr: "자메이카", en: "Jamaica", phone: "1876", code: "JAM" },
  { kr: "잠비아", en: "Zambia", phone: "260", code: "ZMB" },
  {
    kr: "적도 기니",
    en: "Equatorial Guinea",
    phone: "240",
    code: "GNQ",
  },
  { kr: "북한", en: "DPR of Korea", phone: "850", code: "PRK" },
  { kr: "조지아", en: "Georgia", phone: "995", code: "GEO" },
  {
    kr: "중앙아프리카 공화국",
    en: "Central Africa",
    phone: "236",
    code: "CAF",
  },
  { kr: "대만", en: "Taiwan", phone: "886", code: "TWN" },
  { kr: "중화인민공화국", en: "China", phone: "86", code: "CHN" },
  { kr: "지부티", en: "Djibouti", phone: "253", code: "DJI" },
  { kr: "지브롤터", en: "Gibraltar", phone: "350", code: "GIB" },
  { kr: "짐바브웨", en: "Zimbabwe", phone: "263", code: "ZWE" },
  { kr: "차드", en: "Chad", phone: "235", code: "TCD" },
  { kr: "체코", en: "Czech", phone: "420", code: "CZE" },
  { kr: "칠레", en: "Chile", phone: "56", code: "CHL" },
  { kr: "카메룬", en: "Cameroon", phone: "237", code: "CMR" },
  { kr: "카보베르데", en: "Cape Verde", phone: "238", code: "CPV" },
  { kr: "카자흐스탄", en: "Kazakstan", phone: "7", code: "KAZ" },
  { kr: "카타르", en: "Qatar", phone: "974", code: "QAT" },
  { kr: "캄보디아", en: "Cambodia", phone: "855", code: "KHM" },
  { kr: "캐나다", en: "Canada", phone: "1", code: "CAN" },
  { kr: "케냐", en: "Kenya", phone: "254", code: "KEN" },
  {
    kr: "케이맨 제도",
    en: "Cayman Islands",
    phone: "1345",
    code: "CYM",
  },
  { kr: "코모로", en: "the Comoros", phone: "269", code: "COM" },
  { kr: "코스타리카", en: "Costa Rica", phone: "506", code: "CRI" },
  { kr: "코트디부아르", en: "Ivory Coast", phone: "225", code: "CIV" },
  { kr: "콜롬비아", en: "Colombia", phone: "57", code: "COL" },
  { kr: "콩고 공화국", en: "Congo", phone: "242", code: "COG" },
  { kr: "콩고 민주 공화국", en: "DR Congo", phone: "243", code: "COD" },
  { kr: "쿠바", en: "Cuba", phone: "53", code: "CUB" },
  { kr: "쿠웨이트", en: "Kuwait", phone: "965", code: "KWT" },
  { kr: "쿡 제도", en: "Cook Islands", phone: "682", code: "COK" },
  { kr: "크로아티아", en: "Croatia", phone: "385", code: "HRV" },
  { kr: "키르기스스탄", en: "Kyrgizstan", phone: "996", code: "KGZ" },
  { kr: "키리바시", en: "Kiribati", phone: "686", code: "KIR" },
  { kr: "키프로스", en: "Cyprus", phone: "357", code: "CYP" },
  { kr: "타지키스탄", en: "Tajikistan", phone: "992", code: "TJK" },
  { kr: "탄자니아", en: "Tanzania", phone: "255", code: "TZA" },
  { kr: "태국", en: "Thailand", phone: "66", code: "THA" },
  { kr: "터키", en: "Turkey", phone: "90", code: "TUR" },
  { kr: "토고", en: "Togo", phone: "228", code: "TGO" },
  { kr: "토켈라우", en: "Tokelau", phone: "690", code: "TKL" },
  { kr: "통가", en: "Tonga", phone: "676", code: "TON" },
  {
    kr: "투르크메니스탄",
    en: "Turkmenistan",
    phone: "993",
    code: "TKM",
  },
  { kr: "투발루", en: "Tuvalu", phone: "688", code: "TUV" },
  { kr: "튀니지", en: "Tunisia", phone: "216", code: "TUN" },
  {
    kr: "트리니다드 토바고",
    en: "Trinidad and Tobago",
    phone: "1868",
    code: "TTO",
  },
  { kr: "파나마", en: "Panama", phone: "507", code: "PAN" },
  { kr: "파라과이", en: "Paraguay", phone: "595", code: "PRY" },
  { kr: "파키스탄", en: "Pakistan", phone: "92", code: "PAK" },
  {
    kr: "파푸아뉴기니",
    en: "Papua New Guinea",
    phone: "675",
    code: "PNG",
  },
  { kr: "팔라우", en: "Palau", phone: "680", code: "PLW" },
  {
    kr: "팔레스타인",
    en: "State of Palestine",
    phone: "970",
    code: "PSE",
  },
  { kr: "페루", en: "Peru", phone: "51", code: "PER" },
  { kr: "포르투갈", en: "Portugal", phone: "351", code: "PRT" },
  {
    kr: "포클랜드 제도",
    en: "Falkland Islands",
    phone: "500",
    code: "FLK",
  },
  { kr: "폴란드", en: "Poland", phone: "48", code: "POL" },
  { kr: "푸에르토리코", en: "Puerto Rico", phone: "1787", code: "PRI" },
  { kr: "프랑스", en: "France", phone: "33", code: "FRA" },
  { kr: "피지", en: "Fiji", phone: "679", code: "FJI" },
  { kr: "핀란드", en: "Finland", phone: "358", code: "FIN" },
  { kr: "필리핀", en: "the Philippines", phone: "63", code: "PHL" },
  { kr: "헝가리", en: "Hungary", phone: "36", code: "HUN" },
  { kr: "홍콩", en: "Hong Kong", phone: "852", code: "HKG" },
];
