import DefaultLoading from "components/loading/default";
import "./nodata.scss";
import Loading from "components/view/loading";
import { useSelector } from "react-redux";
import { isSearchSelector } from "store/commonSlice";

type IProps = {
  isLoading: boolean;
  isNotData: boolean;
  isSuccess?: boolean;
  styles?: string;
  txt?: string;
  type?: "small" | "middle" | "lds-wrap";
};

const NotData = ({
  isLoading,
  isNotData,
  isSuccess,
  styles,
  txt,
  type = "lds-wrap",
}: IProps) => {
  const isSearch = useSelector(isSearchSelector);
  return (
    <div className={`${styles} no-data`}>
      {isLoading && isNotData && <Loading type={type} />}
      {!isLoading && isNotData && isSuccess && <>검색 결과가 없습니다.</>}
      {!isSearch && isNotData && !isSuccess && (
        <>조건을 입력하여 검색하여 주세요.</>
      )}
    </div>
  );
};

export default NotData;
