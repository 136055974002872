import { useLocation, useNavigate } from "react-router-dom";
import "../signup.scss";
import Layout from "components/layout";

const Step5View = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const memType = pathname.includes("manager") ? "매니저" : "관리자";
  return (
    <div className="no-logged-wrap">
      <h2 className="mail-done">{memType} 회원가입 완료메일</h2>
      <h3>
        환영합니다.
        <br />
        회원으로 가입해 주셔서 감사합니다. <br />
        <span>[로그인 하기]</span> 버튼을 클릭한 후
        <br />
        이동된 페이지를 통하여
        <br />
        로그인하기 바랍니다.
      </h3>
      <div className="signup-btn">
        <button className="btn-red" onClick={() => navigate("/")}>
          로그인 하기
        </button>
      </div>
    </div>
  );
};

export default Step5View;
