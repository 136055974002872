import RadioButton from "components/radio/radiobtn";

type IProps = {
  label: string;
  radio1: boolean;
  radio2: boolean;
  handleChange(target: EventTarget & HTMLInputElement): void;
};

const RadioButtonWrap = ({ label, radio1, radio2, handleChange }: IProps) => {
  return (
    <span className="flex gap-x-2 items-center mb-2 ">
      <span className="w-[7.5rem]">{label}</span>
      <RadioButton
        id="Y"
        name=""
        label="Y"
        labelstyles="square"
        checked={radio1}
        onChange={handleChange}
      />
      <RadioButton
        id="N"
        name=""
        label="N"
        labelstyles="square"
        checked={radio2}
        onChange={handleChange}
      />
    </span>
  );
};

export default RadioButtonWrap;
