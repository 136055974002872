import { MouseEvent, useEffect, useRef, useState } from "react";
import "./select.scss";
import i18n from "locales/i18n";
import { optionProps } from "interface";

type selectOptions = optionProps & {
  events?: string;
};

type IProps = {
  id: string;
  name: string;
  option?: string;
  options: selectOptions[];
  wrapStyle?: string;
  ulStyle?: string;
  isSusess?: boolean;
  disabled?: boolean;
  onSelect(target: EventTarget & HTMLLIElement): void;
};

const Select = ({
  id,
  name,
  options,
  wrapStyle = "w-full h-[2.75rem]",
  ulStyle,
  option,
  isSusess = true,
  disabled = false,
  onSelect,
}: IProps) => {
  const ulRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  let lng = i18n.language || "kr";

  const outSideClick = (e: any) => {
    if (ulRef.current && !ulRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", outSideClick);

    return () => {
      document.removeEventListener("click", outSideClick);
    };
  }, [ulRef]);

  const opNm =
    options &&
    options.find((op) => {
      return op.value + "" === option + "";
    });

  return (
    <div className={`${wrapStyle} select-wrap`} ref={ulRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={isOpen ? "on" : ""}
        disabled={disabled}
      >
        {(opNm && opNm[lng as keyof optionProps]) ||
          (options && options[0][lng as keyof optionProps])}
      </button>

      <ul className={isOpen ? `${ulStyle} on` : "hidden"}>
        {!isSusess && (
          <span className="w-full h-full pt-5 text-center text-sm ">
            검색결과가 없습니다.
          </span>
        )}
        {isSusess &&
          options.map((op, index) => (
            <li
              key={index}
              data-name={name || op[lng as keyof optionProps]}
              data-lng={op[lng as keyof optionProps]}
              data-value={op.value}
              data-events={op?.events}
              onClick={(e) => {
                onSelect(e.currentTarget);
                setIsOpen(!isOpen);
              }}
            >
              {op[lng as keyof optionProps]}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Select;
