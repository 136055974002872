import FormSearchBtn from "components/button/formSearch";
import SubHeader from "components/layout/header/subHeader";
import CountrySelect from "components/select/country";
import PromotionList from "components/tableList/promotion/list";

type IProps = {
  searchInfo: any;
  handleReset(): void;
  handleSearch(): void;
};

const PromView = ({ searchInfo, handleReset, handleSearch }: IProps) => {
  return (
    <>
      <div className="search-form">
        <SubHeader title="프로모션" add />
        <div className="table-form">
          <table>
            <tbody>
              <tr>
                <th>프로모션코드</th>
                <td></td>
              </tr>
              <tr>
                <th>인풀루언서명</th>
                <td>
                  <input type="text" />
                </td>
                <th>인플루언서 국가</th>
                <td>
                  <CountrySelect
                    handleSelect={function (
                      target: EventTarget & HTMLLIElement
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            handleReset={handleReset}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <PromotionList searchInfo={searchInfo} />
    </>
  );
};

export default PromView;
