import { useNavigate } from "react-router-dom";
import "./notfound.scss";
import errorImage from "assets/icon/icon_error.svg";

export default function NotFoundView() {
  const navigate = useNavigate();
  return (
    <>
      <header>
        <div className="header-wrap">
          <div className="flex items-center">
            <h2>UB</h2>
            <h4 className="ps-3">관리자</h4>
          </div>
        </div>
      </header>
      <section className="error-cont">
        <img src={errorImage} alt="느낌표" />
        <h1>요청하신 페이지를 찾을 수 없습니다.</h1>
        <p>
          방문하시려는 페이지의 주소가 잘못 입력되었거나,
          <br /> 페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수
          없습니다.
          <br /> 입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.
        </p>
        <div className="err-btn">
          <button onClick={() => navigate(-1)}>이전페이로</button>
          <button onClick={() => navigate("/", { replace: true })}>
            메인으로
          </button>
        </div>
      </section>
    </>
  );
}
