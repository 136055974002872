import { useMutation, useQuery } from "@tanstack/react-query";
import { rmResvCnlInfoApi } from "components/api/room";
import RoomBookCancelInfoView from "components/view/room/book/cancelInfo";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const RoomBookCancelInfoPage = () => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";

  // const params = {
  //   rmResvNo: state?.rmResvNo,
  //   rmPdNo: state?.rmPdNo,
  //   rmNo: state?.rmNo,
  // };

  // const query = useQuery({
  //   queryKey: ["rmResvInfo", memType],
  //   queryFn: () => {
  //     return rmResvCnlInfoApi(params);
  //   },
  //   enabled: !!state?.rmResvNo,
  //   onSuccess(data) {
  //     if (data) {
  //       const {
  //         header: { code, message },
  //         body,
  //       } = data;

  //       if (code === -1) {
  //         dispatch(
  //           setMessage({
  //             message: message || "검색 데이터가 없습니다.",
  //             isConfirm: true,
  //             handleClick() {
  //               dispatch(setMessage({ message: null }));
  //             },
  //           })
  //         );
  //       }
  //     }
  //   },
  // });

  const rmResvCnlDtlMutation = useMutation(rmResvCnlInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  useEffect(() => {
    const params = {
      rmResvNo: state?.rmResvNo ?? "",
      rmPdNo: state?.rmPdNo ?? "",
      rmNo: state?.rmNo ?? "",
    };
    rmResvCnlDtlMutation.mutate(params);
  }, [state]);

  const rmResvCnlDtl = rmResvCnlDtlMutation?.data?.body?.rmResvCnlDtl || {};

  return (
    <>
      <RoomBookCancelInfoView rmResvCnlDtl={rmResvCnlDtl} />
    </>
  );
};

export default RoomBookCancelInfoPage;
