import FormSearchBtn from "components/button/formSearch";
import SubHeader from "components/layout/header/subHeader";
import Select from "components/select";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import RmNoSelect from "components/select/room/rmNoSlect";
import { rmSearchProps } from "interface/room";
import { lodGrdCodeOptions, lodGrpStatus } from "util/option";
import RmTypeSelect from "components/select/room/rmTypSelect";
import RmOlkSelect from "components/select/room/rmOlkSelect";
import { regNum } from "util/common";
import RoomList from "components/tableList/room/list";
// import Grid from "components/tableList";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmSearchProps;
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
  handleSearch(): void;
};

const RoomeListView = ({
  memType,
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
  handleSearch,
}: IProps) => {
  return (
    <>
      <div className="search-form">
        <SubHeader title="객실" add />
        <div className="table-form">
          <table>
            <tbody>
              {memType === "admin" && (
                <tr>
                  <th>숙소그룹번호</th>
                  <td>
                    <LodGrpSelect
                      option={searchInfo.lodGrpNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소그룹명</th>
                  <td>
                    <input
                      type="text"
                      name="lodGrpNm"
                      value={searchInfo?.lodGrpNm}
                      onChange={(e) => handleChange(e.currentTarget)}
                    />
                  </td>
                </tr>
              )}
              {/* {memType === "admin" && (
                <tr>
                  <th>숙소등급</th>
                  <td>
                    <Select
                      id="lodGrpCd"
                      name="lodGrpCd"
                      options={lodGrdCodeOptions}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                </tr>
              )} */}

              {memType === "admin" && (
                <tr>
                  <th>숙소번호</th>
                  <td>
                    <LodNoSelect
                      disabled={!searchInfo?.lodGrpNo}
                      params={searchInfo.lodGrpNo}
                      option={searchInfo.lodNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소명</th>
                  <td>
                    <input
                      type="text"
                      name="lodNm"
                      value={searchInfo.lodNm}
                      onChange={(e) => handleChange(e.currentTarget)}
                    />
                  </td>
                </tr>
              )}

              <tr>
                <th>객실번호</th>
                <td>
                  <RmNoSelect
                    disabled={!searchInfo.lodNo}
                    params={searchInfo.lodNo}
                    option={searchInfo.rmNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>객실명</th>
                <td>
                  <input
                    type="text"
                    name="rmNm"
                    value={searchInfo.rmNm}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
              </tr>
              <tr>
                <th>객실타입</th>
                <td>
                  <RmTypeSelect
                    option={searchInfo.rmTypCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>객실전망</th>
                <td>
                  <RmOlkSelect
                    option={searchInfo.rmOlkCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th>객실 최대인원수 조회최소값</th>
                <td>
                  <input
                    type="text"
                    name="minPerCt"
                    value={searchInfo.minPerCt}
                    onChange={(e) => {
                      if (regNum.test(e.currentTarget.value)) {
                        handleChange(e.currentTarget);
                      } else {
                        return;
                      }
                    }}
                  />
                </td>
                <th>객실 최대인원수 조회최대값</th>
                <td>
                  <input
                    type="text"
                    name="maxPerCt"
                    value={searchInfo.maxPerCt}
                    onChange={(e) => {
                      if (regNum.test(e.currentTarget.value)) {
                        handleChange(e.currentTarget);
                      } else {
                        return;
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>상태</th>
                <td>
                  <Select
                    id="uyn"
                    name="uyn"
                    option={searchInfo.uyn}
                    options={lodGrpStatus}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            handleReset={handleReset}
            handleSearch={handleSearch}
          />
        </div>
      </div>

      <RoomList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default RoomeListView;
