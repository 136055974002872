import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface memberState {
  isSearch: boolean;
  searchMemberParams: {};
  admNo: string;
}

const initialState: memberState = {
  isSearch: false,
  admNo: "",
  searchMemberParams: {},
};

export const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    setIsSearchMember: (
      state,
      { payload }: PayloadAction<memberState["isSearch"]>
    ) => {
      state.isSearch = payload;
    },

    setMemNo: (state, { payload }: PayloadAction<string>) => {
      state.admNo = payload;
    },

    setSearchParams: (
      state,
      { payload }: PayloadAction<memberState["searchMemberParams"]>
    ) => {
      state.searchMemberParams = {
        ...state.searchMemberParams,
        payload,
      };
    },
  },
});

export const isSearchSelector = createSelector(
  [(state: RootState) => state.member.isSearch],
  (isSearch) => isSearch
);

export const admNoSelector = createSelector(
  [(state: RootState) => state.member.admNo],
  (admNo) => admNo
);

export const { setIsSearchMember, setMemNo } = memberSlice.actions;

export default memberSlice.reducer;
