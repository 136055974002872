import FormSearchBtn from "components/button/formSearch";
import SubHeader from "components/layout/header/subHeader";
import Select from "components/select";
import CountrySelect from "components/select/country";
import LodGrdSelect from "components/select/room/lodGrdSelect";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import LodList from "components/tableList/lod/list";
import { lodSearchProps } from "interface/lod";
import { useLocation } from "react-router-dom";
import { lodGrpStatus, lodSellAmdvcOptions } from "util/option";

type IProps = {
  searchInfo: lodSearchProps;
  isLoding: boolean;
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleSearch(): void;
  handleReset(): void;
};

const LodListView = ({
  searchInfo,
  isLoding,
  handleChange,
  handleSelect,
  handleReset,
  handleSearch,
}: IProps) => {
  const { pathname } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  return (
    <>
      <div className="search-form">
        <SubHeader title="숙소" add />
        <div className="table-form">
          <table>
            <tbody>
              {memType === "admin" && (
                <tr>
                  <th>숙소그룹번호</th>
                  <td>
                    <LodGrpSelect
                      option={searchInfo.lodGrpNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소그룹 명</th>
                  <td>
                    <input
                      type="text"
                      name="lodGrpNm"
                      value={searchInfo?.lodGrpNm}
                      onChange={(e) => handleChange(e.currentTarget)}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <th>숙소번호</th>
                <td>
                  <LodNoSelect
                    option={searchInfo?.lodGrpNo}
                    params={searchInfo.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소명</th>
                <td>
                  <input
                    type="text"
                    name="lodNm"
                    value={searchInfo.lodNm}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소 등급</th>
                <td>
                  <LodGrdSelect
                    option={searchInfo.lodGrdCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소 주소국가</th>
                <td>
                  <CountrySelect
                    handleSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소 주소1</th>
                <td>
                  <input
                    type="text"
                    name="lodAddr1"
                    value={searchInfo.lodAddr1}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
                <th>숙소 주소2</th>
                <td>
                  <input
                    type="text"
                    name="lodAddr2"
                    value={searchInfo.lodAddr2}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소계약 시작회일자</th>
                <td>
                  <input
                    type="date"
                    id="date"
                    max="2100-12-31"
                    name="contSdt"
                    value={searchInfo?.contSdt}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
                <th>숙소계약 종료조회일자</th>
                <td>
                  <input
                    type="date"
                    id="date"
                    max="2100-12-31"
                    name="contEdt"
                    value={searchInfo?.contEdt}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소 판매금액구분</th>
                <td>
                  <Select
                    id="sellAmdvc"
                    name="sellAmdvc"
                    option={searchInfo.sellAmdvc}
                    options={lodSellAmdvcOptions}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소상태</th>
                <td>
                  <Select
                    id="uYn"
                    name="uYn"
                    options={lodGrpStatus}
                    option={searchInfo.uYn}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            disabled={false}
            handleReset={handleReset}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <LodList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default LodListView;
