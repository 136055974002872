import { useMutation } from "@tanstack/react-query";
import { authMailApi } from "components/api/member";
import Step3View from "components/view/signup/step3";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const Step3Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname, search: params } = useLocation();
  const type = pathname.includes("admin") ? "admin" : "manager";
  const authMatilMutation = useMutation(authMailApi, {
    onSuccess(data, context) {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          navigate(`/signup/${type}/step2`, {
            state: params,
            replace: true,
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleAuthMail = () => {
    const url =
      type === "admin"
        ? `admin/join/adminCertInfo${params}`
        : `manager/join/manCertInfo${params}`;
    authMatilMutation.mutate(url);
  };
  return (
    <Step3View
      isLoading={authMatilMutation.isLoading}
      pathname={pathname}
      handleAuthMail={handleAuthMail}
    />
  );
};

export default Step3Page;
