import { roomHstrHead } from "util/option";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { isSearchSelector, setIsLoading, setIsSearch } from "store/commonSlice";
import NotData from "../notData";
import { useSelector } from "react-redux";
import { rmHstSearhInfo } from "interface/room";
import { pageNavProps } from "interface";
import { rmHstListApi } from "components/api/room";
import { dateFormat, getDay, inCom } from "util/common";
import i18n from "locales/i18n";

type IListProps = {
  memType: "admin" | "manager";
  searchInfo: Omit<rmHstSearhInfo, "pageNo">;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const [list, setList] = useState([]);
  const isSearch = useSelector(isSearchSelector);
  const rmHsrMutation = useMutation(rmHstListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          setPageNav(body?.pageNav);
          setList(body?.rmPdHistList);
        }
      }
    },
  });

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo?.lodGrpNo,
        lodChlCd: searchInfo?.lodChlCd,
        rmNo: searchInfo?.rmNo,
        opRgSeq: searchInfo?.opRgSeq,
        rmOpNm: searchInfo?.rmOpNm,
        sellSDt: searchInfo?.sellSDt?.replaceAll("-", ""),
        sellEDt: searchInfo?.sellEDt?.replaceAll("-", ""),
        pageNo: Number(search?.replace(`?page=`, "")) ?? 1,
      };
      rmHsrMutation.mutate(params);
    }
  }, [searchInfo, isSearch]);

  const pageRows = rmHsrMutation.data?.body?.pageNav?.pageRows;

  return (
    <>
      {list &&
        list?.map((li: any, index: number) => {
          return (
            <tr
              key={index}
              onClick={(e) => {
                e.preventDefault();
                navigate(`info?rmPdHistNo=${li?.rmPdHistNo}`, {
                  state: { searchInfo, url: pathname + search },
                });
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              {/* {memType === "admin" && <td>{li.lodGrpNo}</td>}
              {memType === "admin" && <td>{li.lodGrpNm}</td>}
              {memType === "admin" && <td>{li.lodNo}</td>}
              {memType === "admin" && <td>{li.lodNm}</td>} */}
              <td>{li?.rmNo}</td>
              <td>
                <span className="w-[7.5rem]">{li?.rmNm}</span>
              </td>
              <td>{li?.rmOpNm}</td>
              <td>{dateFormat(li?.sellDt ?? "")}</td>
              <td>{getDay(li?.sellDt, i18n.language)}</td>
              <td>{li?.rmPdCt}</td>
              <td>{li?.sellYCt}</td>
              <td>{li?.sellNCt}</td>
              <td>{li?.resvCt}</td>
              <td>{inCom(li?.rmSellAmt ?? "")}</td>
              <td>{li?.acsrNo}</td>
              <td>
                <span className="min-w-[10rem]">{li?.memo}</span>
              </td>
              <td>
                <span data-sts={li?.histStaTyp}>
                  {li?.histStaTyp === "C"
                    ? "생성"
                    : li?.histStaTyp === "D"
                    ? "삭제"
                    : "수정"}
                </span>
              </td>
              <td>
                <button className={li?.sellYn === "Y" ? "sll" : "n-use"}>
                  {li?.sellYn === "Y" ? "판매" : "중지"}
                </button>
              </td>
            </tr>
          );
        })}
    </>
  );
};

type IProps = {
  memType: "admin" | "manager";
  searchInfo: Omit<rmHstSearhInfo, "pageNo">;
};

const RoomHsrList = ({ memType, searchInfo }: IProps) => {
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = roomHstrHead.map((head) => {
    return head;
  });

  const [No, lodGrpNo, lodGrpNm, lodNo, lodNm, ...res] = headList;

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>
        <div>
          <table>
            <thead>
              {memType === "admin" && (
                <tr>
                  {[No, ...res].map((li) => (
                    <th key={li}>{li}</th>
                  ))}
                </tr>
              )}

              {memType === "manager" && (
                <tr>
                  {[No, ...res].map((li) => (
                    <th key={li}>{li}</th>
                  ))}
                </tr>
              )}
            </thead>

            <tbody>
              <Suspense>
                <List
                  searchInfo={searchInfo}
                  memType={memType}
                  setPageNav={setPageNav}
                />
              </Suspense>
            </tbody>
          </table>
        </div>
        <NotData
          isLoading={false}
          isNotData={!pageNav?.totalRows}
          isSuccess={!!pageNav}
          styles=""
          txt="Loading ..."
        />
      </div>
      {/* {rmList && !isLoading && <Pagenation total={1} />} */}
      {/* <AgGrid rowData={rowData} columnDefs={columnDefs} pagination={true} /> */}
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav?.totalPage} />
      )}
    </div>
  );
};

export default RoomHsrList;
