import { useQuery } from "@tanstack/react-query";
import { homeManagerInfoApi } from "components/api/home";
import HomeView from "components/view/home";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsSelect, setMessage } from "store/commonSlice";

const ManageHomePage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { data } = useQuery({
    queryKey: ["homeInfo", "manager"],
    queryFn: () => {
      return homeManagerInfoApi();
    },

    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const lodList = data?.body?.lodList || [];

  const [lodNo, setLodNo] = useState<string | null>(null);

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const value = target.dataset.value + "";
    setLodNo(value);
    if (value !== "A") {
      localStorage.setItem("sltLodNo", target.dataset.value + "");
      const lodGrpInfo = lodList.find((lod: any) => {
        return lod.lodNo === value;
      });
      localStorage.setItem("lodGrpInfo", JSON.stringify(lodGrpInfo));
      dispatch(setIsSelect(true));
      return;
    }
    if (value === "A") {
      localStorage.removeItem("sltLodNo");
      localStorage.removeItem("lodGrpInfo");
      dispatch(setIsSelect(false));

      return;
    }
  };

  return <HomeView lodNo={lodNo} data={data} handleSelect={handleSelect} />;
};

export default ManageHomePage;
