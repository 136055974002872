import { useSelector } from "react-redux";
import LoadingButton from "../loadingButton";
import { isLoadingSelector } from "store/commonSlice";

type IProps = {
  disabled?: boolean;
  handleSearch(): void;
  handleReset(): void;
};

const FormSearchBtn = ({
  disabled = false,
  handleReset,
  handleSearch,
}: IProps) => {
  const isLoading = useSelector(isLoadingSelector);
  return (
    <div className="search-btn-wrap">
      <LoadingButton
        txt={"검색"}
        styles="btn-search"
        isLoading={isLoading}
        disabled={disabled}
        onClick={handleSearch}
      />
      <button className="btn-reset mt-[0.75rem]" onClick={handleReset}>
        초기화
      </button>
    </div>
  );
};

export default FormSearchBtn;
