import RoomeListView from "components/view/room/rm/list";
import { rmSearchProps } from "interface/room";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";

export const RoomeListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  const memType = pathname.includes("admin") ? "admin" : "manager";
  let lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
      : "";

  const [searchInfo, setSearchInfo] = useState<rmSearchProps>({
    lodGrpNo: state?.lodGrpNo || lodGrpInfo?.lodGrpNo,
    lodGrpNm: state?.lodGrpNm || lodGrpInfo?.lodGrpNm,
    lodNo: state?.lodNo || localStorage.getItem("sltLodNo"),
    lodGrpCd: "",
    lodNm: state?.lodNm || "",
    rmNo: state?.rmNo || "",
    rmNm: state?.rmNm || "",
    rmTypCd: state?.rmTypCd || "",
    rmOlkCd: state?.rmOlkCd || "",
    maxPerCt: state?.maxPerCt || "",
    minPerCt: state?.minPerCt || "",
    uyn: state?.uYn || "",
  });

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;
    setSearchInfo({
      ...searchInfo,
      [name]:
        name === "maxPerCt" || name === "minPerCt" ? Number(value) : value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;
    if (name && value) {
      if (name === "lodGrpNo") {
        setSearchInfo({
          ...searchInfo,
          lodNo: "",
          rmNo: "",
          rmTypCd: "",
          rmOlkCd: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      if (name === "lodNo") {
        setSearchInfo({
          ...searchInfo,
          rmNo: "",
          rmTypCd: "",
          rmOlkCd: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: lodGrpInfo?.lodGrpNo ?? "",
      lodGrpNm: lodGrpInfo?.lodGrpNm ?? "",
      lodNo: localStorage.getItem("sltLodNo") ?? "",
      lodGrpCd: "",
      lodNm: "",
      rmNo: "",
      rmNm: "",
      rmTypCd: "",
      rmOlkCd: "",
      maxPerCt: "",
      minPerCt: "",
      uyn: "",
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };

  const handleSearch = () => {
    dispatch(setIsSearch(true));
    dispatch(setIsLoading(true));
    navigate(`?page=1`);
  };

  return (
    <>
      <RoomeListView
        memType={memType}
        searchInfo={searchInfo}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleReset={handleReset}
        handleSearch={handleSearch}
      />
    </>
  );
};

export default RoomeListPage;
