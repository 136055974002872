import { IAdmInfo } from "interface/member";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingButton from "components/button/loadingButton";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { setIsSearch, setMessage } from "store/commonSlice";
import { useState } from "react";
import Modal from "components/modal/modal";

type IProps = {
  title: string;
  memInfo: IAdmInfo | any;
  memType: "admin" | "manager";
  handleSttPwd(): void;
  handleWthdw(): void;
};

const MemInfoView = ({
  title,
  memInfo,
  memType,
  handleSttPwd,
  handleWthdw,
}: IProps) => {
  const { pathname, state, search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState({
    pwd: false,
    wthdw: false,
  });

  const authName =
    memInfo?.authGrpNm === "OTAOWENER01"
      ? "소유자"
      : memInfo?.authGrpNm === "OTAMAN01"
      ? "관리자"
      : memInfo?.authGrpNm === "OTADEV01"
      ? "개발자"
      : "리포터";

  // const date = search.includes('admNo') ?  memInfo.joiAprDtti ? memInfo.joiAprDtti.split(" ")[0] : "";

  // const name =
  //   memInfo?.admSurnm + memInfo.admFstnm ||
  //   memInfo?.manSurnm + memInfo?.manFstnm;

  const typeNm = search?.includes("admNo")
    ? "관리자"
    : search?.includes("manNo")
    ? "매니저"
    : "회원";

  return (
    <>
      <section className={"main-layout"}>
        <div className="title">
          <h3 className="pb-[0.625rem]">{title}</h3>
          <div className="flex items-center gap-x-4">
            {/* <div className="text-sm flex gap-x-4">
              <button
                className="p-2"
                onClick={() => {
                  dispatch(
                    setMessage({
                      message: "비밀번호를 변경하시겠습니까?",
                      isConfirm: true,
                      isCancel: true,
                      handleClick() {
                        setIsOpen({
                          pwd: true,
                          wthdw: false,
                        });
                        dispatch(setMessage({ message: null }));
                      },
                    })
                  );
                }}
              >
                비밀번호 설정
              </button>
              <button
                className="p-2"
                onClick={() => {
                  dispatch(
                    setMessage({
                      message: "회원탈퇴 하시겠습니까?",
                      isConfirm: true,
                      isCancel: true,
                      handleClick() {
                        setIsOpen({
                          pwd: false,
                          wthdw: true,
                        });
                        dispatch(setMessage({ message: null }));
                      },
                    })
                  );
                }}
              >
                탈퇴
              </button>
            </div> */}
            <button
              onClick={() => {
                navigate(state?.url || -1, {
                  state: state?.searchInfo,
                });
                dispatch(setIsSearch(true));
              }}
            >
              <IoIosCloseCircleOutline
                size={50}
                style={{ cursor: "pointer", padding: 10 }}
              />
            </button>
          </div>
        </div>
        <div className="mt-[-2rem]">
          <div className="table-form">
            <table>
              <tbody>
                <tr>
                  <th>{typeNm}번호</th>
                  <td colSpan={1}>
                    <input
                      type="text"
                      defaultValue={memInfo?.memNo ?? ""}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <th>{typeNm} 영문 이름</th>
                  <td>
                    <input
                      type="text"
                      defaultValue={memInfo?.fstnm ?? ""}
                      readOnly
                    />
                  </td>
                  <th>{typeNm} 영문 성</th>
                  <td>
                    <input
                      type="text"
                      defaultValue={memInfo?.surnm ?? ""}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <th>{typeNm} 소셜 연동</th>
                  <td>
                    <input type="text" defaultValue={""} readOnly />
                  </td>
                  <th>{typeNm} 이메일</th>
                  <td>
                    <input
                      type="text"
                      defaultValue={memInfo?.emlId ?? ""}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <th>{typeNm} 사용자명</th>
                  <td>
                    <input
                      type="text"
                      defaultValue={memInfo?.name ?? ""}
                      readOnly
                    />
                  </td>
                </tr>

                {memType === "manager" && (
                  <tr>
                    <th>{typeNm} 영문 직급</th>
                    <td>
                      <input type="text" defaultValue="" readOnly />
                    </td>
                  </tr>
                )}

                <tr>
                  <th>{typeNm} 국가번호</th>
                  <td>
                    <input
                      type="text"
                      defaultValue={memInfo.mobCntyCd ?? ""}
                      readOnly
                    />
                  </td>
                  <th>{typeNm} 전화번호</th>
                  <td>
                    <input
                      type="text"
                      name="mobNo"
                      maxLength={11}
                      defaultValue={memInfo?.mobNo ?? ""}
                      readOnly
                    />
                  </td>
                </tr>

                {memType === "admin" && (
                  <tr>
                    <th>{typeNm} 권한</th>
                    <td>
                      <input
                        type="text"
                        defaultValue={memInfo?.authName ?? ""}
                        readOnly
                      />
                    </td>
                  </tr>
                )}

                <tr>
                  <th>언어</th>
                  <td>
                    <input
                      type="text"
                      defaultValue={memInfo?.langCd ?? ""}
                      readOnly
                    />
                  </td>
                  <th> 통화 </th>
                  <td>
                    <input
                      type="text"
                      defaultValue={memInfo?.currCd ?? ""}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <th>{typeNm} 가입날짜</th>
                  <td>
                    <input type="text" defaultValue={memInfo?.date} readOnly />
                  </td>
                  <th>{typeNm} 상태</th>
                  <td>
                    <input
                      type="text"
                      value={memInfo.joiAprYn === "Y" ? "정상" : "수정"}
                      readOnly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <LoadingButton
            isLoading={false}
            txt={"수정"}
            styles={"btn-search"}
            disabled={false}
            onClick={() => {
              navigate(pathname.replace("info", "modify") + search, {
                state: {
                  searchInfo: state?.searchInfo,
                  url: memType === "manager" ? -1 : state?.url,
                },
              });
            }}
          />
        </div>
      </section>
      <Modal
        title={"비밀번호 변경"}
        btnText="변경"
        isOpen={isOpen.pwd}
        handleCloseModal={() => {
          setIsOpen({
            ...isOpen,
            pwd: false,
          });
        }}
        onClick={handleSttPwd}
      >
        <div className="py-2">
          <label className="w-full text-left pb-2" htmlFor="">
            비밀번호
          </label>
          <input type="text" className="mb-2" value={""} />
          <label className="w-full text-left pb-2" htmlFor="">
            비밀번호 확인
          </label>
          <input type="text" value="" />
        </div>
      </Modal>
    </>
  );
};

export default MemInfoView;
