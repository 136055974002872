import LodCreateView from "components/view/lod/lod/create";
import { lodProps } from "interface/lod";
import { useState } from "react";

const LodCreatePage = () => {
  const [info, setInfo] = useState<lodProps>({
    lodGrpNo: "",
    lodNo: "",
    lodGrdCd: "",
    lodCntyCd: "",
    lodAddr1: "",
    lodAddr2: "",
    lodAddr3: "",
    lodAddr4: "",
    lodZipc: "",
    lodTelCntyCd: "+82",
    lodTelNo: "",
    telCntyCd: "+82",
    telNo: "",
    lodEml: "",
    hpUrl: "",
    rmCnt: 0,
    manFstnm: "",
    manSurnm: "",
    manGrd: "",
    sellAmDvc: "",
    sellAmVatInYn: "N",
    resvETi: "",
  });

  const [addInfo, setAddInfo] = useState<any>();
  const [img, setImg] = useState<any>();

  const handleChange = (
    target: EventTarget & (HTMLInputElement | HTMLTextAreaElement)
  ) => {};

  const handleAddInfoChange = (name: string, value: string) => {};

  const handleSelect = (target: EventTarget & HTMLLIElement) => {};

  return (
    <LodCreateView
      info={info}
      addInfo={addInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleAddInfoChange={handleAddInfoChange}
    />
  );
};

export default LodCreatePage;
