import {
  lodDefaultInfoProps,
  lodDefaultSearchProps,
  lodGrpSearchProps,
  lodSearchProps,
} from "interface/lod";
import api from "./api";

export const lodGrpListApi = async (params: lodGrpSearchProps) => {
  const res = await api({
    method: "post",
    url: "",
    data: params,
  });

  return res;
};

export const lodGrpStatusApi = async () => {
  const res = await api({
    method: "post",
    url: "",
  });

  return res;
};

export const lodListApi = async (params: lodSearchProps) => {
  const res = await api({
    method: "get",
    url: "",
  });

  return res;
};

export const lodStatusApi = async (params: { lodNo: string; uYn: string }) => {
  const res = await api({
    method: "get",
    url: "",
  });
  return res;
};

export const lodDefaultListApi = async (params: lodDefaultSearchProps) => {
  const res = await api({
    method: "post",
    url: "",
    data: params,
  });

  return res;
};

export const lodDefaultCreateApi = async (params: lodDefaultInfoProps) => {
  const res = await api({
    method: "post",
    url: "",
    data: params,
  });

  return res;
};

export const lodGrpNoListApi = async () => {
  const res = await api({
    method: "get",
    url: "",
  });

  return res;
};

export const lodGrpNmListApi = async () => {
  const res = await api({
    method: "get",
    url: "",
  });

  return res;
};

export const logdmentStatus = async () => {
  const res = await api({
    method: "post",
    url: "",
  });

  return res;
};

export const createLodmentApi = async () => {
  const res = await api({
    method: "post",
    url: "",
    data: {},
  });

  return res;
};

export const lodmentInfoApi = async () => {
  const res = await api({
    method: "get",
    url: "",
  });

  return res;
};

export const lodmentModifyApi = async () => {
  const res = await api({
    method: "post",
    url: "",
    data: {},
  });

  return res;
};
