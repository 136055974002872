import "../list.scss";
import {
  authOptions,
  adminListHead,
  headType,
  userListHead,
} from "util/option";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IadmList } from "interface/member";
import { setMemNo, setIsSearchMember } from "store/memberSlice";
import { setMessage } from "store/commonSlice";
import Pagenation from "components/pagenation";
import { modifyUserStatusApi } from "components/api/member";
import Modal from "components/modal/modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotData from "../notData";
import i18n from "locales/i18n";
import { optionProps } from "interface";

type Iprops = {
  isLoading: boolean;
};

const UserList = ({ isLoading }: Iprops) => {
  const lng = i18n.language;
  const list = [] as [];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [memAdmNo, setmemAdmNo] = useState("");

  const statusMutation = useMutation(modifyUserStatusApi, {
    onSuccess(data, context) {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          setIsOpen(false);
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setIsSearchMember(true));
                dispatch(setMessage({ message: null }));
              },
            })
          );

          dispatch(setMemNo(""));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleJoinApply = ({
    admNo,
    apply,
  }: {
    admNo: string;
    apply: boolean;
  }) => {
    if (apply) {
      setIsOpen(!isOpen);
      setmemAdmNo(admNo);
    }
  };

  const handleModifyState = () => {
    statusMutation.mutate({ admNo: memAdmNo, admStatus: "D" });
  };

  const auth = (value: string) => {
    const authOb = authOptions.find((auth) => {
      return auth.value === value;
    });

    return authOb ? authOb[lng as keyof optionProps] : "";
  };

  const headList = userListHead.map((head) => {
    return head;
  });
  // const statusRender = (props: CustomCellRendererProps) => {
  //   const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  //   return <button onClick={() => alert("버튼 클릭")}>{cellValue}</button>;
  // };

  // const columnDefs = [
  //   { field: "번호", cellStyle: { width: 40 } },
  //   { field: "회원번호" },
  //   { field: "권한" },
  //   { field: "영문이름" },
  //   { field: "영문성" },
  //   { field: "이메일" },
  //   { field: "전화번호" },
  //   { field: "상태", editable: false, cellRenderer: statusRender },
  // ];

  // const rowData = list?.map((li, index) => {
  //   const list = {
  //     번호: index + 1,
  //     회원번호: li.admNo,
  //     권한: auth(li.authGrpCod),
  //     영문이름: li.admFstnm,
  //     영문성: li.admSurnm,
  //     이메일: li.emlId,
  //     전화번호: li.mobNo,
  //     상태: li.soYn === "Y" ? "탈퇴" : li.joiAprYn === "N" ? "신청" : "정상",
  //   };
  //   return list;
  // });

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          {list && (
            <h3>
              검색결과
              <span>
                총<b>{list && list.length}</b>개
              </span>
            </h3>
          )}
        </div>

        <table>
          <thead>
            <tr>
              {userListHead.map((li) => (
                <th key={li}>{li}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className={!list ? "" : "hidden"}>
              <td colSpan={8}>
                <NotData
                  isLoading={isLoading}
                  isNotData={!Boolean(list)}
                  styles=""
                  txt="Loading ..."
                />
              </td>
            </tr>

            {list &&
              list?.map((li: IadmList, index: number) => {
                return (
                  <tr key={li.admNo} className={li.soYn === "Y" ? "drop" : ""}>
                    <td>{index + 1}</td>
                    <td>
                      <button
                        onClick={() => {
                          dispatch(setMemNo(li.admNo));
                          navigate(`?admNo=${li.admNo}`);
                        }}
                        disabled={li.soYn === "Y"}
                      >
                        {li.admNo}
                      </button>
                    </td>
                    <td>{auth(li.authGrpCod)}</td>
                    <td>{li.admFstnm}</td>
                    <td>{li.admSurnm}</td>
                    <td>{li.emlId}</td>
                    <td>{li.mobNo}</td>
                    <td>
                      <span
                        className={
                          li.soYn === "Y"
                            ? "soYn"
                            : li.joiAprYn === "N"
                            ? "joiAprYn"
                            : ""
                        }
                        onClick={() =>
                          handleJoinApply({
                            admNo: li.admNo,
                            apply: li.joiAprYn === "N",
                          })
                        }
                      >
                        {li.soYn === "Y"
                          ? "탈퇴"
                          : li.joiAprYn === "N"
                          ? "신청"
                          : "정상"}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {list && !isLoading && <Pagenation total={1} />}
      <Modal
        title="회원 상태 변경"
        isOpen={isOpen}
        isLoading={statusMutation.isLoading}
        disabled={statusMutation.isLoading}
        handleCloseModal={() => setIsOpen(!isOpen)}
        onClick={handleModifyState}
      >
        <p>
          회원 가입을 <b>승인</b> 하시겠습니까??
        </p>
      </Modal>
    </div>
  );
};

export default UserList;

{
  /* <AgGrid
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={true}
          isLoading={isLoading}
        /> */
}
