import { useMutation } from "@tanstack/react-query";
import { promCreateApi } from "components/api/promotion";
import PromModifyView from "components/view/promotion/modify";
import { promProps } from "interface/promotion";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const PromModifyPage = () => {
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();

  const [info, setInfo] = useState<promProps>({
    promCd: "",
    influNm: "",
    influEml: "",
    influCnty: "대한민국",
    influLangCd: "",
    influMobCntyNo: "+82",
    influMobNo: "",
  });

  const handleChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;
    if (name === "mobCntyCd") {
      setInfo({
        ...info,
        influMobCntyNo: value + "",
      });
    }
    if (name === "country") {
      setInfo({
        ...info,
        influCnty: value + "",
      });
    }
  };
  const promMutation = useMutation(promCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    promMutation.mutate(info);
  };

  const promInfo = {} as any;

  return (
    <PromModifyView
      isLoading={false}
      info={info}
      promInfo={promInfo}
      handleSelect={handleSelect}
      handleChange={handleChange}
      handleModify={handleModify}
    />
  );
};

export default PromModifyPage;
