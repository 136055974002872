import { useMutation } from "@tanstack/react-query";
import { rmOpCreateApi } from "components/api/room";
import RoomOptionCreateView from "components/view/room/opt/create";
import { rmOptCreateProps } from "interface/room";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { setIsSuccess } from "store/roomSlice";

const RoomOptCreatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const memType = pathname.includes("manager") ? "manager" : "admin";

  let lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
      : "";

  const [info, setInfo] = useState<rmOptCreateProps | any>({
    lodGrpNo: lodGrpInfo?.lodGrpNo,
    lodGrpNm: lodGrpInfo?.lodGrpNm,
    lodNo: localStorage.getItem("sltLodNo") ?? "",
    lodNm: lodGrpInfo?.lodNm,
    // lodGrdCd: "",
    rmNo: "",
    brkfInYn: "N",
    latChkOutTi: "",
    uyn: "Y",
  });

  const [opAddInfo, setOpAddInfo] = useState<any>({});

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleAddInfoChange = (name: string, value: string) => {
    const names = name.split("_");
    const langCd = names[1];

    setOpAddInfo({
      ...opAddInfo,
      [langCd]: {
        ...opAddInfo[langCd],
        langCd,
        [names[0]]: value,
      },
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;
    if (name && value) {
      setInfo({
        ...info,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const rmOpCreateMutation = useMutation(rmOpCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setInfo({
            lodGrpNo: lodGrpInfo?.lodGrpNo,
            lodGrpNm: lodGrpInfo?.lodGrpNmk,
            lodNo: lodGrpInfo?.lodNo,
            // lodGrdCd: "",
            rmNo: "",
            brkfInYn: "N",
            latChkOutTi: "",
            uyn: "Y",
          });

          setOpAddInfo({});
          dispatch(setIsSuccess(true));

          dispatch(
            setMessage({
              message: "객실옵션이 성공적으로 등록되었습니다.",
              isConfirm: true,
              isCancel: true,
              cnfrmBtnText: "뒤록가기",
              canclBntText: "확인",
              handleClick() {
                navigate(-1);
                dispatch(setMessage({ message: null }));
                dispatch(setIsSuccess(true));
                // window.location.reload();
              },
              handleCancel() {
                // navigate(-1);
                dispatch(setMessage({ message: null }));
                dispatch(setIsSuccess(true));
                window.location.reload();
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    const { latChkOutTi, ...res } = info;

    const params = {
      ...res,
      latChkOutTi: latChkOutTi.replaceAll(":", "") ?? "1500",
      rmOpInfList: Object.values(opAddInfo),
    };

    dispatch(
      setMessage({
        message: "객실옵션을 등록하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          rmOpCreateMutation.mutate(params);
        },
      })
    );
  };

  return (
    <RoomOptionCreateView
      info={info}
      opAddInfo={opAddInfo}
      memType={memType}
      isLoading={rmOpCreateMutation.isLoading}
      handleChange={handleChange}
      handleAddInfoChange={handleAddInfoChange}
      handleSelect={handleSelect}
      handleCreate={handleCreate}
    />
  );
};
export default RoomOptCreatePage;
