import { useMutation } from "@tanstack/react-query";
import { admInfoApi, managerInfoApi } from "components/api/member";
import MemInfoView from "components/view/member/info";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { dateFormat } from "util/common";

const MemInfoPage = () => {
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const memType = search.includes("admin") ? "admin" : "manager";
  const [memInfo, setMemInfo] = useState({});

  const admMutation = useMutation(admInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const {
            admFstnm,
            admSurnm,
            admNo,
            authGrpCod,
            authGrpNm,
            currCd,
            emlId,
            joiAprDtti,
            joiReqDtti,
            langCd,
            mobCntyCd,
            mobNo,

            soYn,
            joiAprYn,
          } = body;
          setMemInfo({
            memNo: admNo,
            name: admSurnm + admFstnm,
            fstnm: admFstnm,
            surnm: admSurnm,
            authGrpCod,
            authGrpNm,
            date: dateFormat(joiReqDtti?.slice(0, 8), "-") ?? "",
            joiAprDtti,
            joiReqDtti,
            currCd,
            emlId,
            langCd,
            mobCntyCd,
            mobNo,
            soYn,
            joiAprYn,
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // const joiReqDtti = dateFormat(memInfo?.joiReqDtti?.slice(0, 8), "-") ?? "";

  const manMutation = useMutation(managerInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const {
            currCd,
            emlId,
            joiReqDtti,
            langCd,
            lodGrpNo,
            manFstnm,
            manNo,
            manSurnm,
            manTypCod,
            mobNo,
            soYn,
            mobCntyCd,
          } = body.manInfo;
          setMemInfo({
            currCd,
            emlId,
            joiReqDtti,
            date: dateFormat(joiReqDtti?.slice(0, 8), "-") ?? "",
            lodGrpNo,
            name: manSurnm + manFstnm,
            fstnm: manFstnm,
            surnm: manSurnm,
            memNo: manNo,
            mobNo,
            mobCntyCd,
            langCd,
            manTypCod,
            soYn,
          });
        }

        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleSttPwd = () => {
    alert("준비중입니다.");
  };

  const handleWthdw = () => {
    alert("준비중입니다.");
  };

  useEffect(() => {
    if (!!search.includes("manNo")) {
      manMutation.mutate(search.replace("?manNo=", ""));
      return;
    }
    if (!!search.includes("admNo")) {
      admMutation.mutate(search.replace("?admNo=", ""));
      return;
    }
  }, [pathname, search]);

  return (
    <>
      <MemInfoView
        title={"회원정보 상세"}
        memType={memType}
        memInfo={memInfo}
        handleSttPwd={handleSttPwd}
        handleWthdw={handleWthdw}
      />
    </>
  );
};

export default MemInfoPage;
