import LoadingButton from "components/button/loadingButton";
import SubHeader from "components/layout/header/subHeader";
import Modal from "components/modal/modal";
import i18n from "locales/i18n";
import { Dispatch, MutableRefObject, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isSearchSelector } from "store/commonSlice";
import { dateFormat, getDay, inCom } from "util/common";

type Iprops = {
  memType: "manager" | "admin";
  rmResvDtl: any;
  resvPayDtl: any;
  cnlInfo: {
    cnlFeeAmt: string;
    refAmt: string;
    rmPdCt: string;
  };
  cnlRef: MutableRefObject<boolean>;
  setCnlInfo: Dispatch<
    SetStateAction<{
      cnlFeeAmt: string;
      refAmt: string;
      rmPdCt: string;
    }>
  >;
  handleCancel(): void;
  handleCnlAmt(): void;
};

const RoomBookInfoView = ({
  memType,
  rmResvDtl,
  resvPayDtl,
  cnlInfo,
  cnlRef,
  setCnlInfo,
  handleCancel,
  handleCnlAmt,
}: Iprops) => {
  const { state } = useLocation();
  const isLoading = useSelector(isSearchSelector);

  const isPass =
    Number(new Date().toISOString().split("T")[0].replaceAll("-", "")) >=
    Number(rmResvDtl.resvSDt);

  const allPerCt =
    Number(resvPayDtl?.adltPerCt ?? 0) + Number(resvPayDtl?.chldPerCt ?? 0);

  const resvNm =
    rmResvDtl?.resvMemSurnm && rmResvDtl?.resvMemFstnm
      ? rmResvDtl?.resvMemSurnm + rmResvDtl?.resvMemFstnm
      : "";

  const cstNm =
    rmResvDtl?.resvCstSurnm && rmResvDtl?.resvCstFstnm
      ? rmResvDtl?.resvCstSurnm + rmResvDtl?.resvCstFstnm
      : "";

  return (
    <>
      <div className="main-cnt">
        <SubHeader
          title="객실예약 상세"
          close
          url={state?.url}
          state={state?.searchInfo}
        />
        <div className="table-form">
          <table>
            <tbody>
              {memType === "admin" && (
                <tr>
                  <th>숙소그룹명</th>
                  <td>
                    <input
                      type="text"
                      defaultValue={rmResvDtl?.lodGrpNm ?? ""}
                      readOnly
                    />
                  </td>
                  <th>숙소명</th>
                  <td>
                    <input
                      type="text"
                      defaultValue={rmResvDtl?.lodNm ?? ""}
                      readOnly
                    />
                  </td>
                </tr>
              )}
              <tr>
                <th>객실명</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.rmNm ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실타입</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.rmTypNm ?? ""}
                    readOnly
                  />
                </td>
                <th>객실전망</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.rmOlkNm ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실상품번호</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.rmPdNo ?? ""}
                    readOnly
                  />
                </td>
                <th>객실상품채널</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.lodChlNm ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              {/* <tr>
                <th>객실상품명</th>
                <td></td>
                <th>객실상품설명</th>
                <td></td>
              </tr> */}
              <tr>
                <th>객실상품 조식포함</th>
                <td>
                  <input
                    type="text"
                    defaultValue={
                      rmResvDtl?.brkfInYn === "Y" ? "포함" : "미포함"
                    }
                    readOnly
                  />
                </td>
                <th>객실상품 레이트 체크아웃시간</th>
                <td>
                  <input
                    type="text"
                    defaultValue={
                      rmResvDtl?.latChkOutTi?.replace(
                        /\B(?=(\d{2})+(?!\d))/g,
                        ":"
                      ) ?? ""
                    }
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실상품 특가할인</th>
                <td>
                  <input type="text" readOnly />
                </td>
              </tr>
              <tr>
                <th>객실상품 일자</th>
                <td>
                  <input
                    type="text"
                    value={dateFormat(rmResvDtl.sellDt ?? "")}
                    readOnly
                  />
                </td>
                <th>객실상품 요일 </th>
                <td>
                  <input
                    type="text"
                    defaultValue={
                      getDay(rmResvDtl?.sellDt + "", i18n.language) ?? ""
                    }
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실상품 금액</th>
                <td>
                  <input
                    type="text"
                    defaultValue={inCom(rmResvDtl?.sellPri ?? "")}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>프로모션번호</th>
                <td>
                  <input type="text" defaultValue="" readOnly />
                </td>
                <th>프로모션명</th>
                <td>
                  <input type="text" defaultValue={""} readOnly />
                </td>
              </tr>
              <tr>
                <th>프로모션 금액 </th>
                <td>
                  <input type="text" defaultValue={""} readOnly />
                </td>
              </tr>
              <tr>
                <th>객실예약 번호</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.rmResvNo ?? ""}
                    readOnly
                  />
                </td>
                <th>객실예약 순번번호</th>
                <td>
                  <input type="text" defaultValue={"1"} readOnly />
                </td>
              </tr>
              <tr>
                <th>객실예약 시작일자</th>
                <td>
                  <input
                    type="text"
                    value={dateFormat(rmResvDtl.resvSDt ?? "")}
                    readOnly
                  />
                </td>
                <th>객실예약 종료일자</th>
                <td>
                  <input
                    type="text"
                    value={dateFormat(rmResvDtl.resvEDt ?? "")}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 일수</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.resvDayCt ?? ""}
                    readOnly
                  />
                </td>
                <th>객실예약 수량</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.resvRmCt ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 전체인원수</th>
                <td>
                  <input type="text" defaultValue={allPerCt ?? ""} readOnly />
                </td>
                <th>객실예약 성인인원수</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.adltPerCt ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 아동인원수</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.chldPerCt ?? ""}
                    readOnly
                  />
                </td>
                <th>객실예약 무료인원수</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.basPerCt ?? ""}
                    readOnly
                  />
                </td>
              </tr>

              {/* <tr>
                <th>객실예약이메일 발송일시</th>
                <td>
                  <input type="text" readOnly />
                </td>
                <th>갤실예약팩스 발송일시</th>
                <td>
                  <input type="text" readOnly />
                </td>
              </tr> */}
              <tr>
                <th>객실예약 회원이름</th>
                <td>
                  <input type="text" defaultValue={resvNm} readOnly />
                </td>
                <th>객실예약 회원전화번호</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.resvMemMobNo ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 고객이름</th>
                <td>
                  <input type="text" defaultValue={cstNm} readOnly />
                </td>
                <th>객실예약 고객전화번호</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.resvCstMblNo ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 고객이메일</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmResvDtl?.resvCstEml ?? ""}
                    readOnly
                  />
                </td>
                <th>객실예약 취소</th>
                <td>
                  <button
                    className="btn-sm"
                    disabled={cnlRef.current || isPass}
                    onClick={handleCnlAmt}
                  >
                    {isPass
                      ? "투숙완료"
                      : cnlRef?.current
                      ? "취소완료"
                      : "예약취소"}
                  </button>
                </td>
              </tr>
              <tr>
                <th>정산금액</th>
                <td>
                  <input
                    type="text"
                    defaultValue={inCom(rmResvDtl?.resvSumAmt ?? "")}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 요청사항</th>
                <td colSpan={3}>
                  <textarea
                    name=""
                    id=""
                    defaultValue={rmResvDtl?.reqCm ?? ""}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>객실예약 결제정보</th>
                <td colSpan={3}>
                  <fieldset disabled={true} className="pay-inf">
                    <div>
                      <span>결제일시</span>
                      <span>
                        {dateFormat(resvPayDtl?.tranDtti?.slice(0, 8))}{" "}
                        {resvPayDtl?.tranDtti?.slice(8, 10)}:
                        {resvPayDtl?.tranDtti?.slice(10, 12)}:
                        {resvPayDtl?.tranDtti?.slice(12, 14)}
                      </span>
                    </div>
                    <div>
                      <span>결제등록일련번호</span>
                      <span>{resvPayDtl?.payRgSeq ?? ""}</span>
                    </div>
                    <div>
                      <span>결제금액</span>
                      <span>{inCom(resvPayDtl?.payAmt ?? "") ?? ""}</span>
                    </div>
                    <div>
                      <span>통화코드</span>
                      <span>{resvPayDtl?.currCd ?? ""}</span>
                    </div>
                    <div>
                      <span>할부월</span>
                      <span>
                        {resvPayDtl?.instMont === "00"
                          ? "일시불"
                          : resvPayDtl?.instMont}
                        {resvPayDtl?.instMont ? "월" : ""}
                      </span>
                    </div>
                    <div>
                      <span>결제유형</span>
                      <span>{resvPayDtl?.cardCorp}</span>
                    </div>
                    <div>
                      <span>결제언어코드</span>
                      <span>{resvPayDtl?.payLangCd}</span>
                    </div>
                    <div>
                      <span>구매자이름</span>
                      <span>{resvPayDtl?.buyerNm}</span>
                    </div>
                    <div>
                      <span>구매자 이메일</span>
                      <span>{resvPayDtl?.buyerEml ?? ""}</span>
                    </div>
                    <div>
                      <span>결제방법</span>
                      <span>{resvPayDtl?.cardCorp ?? ""}</span>
                    </div>
                  </fieldset>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        title={"환불수수료"}
        isOpen={!!(cnlInfo?.refAmt && cnlInfo?.rmPdCt)}
        btnText=""
        handleCloseModal={() => {
          setCnlInfo({
            cnlFeeAmt: "",
            refAmt: "",
            rmPdCt: "",
          });
        }}
        onClick={() => {}}
      >
        <div className="w-[80%] mx-auto">
          <div className="text-left">객실예약수 : {cnlInfo?.rmPdCt}</div>
          <div className="text-left"> 결제 금액 : {inCom(cnlInfo?.refAmt)}</div>
          <div className="text-left">
            취소 수수료 : {inCom(cnlInfo?.cnlFeeAmt)}{" "}
          </div>
        </div>
        <div className="text-sm text-red-350 text-center">
          * 숙소환불수수료부과방식 등의 정보에 따른 계산방식을 포함하여
          환불수수료 금액을 표시합니다.
        </div>
        <div className="w-full mt-8 flex justify-center gap-x-8">
          <LoadingButton
            txt="취소"
            styles="btn-search"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={handleCancel}
          />

          {/* <button
            className="btn-reset"
            onClick={() => {
              setCnlInfo({
                cnlFeeAmt: "",
                refAmt: "",
                rmPdCt: "",
              });
            }}
          >
            확인
          </button> */}
        </div>
      </Modal>
    </>
  );
};

export default RoomBookInfoView;
