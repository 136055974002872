import "./radio.scss";

type IProps = {
  id: string;
  name: string;
  label: string;
  disable?: boolean;
  defaultChecked?: boolean;
  labelstyles?: string;
  checked?: boolean;
  onChange(target: EventTarget & HTMLInputElement): void;
};

const RadioButton = ({
  id,
  name,
  label,
  disable,
  defaultChecked,
  checked,
  labelstyles,
  onChange,
}: IProps) => {
  return (
    <div className={`radio-btn`}>
      <input
        type="radio"
        id={id}
        name={name}
        defaultChecked={defaultChecked}
        disabled={disable}
        checked={checked}
        onChange={(e) => onChange(e.currentTarget)}
      />
      <label htmlFor={id} className={`${labelstyles}`}>
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
