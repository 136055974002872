import "./home.scss";
import { Link } from "react-router-dom";
import ChkInList from "./chkInList";
import PayInfoList from "./payInfoList";
import Select from "components/select";
import { IoAddCircleOutline } from "react-icons/io5";

type IProps = {
  lodNo: string | null;
  data: any;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const HomeView = ({ lodNo, data, handleSelect }: IProps) => {
  const dayChkInList = data?.body?.dayChkInList || [];
  const dayPayInfo = data?.body?.dayPayInfo || {};
  const lodInfo = data?.body?.lodList[0] || {};

  const lodList = data?.body?.lodList?.map((li: any) => {
    const option = {
      value: li.lodNo,
      kr: li.lodNm,
      en: li.lodNm,
    };
    return option;
  });

  const sltLodNo = localStorage.getItem("sltLodNo");
  const manInf = JSON.parse(localStorage.getItem("manInf") ?? "");

  return (
    <div className={`home-wrap`}>
      <h2>홈</h2>
      <div className="flex gap-x-5">
        <div className="home card ">
          <div className="home card-title">
            <h4>공지사항</h4>
            <Link
              to={`board`}
              onClick={(e) => {
                alert("페이지 준비중입니다.");
                e.preventDefault();
              }}
            >
              <IoAddCircleOutline size={30} style={{ cursor: "pointer" }} />
            </Link>
          </div>

          <ul>
            <li>
              <Link
                to={`board`}
                onClick={(e) => {
                  alert("페이지 준비중입니다.");
                  e.preventDefault();
                }}
              >
                9월 회계 마감 안내
              </Link>
            </li>
            <li>
              <Link
                to={`board`}
                onClick={(e) => {
                  alert("페이지 준비중입니다.");
                  e.preventDefault();
                }}
              >
                9월 회계 마감 안내
              </Link>
            </li>
          </ul>
        </div>
        <div className="home card ">
          <h4 className="home card-title">
            {manInf?.manSurnm } {manInf?.manFstnm}
          </h4>
          <ul>
            <li className="flex items-center gap-x-5 ">
              <label htmlFor="" className="w-[7.5rem]">
                숙소그룹
              </label>
              <input type="text" value={lodInfo?.lodGrpNm} readOnly />
            </li>
            <li className="flex pt-[1.25rem] items-center gap-x-5">
              <label htmlFor="" className="w-[7.5rem]">
                숙소
              </label>
              <Select
                wrapStyle="flex-1 w-full h-[2.75rem]"
                id="lodNo"
                name="lodNo"
                option={lodNo ?? sltLodNo + ""}
                options={[
                  { value: "A", kr: "선택하세요", en: "select" },
                  ...lodList,
                ]}
                onSelect={(target) => {
                  handleSelect(target);
                }}
              />
            </li>
          </ul>
        </div>
      </div>
      <h3>예약현황</h3>
      <strong className={!!sltLodNo ? "hidden" : "text-sm text-red-350"}>
        * 예약현황을 확인하시려면 숙소를 선택하여 주세요
      </strong>
      <div className={!!sltLodNo ? `home day-list` : "hidden"}>
        <PayInfoList payInfo={dayPayInfo} lodNo={lodInfo?.lodNo} />
        <ChkInList list={dayChkInList} lodNo={lodInfo?.lodNo} />
      </div>
    </div>
  );
};

export default HomeView;

{
  /* <div className="home-card ">
        <div className="home card-title">
          <h4>예약 이력 현황</h4>
          <button onClick={() => handleReload("bkHistory")} disabled={true}>
            <i
              className={`fa fa-refresh ${
                bkHstrLoading ? "fa-spin text-slate-400" : ""
              }`}
              aria-hidden="true"
            ></i>
          </button>
        </div>
        <table>
          <thead>
            <tr>
              {HomeBKHistory.map((bk) => (
                <th key={bk}>{bk}</th>
              ))}
            </tr>
          </thead>
          <BKHistoryView />
        </table>
        </div> */
}
