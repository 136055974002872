import SubHeader from "components/layout/header/subHeader";
import LoadingButton from "components/button/loadingButton";
import Select from "components/select";
import { aplyYnOptions, essYnOption, langOptions } from "util/option";
import "./term.scss";
import { prvProps } from "interface/term";
import RadioEditor from "components/radio/radioEditor";
import { regNum } from "util/common";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "locales/i18n";

type IProps = {
  isLoading: boolean;
  prv: prvProps;
  prvDtl: any;
  handleChange(name: string, value: string): void;
  handleModify(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const TrmModifyView = ({
  isLoading,
  prv,
  prvDtl,
  handleChange,
  handleModify,
  handleSelect,
}: IProps) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="search-form">
        <SubHeader title="약관 수정" back url={`/admin/trmsManage/trm`} />

        <div className="table-form">
          <table>
            <tbody>
              <tr>
                <th>약관명</th>
                <td>
                  <input
                    type="text"
                    name="prvNm"
                    defaultValue={prv.prvNm || prvDtl?.prvNm}
                    onChange={(e) => handleChange("prvNm", e.target.value)}
                  />
                </td>
                <th>언어</th>
                <td>
                  <Select
                    id={"langCd"}
                    name={"langCd"}
                    option={prv.langCd || prvDtl.langCd}
                    options={langOptions}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th>약관버전명</th>
                <td>
                  <input
                    type="text"
                    defaultValue={prv.verNm || prvDtl?.verNm}
                    onChange={(e) =>
                      handleChange("verNm", e.currentTarget.value)
                    }
                  />
                </td>
                <th>약관버전</th>
                <td>
                  <input
                    type="text"
                    defaultValue={prv.rgSeq || prvDtl?.rgSeq}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      if (!regNum.test(value)) {
                        return;
                      }

                      handleChange("rgSeq", value);
                    }}
                  />
                </td>
              </tr>
              {/* <tr>
                <th>약관코드</th>
                <td>
                  <input
                    type="text"
                    name="prvCd"
                    defaultValue={prv.prvCd || prvDtl?.prvCd}
                    onChange={(e) => handleChange("prvCd", e.target.value)}
                  />
                </td>
              </tr> */}
              <tr>
                <th>필수여부</th>
                <td>
                  <Select
                    id={"essYn"}
                    name={"essYn"}
                    options={essYnOption}
                    option={prv.essYn || prvDtl?.essYn}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>적용여부</th>
                <td>
                  <Select
                    id={"aplyYn"}
                    name={"aplyYn"}
                    option={prv.aplyYn || prvDtl?.aplyYn}
                    options={aplyYnOptions}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>

              <tr>
                <th>적용시작일</th>
                <td>
                  <input
                    type="date"
                    defaultValue={prv.aplySDt}
                    onChange={(e) =>
                      handleChange("aplySDt", e.currentTarget.value)
                    }
                  />
                </td>
                <th>적용종료일</th>
                <td>
                  <input
                    type="date"
                    defaultValue={prv.aplyEDt}
                    onChange={(e) =>
                      handleChange("aplyEDt", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>약관설명</th>
                <td colSpan={3}>
                  <textarea
                    name="prvDesc"
                    id=""
                    defaultValue={prv.prvDesc || prvDtl?.prvDesc}
                    onChange={(e) =>
                      handleChange("prvDesc", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <label htmlFor="" className="px-4 pb-4 text-base" data-dot>
            약관 내용
          </label>
          {prvDtl?.trmCn && (
            <RadioEditor
              options={[]}
              name={"trmCn"}
              info={{
                [`trmCn_${i18n.language}`]: prv.trmCn || prvDtl?.trmCn,
              }}
              onChange={handleChange}
            />
          )}
          {/* <Editor
            id="trmCn"
            content={prvDtl?.trmCn}
            handleChange={handleChange}
          /> */}
        </div>
        <div className="btn-form-wrap">
          <LoadingButton
            txt="약관수정"
            isLoading={isLoading}
            styles={"btn-search"}
            onClick={handleModify}
          />
          <button
            className="btn-reset"
            onClick={() => {
              navigate(-1);
            }}
          >
            뒤로가기
          </button>
        </div>
      </div>
    </>
  );
};

export default TrmModifyView;
