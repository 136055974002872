import Button from "components/button/createButtonWrap";
import SubHeader from "components/layout/header/subHeader";
import Radio from "components/radio/radio";
import RadioEditor from "components/radio/radioEditor";
import RadioContent from "components/radio/radiocontent";
import Select from "components/select";
import CountrySelect from "components/select/country";
import PhoneSelect from "components/select/phone";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import { lodProps } from "interface/lod";
import { IprivList } from "interface/term";

import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { regNum } from "util/common";
import {
  bankOptions,
  lngLabel,
  lastTimeOptions,
  lodGrpStatus,
  moneyOptions,
  uYnOptions,
} from "util/option";

type IProps = {
  info: lodProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleAddInfoChange(name: string, value: string): void;
};

const LodInfoView = ({
  info,
  handleChange,
  handleAddInfoChange,
  handleSelect,
}: IProps) => {
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();
  return (
    <div className="main-cnt">
      <SubHeader
        title="숙소 상세"
        close
        url={state?.url}
        state={state?.searchInfo}
      />

      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹 명</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
              <th>숙소 등급</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소명</th>
              <td colSpan={3}>
                <RadioContent
                  options={lngLabel}
                  name={"lodNm"}
                  readOnly
                  onChange={(target) =>
                    handleAddInfoChange("lodNm", target.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <th>숙소 설명</th>
              <td colSpan={3}>
                <RadioEditor
                  options={lngLabel}
                  name={"lodDesc"}
                  readOnly
                  info={undefined}
                  onChange={(name, value) => handleAddInfoChange(name, value)}
                />
              </td>
            </tr>
            <tr>
              <th>숙소그룹 주소국가</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소그룹 주소1</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    options={lngLabel}
                    name={"lodAddr1"}
                    readOnly
                    onChange={(target) =>
                      handleChange(target.name, target.value)
                    }
                  />
                </div>
              </td>
              <th>숙소그룹 주소2</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    options={lngLabel}
                    name={"lodAddr2"}
                    readOnly
                    onChange={(target) =>
                      handleChange(target.name, target.value)
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>숙소그룹 주소3</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    options={lngLabel}
                    name={"lodAddr3"}
                    readOnly
                    onChange={(target) =>
                      handleChange(target.name, target.value)
                    }
                  />
                </div>
              </td>
              <th>숙소그룹 주소4</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    options={lngLabel}
                    name={"lodAddr4"}
                    readOnly
                    onChange={(target) =>
                      handleChange(target.name, target.value)
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>숙소 우편번호</th>
              <td>
                <input type="text" name="lodZipc" value="" readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소 전화번호</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
              <th>숙소 팩스번호</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소 이메일</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
              <th>숙소 홈페이지</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소 총객실수량</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
              <th>숙소 UTC</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
            </tr>

            <tr>
              <th>담당1 영문 이름</th>
              <td>
                <input type="text" name="manSurnm" value="" readOnly />
              </td>
              <th>담당1 영문 성</th>
              <td>
                <input type="text" name="manFstnm" value="" readOnly />
              </td>
            </tr>
            <tr>
              <th>담당1 직급</th>
              <td>
                <input type="text" name="man" value="" readOnly />
              </td>
              <th>담당1 전화번호</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소 판매금액구분</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
              <th>숙소 판매금액 VAT포함여부</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소 판매당일 예약마감시간</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소 환불수수료 부과방식</th>
              <td colSpan={3} className="py-[0.375rem]">
                <div className="w-full flex flex-col gap-y-4 ">
                  <span className="text-xs mx-[13.25rem]">
                    취소 수수료율은 취소를 접수한 날짜를 기준으로 적용
                  </span>
                  <span className="w-full flex justify-start">
                    <Radio
                      id="PerNight"
                      name="refund"
                      label="PerNight"
                      lableStyle="w-[12.5rem]"
                      onChange={() => {}}
                    />
                    <span className="text-xs">
                      예약 일자별 판매금액에 취소수수료 부과 <br /> 일별판매금액
                      x 일별취소수수료율 (연박 시 각 일별취소수수료 합산)
                    </span>
                  </span>

                  <span className="w-full flex justify-start">
                    <Radio
                      id="PerBooking"
                      name="refund"
                      label="PerBooking"
                      lableStyle="w-[12.5rem]"
                      onChange={() => {}}
                    />
                    <span className="text-xs">
                      총판매금액에 취소수수료 부과
                      <br /> 총판매금엑 x 예약1일차취소수수료율 (연박 시 동일)
                    </span>
                  </span>

                  <span className="w-full flex justify-start">
                    <Radio
                      id="PerOneNight"
                      name="refund"
                      label="PerOneNight"
                      lableStyle="w-[12.5rem]"
                      onChange={() => {}}
                    />
                    <span className="text-xs">
                      예약1일차판매금액에 취소수수료 부과
                      <br /> 예약1일차판매금액 x 예약1일차취소수수료율 (연박 시
                      동일)
                    </span>
                  </span>
                  <span className="w-full italic underline text-right">
                    취소 수수료 부과 방식 예시
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>숙소 정산입금일</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
              <th>숙소 정산은행</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소 정산은행계좌번호</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
              <th>숙소 정산통화</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
            </tr>

            <tr>
              <th>
                숙소 유아나이기준 <br /> (설정 값 미만)
              </th>
              <td>
                <input type="text" value="" readOnly />
              </td>
              <th>
                숙소 아동나이기준 <br /> (설정 값 미만)
              </th>
              <td>
                <input type="text" value="" readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소 체크인 시간</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
              <th>숙소 체크아웃시간</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
            </tr>
            <tr className="">
              <th>숙소 이미지</th>
              <td colSpan={3}>
                <div className="flex items-center gap-x-5 py-[0.25rem]">
                  <span className={""}>
                    <input type="file" id="file" multiple onChange={() => {}} />
                    <label htmlFor="file" className="img-add" />
                  </span>

                  <span className="text-red-400">
                    * 최대 10개까지 등록 가능
                  </span>
                </div>

                <div className="img-wrap">
                  <div className="img-wrap">{/* <img src="" alt="" /> */}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button isLoading={false} disabled={true} onClick={() => {}} />
    </div>
  );
};

export default LodInfoView;
