import { promProps } from "interface/promotion"
import api from "./api"


export const promListApi = async () => {
    const res = await api({
        method: 'get',
        url: ''
    })
    return res
}

export const promCreateApi = async (params: promProps) => {
    const res = await api({
        method: 'post',
        url: '',
        data: params
    })

    return res
}