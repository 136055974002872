import { useLocation, useNavigate } from "react-router-dom";
import LoadingButton from "../loadingButton";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

type IProps = {
  txt?: string;
  cancelTxt?: string;
  isLoading: boolean;
  styles?: string;
  disabled?: boolean;
  cancelMsg?: string;
  link?: string;
  state?: any;
  onClick(): void;
  handleCancel?(): void;
};

const Button = ({
  txt = "생성",
  cancelTxt = "뒤로가기",
  isLoading,
  styles = "",
  disabled = false,
  link,
  state,
  onClick,
  handleCancel,
}: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="btn-form-wrap">
      <LoadingButton
        isLoading={isLoading}
        txt={txt}
        styles={"btn-search"}
        disabled={disabled}
        onClick={onClick}
      />

      <button
        className="btn-reset"
        onClick={() => {
          dispatch(
            setMessage({
              title: "",
              message: "페이지에서 나가시겠습니까?",
              isConfirm: true,
              isCancel: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                if (!!link) {
                  navigate(link, { state });
                  return;
                }
                if (!link) {
                  navigate(-1);
                  return;
                }
              },
            })
          );
        }}
      >
        {cancelTxt}
      </button>
    </div>
  );
};

export default Button;
