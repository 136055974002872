import { useQuery } from "@tanstack/react-query";
import { rmHstDtlApi } from "components/api/room";
import RoomHstInfoView from "components/view/room/hst/info";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const RmHstInfoPage = () => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const memType = pathname.includes("manager") ? "manager" : "admin";
  const query = useQuery({
    queryKey: ["hstInfo", memType],
    queryFn: () => {
      return rmHstDtlApi({ rmPdHistNo: search.replace("?rmPdHistNo=", "") });
    },
    enabled: !!search,
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const rmPdHistDtl = query?.data?.body?.rmPdHistDtl || {};

  return <RoomHstInfoView rmPdHistDtl={rmPdHistDtl} memType={memType} />;
};

export default RmHstInfoPage;
