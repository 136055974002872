import LodChlModifyView from "components/view/lod/channel/modify";
import { useState } from "react";

const LodChlModifyPage = () => {
  const [info, setInfo] = useState<any>({});
  const handleChange = (name: string, value: string) => {};

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, id } = target.dataset;
  };

  const handleModify = () => {};

  return (
    <LodChlModifyView
      info={info}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleModify={handleModify}
    />
  );
};

export default LodChlModifyPage;
