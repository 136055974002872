import { useState } from "react";
import LodChannelListView from "components/view/lod/channel/list";
import { lodChlSearchProps } from "interface/lod";
import { useLocation } from "react-router-dom";

const LodChannelListPage = () => {
  const { pathname } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  const [searchInfo, setSearchInfo] = useState<lodChlSearchProps>({
    lodGrpNo: "",
    lodNo: "",
    lodChlCd: "",
    lodGrdCd: "",
    SDt: "",
    EDt: "",
    uYn: "N",
  });
  const handleChange = (name: string, value: string) => {};

  const handleSelect = (target: EventTarget & HTMLLIElement) => {};
  const handleReset = () => {};
  const handleSearch = () => {};

  return (
    <LodChannelListView
      searchInfo={searchInfo}
      memType={memType}
      handleChange={handleChange}
      handleReset={handleReset}
      handleSearch={handleSearch}
      handleSelect={handleSelect}
    />
  );
};

export default LodChannelListPage;
