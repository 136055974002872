import LodModifyView from "components/view/lod/lod/modify";
import { lodProps } from "interface/lod";
import { useState } from "react";

const LodModifyPage = () => {
  const info = {} as lodProps;

  const [addInfo, setAddInfo] = useState<any>();
  const [img, setImg] = useState<any>();

  const handleChange = (
    target: EventTarget & (HTMLInputElement | HTMLTextAreaElement)
  ) => {};

  const handleAddInfoChange = (name: string, value: string) => {};

  const handleSelect = (target: EventTarget & HTMLLIElement) => {};
  return (
    <LodModifyView
      info={info}
      addInfo={addInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleAddInfoChange={handleAddInfoChange}
    />
  );
};

export default LodModifyPage;
