import SubHeader from "components/layout/header/subHeader";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { dateFormat } from "util/common";
type IProps = {
  trmDtl: any;
};
const TrmDtlView = ({ trmDtl }: IProps) => {
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();
  const termRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (termRef.current) {
      termRef.current.innerHTML = trmDtl?.trmCn;
    }
  }, [trmDtl]);

  return (
    <div className="main-layout">
      <SubHeader
        // title={"약관상세"}
        title={trmDtl?.prvNm}
        close
        url={`/admin/trmsManage/trm`}
      />
      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>약관명</th>
              <td>
                <input
                  type="text"
                  name="prvNm"
                  value={trmDtl?.prvNm ?? ""}
                  readOnly
                />
              </td>
              <th>약관버전명</th>
              <td>
                <input
                  type="text"
                  name="verNm"
                  value={trmDtl?.verNm ?? ""}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>약관코드</th>
              <td>
                <input
                  type="text"
                  name="prvCd"
                  value={trmDtl?.prvCd ?? ""}
                  readOnly
                />
              </td>
              {/* <th>언어</th>
              <td>
                <input
                  type="text"
                  name="prvCd"
                  value={trmDtl.langCd}
                  readOnly
                />
              </td> */}
            </tr>
            <tr>
              <th>필수여부</th>
              <td>
                <input
                  type="text"
                  name="prvCd"
                  value={trmDtl?.essYn === "Y" ? "필수" : "선택"}
                  readOnly
                />
              </td>
              <th>적용여부</th>
              <td>
                <input
                  type="text"
                  name="prvCd"
                  value={trmDtl?.aplyYn === "Y" ? "적용" : "미적용"}
                  readOnly
                />
              </td>
            </tr>

            <tr>
              <th>적용시작일</th>
              <td>
                <input
                  type="text"
                  value={dateFormat(trmDtl?.aplySDt ?? "")}
                  readOnly
                />
              </td>
              <th>적용종료일</th>
              <td>
                <input
                  type="text"
                  value={dateFormat(trmDtl?.aplyEDt ?? "")}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>약관설명</th>
              <td colSpan={3}>
                <textarea
                  name="prvDesc"
                  id=""
                  value={trmDtl?.prvDesc ?? ""}
                  readOnly
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <label htmlFor="">약관내용</label>
      <div ref={termRef} className="term-detail" />
      <div className="btn-form-wrap">
        <button
          className="btn-search"
          onClick={() => {
            navigate(`${pathname.replace("info", "modify")}${search}`, {
              state: {
                prvCd: search?.replace("?prvCd=", ""),
              },
            });
          }}
        >
          수정
        </button>
      </div>
    </div>
  );
};

export default TrmDtlView;
// <div className="term-list">
//   <div>{termDtl?.prvNm}</div>
//   <div ref={termRef} className="term-detail" />
// </div>
