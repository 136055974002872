import Layout from "components/layout";
import "../signup.scss";
import LoadingButton from "components/button/loadingButton";

type IProps = {
  pathname: string;
  isLoading: boolean;
  handleAuthMail(): void;
};

const Step3View = ({ isLoading, pathname, handleAuthMail }: IProps) => {
  return (
    <div className="no-logged-wrap">
      <h2 className="autho-mail">
        {pathname.includes("admin") ? "관리자" : "매니저"} 회원가입 인증메일
      </h2>
      <h3>
        본메일은
        <br />
        UB {pathname.includes("admin") ? "관리자" : "매니저"} 회원가입을 위한{" "}
        <br />
        안내 메일입니다.
        <br />
        <b>[이메일 인증하기]</b> 버튼을 클릭한 후
        <br />
        이동된 페이지를 통하여
        <br />
        남은 회원가입 절차를
        <br />
        완료하여 주시기 바랍니다.
      </h3>
      <div className="signup-btn">
        <LoadingButton
          isLoading={isLoading}
          txt={"이메일 인증하기"}
          styles={"btn-red"}
          onClick={handleAuthMail}
        />
      </div>
    </div>
  );
};

export default Step3View;
