import FormSearchBtn from "components/button/formSearch";
import SubHeader from "components/layout/header/subHeader";
import Select from "components/select";
import LodGrdSelect from "components/select/room/lodGrdSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import LodPrdList from "components/tableList/lod/prd";
import { lodGrpSearchProps, lodPrdSearchProps } from "interface/lod";
import { lodGrpStatus } from "util/option";
import { serialize } from "v8";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: lodPrdSearchProps;
  handleChange(name: string, value: string): void;
  handleSearch(): void;
  handleReset(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const LodPrdView = ({
  searchInfo,
  memType,
  handleChange,
  handleSelect,
  handleReset,
  handleSearch,
}: IProps) => {
  return (
    <>
      <div className="search-form">
        <SubHeader title="숙소제공 정보" add />

        <div className="table-form">
          <table>
            <tbody>
              <tr>
                <th>숙소그룹번호</th>
                <td>
                  <LodGrdSelect
                    option={searchInfo?.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소그룹 명</th>
                <td>
                  <LodNoSelect
                    option={searchInfo.lodGrpNo}
                    params={searchInfo.lodNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소번호</th>
                <td>
                  <LodNoSelect
                    option={searchInfo?.lodNo}
                    params={searchInfo?.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소명</th>
                <td>
                  <input
                    type="text"
                    value=""
                    onChange={(e) =>
                      handleChange("lodNm", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>숙소 등급</th>
                <td>
                  <LodGrdSelect
                    option={searchInfo.lodGrdCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소상태</th>
                <td>
                  <Select
                    id=""
                    name=""
                    options={lodGrpStatus}
                    onSelect={() => {}}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            disabled={false}
            handleSearch={handleSearch}
            handleReset={handleReset}
          />
        </div>
      </div>
      <LodPrdList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default LodPrdView;
