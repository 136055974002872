import LodChlCreateView from "components/view/lod/channel/create";
import { lodChlCreateProps } from "interface/lod";
import { useState } from "react";

const LodChlCreatePage = () => {
  const [info, setInfo] = useState<lodChlCreateProps>({
    lodGrpNo: "",
    lodChlCd: "",
    lodGrdCd: "",
    lodNo: "",
    aplyEDt: "",
    aplySDt: "",
    sellFeeRti: 0,
  });

  const handleChange = (name: string, value: string) => {};

  const handleSelect = (target: EventTarget & HTMLLIElement) => {};

  const handleCreate = () => {};
  return (
    <LodChlCreateView
      info={info}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleCreate={handleCreate}
    />
  );
};

export default LodChlCreatePage;
