import { trmDtlApi } from "components/api/term";
import "./term.scss";
import { RefObject, useRef } from "react";
import i18n from "locales/i18n";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import SubHeader from "components/layout/header/subHeader";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "components/select";
import { langOptions, userTypOptions } from "util/option";
import FormSearchBtn from "components/button/formSearch";
import TermList from "components/tableList/term/list";

type IProps = {
  searchInfo: {
    userType: string;
    langCd: string;
  };
  // prvList: any[];
  // prvCd: string;
  termRef: RefObject<HTMLDivElement>;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleSearch(): void;
  handleReset(): void;
  // handlePrvList(userType: string): void;
  // handlePrvDtl(prvCd: string): void;
};

const TermListView = ({
  // userType,
  // prvList,
  // prvCd,
  termRef,
  searchInfo,
  handleSelect,
  handleReset,
  handleSearch,
}: // handlePrvList,
// handlePrvDtl,
IProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const memType = pathname.includes("admin") ? "admin" : "manager";

  return (
    <>
      <div className="search-form">
        <SubHeader title="약관" add />
        <div className="table-form">
          <table>
            <tbody>
              <tr>
                <th>회원타입</th>
                <td>
                  <Select
                    id={"userType"}
                    name={"userType"}
                    option={searchInfo.userType}
                    options={userTypOptions}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>언어</th>
                <td>
                  <Select
                    id={"langCd"}
                    name={"langCd"}
                    option={searchInfo.langCd}
                    options={langOptions}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            handleSearch={handleSearch}
            handleReset={handleReset}
          />
        </div>
      </div>
      <TermList searchInfo={searchInfo} />
    </>

    // <div className="term-wrap">
    //   <div>
    //     <button
    //       onClick={() => handlePrvList("admin")}
    //       className={userType === "admin" ? "on" : ""}
    //     >
    //       관리자
    //     </button>
    //     <button
    //       onClick={() => handlePrvList("manager")}
    //       className={userType === "manager" ? "on" : ""}
    //     >
    //       매니저
    //     </button>
    //   </div>
    //   <div className="term-list">
    //     <ul>
    //       {prvList.map((prv) => (
    //         <li
    //           key={prv.prvCd}
    //           onClick={() => {
    //             handlePrvDtl(prv.prvCd);
    //           }}
    //         >
    //           {prv.prvNm}
    //         </li>
    //       ))}
    //     </ul>

    //     <div ref={termRef} className="term-detail" />
    //     <div className="btn-form-wrap">
    //       <button
    //         className="btn-search"
    //         onClick={() => {
    //           navigate(`/${memType}/term/modify`, {
    //             state: {
    //               prvCd,
    //             },
    //           });
    //         }}
    //       >
    //         수정
    //       </button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default TermListView;
