import "../list.scss";
import { authOptions, adminListHead } from "util/option";
import { useMutation } from "@tanstack/react-query";
import { IadmList, searchMemberListProps } from "interface/member";
import { setMemNo, setIsSearchMember } from "store/memberSlice";
import {
  isLoadingSelector,
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import Pagenation from "components/pagenation";
import { admListApi, modifyUserStatusApi } from "components/api/member";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import i18n from "locales/i18n";
import { optionProps, pageNavProps } from "interface";
import NotData from "../notData";

type IListProps = {
  searchInfo: Partial<searchMemberListProps>;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
  memType: "admin" | "manager";
};

const List = ({ searchInfo, memType, setPageNav }: IListProps) => {
  const lng = i18n.language;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const auth = (value: string) => {
    const authOb = authOptions.find((auth) => {
      return auth.value === value;
    });

    return authOb ? authOb[lng as keyof optionProps] : "";
  };

  const admMutation = useMutation(admListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setPageNav(body?.pageNav);
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          setList(body?.admList);
        }
        if (code === -1) {
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const statusMutation = useMutation(modifyUserStatusApi, {
    onSuccess(data, context) {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          // setIsOpen(false);
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setIsSearchMember(true));
                dispatch(setMessage({ message: null }));
              },
            })
          );

          dispatch(setMemNo(""));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch && searchInfo?.type === "admin") {
      const params = {
        authGrpCod: searchInfo?.authGrpCod,
        emlId: searchInfo?.emlId,
        mobCntyCd: searchInfo?.mobCntyCd,
        mobNo: searchInfo?.mobNo,
        admStatus: "",
        pageNo: 1,
      };
      admMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  return (
    <>
      {list &&
        list?.map((li: IadmList, index: number) => {
          return (
            <tr
              key={li.admNo}
              className={li.soYn === "Y" ? "drop" : ""}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`info?admNo=${li.admNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                    admNo: li.admNo,
                  },
                });
              }}
            >
              <td>{index + 1}</td>
              <td>
                <button
                  onClick={() => {
                    dispatch(setMemNo(li.admNo));
                    navigate(`?admNo=${li.admNo}`);
                  }}
                  disabled={li.soYn === "Y"}
                >
                  {li.admNo}
                </button>
              </td>
              <td>{auth(li.authGrpCod)}</td>
              <td>{li.admFstnm}</td>
              <td>{li.admSurnm}</td>
              <td>{li.emlId}</td>
              <td>{li.mobNo}</td>
              <td>
                <span
                  className={
                    li.soYn === "Y"
                      ? "soYn"
                      : li.joiAprYn === "N"
                      ? "joiAprYn"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  // onClick={handleJoinApply({
                  //   admNo: li.admNo,
                  //   apply: li.joiAprYn === "N",
                  // })}
                >
                  {li.soYn === "Y"
                    ? "탈퇴"
                    : li.joiAprYn === "N"
                    ? "신청"
                    : "정상"}
                </span>
              </td>
            </tr>
          );
        })}
    </>
  );
};

type Iprops = {
  searchInfo: Partial<searchMemberListProps>;
};

const AdminList = ({ searchInfo }: Iprops) => {
  const isLoading = useSelector(isLoadingSelector);

  const dispatch = useDispatch();
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [memAdmNo, setmemAdmNo] = useState("");

  // const handleJoinApply = ({
  //   admNo,
  //   apply,
  // }: {
  //   admNo: string;
  //   apply: boolean;
  // }) => {
  //   if (apply) {
  //     setIsOpen(!isOpen);
  //     setmemAdmNo(admNo);
  //   }
  // };

  // const handleModifyState = () => {
  //   statusMutation.mutate({ admNo: memAdmNo, admStatus: "D" });
  // };

  // const auth = (value: string) => {
  //   const authOb = authOptions.find((auth) => {
  //     return auth.value === value;
  //   });

  //   return authOb ? authOb[lng as keyof optionProps] : "";
  // };

  const headList = adminListHead.map((head) => {
    return head;
  });

  // const statusRender = (props: CustomCellRendererProps) => {
  //   const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

  //   return <button onClick={() => alert("버튼 클릭")}>{cellValue}</button>;
  // };

  // const columnDefs = [
  //   { field: "번호", cellStyle: { width: 40 } },
  //   { field: "회원번호" },
  //   { field: "권한" },
  //   { field: "영문이름" },
  //   { field: "영문성" },
  //   { field: "이메일" },
  //   { field: "전화번호" },
  //   { field: "상태", editable: false, cellRenderer: statusRender },
  // ];

  // const rowData = list?.map((li, index) => {
  //   const list = {
  //     번호: index + 1,
  //     회원번호: li.admNo,
  //     권한: auth(li.authGrpCod),
  //     영문이름: li.admFstnm,
  //     영문성: li.admSurnm,
  //     이메일: li.emlId,
  //     전화번호: li.mobNo,
  //     상태: li.soYn === "Y" ? "탈퇴" : li.joiAprYn === "N" ? "신청" : "정상",
  //   };
  //   return list;
  // });

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>

        <table>
          <thead>
            <tr>
              {headList.map((li) => (
                <th key={li}>{li}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <Suspense>
              <List
                searchInfo={searchInfo}
                setPageNav={setPageNav}
                memType={"manager"}
              />
            </Suspense>
          </tbody>
        </table>
      </div>
      <NotData
        isLoading={isLoading}
        isNotData={!pageNav?.totalRows}
        isSuccess={!!pageNav}
        styles=""
        txt="Loading ..."
      />
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav.totalPage} />
      )}
      {/* <Modal
        title="회원 상태 변경"
        isOpen={isOpen}
        isLoading={statusMutation.isLoading}
        disabled={statusMutation.isLoading}
        handleCloseModal={() => setIsOpen(!isOpen)}
        onClick={handleModifyState}
      >
        <p>
          회원 가입을 <b>승인</b> 하시겠습니까??
        </p>
      </Modal> */}
    </div>
  );
};

export default AdminList;

{
  /* <AgGrid
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={true}
          isLoading={isLoading}
        /> */
}
