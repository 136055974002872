import PromView from "components/view/promotion/list";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";

const PromPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchInfo, setSearchInfo] = useState({});

  const handleChange = (name: string, value: string) => {
    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };
  const handleReset = () => {
    setSearchInfo({});
  };
  const handleSearch = () => {
    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));
    navigate(`?page=1`);
  };
  return (
    <>
      <PromView
        searchInfo={searchInfo}
        handleReset={handleReset}
        handleSearch={handleSearch}
      />
    </>
  );
};

export default PromPage;
