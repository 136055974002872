import SubHeader from "components/layout/header/subHeader";
import { IRmHstrInfo } from "interface/room";
import { useLocation } from "react-router-dom";
import { dateFormat } from "util/common";

type IProps = {
  memType: "admin" | "manager";
  rmPdHistDtl: IRmHstrInfo;
};

const RoomHstInfoView = ({ memType, rmPdHistDtl }: IProps) => {
  const { state } = useLocation();

  return (
    <div className="main-cnt">
      <SubHeader
        title="객실상품이력 상세"
        close
        url={state?.url}
        state={state.searchInfo}
      />
      <div className="table-form">
        <table>
          <tbody>
            {memType === "admin" && (
              <tr>
                <th>숙소그룹명</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmPdHistDtl?.lodGrpNm ?? ""}
                    readOnly
                  />
                </td>
                <th>숙소명</th>
                <td>
                  <input
                    type="text"
                    defaultValue={rmPdHistDtl?.lodNm ?? ""}
                    readOnly
                  />
                </td>
              </tr>
            )}
            <tr>
              <th>객실상품채널</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdHistDtl?.lodChlNm ?? ""}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>객실번호</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdHistDtl?.rmNo ?? ""}
                  readOnly
                />
              </td>
              <th>객실명</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdHistDtl?.rmNm ?? ""}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>객실옵션명</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdHistDtl?.rmOpNm ?? ""}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>객실상품 일자</th>
              <td>
                <input
                  type="text"
                  defaultValue={dateFormat(rmPdHistDtl?.sellDt ?? "")}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>객실상품 상태</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    rmPdHistDtl?.sellYn === "Y"
                      ? "판매"
                      : rmPdHistDtl?.sellYn === "N"
                      ? "중지"
                      : ""
                  }
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>객실상품 생성수량</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdHistDtl?.rmPdCt ?? 0}
                  readOnly
                />
              </td>
              <th>객실상품 취소수량</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdHistDtl?.resvCt ?? 0}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>객실상품 판매중수량</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdHistDtl?.sellYCt ?? 0}
                  readOnly
                />
              </td>
              <th>객실상품 판메중지수량</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdHistDtl?.sellNCt ?? 0}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>최초접속자명</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdHistDtl?.fstCrtAscrNm ?? ""}
                  readOnly
                />
              </td>
              <th>접속자명</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdHistDtl?.acsrNm ?? ""}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>이력상태 유형</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    rmPdHistDtl?.histStaTyp === "C"
                      ? "생성"
                      : rmPdHistDtl?.histStaTyp === "D"
                      ? "삭제"
                      : rmPdHistDtl?.histStaTyp === "U"
                      ? "수정"
                      : ""
                  }
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>메모</th>
              <td colSpan={3}>
                <textarea
                  name=""
                  id=""
                  defaultValue={rmPdHistDtl?.memo ?? ""}
                  readOnly
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoomHstInfoView;
