import { useEffect, useState } from "react";
import "./pagenation.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsSearch, setIsLoading } from "store/commonSlice";

type IProps = {
  total: number;
};

const Pagenation = ({ total }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const pageCnt = 10;

  const pageNo = Number(search.split("=")[1]);

  const [pageNm, setPageNm] = useState<number[]>([]);

  const [info, setInfo] = useState({
    start: 0,
    end: total <= pageCnt ? total : pageCnt,
  });

  const handleClick = (page: number) => {
    // dispatch(setPage(page));
    navigate(`?page=${page}`);
    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));
  };

  // const handlePage = () => {
  //   let pageNum = [];
  //   for (let start = info.start; start < info.end; start++) {
  //     pageNum.push(start + 1);
  //   }
  //   setPageNm(pageNum);
  // };

  const handlePre = () => {
    const start = pageNm[0] - pageCnt;
    const end = start + pageCnt;

    setInfo({
      start: start > 0 ? start : 0,
      end,
    });

    navigate(`?page=${end - 1}`);
    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));

    let page = [];
    for (let i = start; i < end; i++) {
      page.push(i);
    }
    setPageNm(page);
  };

  const handleNext = () => {
    const start = pageNm[0] + pageCnt;
    const end = start + pageCnt > total ? total : start + pageCnt;
    setInfo({
      start: start,
      end: end,
    });
    navigate(`?page=${start}`);
    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));

    let page = [];
    for (let i = start - 1; i < end - 1; i++) {
      page.push(i + 1);
    }
    setPageNm(page);
  };

  useEffect(() => {
    let page = [];
    let end = total <= pageCnt ? total : pageCnt;
    for (let start = info.start; start < end; start++) {
      page.push(start + 1);
    }
    setPageNm(page);
  }, [total]);

  return (
    <div className="pagenation-wrap">
      <ul>
        {info.start > 1 && <li className="pre-btn" onClick={handlePre}></li>}
        {pageNm.map((page) => (
          <li
            key={page}
            className={pageNo === page ? "curr" : ""}
            onClick={() => handleClick(page)}
          >
            {page}
          </li>
        ))}
        {(total - info.end) % pageCnt > 0 && (
          <li className="nxt-btn" onClick={handleNext}></li>
        )}
      </ul>
    </div>
  );
};

export default Pagenation;
