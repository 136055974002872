import "./select.scss";
import { useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { countryCode } from "../countryCode";
import { setOpenPhoneSelect } from "store/commonSlice";
import i18n from "locales/i18n";

type IProps = {
  wrapStyle?: string;
  optionStyle?: string;
  btnStyle?: string;
  inpStyle?: string;
  disabled?: boolean;
  mobCd?: string;
  direct?: "up" | "down";
  mobNo?: string;
  isNum?: boolean;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleChange?(target: EventTarget & HTMLInputElement): void;
};

export interface codeProps {
  kr: string;
  en: string;
  phone: string;
  code: string;
}

const PhoneSelect = ({
  wrapStyle = "relative",
  optionStyle = "bg-white max-w-[25rem]",
  btnStyle = "flex-1 h-[2.75rem]",
  inpStyle,
  disabled = false,
  mobCd = "+82",
  mobNo,
  isNum = false,
  handleSelect,
  handleChange,
}: IProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lng = i18n.language;
  const optionRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const mobCntNm = countryCode.find((cnt) => {
    return "+" + cnt.phone === mobCd;
  });

  return (
    <div className={wrapStyle}>
      <div className={`${wrapStyle} phone-select-wrap`}>
        <button
          className={`${btnStyle} ${isOpen ? "on" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          disabled={disabled}
        >
          <span>
            {mobCd}
            <span className="text-sm hidden ps-2 sm:inline-flex">
              ( {lng === "kr" ? mobCntNm?.kr : mobCntNm?.en} )
            </span>
          </span>
        </button>

        {isNum && (
          <input
            type="text"
            // placeholder={t("plchl_nm_cmm")}
            placeholder="숫자만 입력"
            value={mobNo}
            name="mobNo"
            maxLength={11}
            className={inpStyle}
            onChange={(e) => {
              const isNum = !/[^0-9]/.test(e.currentTarget.value);
              if (isNum || !e.currentTarget.value) {
                handleChange && handleChange(e.currentTarget);
              } else {
                return;
              }
            }}
          />
        )}
      </div>

      <div
        className={`${optionStyle}  option-wrap ${isOpen ? `on` : ""} `}
        ref={optionRef}
        onClick={(e) => {
          e.stopPropagation();
          dispatch(setOpenPhoneSelect(false));
        }}
      >
        <ul className={`${isOpen ? ` on` : ""}`}>
          <li className="list-title">
            <span>국가명</span>
            <span>국가번호</span>
            <span>국가코드</span>
            {/* <span>{t("sb_ttl_phnm_cmm")}</span>
            <span>{t("sb_ttl_phno_cmm")}</span>
            <span>{t("sb_ttl_phcd_cmm")}</span> */}
          </li>
          {countryCode.map((op: codeProps) => (
            <li
              key={op.kr}
              data-name="mobCntyCd"
              data-lng={op[lng as keyof codeProps]}
              data-value={"+" + op.phone}
              data-code={op.code}
              className={""}
              onClick={(e) => {
                handleSelect(e.currentTarget);
                setIsOpen(!isOpen);
              }}
            >
              <span>{op[lng as keyof codeProps]}</span>
              <span>+{op.phone} </span>
              <span> {op.code}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PhoneSelect;
