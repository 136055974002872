import SubHeader from "components/layout/header/subHeader";
import RadioEditor from "components/radio/radioEditor";
import RadioContent from "components/radio/radiocontent";
import i18n from "locales/i18n";
import { useLocation, useParams } from "react-router-dom";
import { dateFormat, getDay, inCom } from "util/common";
import { lngLabel } from "util/option";

type IProps = {
  memType: "admin" | "manager";
  rmPdInfo: any;
  rmOpNmList: any;
};

const RmPdInfoView = ({ memType, rmPdInfo, rmOpNmList }: IProps) => {
  const { state } = useLocation();
  let rmOpNmInfo = {};
  let rmOpDescInfo = {};
  let labels = [] as any[];

  rmOpNmList.forEach((inf: any) => {
    let langCd = inf.langCd === "en" ? "us" : inf.langCd;
    rmOpNmInfo = {
      ...rmOpNmInfo,
      langCd: i18n.language,
      [`rmOpNm_${langCd}`]: inf.rmOpNm,
    };
    rmOpDescInfo = {
      ...rmOpDescInfo,
      langCd: i18n.language,
      [`rmOpDesc_${langCd}`]: inf.rmOpDesc,
    };
  });

  lngLabel.forEach((lang) => {
    rmOpNmList.forEach((inf: any) => {
      let langCd = lang.id === inf.langCd ? lang : "";
      if (!!langCd) {
        labels.push(langCd);
      }
    });
  });

  return (
    <div className="main-cnt">
      <SubHeader
        title="객실상품 상세"
        close
        url={state?.url}
        state={state?.searchInfo}
      />

      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹명</th>
              <td>
                <input type="text" defaultValue={rmPdInfo?.lodGrpNm} readOnly />
              </td>
              <th>숙소명</th>
              <td>
                <input type="text" defaultValue={rmPdInfo?.lodNm} readOnly />
              </td>
            </tr>
            <tr>
              <th>객실명</th>
              <td>
                <input type="text" defaultValue={rmPdInfo?.rmNm} readOnly />
              </td>
            </tr>
            <tr>
              <th>객실상품 번호</th>
              <td>
                <input type="text" defaultValue={rmPdInfo.rmPdNo} readOnly />
              </td>
              <th>객실상품채널</th>
              <td>
                <input type="text" defaultValue={rmPdInfo?.lodChlNm} readOnly />
              </td>
            </tr>
            <tr>
              <th>객실타입</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdInfo?.rmTypNm ?? ""}
                  readOnly
                />
              </td>
              <th>객실전망</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdInfo?.rmOlkNm ?? ""}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>객실옵션명</th>
              <td colSpan={3}>
                {Object.values(rmOpDescInfo).length > 0 && (
                  <RadioContent
                    options={labels}
                    name={"rmOpNm"}
                    info={rmOpNmInfo}
                    readOnly
                    onChange={(target) => {}}
                  />
                )}
              </td>
            </tr>
            <tr>
              <th>객실옵션설명</th>
              <td colSpan={3}>
                {Object.values(rmOpDescInfo).length > 0 && (
                  <RadioEditor
                    options={labels}
                    name={"rmOpDesc"}
                    contStyle=""
                    info={rmOpDescInfo}
                    readOnly={true}
                    onChange={(name, value) => {}}
                  />
                )}
              </td>
            </tr>

            <tr>
              <th>객실상품 조식포함</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdInfo?.brkfInYn === "Y" ? "포함" : "미포함"}
                  readOnly
                />
              </td>
              <th>객실상품 레이트 체크아웃시간</th>
              <td>
                <input
                  type="text"
                  value={
                    rmPdInfo?.latChkOutTi?.replace(
                      /\B(?=(\d{2})+(?!\d))/g,
                      ":"
                    ) ?? "11:00"
                  }
                  readOnly
                />
              </td>
            </tr>
            {/* <tr>
              <th>객실상품 특가할인</th>
              <td colSpan={1}>
                <div className="flex items-center justify-between gap-x-2 max-w-[25rem] pe-2">
                  <input
                    type="text"
                    className="unit"
                    name="dscPri"
                    maxLength={2}
                    //defaultValue={info.dscPri}
                    onChange={(e) => {
                      const isNum = !/[^0-9]/.test(e.currentTargetdefaultValue);
                      if (isNum || !e.currentTargetdefaultValue) {
                        handleChange && handleChange(e.currentTarget);
                      } else {
                        return;
                      }
                    }}
                  />
                  <span>%</span>
                </div>
              </td>
            </tr> */}

            <tr>
              <th>객실상품 일자</th>
              <td>
                <input
                  type="text"
                  id="date"
                  defaultValue={dateFormat(rmPdInfo.sellDt)}
                  readOnly
                />
              </td>
              <th>객실상품 적용요일</th>
              <td>
                <input
                  type="text"
                  defaultValue={getDay(
                    dateFormat(rmPdInfo.sellDt),
                    i18n.language
                  )}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>객실상품 생성수량</th>
              <td>
                <input type="text" defaultValue={rmPdInfo.rmPdCt} readOnly />
              </td>
              <th>객실상품 판매수량</th>
              <td>
                <input type="text" defaultValue={"0"} readOnly />
              </td>
            </tr>
            <tr>
              <th>객실상품 금액</th>
              <td>
                <div className="flex max-w-[25rem] gap-x-2">
                  <input
                    type="text"
                    name="rmPdSellPri"
                    value={inCom(rmPdInfo?.sellPri)}
                    readOnly
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>등록일시</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdInfo?.fstCrtDtti?.split(" ")[0] ?? ""}
                  readOnly
                />
              </td>
              <th>등록회원명</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdInfo?.fstCrtUsid}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>수정일시</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmPdInfo?.ltChDtti?.split(" ")[0] ?? ""}
                  readOnly
                />
              </td>
              <th>수정회원명</th>
              <td>
                <input type="text" defaultValue={rmPdInfo?.ltChUsid} readOnly />
              </td>
            </tr>
            <tr>
              <th>상태</th>
              <td>
                <input
                  type="text"
                  value={
                    rmPdInfo.sellYn === "Y"
                      ? "판매"
                      : rmPdInfo?.sellYn === "N"
                      ? "중지"
                      : ""
                  }
                  readOnly
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <Button
        txt="수정"
        isLoading={isLoading}
        disabled={isAble || isLoading}
        onClick={handleCreate}
      /> */}
    </div>
  );
};

export default RmPdInfoView;
