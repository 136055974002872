import { useMutation } from "@tanstack/react-query";
import { lodDefaultListApi } from "components/api/lod";
import LodDefaultListView from "components/view/lod/default/list";
import { lodDefaultSearchProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsLoading, setMessage } from "store/commonSlice";
import { setIsSearch } from "store/roomSlice";

const LodDefaultListPage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  const [searchInfo, setSearchInfo] = useState<lodDefaultSearchProps>({
    lodGrpNo: "",
    lodGrpNm: "",
    lodNo: "",
    lodGrpCd: "",
    lodNm: "",
    uYn: "A",
  });
  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;
    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng } = target.dataset;
    if (name && value) {
      setSearchInfo({
        ...searchInfo,
        [name]: value,
      });
    }
  };
  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: "",
      lodGrpNm: "",
      lodNo: "",
      lodGrpCd: "",
      lodNm: "",
      uYn: "A",
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };
  const searchMutation = useMutation(lodDefaultListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const handleSearch = () => {};

  return (
    <LodDefaultListView
      isLoading={false}
      memType={memType}
      searchInfo={searchInfo}
      defaultInfoList={[]}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleReset={handleReset}
      handleSearch={handleSearch}
    />
  );
};

export default LodDefaultListPage;
