import { useQuery } from "@tanstack/react-query";
import "./crr.scss";
import Calendar from "components/calendar";
import { rmCrrProps } from "interface/room";
import { useSelector } from "react-redux";
import { isLoadingSelector, isSearchSelector } from "store/commonSlice";
import { rmMonthListApi } from "components/api/room";
import { useState } from "react";

type IProps = {
  searchInfo: Omit<rmCrrProps, "sellSDt" | "sellEDt">;
};

const CurrentList = ({ searchInfo }: IProps) => {
  const isSearch = useSelector(isSearchSelector);
  const isLoading = useSelector(isLoadingSelector);

  const [now, setNow] = useState(new Date());
  const [date, setDate] = useState<any>({
    sellSDt:
      now.getFullYear() +
      "" +
      ("00" + (now.getMonth() + 1)).slice(-2) +
      "" +
      "01",
    sellEDt:
      now.getFullYear() +
      "" +
      ("00" + (now.getMonth() + 1)).slice(-2) +
      "" +
      "31",
  });
  const params = {
    lodNo: searchInfo?.lodNo,
    sellSDt: date?.sellSDt,
    sellEDt: date?.sellEDt,
  };

  const query = useQuery({
    queryKey: ["crrList", isSearch, params],
    queryFn: () => {
      return rmMonthListApi(params);
    },
    // enabled: isSearch,
  });

  const rmPdStaList = query?.data?.body?.rmPdStaList;

  const handleNext = () => {
    const nextMonth = new Date(now.getFullYear(), now.getMonth() + 2, 0);
    setNow(nextMonth);
    setDate({
      ...searchInfo,
      sellSDt:
        nextMonth.getFullYear() +
        "" +
        ("00" + (nextMonth.getMonth() + 1)).slice(-2) +
        "" +
        "01",
      sellEDt:
        nextMonth.getFullYear() +
        "" +
        ("00" + (nextMonth.getMonth() + 1)).slice(-2) +
        "" +
        "31",
    });
  };

  const handlePrev = () => {
    const prevMonth = new Date(now.getFullYear(), now.getMonth(), 0);
    setNow(prevMonth);

    setDate({
      ...searchInfo,
      sellSDt:
        prevMonth.getFullYear() +
        "" +
        ("00" + (prevMonth.getMonth() + 1)).slice(-2) +
        "" +
        "01",
      sellEDt:
        prevMonth.getFullYear() +
        "" +
        ("00" + (prevMonth.getMonth() + 1)).slice(-2) +
        "" +
        "31",
    });
  };

  const handleCurr = () => {
    const now = new Date();
    setNow(now);
    setDate({
      ...searchInfo,
      sellSDt:
        now.getFullYear() +
        "" +
        ("00" + (now.getMonth() + 1)).slice(-2) +
        "" +
        "01",
      sellEDt:
        now.getFullYear() +
        "" +
        ("00" + (now.getMonth() + 1)).slice(-2) +
        "" +
        "31",
    });
  };

  return (
    <div className="data-list-wrap">
      <Calendar
        now={now}
        date={date}
        rmPdStaList={rmPdStaList}
        isLoading={isLoading}
        handleNext={handleNext}
        handlePrev={handlePrev}
        handleCurr={handleCurr}
      />
    </div>
  );
};

export default CurrentList;
