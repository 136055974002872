import PhoneSelect from "components/select/phone";
import { IAdmInfo } from "interface/member";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "components/select";
import { memSts } from "util/option";
import { dateFormat } from "util/common";
import { setIsSearch } from "store/commonSlice";
import LoadingButton from "components/button/loadingButton";
type IProps = {
  title: string;
  info: {
    mobNo: string | null;
    mobCntyCd: string;
    joiAprYn: string | undefined;
  };
  memInfo: IAdmInfo;
  memType: "admin" | "manager";
  isLoading: boolean;
  handleChange(target: { name: string; value: string }): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleModifyMemInfo(): void;
};

const MemInfoModifyView = ({
  title,
  info,
  memInfo,
  memType,
  handleChange,
  handleSelect,
  handleModifyMemInfo,
}: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();

  const authName =
    memInfo?.authGrpNm === "OTAOWENER01"
      ? "소유자"
      : memInfo?.authGrpNm === "OTAMAN01"
      ? "관리자"
      : memInfo?.authGrpNm === "OTADEV01"
      ? "개발자"
      : "리포터";

  const date = memInfo.joiAprDtti ? memInfo.joiAprDtti.split(" ")[0] : "";

  const name =
    memInfo?.admFstnm + memInfo.admFstnm ||
    memInfo?.manFstnm + memInfo?.manSurnm;

  const typeNm = search.includes("manNo")
    ? "매니저"
    : search.includes("admNo")
    ? "관리자"
    : search.includes("memNo")
    ? "사용자"
    : "";

  const joiReqDtti = dateFormat(memInfo?.joiReqDtti?.slice(0, 8), "-");

  return (
    <>
      <section className={"main-layout"}>
        <div className="title">
          <h3 className="pb-[0.625rem]">{title}</h3>
        </div>
        <span className="border-b border-gray-600 pb-[1.25rem]"></span>
        <div className="table-form">
          <table>
            <tbody>
              <tr>
                <th>{typeNm}번호</th>
                <td colSpan={1}>
                  <input
                    type="text"
                    value={memInfo?.admNo || memInfo?.manNo}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>{typeNm} 영문 이름</th>
                <td>
                  <input
                    type="text"
                    value={memInfo?.admSurnm || memInfo?.manSurnm}
                    readOnly
                  />
                </td>
                <th>{typeNm} 영문 성</th>
                <td>
                  <input
                    type="text"
                    value={memInfo?.admFstnm || memInfo?.manFstnm}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>{typeNm} 소셜 연동</th>
                <td>
                  <input type="text" value={""} readOnly />
                </td>
                <th>{typeNm} 이메일</th>
                <td>
                  <input type="text" value={memInfo?.emlId} readOnly />
                </td>
              </tr>
              <tr>
                <th>{typeNm} 사용자명</th>
                <td>
                  <input type="text" value={name} readOnly />
                </td>
              </tr>

              {memType === "manager" && (
                <tr>
                  <th>{typeNm} 영문 직급</th>
                  <td>
                    <input type="text" value="" readOnly />
                  </td>
                </tr>
              )}

              <tr>
                <th>{typeNm} 국가번호</th>
                <td>
                  <PhoneSelect
                    wrapStyle="relative mx-auto"
                    optionStyle="max-w-[25rem] mx-auto"
                    handleSelect={handleSelect}
                    mobCd={info.mobCntyCd || memInfo.mobCntyCd}
                  />
                </td>
                <th>{typeNm} 전화번호</th>
                <td>
                  <input
                    type="text"
                    name="mobNo"
                    maxLength={11}
                    value={info.mobNo || memInfo?.mobNo}
                    onChange={(e) => {
                      handleChange({
                        name: "mobNo",
                        value: e.currentTarget.value,
                      });
                    }}
                  />
                </td>
              </tr>

              <tr>
                <th>{typeNm} 권한</th>
                <td>
                  <input type="text" value={authName ?? ""} readOnly />
                </td>
              </tr>

              <tr>
                <th>{typeNm} 언어</th>
                <td>
                  <input type="text" value={memInfo?.langCd} readOnly />
                </td>
                <th>{typeNm} 통화 </th>
                <td>
                  <input type="text" value={memInfo?.currCd} readOnly />
                </td>
              </tr>
              <tr>
                <th>{typeNm} 가입날짜</th>
                <td>
                  <input type="text" value={joiReqDtti} readOnly />
                </td>
                <th>{typeNm} 상태</th>
                <td>
                  <Select
                    id={"joiAprYn"}
                    name={"joiAprYn"}
                    wrapStyle="h-[2.75rem] w-full m-auto"
                    option={info?.joiAprYn || memInfo.joiAprYn}
                    options={memSts}
                    disabled={memType === "manager" ? true : false}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="btn-form-wrap">
          <LoadingButton
            txt="수정"
            styles="btn-search"
            isLoading={false}
            onClick={handleModifyMemInfo}
          />
          <button
            className="btn-reset"
            onClick={() => {
              navigate(state.url || -1, {
                state: {
                  searchInfo: state.searchInfo,
                  url: state.url,
                },
              });
              dispatch(setIsSearch(true));
            }}
          >
            뒤로가기
          </button>
        </div>
        {/* <ModifyButtonWrap
          txt="수정"
          isLoading={false}
          link={state.url}
          onClick={handleModifyMemInfo}
          handleCancel={() => {
            navigate(state.url, {
              state: {
                searchInfo: state.searchInfo,
                url: state.url,
              },
            });
          }}
        /> */}
      </section>
    </>
  );
};

export default MemInfoModifyView;
