import RoomBookListView from "components/view/room/book/list";
import { rmResvSearchProps } from "interface/room";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch, setMessage } from "store/commonSlice";
import { afterDay } from "util/common";
import { dateTypOptions } from "util/option";

const RoomBookListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();

  const memType = pathname.includes("admin") ? "admin" : "manager";
  let lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
      : "";
  const { sDt, eDt } = afterDay(14);
  const [searchInfo, setSearchInfo] = useState<rmResvSearchProps>({
    lodGrpNo: lodGrpInfo?.lodGrpNo,
    lodGrpNm: lodGrpInfo?.lodGrpNm,
    lodNm: lodGrpInfo?.lodNm,
    lodNo: lodGrpInfo?.lodNo,
    lodGrpCd: state?.lodGrpCd ?? "",
    rmNo: state?.rmNo ?? "",
    rmNm: state?.rmNM ?? "",
    rmTypCd: state?.rmTypCd,
    rmOlkCd: state?.rmOlkCd,
    maxPerCtMax: state?.maxPerCtMax ?? "",
    maxPerCtMin: state?.maxPerCtMin ?? "",
    rmPdNo: state?.rmPdNo ?? "",
    lodChlSeq: state?.lodChlSeq ?? "",
    lodChlCd: state?.lodCnlCd ?? "",
    rmResvNo: state?.rmResvNo ?? "",
    resvCstMblCntyCd: state?.resvCstMblCntyCd,
    resvCstMblNo: state?.resvCstMblNo ?? "",
    resvCstnm: state?.resvCstnm ?? "",
    rmOpNm: state?.rmOpNm ?? "",
    resvMemFstnm: state?.resvMemFstnm ?? "",
    resvMemMobNo: state?.resvMemMobNo ?? "",
    mobCntyCd: state?.mobCntyCd ?? "",
    memMobNo: state?.memMobNo ?? "",
    memFstnm: state?.memFstnm ?? "",
    resvCstFstnm: state?.resvCstFstnm ?? "",
    sellSDt: state?.sellSDt ?? "",
    sellEDt: state?.sellEDt ?? "",
    // cnlSDt: state?.cnlSDt ?? "",
    // cnlEDt: state?.cnlEDt ?? "",
    resvSDt: state?.resvSDt ?? "",
    resvEDt: state?.resvEDt ?? "",
    dateTyp: state?.dateTyp ?? dateTypOptions[0]?.value,
  });

  const handleChange = (name: string, value: string) => {
    if (name === "SDt" || name === "EDt") {
      if (searchInfo?.dateTyp === "rmPdDt") {
        setSearchInfo({
          ...searchInfo,
          [`sell${name}`]: value,
        });

        return;
      }
      if (searchInfo?.dateTyp === "rmResvDt") {
        setSearchInfo({
          ...searchInfo,
          [`resv${name}`]: value,
        });
        return;
      }
    }

    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;
    if (name && value) {
      if (name === "dateTyp") {
        setSearchInfo({
          ...searchInfo,
          [name]: value,
          sellEDt: "",
          sellSDt: "",
          resvEDt: "",
          resvSDt: "",
        });
        return;
      }
      if (name === "lodGrpNo") {
        setSearchInfo({
          ...searchInfo,
          lodNo: "",
          rmNo: "",
          rmOlkCd: "",
          rmTypCd: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      if (name === "lodNo") {
        setSearchInfo({
          ...searchInfo,
          rmNo: "",
          rmOlkCd: "",
          rmTypCd: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: lodGrpInfo?.lodGrpNo,
      lodGrpNm: lodGrpInfo?.lodGrpNm,
      lodNo: localStorage.getItem("sltLodNo") ?? "",
      lodGrpCd: "",
      lodNm: "",
      rmNo: "",
      rmNm: "",
      rmTypCd: "",
      rmOlkCd: "",
      maxPerCtMax: "",
      maxPerCtMin: "",
      rmPdNo: "",
      lodChlSeq: "",
      lodChlCd: "",
      rmResvNo: "",
      resvCstMblCntyCd: "",
      resvCstMblNo: "",
      resvCstnm: "",
      rmOpNm: "",
      resvMemFstnm: "",
      resvMemMobNo: "",
      mobCntyCd: "",
      memMobNo: "",
      memFstnm: "",
      resvCstFstnm: "",
      sellSDt: "",
      sellEDt: "",
      // cnlSDt: "",
      // cnlEDt: "",
      resvSDt: "",
      resvEDt: "",
      dateTyp: dateTypOptions[0]?.value,
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };

  const handleSearch = () => {
    if (
      !(
        (searchInfo.sellEDt && searchInfo.sellSDt) ||
        (searchInfo.resvSDt && searchInfo.resvEDt)
      )
    ) {
      dispatch(
        setMessage({
          message: "조회시작일자와 조회종료일자를 선택하여주세요!",
          isConfirm: true,
          handleClick() {
            dispatch(setMessage({ message: null }));
          },
        })
      );
      return;
    }

    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));
    navigate(`?page=1`);
  };

  return (
    <>
      <RoomBookListView
        memType={memType}
        searchInfo={searchInfo}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleReset={handleReset}
        handleSearch={handleSearch}
      />
    </>
  );
};

export default RoomBookListPage;
