import PromInfoView from "components/view/promotion/info";

const PromInfoPage = () => {
  const info = {} as any;
  return (
    <>
      <PromInfoView promInfo={info} />
    </>
  );
};

export default PromInfoPage;
