import { rmListHead } from "util/option";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import {
  isSearchSelector,
  setIsLoading,
  setMessage,
  setIsSearch,
  isLoadingSelector,
} from "store/commonSlice";
import NotData from "../notData";
import { useSelector } from "react-redux";
import { rmList, rmSearchProps } from "interface/room";
import { rmListApi, rmModifyApi } from "components/api/room";
import { pageNavProps } from "interface";
import i18n from "locales/i18n";
import Modal from "components/modal/modal";

type IListProps = {
  searchInfo: rmSearchProps;
  memType: "admin" | "manager";
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const [info, setInfo] = useState({
    isOpen: false,
    rmNo: "",
    uyn: "",
  });
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const rmListMutation = useMutation(rmListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        dispatch(setIsLoading(false));
        dispatch(setIsSearch(false));
        if (code === 0) {
          setPageNav(body?.pageNav);
          setList(body?.rmList);
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const statusMutation = useMutation(rmModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          dispatch(
            setMessage({
              message: "객실 상태를 변경하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setIsSearch(true));
                dispatch(setMessage({ message: null }));
                setInfo({
                  isOpen: false,
                  rmNo: "",
                  uyn: "",
                });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
          setInfo({
            isOpen: false,
            rmNo: "",
            uyn: "",
          });
        }
      }
    },
  });

  const handleStatus = () => {
    const params = {
      rmNo: info.rmNo,
      uyn: info.uyn,
      langCd: i18n.language,
    };

    statusMutation.mutate(params);
  };

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo?.lodGrpNo || state?.lodGrpNo,
        lodNo: searchInfo?.lodNo || state?.lodNo,
        // lodGrpCd: "",
        lodNm: searchInfo?.lodNm || state?.lodNm,
        rmNo: searchInfo?.rmNo || state?.rmNo,
        rmNm: searchInfo?.rmNm || state?.rmNm,
        rmTypCd: searchInfo?.rmTypCd || state?.rmTypCd,
        rmOlkCd: searchInfo?.rmOlkCd || state?.rmOlkCd,
        maxPerCt: searchInfo?.maxPerCt || state?.maxPerCt,
        minPerCt: searchInfo?.minPerCt || state?.minPerCt,
        uyn: searchInfo?.uyn || state?.uyn,
        pageNo: page || state?.pageNo,
      };

      rmListMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = rmListMutation.data?.body?.pageNav?.pageRows;

  return (
    <>
      {list &&
        list?.map((li: rmList, index: number) => {
          return (
            <tr
              key={index}
              onClick={(e) => {
                e.preventDefault();
                navigate(`info?rmNo=${li.rmNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                  },
                });
                dispatch(setIsSearch(false));
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              {memType === "admin" && (
                <td>
                  <button
                    onClick={() => {
                      navigate(`?lodGrpNo=${li.lodGrpNo}`);
                    }}
                  >
                    {li.lodGrpNo}
                  </button>
                </td>
              )}
              {memType === "admin" && <td>{li.lodGrpNm}</td>}
              {memType === "admin" && <td>{li.lodNo}</td>}
              {memType === "admin" && (
                <td>
                  <span className="w-[12.5rem]">{li.lodNm}</span>
                </td>
              )}
              <td>{li.rmNo}</td>
              <td>
                <span className="w-[12.5rem]">{li.rmNm}</span>
              </td>
              <td>
                <span className="w-[7.5rem]">{li.rmTypNm}</span>
              </td>
              <td>{li.rmOlkNm}</td>
              <td>{li.maxPerCt}</td>

              <td>
                <button
                  className={li.uyn === "Y" ? "" : "n-use"}
                  disabled={li.uyn === "Y"}
                  onClick={(e) => {
                    e.stopPropagation();
                    setInfo({
                      isOpen: true,
                      rmNo: li.rmNo,
                      uyn: "Y",
                    });
                  }}
                >
                  {li.uyn === "Y" ? "사용" : "미사용"}
                </button>
              </td>
            </tr>
          );
        })}
      <Modal
        title="상태 변경"
        isOpen={info.isOpen}
        cnlText="취소"
        isLoading={statusMutation.isLoading}
        disabled={statusMutation.isLoading}
        handleCloseModal={() => {
          setInfo({
            isOpen: false,
            rmNo: "",
            uyn: "",
          });
        }}
        onClick={handleStatus}
      >
        <div>객실 상태를 변경 하시겠습니까?</div>
      </Modal>
    </>
  );
};
type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmSearchProps;
};
const RoomList = ({ searchInfo, memType }: IProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = rmListHead.map((head) => {
    return head;
  });

  const [No, lodGrpNo, lodGrpNm, lodNo, lodNm, ...res] = headList;

  const tableRef = useRef<HTMLDivElement>(null);

  return (
    <div className="data-list-wrap" ref={tableRef}>
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>
        <div>
          <table>
            <thead>
              {memType === "admin" && (
                <tr>
                  {headList.map((li) => (
                    <th key={li}>{li}</th>
                  ))}
                </tr>
              )}

              {memType === "manager" && (
                <tr>
                  {[No, ...res].map((li) => (
                    <th key={li}>{li}</th>
                  ))}
                </tr>
              )}
            </thead>

            <tbody>
              <Suspense>
                <List
                  searchInfo={searchInfo}
                  setPageNav={setPageNav}
                  memType={memType}
                />
              </Suspense>
            </tbody>
          </table>
        </div>
        <NotData
          isLoading={isLoading}
          isNotData={!pageNav?.totalRows}
          isSuccess={!!pageNav}
          styles=""
          txt="Loading ..."
        />
      </div>
      {/* <AgGrid rowData={rowData} columnDefs={columnDefs} pagination={true} /> */}
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav?.totalPage} />
      )}
    </div>
  );
};

export default RoomList;
