import LoadingButton from "components/button/loadingButton";
import SubHeader from "components/layout/header/subHeader";
import RadioEditor from "components/radio/radioEditor";
import RadioContent from "components/radio/radiocontent";
import Grid from "components/tableList";
import i18n from "locales/i18n";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { langOptions, lngLabel } from "util/option";
type IProps = {
  memType: "admin" | "manager";
  rmOpDtl: any;
  addInfList: any[];
};

const RoomOptInfoView = ({ memType, rmOpDtl, addInfList }: IProps) => {
  const { pathname, search, state } = useLocation();
  const navigate = useNavigate();
  const lodGrpInfo = localStorage.getItem("lodGrpInfo") ?? "";
  let rmOpNmInfo = {};
  let rmOpDescInfo = {};
  let labels = [] as any[];

  addInfList.forEach((inf: any) => {
    let langCd = inf.langCd === "en" ? "us" : inf.langCd;
    rmOpNmInfo = {
      ...rmOpNmInfo,
      langCd: i18n.language,
      [`rmOpNm_${langCd}`]: inf.rmOpNm,
    };
    rmOpDescInfo = {
      ...rmOpDescInfo,
      langCd: i18n.language,
      [`rmOpDesc_${langCd}`]: inf.rmOpDesc,
    };
  });

  lngLabel.forEach((lang) => {
    addInfList.forEach((inf) => {
      let langCd = lang.id === inf.langCd ? lang : "";
      if (!!langCd) {
        labels.push(langCd);
      }
    });
  });

  return (
    <div className="main-cnt">
      <SubHeader
        title="객실옵션 상세"
        close
        url={state.url}
        state={state?.searchInfo}
      />
      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹명</th>
              {memType === "admin" && (
                <td>
                  <input
                    type="text"
                    defaultValue={rmOpDtl?.lodGrpNm ?? ""}
                    readOnly
                  />
                </td>
              )}

              {memType === "manager" && (
                <td>
                  <input
                    type="text"
                    defaultValue={rmOpDtl?.lodGrpNm ?? ""}
                    readOnly
                  />
                </td>
              )}
              <th>숙소명</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmOpDtl?.lodNm ?? ""}
                  readOnly
                />
              </td>
            </tr>
            {memType === "manager" && (
              <tr>
                <th>숙소체크인시간</th>
                <td>
                  <input
                    type="text"
                    defaultValue={
                      rmOpDtl?.chkInTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ??
                      ""
                    }
                    readOnly
                  />
                </td>
                <th>숙소체크아웃시간</th>
                <td>
                  <input
                    type="text"
                    defaultValue={
                      rmOpDtl?.chkOutTi?.replace(
                        /\B(?=(\d{2})+(?!\d))/g,
                        ":"
                      ) ?? ""
                    }
                    readOnly
                  />
                </td>
              </tr>
            )}
            <tr>
              <th>객실옵션 조식포함</th>
              <td>
                <input
                  type="text"
                  defaultValue={rmOpDtl?.brkfInYn === "N" ? "미포함" : "포함"}
                  readOnly
                />
              </td>
              <th>객실옵션 레이트 체크아웃시간</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    rmOpDtl?.latChkOutTi?.replace(
                      /\B(?=(\d{2})+(?!\d))/g,
                      ":"
                    ) ||
                    (lodGrpInfo
                      ? JSON.parse(lodGrpInfo ?? "")?.chkOutti?.replace(
                          /\B(?=(\d{2})+(?!\d))/g,
                          ":"
                        )
                      : "")
                  }
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>상태</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    rmOpDtl?.uyn === "Y"
                      ? "사용"
                      : rmOpDtl?.uyn === "N"
                      ? "미사용"
                      : ""
                  }
                  readOnly
                />
              </td>
            </tr>

            <tr>
              <th>객실옵션명</th>
              <td colSpan={3}>
                {Object.values(rmOpNmInfo).length !== 0 && (
                  <RadioContent
                    options={labels}
                    name={"rmOpNm"}
                    info={rmOpNmInfo}
                    readOnly
                    onChange={(target) => {}}
                  />
                )}
                {Object.values(rmOpDescInfo).length === 0 && (
                  <RadioContent
                    options={[]}
                    name={"rmOpNm"}
                    info={{}}
                    readOnly
                    onChange={(target) => {}}
                  />
                )}
              </td>
            </tr>
            <tr>
              <th>객실옵션설명</th>
              <td colSpan={3}>
                {Object.values(rmOpDescInfo).length !== 0 && (
                  <RadioEditor
                    options={labels}
                    name={"rmOpDesc"}
                    contStyle=""
                    info={rmOpDescInfo}
                    readOnly={true}
                    onChange={(name, value) => {}}
                  />
                )}
                {Object.values(rmOpDescInfo).length === 0 && (
                  <RadioEditor
                    options={[]}
                    name={"rmOpDesc"}
                    contStyle=""
                    info={{}}
                    readOnly={true}
                    onChange={(name, value) => {}}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-form-wrap">
        <LoadingButton
          isLoading={false}
          txt={"수정"}
          styles={"btn-search"}
          onClick={() => {
            navigate(pathname.replace("info", "modify") + search, {
              state: state,
            });
          }}
        />
      </div>
    </div>
  );
};

export default RoomOptInfoView;
