import Button from "components/button/createButtonWrap";
import SubHeader from "components/layout/header/subHeader";
import Select from "components/select";
import CountrySelect from "components/select/country";
import PhoneSelect from "components/select/phone";
import { promProps } from "interface/promotion";
import { regNum } from "util/common";
import { langOptions } from "util/option";

type IProps = {
  isLoading: boolean;
  promInfo: promProps;
  handleChange(name: string, value: string): void;
  handleCreate(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const PromCreateView = ({
  isLoading,
  promInfo,
  handleChange,
  handleCreate,
  handleSelect,
}: IProps) => {
  const isAble = true;
  return (
    <div className="main-cnt">
      <SubHeader title="프로모션 생성" />
      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>프로모션 코드</th>
              <td>
                <input
                  type="text"
                  name="promCd"
                  value={promInfo.promCd}
                  onChange={(e) =>
                    handleChange("promCd", e.currentTarget.value)
                  }
                />
              </td>
              <th>인풀루언서명</th>
              <td>
                <input
                  type="text"
                  name="influNm"
                  value={promInfo.influNm}
                  onChange={(e) =>
                    handleChange("influNm", e.currentTarget.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <th>인플루언서 휴대폰국가번호</th>
              <td>
                <PhoneSelect
                  handleSelect={handleSelect}
                  mobNo={promInfo.influMobNo}
                  mobCd={promInfo.influMobCntyNo}
                />
              </td>
              <th>인플루언서 휴대폰번호</th>
              <td>
                <input
                  type="text"
                  name="influMobNo"
                  value={promInfo.influMobNo}
                  maxLength={11}
                  onChange={(e) => {
                    if (regNum.test(e.currentTarget.value)) {
                      handleChange("influMobNo", e.currentTarget.value);
                    } else {
                      return;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>인플루언서 이메일</th>
              <td>
                <input
                  type="text"
                  name-="influEml"
                  value={promInfo.influEml}
                  onChange={(e) =>
                    handleChange("influEml", e.currentTarget.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <th>인풀루언서 국가</th>
              <td>
                <CountrySelect
                  option={promInfo.influCnty}
                  handleSelect={handleSelect}
                />
              </td>
              <th>인풀루언서 언어코드</th>
              <td>
                <Select
                  id={"langCd"}
                  name={"langCd"}
                  options={langOptions}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            {/* <tr>
                            <th>최초생성사용자</th>
                            <td></td>
                            <th>최초수정사용자</th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>최초생성일</th>
                            <td>
                                <input type="date" />
                            </td>
                            <th>최초수정일</th>
                            <td>
                                <input type="date" />
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
      <Button
        isLoading={isLoading}
        disabled={isAble || isLoading}
        onClick={handleCreate}
      />
    </div>
  );
};

export default PromCreateView;
