import { imgProps, removeImgProps } from "interface";
import api from "./api";

export const rmTypeListApi = async () => {
  const res = await api({
    method: "get",
    url: "comn/lodCdList/kr/RT",
  });

  return res;
};

export const rmOlkListApi = async () => {
  const res = await api({
    method: "get",
    url: "comn/lodCdList/kr/RV",
  });

  return res;
};

export const bedtypListApi = async () => {
  const res = await api({
    method: "get",
    url: "comn/lodCdList/kr/RB",
  });

  return res;
};

export const rmListApi = async (params: string) => {
  const res = await api({
    method: "get",
    url: `manager/rmman/rmList/${params}`,
  });

  return res;
};

export const lodListApi = async (params: string) => {
  const res = await api({
    method: "get",
    url: `manager/rmman/lodList/${params}`,
  });

  return res;
};

export const rmOpListApi = async (params: string) => {
  const res = await api({
    method: "get",
    url: `manager/rmman/rmOpList/${params}`,
  });

  return res;
};

export const modifyImgApi = async (params: imgProps) => {
  const res = await api({
    method: `post`,
    url: `manager/rmman/rmImgModify`,
    data: params,
  });
  return res;
};

export const removeImgApi = async (params: removeImgProps) => {
  const res = await api({
    method: "post",
    url: `manager/rmman/deleteRmImg`,
    data: params,
  });

  return res;
};
