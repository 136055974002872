// 정규식 체크

export const regNewLine = /(\n|\r\n)/g;

// 영문 + 숫자 + 특수 문자
export const regPwd = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,20}/;

// 이메일

export const regEmail =
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}/;
// /[a-zA-Z0-9._+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9.]+/;

// id | pw 정규식 체크 (영문 + 숫자)
export const regId = /^(?=.*[a-zA-z])(?=.*[0-9]).{8,20}/;

// export const regId = /^[a-zA-Z][a-zA-Z0-9]*$/;
export const regEN = /^[a-zA-Z]*$/;
export const regNum = /^[0-9]*$/;
export const regPW = /^(?=.*[a-zA-z])(?=.*[0-9]).{8,20}/;

//전화번호 정규 체크 (일반)
export const regtelNo = /^\d{2,3}\d{3,4}\d{4}$/; // /^\d{3}-\d{3,4}-\d{4}$/
export const regPhone = /^01([0|1|6|7|8|9]?)([0-9]{3,4})([0-9]{4})$/; //  /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/

//time
export const getTime = (date: number) => {
  if (!date) {
    return "";
  }
};

// 날짜
export const getDate = (int: number | string) => {
  if (!int) {
    return "";
  }
};

// timestamp
export const getTimestamp = () => {
  const date = new Date();
  const timestamp = date.getTime();
  return timestamp;
};

// 전화 '-' 입력
// export function getDashPhone(str: string) {
//   if (str) {
//     return str
//       .replace(/[^0-9]/g, "")
//       .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
//       .replace(/(\-{1,2})$/g, "");
//   }
//   return str ?? "";
// }

//콤마 입력
export const regIncom = /\B(?=(\d{3})+(?!\d))/g;

export const inCom = (price: string | number) => {
  return (price + "")?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//날짜
// export const dataFormat = (date: string | number, type: string) => {
//   if ((date + "").length > 6) {
//     return (date + "").replace(
//       /^(\d{0,4})(\d{0,2})(\d{0,2})$/g,
//       `$1${type}$2${type}$3`
//     );
//   }
//   if ((date + "").length <= 6) {
//     return (date + "").replace(/^(\d{0,4})(\d{0,2})(\d{0,2})$/g, `$1${type}$2`);
//   }
// };

// dateFormat
export const dateFormat = (date: string, sign = "-") => {
  const format = date?.replace(
    /^(\d{0,4})(\d{0,2})(\d{0,2})$/g,
    `$1${sign}$2${sign}$3`
  );

  return format;
};

// 요일
export const getDay = (date: string | number, langCd: string) => {
  const day = new Date(dateFormat(date + "" ?? ""));

  if (langCd === "kr") {
    switch (day.getDay() + "") {
      case "0":
        return "일요일";
      case "1":
        return "월요일";
      case "2":
        return "화요일";
      case "3":
        return "수요일";
      case "4":
        return "목요일";
      case "5":
        return "금요일";
      case "6":
        return "토요일";
    }
  }
  if (langCd === "en") {
    switch (day.getDay() + "") {
      case "0":
        return "SUM";
      case "1":
        return "MON";
      case "2":
        return "TUE";
      case "3":
        return "WED";
      case "4":
        return "THU";
      case "5":
        return "FRI";
      case "6":
        return "SAT";
    }
  }
};

export const useDate = () => {
  let date = [];

  for (let i = 1; i <= 30; i++) {
    date.push({
      name: i + "",
      value: i + "",
    });
  }

  return date;
};

export const lngPropsFormat = (info: any, name: string) => {
  const list = Object.keys(info).filter((li) => {
    return li.includes(name);
  });
  const newList = list.map((li) => {
    const value = li.split("_");

    const newvalue = {
      langCd: value[1],
      [name]: info[li],
    } as any;
    return newvalue;
  });

  return newList;
};

export const propsFormat = (info: any, name: string) => {
  const list = Object.keys(info).filter((li) => {
    return li.includes(name);
  });

  const newList = list.map((li) => {
    const value = li.split("_");

    const newvalue = {
      langCd: value[1],
      [name]: info[li],
    } as any;
    return newvalue;
  });

  const arr = Object.keys(info).filter((li) => {
    // return li.includes(name);
  });

  return newList;
};

type IProps = {
  isNext?: boolean;
  now: Date;
};

export const useCalendar = ({ now }: IProps) => {
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // +1
  const date = now.getDate();
  const day = now.getDay(); // day = 6 (토요일) day = 0 (일요일)
  const fdate = new Date(`${year}/${month}/1`); // current  month first date
  const ldate = new Date(year, month, 0).getDate(); // current month last date
  const lmldate = new Date(year, month - 1, 0).getDate(); // last month last date
  const fday = fdate.getDay(); // current month first day
  const yearSt = year + "";
  const monthSt = (month + "").length < 2 ? `0${month}` : month + "";
  const dateSt = (date + "").length < 2 ? `0${date}` : date + "";
  // const { year, month, date, day, fdate, ldate, lmldate, fday } = useDate(now);

  const current = new Date();
  const today =
    current.getFullYear() +
    ("00" + (current.getMonth() + 1).toString()).slice(-2) +
    ("00" + current.getDate().toString()).slice(-2);

  const lastMonth = [];
  const currentMonth = [];
  const nextMonth = [];
  let monthDate = [];

  for (let i = 0; i < fday; i++) {
    const date = lmldate - fday + i + 1;
    lastMonth.push({ month: month - 1, date: "", isLast: true });
  }

  for (let i = 1; i <= ldate; i++) {
    let isNow = new Date(year, month - 1, i);
    let isMonth = ("00" + (isNow.getMonth() + 1)).slice(-2);
    const isDate = ("00" + i.toString()).slice(-2);

    currentMonth.push({
      month,
      date: i,
      isCurrent: true,
      day: isNow.getDay(),
      dateSt: `${year}${isMonth}${isDate}`,
      isLast: Number(today) - Number(`${year}${isMonth}${isDate}`) > 0,
      isWeekStart: isNow.getDay() === 0,
      isWeekEnd: isNow.getDay() === 6,
      isFdate: i === fdate.getDate(),
      isLdate: i === ldate,
    });
  }

  for (let i = 0; i < 42 - ldate - fday; i++) {
    nextMonth.push({
      month: month + 1,
      date: i + 1,
    });
  }

  monthDate = [...lastMonth, ...currentMonth];

  return { year, month, day, monthDate };
};

export const afterDay = (diff: number) => {
  const now = new Date().toISOString();
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const day = new Date().getDate();

  let sDt = now.split("T")[0].replaceAll("-", "");
  let eDt = new Date(year, month, day + 7)
    .toISOString()
    .split("T")[0]
    .replaceAll("-", "");

  return {
    sDt,
    eDt,
  };
};
