import LoadingButton from "components/button/loadingButton";
import SubHeader from "components/layout/header/subHeader";
import RadioEditor from "components/radio/radioEditor";
import RadioContent from "components/radio/radiocontent";
import { imgList } from "interface";
import { IRmInfo } from "interface/room";
import i18n from "locales/i18n";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { lngLabel } from "util/option";

type IProps = {
  rmNo: string | undefined;
  rmDtl: IRmInfo;
  rmImgList: imgList[];
  rmAddInfList: any[];
};

const RoomInfoView = ({ rmNo, rmDtl, rmImgList, rmAddInfList }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname, search, state } = useLocation();

  const memType = pathname.includes("admin") ? "admin" : "manager";
  const lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
      : "";

  const rmInfo = {
    lodGrpNo: rmDtl?.lodGrpNo,
    lodGrpNm: rmDtl?.lodGrpNm,
    lodNo: rmDtl.lodNo,
    lodNm: rmDtl.lodNm,
    rmTypCd: rmDtl.rmTypCd,
    rmTypNm: rmDtl.rmTypNm,
    rmOlkCd: rmDtl.rmOlkCd,
    rmOlkNm: rmDtl.rmOlkNm,
    rmAreaDesc: rmDtl.rmAreaDesc,
    bedTypCd: rmDtl.bedTypCd,
    bedTypNm: rmDtl.bedTypNm,
    rmMinPerCt: rmDtl.minPerCt,
    rmMaxPerCt: rmDtl.maxPerCt,
    babyFreePerCt: rmDtl.babyFreePerCt,
    chldFreePerCt: rmDtl.chldFreePerCt,
  };

  let rmNmInfo = {};
  let rmDescInfo = {};
  let labels = [] as any[];

  rmAddInfList.forEach((inf) => {
    rmNmInfo = {
      ...rmNmInfo,
      langCd: i18n.language,
      [`rmNm_${inf.langCd}`]: inf.rmNm,
    };

    rmDescInfo = {
      ...rmDescInfo,
      langCd: i18n.language,
      [`rmDesc_${inf.langCd}`]: inf.rmDesc,
    };
    // labels = lngLabel.filter((lang) => {
    //   return lang.id === inf.langCd;
    // });
  });

  lngLabel.forEach((lang) => {
    rmAddInfList.forEach((inf) => {
      let langCd = lang.id === inf.langCd ? lang : "";
      if (!!langCd) {
        labels.push(langCd);
      }
    });
  });

  return (
    <div className="main-layout">
      <SubHeader
        title={"객실 상세"}
        close
        url={state.url}
        state={state.searchInfo}
      />

      <fieldset className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹명</th>

              <td>
                <input type="text" defaultValue={rmInfo.lodGrpNm} readOnly />
              </td>

              <th>숙소명</th>
              <td>
                <input type="text" defaultValue={rmInfo.lodNm} readOnly />
              </td>
            </tr>

            <tr>
              <th>객실타입</th>
              <td>
                <input type="text" defaultValue={rmInfo.rmTypNm} readOnly />
              </td>
              <th>객실전망</th>
              <td>
                <input type="text" defaultValue={rmInfo.rmOlkNm} readOnly />
              </td>
            </tr>
            <tr>
              <th>객실크기</th>
              <td>
                <input
                  type="text"
                  name="rmAreaDesc"
                  defaultValue={rmInfo.rmAreaDesc}
                  readOnly
                />
              </td>
              {/* <td className="relative">
                <div className="flex items-center justify-between gap-x-2 max-w-[25rem] pe-2">
                  <input
                    type="text"
                    name="rmAreaDesc"
                    className="unit"
                    defaultValue={rmInfo.rmAreaDesc}
                    readOnly
                  />
                  <span>m2</span>
                </div>
              </td> */}
              <th>객실침대종류</th>
              <td>
                <input type="text" defaultValue={rmInfo.bedTypNm} readOnly />
              </td>
            </tr>
            <tr>
              <th>객실 최소인원수</th>
              <td>
                <input
                  type="text"
                  name="rmMinPerCt"
                  defaultValue={rmInfo.rmMinPerCt}
                  readOnly
                />
              </td>
              <th>객실 최대인원수</th>
              <td>
                <input
                  type="text"
                  name="rmMaxPerCt"
                  defaultValue={rmInfo?.rmMaxPerCt ?? ""}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>객실유아 무료인원수</th>
              <td>
                <input
                  type="text"
                  name="babyFreePerCt"
                  defaultValue={rmInfo?.babyFreePerCt ?? ""}
                  readOnly
                />
              </td>
              <th>객실아동 무료인원수</th>
              <td>
                <input
                  type="text"
                  name="chldFreePerCt"
                  defaultValue={rmInfo?.chldFreePerCt ?? ""}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>상태</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    rmDtl?.uyn === "Y"
                      ? "사용"
                      : rmDtl?.uyn === "N"
                      ? "미사용"
                      : ""
                  }
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>객실명</th>
              <td colSpan={3}>
                {Object.values(rmNmInfo).length !== 0 && (
                  <RadioContent
                    options={labels}
                    name={"rmNm"}
                    info={rmNmInfo}
                    readOnly={true}
                    inpDisabled={true}
                    onChange={(target) => {}}
                  />
                )}
              </td>
            </tr>
            <tr>
              <th>객실설명</th>
              <td colSpan={3}>
                {Object.values(rmDescInfo).length !== 0 && (
                  <RadioEditor
                    options={labels}
                    name={"rmDesc"}
                    info={rmDescInfo}
                    readOnly={true}
                    onChange={(name, value) => {}}
                  />
                )}
              </td>
            </tr>

            <tr>
              <th>객실이미지</th>
              <td colSpan={3}>
                <div
                  className={rmImgList.length !== 0 ? "img-wrap" : "hidden "}
                >
                  {rmImgList.map((img, index) => (
                    <img src={img.resImgPhUrl} key={index} />
                  ))}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
      {
        <div className="btn-form-wrap">
          <LoadingButton
            isLoading={false}
            txt={"수정"}
            styles={"btn-search"}
            onClick={() => {
              navigate(pathname.replace("info", "modify") + search, {
                state: {
                  searchInfo: state.searchInfo,
                  url: state.url,
                },
              });
            }}
          />
        </div>
      }
    </div>
  );
};

export default RoomInfoView;
