import { pageNavProps } from "interface";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  isLoadingSelector,
  isSearchSelector,
  setIsLoading,
  setIsSearch,
} from "store/commonSlice";
import { dateFormat } from "util/common";
import { calcPromHead } from "util/option";

type IProps = {
  searchInfo: any;
};

type IListProps = {
  searchInfo: any;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);
  const [list, setList] = useState([]);

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo?.lodGrpNo || state?.lodGrpNo,
        lodNo: searchInfo?.lodNo || state?.lodNo,
        // lodGrpCd: "",
        lodNm: searchInfo?.lodNm || state?.lodNm,
        rmNo: searchInfo?.rmNo || state?.rmNo,
        rmNm: searchInfo?.rmNm || state?.rmNm,
        rmTypCd: searchInfo?.rmTypCd || state?.rmTypCd,
        rmOlkCd: searchInfo?.rmOlkCd || state?.rmOlkCd,
        maxPerCt: searchInfo?.maxPerCt || state?.maxPerCt,
        minPerCt: searchInfo?.minPerCt || state?.minPerCt,
        uyn: searchInfo?.uyn || state?.uyn,
        pageNo: page || state?.pageNo,
      };
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = 10;
  return (
    <>
      {list &&
        list.map((li: any, index) => {
          return (
            <tr
              key={index}
              onClick={(e) => {
                e.preventDefault();
                navigate(`info?rmNo=${li.rmNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                  },
                });
                dispatch(setIsSearch(false));
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              <td>{li?.prmCd}</td>
              <td>{li?.rmResvNo}</td>
              <td>{""}</td>
              <td>{dateFormat(li?.fstCrtDtti ?? "")}</td>
              <td>{dateFormat(li?.ltChDtti ?? "")}</td>
            </tr>
          );
        })}
    </>
  );
};

const CalculateList = ({ searchInfo }: IProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  return (
    <>
      <div className="data-list-wrap">
        <div className="data-cont">
          <div className="table-title">
            <h3>
              검색결과
              <span>
                총<b>{pageNav?.totalRows || 0}</b>개
              </span>
            </h3>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  {calcPromHead.map((li) => (
                    <th>{li}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <Suspense>
                  <List searchInfo={searchInfo} setPageNav={setPageNav} />
                </Suspense>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalculateList;
