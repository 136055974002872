import "./checkbox.scss";

type IProps = {
  id: string;
  name?: string;
  label: string;
  disabled?: boolean;
  checked?: boolean;
  labelStles?: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
};
const CheckButton = ({
  id,
  name,
  label,
  disabled = false,
  checked = false,
  labelStles,
  onChange,
}: IProps) => {

  return (
    <div className="check-btn">
      <input
        type="checkbox"
        id={id}
        name={name}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
      />
      <label htmlFor={id} className={labelStles}>
        {label}
      </label>
    </div>
  );
};

export default CheckButton;
