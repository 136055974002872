import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { signupAdminInfoApi, signupManageInfoApi } from "components/api/member";
import { signupInfoProps } from "interface/member";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { regEmail } from "util/common";
import Step1View from "components/view/signup/step1";

const Step1Page = () => {
  const dispatch = useDispatch();
  const navigete = useNavigate();
  const { pathname } = useLocation();

  // const authGrpCod = useSelector(authselectSelector);

  const type = pathname.includes("manager") ? "manager" : "admin";

  const [info, setInfo] = useState<signupInfoProps>({
    emlId: "",
    fstnm: "",
    surnm: "",
    mobCntyCd: "+82",
    telNo: "",
    authGrpCod: "",
    lodGrpNo: "",
  });

  const handleChange = (target: { name: string; value: string }) => {
    const { name, value } = target;

    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng, type } = target.dataset;

    if (name === "authGrpCod") {
      setInfo({
        ...info,
        authGrpCod: value,
      });
    }
    if (name === "lodGrpNo") {
      setInfo({
        ...info,
        lodGrpNo: value === "A" ? "" : value,
      });
    }

    if (name === "mobCntyCd") {
      setInfo({
        ...info,
        mobCntyCd: value ?? "+82",
      });
    }
  };

  const adminMutation = useMutation(
    signupAdminInfoApi,
    {
      onSuccess: (data, context) => {
        if (data) {
          const { code, message } = data.header;
          if (code === 0) {
            navigete("guide", { state: info.emlId, replace: true });
          }
          if (code === -1) {
            dispatch(setMessage({ message }));
          }
        }
      },
      onError: (error, variables, context) => {
        console.error("error", error, variables, context);
      },
    }
    // {
    //   retry: 3,
    //   retryDelay: 1000,
    // }
  );

  const manageMutation = useMutation(signupManageInfoApi, {
    onSuccess(data, variables, context) {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          navigete("guide", { state: info.emlId, replace: true });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const handleSignup = () => {
    const { emlId, fstnm, surnm, mobCntyCd, telNo, authGrpCod, lodGrpNo } =
      info;

    const type = pathname.includes("admin") ? "admin" : "manager";

    if (type === "admin") {
      const infoParam = {
        admFstnm: fstnm,
        admSurnm: surnm,
        admEmlId: emlId,
        mobNo: telNo,
        authGrpCod,
        mobCntyCd,
      };
      adminMutation.mutate(infoParam);
    }

    if (type === "manager") {
      const infoParam = {
        lodGrpNo: lodGrpNo ?? "",
        manFstnm: fstnm,
        manSurnm: surnm,
        manEmlId: emlId,
        mobCntyCd,
        mobNo: telNo,
      };

      manageMutation.mutate(infoParam);
    }
  };

  return (
    <>
      <Step1View
        info={info}
        type={type}
        isLoading={adminMutation.isLoading || manageMutation.isLoading}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleSignup={handleSignup}
      />
    </>
  );
};

export default Step1Page;
