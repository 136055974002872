import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { lodListApi } from "components/api/lod";
import LodgmentListView from "components/view/lod/lod/list";
import { lodSearchProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMessage, setSelectLng } from "store/commonSlice";

const LodgmentListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchInfo, setsearchInfo] = useState<lodSearchProps>({
    lodGrpNo: "",
    lodGrpNm: "",
    lodNo: "",
    lodGrdCd: "",
    lodNm: "",
    lodAddr1: "",
    lodAddr2: "",
    sellAmdvc: "A",
    contSdt: "",
    contEdt: "",
    uYn: "A",
  });

  const [lodList, setLodList] = useState();

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;
    setsearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, lng, value } = target.dataset;
    if (value && lng && name) {
      setsearchInfo({
        ...searchInfo,
        [name]: value,
      });
    }
  };

  // const result = useQueries({
  //   queries: [
  //     { queryKey: ["lodGrpNoList"], queryFn: lodGrpNoListApi },
  //     { queryKey: ["lodGrpNmList"], queryFn: lodGrpNmListApi },
  //   ],
  // });

  const lodListMutation = useMutation(lodListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          // setLodList(body);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const handleSearch = () => {
    // lodListMutation.mutate(searchInfo);
  };
  const handleReset = () => {
    setsearchInfo({
      lodGrpNo: "",
      lodGrpNm: "",
      lodNo: "",
      lodGrdCd: "",
      lodNm: "",
      lodAddr1: "",
      lodAddr2: "",
      sellAmdvc: "A",
      contSdt: "",
      contEdt: "",
      uYn: "A",
    });
  };

  return (
    <LodgmentListView
      searchInfo={searchInfo}
      isLoding={lodListMutation.isLoading}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleSearch={handleSearch}
      handleReset={handleReset}
    />
  );
};

export default LodgmentListPage;
