import "../select.scss";
import { useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { countryCode } from "../countryCode";
import i18n from "locales/i18n";

type IProps = {
  wrapStyle?: string;
  optionStyle?: string;
  btnStyle?: string;
  inpStyle?: string;
  disabled?: boolean;
  direct?: "up" | "down";
  option?: string;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleChange?(target: EventTarget & HTMLInputElement): void;
};

export interface codeProps {
  kr: string;
  en: string;
  phone: string;
  code: string;
}

const CountrySelect = ({
  wrapStyle = "relative",
  optionStyle = "bg-white max-w-[25rem]",
  btnStyle = "flex-1 h-[2.75rem]",
  inpStyle,
  disabled = false,
  option = "대한민국",
  handleSelect,
  handleChange,
}: IProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lng = i18n.language;
  const optionRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const cntNm = countryCode.find((cnt) => {
    return cnt[lng as keyof codeProps] === option;
  });

  return (
    <div className={wrapStyle}>
      <div className={`${wrapStyle} phone-select-wrap`}>
        <button
          className={`${btnStyle} ${isOpen ? "on" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          disabled={disabled}
        >
          <span>{cntNm ? cntNm[lng as keyof codeProps] : option}</span>
        </button>
      </div>
      <div
        className={`${optionStyle}  option-wrap ${isOpen ? `on` : ""} `}
        ref={optionRef}
        onClick={(e) => {
          e.stopPropagation();
          // dispatch(setCountry(false));
        }}
      >
        <ul className={`${isOpen ? ` on ` : ""}`}>
          <li className="list-title">
            <span>국가명</span>
            <span>Country</span>
            {/* <span>국가코드</span> */}
          </li>
          {countryCode.map((op: codeProps) => (
            <li
              key={op.kr}
              data-name="country"
              data-lng={op[lng as keyof codeProps]}
              data-value={op[lng as keyof codeProps]}
              data-code={op.code}
              className={""}
              onClick={(e) => {
                handleSelect(e.currentTarget);
                setIsOpen(!isOpen);
              }}
            >
              <span>{op[lng as keyof codeProps]}</span>
              <span> {op.en}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CountrySelect;
