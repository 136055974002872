import Button from "components/button/createButtonWrap";
import SubHeader from "components/layout/header/subHeader";
import RadioEditor from "components/radio/radioEditor";
import RadioContent from "components/radio/radiocontent";
import CountrySelect from "components/select/country";
import PhoneSelect from "components/select/phone";
import { lngLabel } from "util/option";

type IProps = {
  info: { mobCd: string; mobNo: string };
  isLoading: boolean;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleAddInfoChange(name: string, value: string): void;
  handleCreate(): void;
};

const LodGroupCreateView = ({
  isLoading,
  info,
  handleChange,
  handleAddInfoChange,
  handleCreate,
  handleSelect,
}: IProps) => {
  return (
    <div className="main-cnt">
      <SubHeader title="숙소그룹 생성" />
      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룸명</th>
              <td colSpan={3}>
                <RadioContent
                  options={lngLabel}
                  name={""}
                  onChange={(target) => handleChange("lodGrpNm", target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>숙소그룹설명</th>
              <td colSpan={3}>
                <RadioEditor
                  options={lngLabel}
                  name={"lodDesc"}
                  info={{}}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>숙소그룹국가</th>
              <td>
                <CountrySelect
                  handleSelect={(target) => handleSelect(target)}
                />
              </td>
              <th>숙소그룹 우편번호</th>
              <td>
                <input type="text" />
              </td>
            </tr>
            <tr>
              <th>숙소그룹 주소1</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    options={lngLabel}
                    name={""}
                    onChange={(target) =>
                      handleChange("lodGrpNm", target.value)
                    }
                  />
                </div>
              </td>
              <th>숙소그룹 주소2</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    options={lngLabel}
                    name={""}
                    onChange={(target) =>
                      handleChange("lodGrpNm", target.value)
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>숙소그룹 주소3</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    options={lngLabel}
                    name={""}
                    onChange={(target) =>
                      handleChange("lodGrpNm", target.value)
                    }
                  />
                </div>
              </td>
              <th>숙소그룹 주소4</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    options={lngLabel}
                    name={""}
                    onChange={(target) =>
                      handleChange("lodGrpNm", target.value)
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>숙소그룹 전화번호</th>
              <td>
                <div className="flex gap-x-2 max-w-[25rem]">
                  <PhoneSelect
                    handleSelect={(target) => handleSelect(target)}
                  />
                  <input type="text" />
                </div>
              </td>
              <th>숙소그룹 이메일</th>
              <td>
                <input
                  type="text"
                  value={""}
                  onChange={(e) =>
                    handleChange("lodEml", e.currentTarget.value)
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Button isLoading={isLoading} disabled={false} onClick={handleCreate} />
    </div>
  );
};
export default LodGroupCreateView;
