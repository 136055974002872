import { IPayInfo } from "interface/home";
import { Suspense, useState } from "react";
import { homePayInfoHead } from "util/option";
import Loading from "../loading";
import { useMutation } from "@tanstack/react-query";
import { homeManagerDayPayInfoApi } from "components/api/home";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { IoReload } from "react-icons/io5";

type IProps = {
  lodNo: string;
  payInfo: IPayInfo;
};

const PayInfo = ({ payInfo }: { payInfo: IPayInfo }) => {
  return (
    <tbody>
      <tr>
        <td>{payInfo?.resvDt}</td>
        <td>{payInfo?.resvCt}</td>
        <td>{payInfo?.resvPayAmt}</td>
        <td>{payInfo?.resvCnlAmt}</td>
        <td>{payInfo?.resvCalAmt}</td>
      </tr>
    </tbody>
  );
};

const PayList = ({ payInfo, lodNo }: IProps) => {
  const dispatch = useDispatch();
  const now = new Date();

  const [info, setInfo] = useState<IPayInfo | null>(null);
  const payInfoMutation = useMutation(homeManagerDayPayInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setInfo(body?.dayPayInfo);
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const handleReload = () => {
    const date = now.toISOString().split("T")[0].replaceAll("-", "");
    const params = {
      lodNo,
      date,
    };
    payInfoMutation.mutate(params);
  };
  return (
    <div className="pb-[1.25rem]">
      <div className="home title">
        <h4>예약 현황</h4>
        <button onClick={handleReload}>
          <IoReload
            size={28}
            style={{ cursor: "pointer", padding: 0 }}
            className={payInfoMutation.isLoading ? "icon-rotate" : ""}
          />
        </button>
      </div>
      <table>
        <thead>
          <tr>
            {homePayInfoHead.map((bk) => (
              <th key={bk}>{bk}</th>
            ))}
          </tr>
        </thead>
        <Suspense fallback={<Loading type="middle" />}>
          <PayInfo payInfo={info || payInfo} />
        </Suspense>
      </table>
    </div>
  );
};

export default PayList;
