import LoadingButton from "components/button/loadingButton";
import { useLocation } from "react-router-dom";
import { regPwd } from "util/common";

type IProps = {
  isLoading: boolean;
  info: {
    pwd: string;
    pwd_chk: string;
  };
  handleChange(name: string, value: string): void;
  handleSetting(): void;
};

const Step4View = ({
  isLoading,
  info,
  handleChange,
  handleSetting,
}: IProps) => {
  const { pathname } = useLocation();
  const type = pathname.includes("admin") ? "admin" : "manager";

  return (
    <div className="no-logged-wrap">
      <h2>{type === "admin" ? "관리자" : "매니저"} 비밀번호 설정</h2>
      <p className="no-logged-disc">
        비밀번호는 8자리 이상 20자리 이내로 등록해주세요. <br />
        비밀번호 구성은 영문, 숫자, 특수문자를 포함해야 합니다. <br />
        특수문자로 허용되는 문자는 !, @, #, $, %, ^, &, * 입니다.
      </p>
      <form className="py-8  signup-form">
        <div>
          <label htmlFor="">비밀번호</label>
          <input
            type="password"
            name="pwd"
            maxLength={20}
            value={info.pwd}
            onChange={(e) => {
              const value = e.currentTarget.value;
              handleChange("pwd", value);
            }}
          />
        </div>
        <span
          className={regPwd.test(info?.pwd) || !info.pwd ? "hidden" : "err"}
        >
          비밀번호를 정확히 입력하세요
        </span>
        <div className="pt-[1rem]">
          <label htmlFor="">비밀번호 확인</label>
          <input
            type="password"
            name="pwd_chk"
            value={info.pwd_chk}
            onChange={(e) => {
              const value = e.currentTarget.value;
              handleChange("pwd_chk", value);
            }}
          />
        </div>
        <span
          className={
            info.pwd === info.pwd_chk || !info.pwd_chk ? "hidden" : "err"
          }
        >
          비밀번호가 일치하지 않습니다
        </span>
      </form>
      <div className="flex justify-center">
        <LoadingButton
          txt="비밀번호 설정"
          disabled={!(regPwd.test(info?.pwd) && info.pwd === info.pwd_chk)}
          styles="btn-search"
          isLoading={isLoading}
          onClick={handleSetting}
        />
      </div>
    </div>
  );
};

export default Step4View;
