import { useMutation } from "@tanstack/react-query";
import { lodGrpListApi } from "components/api/lod";
import LodGroupListView from "components/view/lod/group/list";
import { IlodGrpList, lodGrpSearchProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsLoading, setIsSearch, setMessage } from "store/commonSlice";

const LodGroupListPage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";

  const [searchInfo, setSearchInfo] = useState<lodGrpSearchProps>({
    lodGrpNo: "",
    lodGrpCntyCd: "+82",
    lodGrpNm: "",
    lodGrpAddr1: "",
    lodGrpAddr2: "",
    uYn: "A",
  });

  const [lodGrpList, setlodGrpList] = useState<IlodGrpList[]>([]);

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;

    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, lng, value } = target.dataset;
    if (value && lng) {
      if (name === "mobCntyCd") {
        setSearchInfo({
          ...searchInfo,
          lodGrpCntyCd: value,
        });
      }
      if (name === "uYn") {
        setSearchInfo({
          ...searchInfo,
          uYn: value,
        });
      }
    }
  };

  const lodGrpListMutation = useMutation(lodGrpListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          setlodGrpList(body);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleSearch = () => {
    lodGrpListMutation.mutate(searchInfo);
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: "",
      lodGrpCntyCd: "+82",
      lodGrpNm: "",
      lodGrpAddr1: "",
      lodGrpAddr2: "",
      uYn: "A",
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };

  return (
    <LodGroupListView
      searchInfo={searchInfo}
      lodGrpList={lodGrpList}
      isLoading={lodGrpListMutation.isLoading}
      memType={memType}
      handleChange={handleChange}
      handleSearch={handleSearch}
      handleReset={handleReset}
      handleSelect={handleSelect}
    />
  );
};

export default LodGroupListPage;
