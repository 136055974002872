import Button from "components/button/createButtonWrap";
import SubHeader from "components/layout/header/subHeader";
import RadioEditor from "components/radio/radioEditor";
import RadioContent from "components/radio/radiocontent";
import Select from "components/select";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNmSelect from "components/select/room/lodNmSelect";
import RmNmSelect from "components/select/room/rmNmSelect";
import Grid from "components/tableList";
import { rmOptCreateProps } from "interface/room";
import i18n from "locales/i18n";
import { useNavigate } from "react-router";
import {
  brkfInYnOptions,
  lngLabel,
  lastTimeOptions,
  uYnOptions,
} from "util/option";

type IProps = {
  info: rmOptCreateProps;
  memType: "admin" | "manager";
  opAddInfo: any;
  isLoading: boolean;
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleAddInfoChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleCreate(): void;
};

const RoomOptCreateView = ({
  info,
  memType,
  opAddInfo,
  isLoading,
  handleChange,
  handleAddInfoChange,
  handleSelect,
  handleCreate,
}: IProps) => {
  const navigate = useNavigate();
  const lng = i18n.language;

  let rmOpNmInfo = {};
  let rmOpDescInfo = {};
  Object.values(opAddInfo)?.forEach((inf: any) => {
    let langCd = inf.langCd === "en" ? "us" : inf.langCd;
    rmOpNmInfo = {
      ...rmOpNmInfo,
      langCd: i18n.language,
      [`rmOpNm_${langCd}`]: inf.rmOpNm,
    };
    rmOpDescInfo = {
      ...rmOpDescInfo,
      langCd: i18n.language,
      [`rmOpDesc_${langCd}`]: inf.rmOpDesc,
    };
  });

  const isAble = !(
    info?.lodNo &&
    info?.rmNo &&
    (opAddInfo?.kr?.rmOpNm || opAddInfo?.us?.rmOpNm)
  );

  return (
    <div className="main-cnt">
      <SubHeader title="객실옵션 생성" />
      <div className="table-form">
        <table>
          <tbody>
            {memType === "admin" && (
              <tr>
                <th>숙소그룹명</th>
                <td>
                  <LodGrpSelect
                    option={info.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th data-dot>숙소명</th>
                <td>
                  <LodNmSelect
                    disabled={!info.lodGrpNo}
                    params={info.lodGrpNo}
                    option={info.lodNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
            )}
            {memType === "manager" && (
              <tr>
                <th>숙소그룹명</th>
                <td>
                  <input type="text" value={info?.lodGrpNm} readOnly />
                </td>
                <th>숙소명</th>
                <td>
                  <input type="text" value={info?.lodNm} readOnly />
                </td>
              </tr>
            )}
            <tr>
              <th data-dot>객실명</th>
              <td>
                <RmNmSelect
                  disabled={!info.lodNo}
                  params={info.lodNo}
                  option={info.rmNo}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
              <th data-dot>사용여부</th>
              <td>
                <Select
                  id={"uyn"}
                  name={"uyn"}
                  option={info.uyn}
                  options={uYnOptions}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>

            <tr>
              <th data-dot>객실옵션명</th>
              <td colSpan={3}>
                <RadioContent
                  options={lngLabel}
                  name={"rmOpNm"}
                  info={rmOpNmInfo}
                  onChange={(target) =>
                    handleAddInfoChange(target.name, target.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <th>객실옵션설명</th>
              <td colSpan={3}>
                <RadioEditor
                  options={lngLabel}
                  name={"rmOpDesc"}
                  contStyle=""
                  info={rmOpDescInfo}
                  onChange={(name, value) => handleAddInfoChange(name, value)}
                />
              </td>
            </tr>

            <tr>
              <th>객실옵션 조식포함</th>
              <td>
                <Select
                  id="brkfInYn"
                  name="brkfInYn"
                  option={info.brkfInYn}
                  options={brkfInYnOptions}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
              <th>객실옵션 레이트 체크아웃시간</th>
              <td>
                <Select
                  id="latChkOutTi"
                  name="latChkOutTi"
                  option={info.latChkOutTi}
                  options={lastTimeOptions}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button
        txt="등록"
        isLoading={isLoading}
        disabled={isAble} //|| isLoading
        // disabled={true}
        onClick={handleCreate}
      />
    </div>
  );
};

export default RoomOptCreateView;
