import { useMutation, useQuery } from "@tanstack/react-query";
import { rmCnlAmtApi, rmCnlResvApi, rmResvDtlApi } from "components/api/room";
import RoomBookInfoView from "components/view/room/book/info";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsLoading, setMessage } from "store/commonSlice";

const RoomBookInfoPage = () => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const memType = pathname.includes("manager") ? "manager" : "admin";
  const [cnlInfo, setCnlInfo] = useState({
    cnlFeeAmt: "",
    refAmt: "",
    rmPdCt: "",
  });

  const cnlRef = useRef(false);

  // const query = useQuery({
  //   queryKey: ["rmResvDtl", memType],
  //   queryFn: () => {
  //     return rmResvDtlApi({ rmPdNo: state.rmPdNo });
  //   },
  //   enabled: !!state.rmPdNo,
  //   onSuccess(data) {
  //     if (data) {
  //       const {
  //         header: { code, message },
  //         body,
  //       } = data;
  //       if (code === -1) {
  //         dispatch(setMessage({ message }));
  //       }
  //     }
  //   },
  // });

  const rmResvDtlMutation = useMutation(rmResvDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  useEffect(() => {
    rmResvDtlMutation.mutate({ rmPdNo: state.rmPdNo });
  }, [state]);

  const rmResvDtl = rmResvDtlMutation?.data?.body?.rmResvDtl || {};
  const resvPayDtl = rmResvDtlMutation?.data?.body?.resvPayDtl || {};

  const cnlAmtMutation = useMutation(rmCnlAmtApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const { cnlFeeAmt, refAmt, rmPdCt } = body;
          setCnlInfo({
            cnlFeeAmt,
            refAmt,
            rmPdCt,
          });
          dispatch(setMessage({ message: null }));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCnlAmt = () => {
    cnlAmtMutation.mutate({ rmPdNo: state.rmPdNo });
  };

  const cnlMutation = useMutation(rmCnlResvApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "예약 취소를 완료하였습니다.",
              isConfirm: true,
              handleClick() {
                cnlRef.current = true;
                dispatch(setIsLoading(false));
                dispatch(setMessage({ message: null }));
                setCnlInfo({
                  cnlFeeAmt: "",
                  refAmt: "",
                  rmPdCt: "",
                });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setIsLoading(false));
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                setCnlInfo({
                  cnlFeeAmt: "",
                  refAmt: "",
                  rmPdCt: "",
                });
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });
  const handleCancel = () => {
    dispatch(
      setMessage({
        message: "객실예약을 취소하시겠습니까? ",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          dispatch(setIsLoading(true));
          cnlMutation.mutate({ rmPdNo: state?.rmPdNo });
        },
      })
    );
  };

  return (
    <>
      <RoomBookInfoView
        memType={memType}
        rmResvDtl={rmResvDtl}
        resvPayDtl={resvPayDtl}
        cnlInfo={cnlInfo}
        cnlRef={cnlRef}
        setCnlInfo={setCnlInfo}
        handleCancel={handleCancel}
        handleCnlAmt={handleCnlAmt}
      />
    </>
  );
};

export default RoomBookInfoPage;
