import api from "./api";

export const homeManagerInfoApi = async () => {
  const res = await api({
    method: "get",
    url: `manager/manManage/homeInfo`,
  });

  return res;
};

export const homeManagerDayPayInfoApi = async (params: {
  lodNo: string;
  date: string;
}) => {
  const { lodNo, date } = params;
  const res = await api({
    method: "get",
    url: `manager/manManage/dayPayInfo/${lodNo}/${date}`,
  });

  return res;
};

export const homeManagerDayChkInfoApi = async (params: {
  lodNo: string;
  date: string;
}) => {
  const { lodNo, date } = params;
  const res = await api({
    method: "get",
    url: `manager/manManage/dayChkInList/${lodNo}/${date}`,
  });

  return res;
};
