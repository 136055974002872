import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  managerInfoApi,
  modifyAdmInfoApi,
  modifyManagerApi,
  userInfoApi,
} from "components/api/member";
import MemInfoModifyView from "components/view/member/modify";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setLoadingButton, setMessage } from "store/commonSlice";
import { setIsSearchMember } from "store/memberSlice";

const MemInfoModifyPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pathname, search } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";

  const { data: admInfo } = useQuery({
    queryKey: ["memInfo", "admin"],
    queryFn: () => {
      if (!!search) {
        return userInfoApi(search.replace("?admNo=", ""));
      }
      return null;
    },
    enabled: !!search.includes("admNo") && !!search.replace("?admNo=", ""),
  });

  const { data: manInfo } = useQuery({
    queryKey: ["memInfo", "manager"],
    queryFn: () => {
      if (!!search) {
        return managerInfoApi(search.replace("?manNo=", ""));
      }
      return null;
    },
    enabled: !!search.includes("manNo") && !!search.replace("?manNo=", ""),
  });

  const memInfo = admInfo?.body || manInfo?.body?.manInfo || {};

  const modifyAdmMutation = useMutation(modifyAdmInfoApi, {
    onSuccess(data) {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `회원 정보 수정이 완료 되였습니다.`,
              isConfirm: true,
              handleClick: () => {
                dispatch(setMessage({ message: null }));
              },
            })
          );
          dispatch(setLoadingButton({ isLoading: false, disabled: false }));

          queryClient.invalidateQueries(["memInfo", "admin"]);
          dispatch(setIsSearchMember(false));
          queryClient.invalidateQueries(["memList", "admin"]);
          dispatch(setIsSearchMember(true));
          setInfo({
            mobCntyCd: "",
            mobNo: null,
            joiAprYn: undefined,
          });
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick: () => {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const modifyMngMutation = useMutation(modifyManagerApi, {
    onSuccess(data) {
      if (data) {
        const { code, message } = data?.header;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `매니저정보 수정이 완료 되었습니다`,
              isConfirm: true,
              handleClick: () => {
                dispatch(setMessage({ message: null }));
              },
            })
          );
          dispatch(setLoadingButton({ isLoading: false, disabled: false }));

          queryClient.invalidateQueries(["memInfo", "manager"]);
          dispatch(setIsSearchMember(false));
          queryClient.invalidateQueries(["memList", "manager"]);
          dispatch(setIsSearchMember(true));
          setInfo({
            mobCntyCd: "",
            mobNo: null,
            joiAprYn: undefined,
          });
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const [info, setInfo] = useState({
    mobNo: null,
    mobCntyCd: "",
    joiAprYn: undefined,
  });

  const handleChange = (target: { name: string; value: string }) => {
    const { name, value } = target;
    setInfo({
      ...info,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset as any;

    setInfo({
      ...info,
      [name]: value ?? "",
    });
  };

  const handleModifyMemInfo = () => {
    const admNo = search.replace("?admNo=", "");
    const manNo = search.replace("?manNo=", "");
    if (search.includes("admNo") && !!admNo) {
      dispatch(
        setMessage({
          message: "회원정보를 수정하시겠습니까?",
          isConfirm: true,
          isCancel: true,
          handleClick: () => {
            modifyAdmMutation.mutate({
              admNo,
              mobNo: info.mobNo || undefined,
              mobCntyCd: info.mobCntyCd || undefined,
            });
            dispatch(setLoadingButton({ isLoading: true, disabled: true }));
          },
        })
      );
      return;
    }
    if (search.includes("manNo") && !!manNo) {
      dispatch(
        setMessage({
          message: "매니저정보를 수정하시겠습니까?",
          isConfirm: true,
          isCancel: true,
          handleClick: () => {
            modifyMngMutation.mutate({
              manNo,
              mobNo: info.mobNo || undefined,
              mobCntyCd: info.mobCntyCd || undefined,
              joiAprYn: info.joiAprYn || undefined,
            });
          },
        })
      );
      return;
    }
  };

  return (
    <>
      <MemInfoModifyView
        title={"회원정보 수정"}
        info={info}
        memType={memType}
        memInfo={memInfo}
        isLoading={modifyAdmMutation.isLoading || modifyMngMutation.isLoading}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleModifyMemInfo={handleModifyMemInfo}
      />
    </>
  );
};

export default MemInfoModifyPage;
