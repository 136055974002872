import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { termListAPi, trmDtlApi } from "components/api/term";
import {
  allCheckSelector,
  itemCheckSelector,
  setAllCheck,
  setItemCheck,
  setMessage,
} from "store/commonSlice";
import { useSelector } from "react-redux";
import Checkbox from "components/checkbox";
import CheckBox from "components/checkbox";
import { IprivList } from "interface/term";
import { useEffect, useRef, useState } from "react";
import Modal from "components/modal/modal";
import i18n from "locales/i18n";

const AllCheck = () => {
  const dispath = useDispatch();
  const { pathname } = useLocation();
  const type = pathname.includes("admin") ? "admin" : "manager";
  const [term, setTerm] = useState({
    prvCd: "",
    prvNm: "",
  });

  const params = {
    langCd: "kr",
    userType: type,
  };
  const { data } = useQuery({
    queryKey: ["termList", params.userType, params],
    queryFn: () => termListAPi(params),
  });

  const prvList = data?.body.prvList;

  const itemCheck = useSelector(itemCheckSelector);
  const allCheck = useSelector(allCheckSelector);

  const dispatch = useDispatch();
  const termRef = useRef<HTMLDivElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.currentTarget;

    if (id === "all") {
      dispatch(setAllCheck(checked));
    }
    if (id === "1") {
      dispatch(
        setItemCheck({
          prvGrpCd: "A01",
          prvSeq: "1",
          required: checked,
          prvAgYn: "Y",
          essYn: itemCheck[`${Number(id) - 1}`].essYn,
        })
      );
    }
    if (id === "2") {
      dispatch(
        setItemCheck({
          prvGrpCd: "B01",
          prvSeq: "2",
          required: checked,
          prvAgYn: "Y",
          essYn: itemCheck[`${Number(id) - 1}`].essYn,
        })
      );
    }
    if (id === "3") {
      dispatch(
        setItemCheck({
          prvGrpCd: "C01",
          prvSeq: "3",
          required: checked,
          prvAgYn: "Y",
          essYn: itemCheck[`${Number(id) - 1}`].essYn,
        })
      );
    }
    if (id === "4") {
      dispatch(
        setItemCheck({
          prvGrpCd: "D01",
          prvSeq: "4",
          required: checked,
          prvAgYn: "Y",
          essYn: itemCheck[`${Number(id) - 1}`].essYn,
        })
      );
    }
  };

  const handleOpenTerm = (term: { prvCd: string; prvNm: string }) => {
    const { prvCd, prvNm } = term;
    setTerm({
      prvCd,
      prvNm,
    });
  };

  const trmDtlMutation = useMutation(trmDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
      }
    },
  });

  useEffect(() => {
    const { prvCd, prvNm } = term;

    // if (!!prvCd) {
    //   trmDtlApi({ langCd: i18n.language, prvCd }).then((data: any) => {
    //     if (data) {
    //       const {
    //         header: { code, message },
    //         body,
    //       } = data;
    //       if (code === 0) {
    //         if (termRef.current) {
    //           termRef.current.innerHTML = body?.prvDtl?.trmCn;
    //         }
    //       }
    //       if (code === -1) {
    //         dispatch(setMessage({ message }));
    //       }
    //     }
    //   });
    // }

    if (termRef.current) {
      const termContent =
        prvCd === "A01"
          ? A01
          : prvCd === "A02"
          ? A02
          : prvCd === "A03"
          ? A03
          : prvCd === "A04"
          ? A04
          : prvCd === "M01"
          ? M01
          : prvCd === "M02"
          ? M02
          : prvCd === "M03"
          ? M03
          : prvCd === "M04"
          ? M04
          : "";

      termRef.current.innerHTML = termContent;
    }
  }, [term.prvCd]);

  useEffect(() => {
    prvList.map((prv: any) => {
      dispatch(
        setItemCheck({
          prvGrpCd: prv.prvGrpCd,
          prvSeq: prv.rgSeq,
          required: false,
          prvAgYn: "Y",
          essYn: prv.essYn,
        })
      );
    });
  }, [prvList]);

  return (
    <div className="allcheck-wrap">
      <div className="check-item">
        <CheckBox
          label="전체약관 동의"
          id="all"
          checked={allCheck}
          handleCheckbox={handleChange}
        />
      </div>

      {prvList.map((prv: IprivList, index: number) => (
        <div className="check-item" key={prv?.prvCd}>
          <Checkbox
            label={` (${prv.essYn === "Y" ? "필수" : "선택"}) ` + prv.prvNm}
            id={index + 1 + ""}
            checked={itemCheck[index].required}
            handleCheckbox={handleChange}
          />
          <button
            onClick={() =>
              handleOpenTerm({
                prvCd: prv.prvCd,
                prvNm: prv.prvNm,
              })
            }
          >
            전체보기
          </button>
        </div>
      ))}
      <Modal
        title={term.prvNm}
        btnText=""
        isOpen={!!term.prvCd}
        styles="max-w-[90%]"
        handleCloseModal={() => {
          setTerm({
            prvCd: "",
            prvNm: "",
          });
        }}
        onClick={() => {}}
      >
        <div className="term-cont" ref={termRef} />
      </Modal>
    </div>
  );
};

export default AllCheck;

// 비즈니스 회원 서비스 이용약관
const M01 = `
<section>
<div class="inner">
  <strong>제 1 조 (목적)</strong>
  <span>
  본 약관은 주식회사 유비컴퍼니 및 관계사(이하 “회사”)가 제공하는 다수의 개별 서비스를 통합하여 사용할 수 있도록 유비컴퍼니 비즈니스 회원 서비스(이하 "비즈니스 회원 서비스")를 제공함에 회원과 회사 간의 권리, 의무 및 책임사항, 이용조건 및 절차 등 기본적인 사항을 규정함을 목적으로 합니다.
  </span>
  <strong>제 2 조 (용어의 정의)</strong>
  <span>
    <p>1. 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>

    <ul>
     <li>비즈니스 회원 서비스: 회사가 제공하는 다수의 개별 서비스를 회원이 하나의 회원 ID 계정으로 통합하여 사용(회원 인증, 회원정보 변경, 회원 가입 및 탈퇴 등) 할 수 있도록 회사가 제공하는 통합 서비스를 말합니다.</li>
     <li>회원: 회사의 개별 서비스 또는 비즈니스 회원 서비스 웹사이트에서 본 약관에 동의하고, ID와 비밀번호(Password)를 발급받은 고객을 말합니다. 회원의 자격 및 권한 등은 본 약관에서 정한 바에 따라 일부 제한될 수 있습니다.</li>
     <li>관계사: 회사의 계열사 및 회사와 제휴 관계를 맺고 있는 법인으로 회사와 비즈니스 회원 서비스를 제공하기로 합의한 법인을 말합니다. 관계사는 회사의 정책 등에 따라 추가, 변동될 수 있습니다.</li>
     <li>개별 서비스: 비즈니스 회원 서비스를 이용하여 접속 가능한 회사의 제공 서비스들을 말합니다. 개별 서비스는 회사의 정책 등에 따라 추후 추가, 변동될 수 있으며 이 경우 본 약관에서 정한 방식으로 회원에게 공지합니다.</li>
     <li>아이디(이하 “ID”): 회사의 개별 서비스를 통해 발급받은 ID 또는 본 약관에 동의하고 가입하며 발급받은 ID를 회사가 제공하는 모든 개별 서비스에 통합하여 동일하게 사용할 수 있는 ID를 말합니다. 회원 식별과 회원의 서비스 이용을 위해 회원이 선정하고 회사가 승인하는 영문자, 숫자 및 특수문자의 조합을 의미하며, 회사는 일관된 ID정책을 위해 특정 유형의 ID를 회원이 선정하도록 사전에 정할 수 있습니다.</li>
     <li>비밀번호(Password): 회원의 정보보호를 위해 회원 자신이 설정한 문자, 숫자 및 특수문자의 조합을 의미합니다.</li>
     <li>비즈니스 회원 서비스 사이트(이하 "본 사이트"): 회사가 제공하는 다수의 개별 서비스 상 회원정보를 통합 조회하고 변경신청 할 수 있는 웹사이트를 말합니다.</li>
     <li>운영자: 비즈니스 회원 서비스의 전반적인 관리와 원활한 운영을 위하여 회사가 선정한 자를 말합니다.</li>
    </ul>
     
  </span>
  <span>
  <p>2. 본 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계 법령 및 개별 서비스약관, 정책에서 정하는 바에 의합니다.</p>
  </span>

  <strong>제3조 (서비스 이용신청)</strong>
  <span>
    <p>1. 비즈니스 회원 서비스를 이용하고자 하는 이용자는 본 약관에 동의한 다음, 회사가 정한 절차에 따라 필요한 사항을 기입하고 회사가 요구한 자료를 제출함으로써 회원 가입을 신청하실 수 있습니다. 회사는 회원이 회사에서 요구하는 자격에 부합하는지 심사를 진행할 수 있으며, 심사 절차를 거쳐 최종 승인된 경우에 한하여 회원가입 절차가 종료됩니다.</p>
    <p>2. 회원가입 신청 처리는 이용자의 신청순서에 의하며, 회원가입 완료시기는 회사의 승낙이 회원에게 도달한 시점으로 하며, 이용자는 그 이후부터 해당 회원으로서 서비스 이용이 가능합니다.</p>
    <p>3. 회원가입 신청 시 이용자 본인의 정보로 가입신청을 해야 하며, 본인의 정보로 가입하지 않은 사실(타인의 명의를 대여하거나 도용한 경우 포함)이 확인된 경우 서비스 이용이 제한되거나 관련 법령에 따라 처벌받을 수 있습니다.</p>
    <p>4. 회사는 회원의 개인정보 보호를 위하여 비즈니스 회원 서비스로 사용하는 이메일 혹은 휴대폰 번호 인증을 필수적으로 진행합니다. 인증 미 완료 시 가입이 제한되거나, 개별 서비스 사용에 제약이 있을 수 있습니다. 가입 후 인증을 완료하지 않은 사용자는 중요한 서비스 변경사항의 통지를 받을 수 없거나, ID 의 분실 또는 비밀번호의 재설정 등의 회원정보 관리가 불가능할 수 있습니다.</p>
    <p>5. 회사는 승인 여부에 대해 별도로 이메일 또는 기타 방법으로 통지합니다.</p>
    <p>6. 회사는 다음과 같은 사유가 발생한 경우 회원가입 신청에 대한 승낙을 거부하거나 유보할 수 있습니다.</p>

    <ul>
      <li>실제 정보로 가입 신청하지 않은 것이 확인된 경우 </li>
      <li>이미 가입된 회원 정보와 동일한 경우</li>
      <li>회사에 의하여 서비스 이용 중지, 혹은 탈퇴 처리된 날로부터 7일 이내에 재 신청을 하는 경우</li>
      <li>회사로부터 회원자격 정지 조치 등을 받은 회원이 그 조치기간 중에 서비스 회원탈퇴 후 재가입 신청을 하는 경우</li>
      <li>기타 회사 정책에 위반 또는 본 약관에 위배되거나 위법 또는 부당한 가입신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우</li>
    </ul>
  </span>
  <strong>제4조 (약관의 효력)</strong>
  <span>
    <p>1. 회사는 관계 법령, 회사 정책, 사업의 방향성 등에 따라 홈페이지 공지를 통해 별도 정책을 제.개정할 수 있으며, 회원은 별도 정책이 본 약관 및 관련 이용계약의 일부로 구성됨을 확인하고 동의합니다.</p>
    <p>2. 회사는 관계 법령, 회사 정책, 사업의 방향성 등에 따라 일부 특정 서비스에 관한 약관(이하 “개별약관”)을 별도로 제.개정할 수 있습니다. 회원이 개별약관에 동의한 경우 본 약관과 함께 본 사이트 이용계약의 일부를 구성하게 되며, 개별약관과 본 약관간 상충하는 내용이 있는 경우, 회원에 관한 사항은 본 약관이, 서비스 이용에 관한 사항은 개별약관이 우선하여 적용됩니다.</p>
    <p>3. 본 약관 또는 개별약관의 변경이 있는 경우, 회사는 변경 내용의 효력발생일 7일 이전(단, 이용자에게 불리한 내용으로 변경 시 30일 이전)에 해당 변경 사항을 본 사이트에 공지하며, 개정약관을 공지 또는 통지하면서, 회원에게 약관 변경 적용일 까지 거부의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지 하였음에도 회원이 명시적으로 약관 변경에 대한 거부의사를 표시하지 아니하는 경우 회원이 개정약관에 동의한 것으로 간주합니다.. 또한 회원이 변경된 약관, 개별약관에 동의하지 않을 경우 회사에 탈퇴(이용계약의 해지)를 요청할 수 있습니다.</p>

  </span>
  <strong>제5조 (제공 서비스)</strong>
  <span>
    <p>1. 회사가 개별 서비스와 연동하여 비즈니스 회원 서비스에서 제공하는 서비스 내용은 아래와 같습니다.</p>

    <ul>
      <li>통합 회원 ID 인증 서비스: 회원은 통합 회원 인증을 통해 통합된 ID를 부여 받고, 회사의 개별 서비스에서 하나의 회원 ID 계정을 사용해 로그인 할 수 있습니다.</li>
      <li>SSO(Single Sign On): 회원은 1회 로그인으로 특정 단말기 내에서 이용 중인 개별 서비스에 추가 로그인 없이 자동접속 서비스를 이용할 수 있습니다. 단, 개별서비스 특성 상 관계법령 및 회사 정책에 따라 이중인증 등이 필요한 경우는 별도 절차가 필요할 수 있습니다.</li>
      <li>맞춤 정보 제공 서비스: 고객이 개인 맞춤∙연계 서비스 제공을 위한 개인정보의 결합∙분석∙활용에 동의하는 경우, 회사는 고객의 개인정보 및 회사∙제3자가 제공하는 다양한 서비스 이용과 관련한 정보를 결합∙분석∙활용하며, 이를 바탕으로 신상품이나 이벤트 정보안내, 설문조사 등 고객 맞춤∙연계 서비스(회사 또는 제3자의 서비스)를 제공하고, 이와 관련된 정보를 안내 및 제공합니다.</li>
      <li>통합 ID 관리: 각 개별서비스상 회원 현황, 회원 정보 변경, 회원 탈퇴 등을 본 사이트에서 통합하여 관리할 수 있습니다.</li>
    </ul>
    <p>2. 제1항에 따라 회원에게 제공되는 서비스의 내용은 변경될 수 있으며 서비스 변경 시 회사는 본 약관에서 정한 방법으로 회원에게 안내합니다.</p>
    <p>3. 회사는 서비스 변경에 대한 동의를 거절한 회원에 대하여는 변경 전 서비스를 제공합니다. 다만, 변경 전 서비스 제공이 불가능할 경우 서비스 제공을 중단하거나 본 서비스 이용 계약을 해지할 수 있습니다.</p>

  </span>
  
  <strong>제6조 (회사의 권리와 의무)</strong>
  <span>
    <p>1. 회사는 회원의 가입신청을 승낙한 때부터 그 회원에게 비즈니스 회원 서비스를 제공합니다.</p>
    <p>2. 비즈니스 회원 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 하지만, 비즈니스 회원 서비스가 제공하는 일부 서비스 또는 연동된 개별 서비스를 일정 범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있습니다.</p>
    <p>3. 회사는 다음 각 호에 해당하는 경우 비즈니스 회원 서비스가 제공하는 서비스의 전부 또는 일부를 제한하거나 중단할 수 있습니다.</p>

    <ul>
      <li>비즈니스 회원 서비스의 업데이트 및 개선, 유지보수가 필요한 경우</li>  
      <li>비즈니스 회원 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>  
      <li>회원이 회사(관계사 포함)의 영업활동을 방해하는 경우</li>  
      <li>정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 비즈니스 회원 서비스 제공이 어려운 경우</li>  
      <li>경영상의 판단, 관계사와의 계약 종료, 정부의 명령</li>  
      <li>규제 등 회사의 제반 사정으로 비즈니스 회원 서비스를 유지할 수 없는 경우</li>
      <li>기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우</li>  
    </ul>
    <p>4. 전 항에 의해 비즈니스 회원 서비스의 제한, 중단이 발생한 경우 회사는 본 약관에서 정한 방법으로 회원에게 통지합니다. 다만, 회사가 통제할 수 없는 사유로 인한 비즈니스 회원 서비스의 중단(운영자의 고의, 과실이 없는 디스크 장애, 시스템 다운 등)이 발생하여 사전 통지가 불가능한 경우에는 그러하지 아니합니다.</p>
    <p>5. 회사는 본 조에 따른 비즈니스 회원 서비스의 제한, 중단 등으로 발생하는 문제에 대하여 회사의 귀책사유가 없는 한 책임을 지지 않습니다.</p>
    <p>6. 회사는 비즈니스 회원 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 비즈니스 회원 서비스 화면에 기재하거나 이메일 등을 통하여 동 회원에게 통지합니다.</p>
    <p>7. 회사는 개인정보 보호법, 정보통신망 이용촉진 및 정보보호에 관한 법률, 통신비밀보호법, 전기통신사업법 등 본 사이트의 운영 및 관리에 있어서 관계 법령을 준수합니다.</p>

  </span>
  <strong>제7조 (회원의 권리와 의무)</strong>
  <span>
    <p>1. 회원은 가입절차 승인 완료 후 회사가 제공하는 비즈니스 회원 서비스를 이용할 수 있고, 이와 함께 또는 개별적으로 동의한 개별 서비스도 회원 자격으로 이용하실 수 있습니다.</p>
    <p>2. 회원은 비즈니스 회원 서비스를 이용할 때, 다음 각 호의 행위를 하여서는 아니 됩니다.</p>

    <ul>
      <li>이용 신청 또는 변경 시 허위 사실을 기재하거나, 다른 회원의 ID 및 비밀번호를 도용, 부정하게 사용하거나, 다른 사람의 명의를 사용하거나 명의자의 허락 없이 문자메시지(SMS) 인증, 범용공인인증 또는 신용카드인증 등을 수행하는 행위</li>
      <li>회사의 비즈니스 회원 서비스를 이용하여 얻은 정보를 회사 및 관계사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 행위</li>
      <li>회사 또는 타인의 명예를 손상시키거나 불이익을 주는 행위</li>
      <li>게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위</li>
      <li>회사 또는 제3자의 저작권 등 기타 권리를 침해하는 행위</li>
      <li>공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위</li>
      <li>비즈니스 회원 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위</li>
      <li>비즈니스 회원 서비스 운영을 고의로 방해하거나 비즈니스 회원 서비스의 안정적 운영을 방해할 수 있는 정보 및 수신자의 명시적인 수신거부의사에 반하여 광고성 정보 또는 스팸메일(Spam Mail)을 전송하는 행위</li>
      <li>타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위</li>
      <li>다른 회원의 개인정보를 수집, 저장, 공개하는 행위</li>
      <li>자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위의 정보를 유통시키는 행위</li>
      <li>윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위</li>
      <li>수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상을 계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을 방해하는 행위</li>
      <li>비즈니스 회원 서비스 웹사이트 또는 비즈니스 회원 서비스 제공화면에서 게시된 정보를 변경하는 행위</li>
      <li>관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 포함)의 전송 또는 게시 행위</li>
      <li>회사 또는 관계사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 이메일을 발송하는 행위</li>
      <li>컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 이메일로 발송하는 행위</li>
      <li>스토킹(stalking) 등 다른 회원을 괴롭히는 행위</li>
      <li>본 약관 제3조 제6항 각호에 기재된 행위</li>
      <li>기타 관계법령을 위반한 불법 행위 및 공공질서를 위반하거나 사회적 물의 등을 일으킬 수 있는 행위</li>
    </ul>
    <p>3. 회원은 관계 법령, 본 약관의 규정, 이용안내 및 비즈니스 회원 서비스 웹사이트 또는 비즈니스 회원 서비스 제공화면에서 공지한 주의사항, 회사 및 관계사가 통지하는 사항 등을 준수하여야 하며, 기타 회사 또는 관계사의 업무에 방해되는 행위를 하여서는 아니 됩니다.</p>
    <p>4. 회원은 회사 및 관계사에서 공식적으로 인정한 경우를 제외하고는 비즈니스 회원 서비스를 이용하여 상품을 판매하는 영업 활동을 할 수 없으며 특히 해킹, 광고를 통한 수익, 음란사이트를 통한 상업행위, 상용소프트웨어 불법배포 등을 할 수 없습니다. 이를 위반하여 발생한 영업 활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 회사가 귀책사유가 없는 한 책임을 지지 않으며, 귀책사유가 있는 회원은 이와 같은 행위와 관련하여 회사에 대하여 손해배상 의무를 집니다.</p>
    <p>5. 회원은 회원정보에 변경사항이 발생할 경우 즉시 이를 갱신(변경)하여야 하고, 회원이 제공하여 등록된 정보 및 갱신(변경)된 회원 정보가 부정확하여 발생되는 문제에 대한 책임은 회원이 부담합니다.</p>
    <p>6. 회원이 본 조를 위반 한 경우 회사는 본 약관 제18조에 의해 회원의 ID 이용을 제한 또는 중지할 수 있습니다.</p>
    <p>7. 회원은 비즈니스 회원 서비스의 권리와 의무, 기타 본 서비스상 지위, 혜택 등을 타인에게 양도, 증여, 담보 제공할 수 없습니다.</p>

  </span>
  <strong>제8조 (ID 및 비밀번호의 관리)</strong>
  <span>
    <p>1. 회원 ID와 비밀번호의 관리 소홀, 부정 사용에 의하여 발생하는 결과에 대한 책임은 회원 본인에게 있으며, 회사의 시스템 고장 등 회사의 책임 있는 사유로 발생하는 문제에 대해서는 회사가 책임을 집니다.</p>
    <p>2. 회원은 본인의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안되며, 회원 본인의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지하는 경우에는 바로 회사 및 관계사에 통보하고 회사 및 관계사의 안내가 있는 경우 그에 따라야 합니다.</p>
    <p>3. 회원은 개인 정보 유출로 인한 피해 확산을 막기 위해 회사의 비밀번호 운영 정책에 따라 본인 인증 후 비밀번호 변경 절차를 수행해야 하고, 회사의 비밀번호 운영 정책에 따른 비밀번호 변경 절차를 수행하지 않을 경우에는 회원의 개인 정보를 보호하기 위하여 로그인이 제한될 수 있습니다.</p>

  </span>
  <strong>제9조 (지식재산권)</strong>
  <span>
    <p>1. 본 사이트를 통하여 제공되는 모든 정보 및 게시물 등(이하 “콘텐츠”라 합니다)의 지식재산권은 회사에 있으며 저작권법 등에 따라 보호받는 회사의 자산으로 콘텐츠의 무단 전재, (재)배포, 복사, 2차적 저작행위 등을 엄격히 금지하고 있습니다. 이용자는 콘텐츠를 서비스 제공 목적 외로 사용할 수 없으며, 이를 위반할 경우 민, 형사적 처벌을 받을 수 있습니다.</p>
    <p>2. 회원은 사이트내 등록하거나 회사에 제공하는 상품 정보 등이 제3자의 상표권, 특허권, 저작권, 성명권, 초상권 등 제반 지식재산권을 침해하지 않았으며, 제3자의 지식재산권을 사용할 때에는 정당한 권리자로부터 사용 허락을 받았음을 보증합니다.</p>
    <p>3. 회사는 회원이 등록한 정보 등이 회사 정책 가이드 미 준수 또는 제3자 이의 제기 등 민원, 문제이 발생한 경우에는 이를 수정할 수 있습니다.</p>
    <p>4. 회원이 등록하거나 제공한 상품 정보에 대하여 제3자가 지식재산권과 관련한 권리침해 이의제기 또는 소송이 발생할 경우 회사는 해당 내용과 관련 모든 서비스를 중단할 수 있습니다. 이 경우 회원은 회사(및 사이트)를 면책시켜야 하며, 그로 인해 발생하는 회사(및 사이트)와 제3자의 모든 손해를 배상하여야 합니다.</p>

  </span>
  <strong>제10조 (개인정보의 보호)</strong>
  <span>
    <p>1. 회원은 본 사이트를 이용하면서 얻은 정보를 본 약관에서 정한 목적 이외의 용도로 사용할 수 없으며, 이를 위반하면 관련 법령에 따라 모든 민, 형사상의 법적 책임을 지고 자신의 노력과 비용으로 회사를 면책시켜야만 하며, 회사는 해당 회원의 본 사이트 이용을 제한할 수 있습니다.</p>
    <p>2. 회사는 서비스 이행 목적으로 이용자에게 제공된 정보 등을 일정 기간이 지난 후 비공개 조치할 수 있습니다.</p>
    <p>3. 회사가 개인정보를 보호하는데 주의를 하였음에도 불구하고 특정 회원이 제1항을 위반하여 타인의 개인정보를 유출하거나 유용한 경우 회사는 그에 대하여 아무런 책임을 지지 않습니다.</p>
    <p>4. 회사는 비즈니스 회원 서비스 제공과 관련하여 취득한 회원의 개인정보를 본인의 승낙 없이 제3자에게 제공, 누설, 배포하지 않습니다. 다만, 전기통신사업법 등 관련 법령이 규정하는 적법한 절차에 따라 수사관서 등이 회원에 관한 정보 제공을 요청할 경우 회사는 해당 자료를 제출할 수 있습니다.</p>
    <p>5. 회사는 회원이 불법행위를 하였다면 회사는 이에 대한 수사 등을 의뢰하기 위하여 관련 자료를 수사관서 등에 제출할 수 있고, 회원은 이에 동의합니다.</p>
    <p>6. 회원은 회사로부터 제공받은 정보를 제공받은 목적의 용도로 법령 또는 회사가 정한 기간 동안 보유할 수 있으며 그 기간이 종료될 경우 즉시 파기해야 합니다. 또한 제공받은 개인정보의 주체로부터 직접 파기 요청을 받은 경우 이에 응해야 합니다.</p>
    <p>7. 회원은 관련 법령 및 방송통신위원회 고시에 따라 회사로부터 제공받은 개인정보를 보호하기 위하여 기술적∙관리적 보호조치를 취해야 합니다.</p>

  </span>
  <strong>제11조 (쿠키 활용 등)</strong>
  <span>
    <p>1. 회사는 업무와 관련하여 사전 동의 없이 회원의 컴퓨터에 쿠키를 전송할 수 있고 회원의 전체 혹은 일부 정보를 특정 개인으로 식별이 불가한 통계 자료 등으로 작성하여 사용할 수 있습니다.</p>
    <p>2. 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있으며 쿠키의 설정 변경에 의해 본 서비스 이용이 변경되는 것은 회원의 책임입니다.</p>

  </span>
  <strong>제12조 (손해배상 및 면책사항)</strong>
  <span>
    <p>1. 당사자 일방이 본 약관을 불이행함으로써 상대방 또는 제3자에게 손해가 발생한 경우, 귀책 당사자는 상대방 또는 제3자에게 발생한 손해를 배상할 책임이 있습니다.</p>
    <p>2. 회원이 본 사이트를 이용하면서 회사의 명예, 신용 등 대외 이미지를 실추함으로써 회사에 유.무형적 손해가 발생한 경우, 이용자 또는 회원은 이에 따른 회사의 손해를 배상해야 합니다.</p>
    <p>3. 회원이 본 약관에서 정한 의무를 위반하거나 회사에서 허용하지 않는 방법으로 본 서비스를 이용함에 있어 제3자의 이의 제기 또는 손해가 발생한 경우 회원은 자신의 비용과 노력으로 이를 해결하여야 하고 법령상 허용하는 범위 내에서 회사를 면책합니다.</p>

  </span>
  <strong>제13조 (금지행위 및 이용제한)</strong>
  <span>
    <p>1. 회원은 회사의 경영 또는 영업 활동을 방해하거나, 회사의 명예, 신용 등 대외 이미지를 실추하는 행위를 직∙간접적으로 하거나 제3자로 하여금 하도록 하여서는 안 됩니다.</p>
    <p>2. 회사는 회원의 법령 위반, 본 약관 위반, 기타 금지행위 여부에 대한 모니터링을 실시할 수 있고, 회원은 회사의 모니터링 업무에 대하여 협조하여야 하며 회사가 요청하는 필요 최소한의 증빙자료를 제출할 의무가 있습니다.</p>

  </span>
  <strong>제14조 (비밀유지)</strong>
  <span>
    회사와 회원은 법령상 요구되는 경우를 제외하고는 본 사이트를 통하여 상대방으로부터 취득한 정보, 기술정보, 판매계획, 노하우 등 비밀로 관리되는 정보를 제3자에게 누설해서는 안 되며, 그 정보를 본 약관 이행 이외의 목적으로 사용해서는 안 됩니다.

  </span>
  <strong>제15조 (정보 제공 및 광고의 게재)</strong>
  <span>
    <p>1. 회사는 비즈니스 회원 서비스 운영, 관리 및 서비스 이용에 필요한 정보(ex. 업데이트 등)를 본 사이트 또는 비즈니스 회원 서비스 제공 화면 등에 게재하거나 이메일, 서신우편, 단문, 장문 문자메시지(SMS, MMS) 등의 방법으로 회원에게 전송할 수 있습니다.</p>
    <p>2. 회사는 비즈니스 회원 서비스를 운영할 때 회사 또는 다른 회사와 관련된 각종 광고를 영리목적 광고성 정보 수신동의한 비즈니스 회원 (기존, 본 약관 동의로 영리목적 광고성 정보 수신 동의로 처리된 회원 포함, 이하 “동의 회원”)에게 서비스 웹사이트 또는 비즈니스 회원 서비스 제공 화면 등에 게재하거나, 회원의 동의를 받아 이메일, 서신우편, 단문, 장문 문자메시지(SMS, MMS), 텔레마케팅(TM) 등의 방법으로 회원에게 전송할 수 있습니다. 다만, 회원은 관련법에 따라 언제든지 수신거절을 할 수 있습니다.</p>
    <p>3. 회원이 제2항에 따른 광고를 이용하거나 비즈니스 회원 서비스를 통한 광고주의 판촉활동에 참여하는 등의 방법으로 교신 또는 거래를 하는 것은 회원과 광고주 간의 문제입니다. 회원과 광고주간에 분쟁, 기타 문제가 발생하는 경우, 회원과 광고주가 해결하여야 하며, 이와 관련하여 회사는 귀책사유가 없는 한 책임을 지지 않습니다.</p>

  </span>
  <strong>제16조 (회원에 대한 통지)</strong>
  <span>
    <p>1. 회원에 대한 통지를 하는 경우 회사는 회원이 등록한 이메일 주소 또는 SMS 등으로 할 수 있습니다.</p>
    <p>2. 회사는 회원이 연락처를 기재하지 않았거나 변경 후 미수정하여 개별 통지를 하기가 어렵거나, 본 약관에 따른 불특정 다수 회원에 대한 공지를 할 경우 본 사이트 화면에 3일 이상 게시함으로써 개별 통지에 갈음할 수 있습니다.</p>

  </span>
  <strong>제17조 (회원탈퇴 및 이용제한)</strong>
  <span>
    <p>1. 회원이 비즈니스 회원 서비스를 탈퇴하고자 할 경우에는 회사가 정한 방법으로 회원 본인이 탈퇴신청을 하여야 하며, 비즈니스 회원 탈퇴 시 회사가 제공하는 모든 개별 서비스에서 탈퇴 처리 됩니다.</p>
    <p>2. 회원 탈퇴 신청을 한 경우, 회사는 해당 회원의 탈퇴 절차를 진행하게 되며, 탈퇴 처리가 불가한 경우 별도 팝업창 등으로 안내 드립니다. 본 사이트를 통해 탈퇴가 불가한 경우 고객센터를 통해 별도 연락주시기 바랍니다.</p>
    <p>3. 탈퇴 절차가 종료되면, 해당 회원 계정에 부속된 포인트, 쿠폰, 정보, 게시물 및 혜택 등 모든 데이터는 소멸됨과 동시에 복구할 수 없고 관련 정보는 파기 될 수 있습니다. 단, 관계 법령 및 개인정보처리방침에 따라 회사가 해당 회원의 정보를 보유할 수 있는 경우는 제외됩니다.</p>
    <p>4. 회사는 회원이 본 약관에서 규정한 회원의 의무를 이행하지 않을 경우, 회원 탈퇴 처리를 하거나 비즈니스 회원 서비스 이용을 제한할 수 있습니다.</p>
    <p>5. 회사는 회원의 개인정보 보호를 위하여 비즈니스 회원 서비스를 이용한 최종 로그인(log-in) 후 11개월간 로그인 기록이 없는 회원에 대해 비즈니스 회원 서비스 해지를 고지하고, 고지일로부터 30일 내에 로그인(log-in)하지 않을 경우 비즈니스 회원 서비스를 해지하며 회원의 개인정보를 파기합니다. 다만, 비즈니스 회원 서비스를 통해 가입한 개별 서비스가 1건 이상 존재하는 회원의 경우 해당 기간 동안은 로그인(log-in) 기록이 없어도 비즈니스 회원 서비스를 이용하는 것으로 봅니다.</p>
    <p>6. 회원은 회사의 제4항 및 제5항의 조치에 대하여 회사가 정한 절차에 따라 이의신청을 할 수 있습니다.</p>
    <p>7. 전항의 이의가 정당한 것으로 판명된 경우, 회사는 회원의 비즈니스 회원 서비스 제공을 재개합니다.</p>

  </span>
  <strong>제18조 (보칙)</strong>
  <span>
    <p>1. 본 약관과 관련하여 당사자가 서로 합의하여 추가로 작성한 계약서, 협정서, 약정서 등과 회사의 정책 변경, 법령의 제·개정 또는 공공기관의 고시·지침 등에 따라 회사가 사이트 등을 통해 회원에게 공지하는 내용도 본 약관의 일부로 구성됩니다.</p>
    <p>2. 본 약관과 개별약관, 별도 정책에서 정하지 않은 사항은 관련 법령 및 상관례를 따릅니다.</p>

  </span>
  <strong>제19조 (합의관할)</strong>
  <span>
    <p>본 약관과 관련하여 회사와 회원간 분쟁이 발생하여 소송이 제기될 때에는 서울중앙지방법원을 전속적 합의관할로 합니다.</p>
    <p>본 사이트 서비스와 관련하여 궁금하신 사항이 있으시다면 언제든지 고객센터로 문의 주시기 바랍니다.</p>

  </span>
  <strong>부 칙</strong>
  <span>
    제1조(시행일) 본 약관은 2024년 04월 24일부터 시행합니다.

  </span>
</div>
</section>
<section>
</section>`;

// 비즈니스 회원 서비스 개인정보 수집 및 이용 동의
const M02 = `
<section>
  <div class='inner' >
  <table>
    <tbody>
      <tr>
        <th>구분</th>	 <td>비즈니스 회원 서비스</td>
      </tr>
      <tr>  
        <th>유형</th>	 <td>필수</td>
      </tr>
      <tr>
        <th>이용 목적</th>	<td>계약의 이행 및 신청 서비스 제공, 회사가 제공하는 서비스의 통합로그인 제공을 위한 고객식별 및 본인여부 확인, 상담∙불만처리, 고지사항 전달, 상품∙서비스 이용실적 정보 통계∙분석, 상품∙서비스 개선 및 추천, 불법∙부정이용방지</td>
      </tr>
      <tr>
        <th>수집 항목</th>	<td>ID(이메일), 비밀번호, 이메일, 휴대폰 번호(파트너센터, 광고센터 서비스 이용 시), CI(연계정보, 파트너센터 이용 시, 광고센터 서비스 이용 시), 이름(파트너센터 이용 시, 광고센터 서비스 이용 시)</td>
      </tr>
      <tr>
        <th>보유 기간</th>	
        <td>
           <p>계약 종료 시까지 또는 회원 탈퇴 시까지.</p>
          <p>관계법령에 따라 보존할 필요가 있는 경우 해당 법령에서 요구하는 기한까지 보관</p>
        </td>
      </tr>
    </tbody>
  </table>

  <p>※ 위 개인정보 수집 · 이용에 대한 동의를 거부할 수 있으며 거부할 경우 서비스 이용이 제한됩니다.</p>
  
  </div>
</section>
`;

// 파트너 서비스 이용약관
const M03 = `
<section>
  <div class="inner">
      <strong>제1조 (목적)</strong>
      <span>
        본 약관은 주식회사 유비컴퍼니(이하 “회사”)가 운영하는 유비컴퍼니 파트너 서비스(이하 “본 사이트”)를 통해 제공하는 서비스와 관련하여 회사와 이용자 간의 권리와 의무, 이용조건 및 절차 등 기본적인 사항을 정하기 위해 마련되었습니다.
      </span>
      <strong>제2조 (용어의 정의)</strong>
      <span>
      <p>1. 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
      <ul>
        <li>“일반 서비스”란 회사가 본 사이트를 통해 제공하는 서비스로서 이용자와 일반회원이 이용할 수 있는 서비스(회사 서비스 안내, 교육 및 정보 제공, 견적 상담, 서비스 이용 상담, 견적 비용 등)를 말합니다.</li>
        <li>“B2B 서비스”란 회사가 본 사이트를 통해 B2B회원을 대상으로 제공하는 서비스로서 예약 및 판매(이하 “제휴입점”)서비스와 기타 유료 서비스를 말합니다. 제휴입점과 유료 서비스를 이용하기 위해서는 본 약관에 대한 동의 외에 유비컴퍼니 예약서비스 이용 약관과 같은 각 서비스별 이용계약 체결 또는 신청서 작성.제출이 필요하며 회사의 최종 승인 이후에 해당 서비스를 이용하실 수 있습니다(이하 포괄하여 “B2B 계약”이라고 합니다).</li>
        <li>“상품”이란 B2B 회원이 B2B 서비스를 이용하기 위하여 회사에 제공한 시설 및 객실 등의 이용에 관한 권리 또는 이용권을 말합니다.</li>
        <li>“이용자”란 회원가입 유무를 불문하고 본 약관에 따라 회사가 제공하는 서비스를 이용하는 자를 말합니다. (본 약관에서 회원으로 별도 지칭하지 않는 한, 본 약관에서 지칭하는 “이용자”란 “일반회원” 및 “B2B 회원”을 포괄하여 지칭하는 것을 의미합니다.)</li>
        <li>“B2B 회원”이란 회사가 요구하는 자격에 부합한 자로서 본 사이트에서 회사가 정한 가입 절차에 따라 회원 가입 및 B2B 계약 체결을 완료한 사업자 회원을 말합니다. B2B 회원으로 가입 완료한 사업자 회원(마스터계정)은 회사가 정한 절차에 따라 고용관계(지배인, 관리자 등)에 근거하여 근무자계정을 추가할 수 있으며, 이때 해당 근무자계정도 B2B 회원으로 봅니다.</li>
        <li>“일반 회원”이란 본 사이트에서 회사가 정한 가입절차에 따라 회원 가입을 완료한 이용자로서, B2B 서비스를 제외한 일반서비스를 이용할 수 있는 자를 말합니다.</li>
        <li>“비즈포인트”란 회원이 회사가 제공하는 서비스 상품 구매 (이용)시 해당 프로모션 등에 따라 적립 받거나 결제 수단으로 사용 가능한 B2B회원 대상 포인트를 말하며, 세부사항은 비즈포인트 정책에 따릅니다.</li>
        <li>“아이디”란 회원의 식별과 회원의 서비스 이용을 위하여 회원이 설정하고 회사가 승인하여 회사에 등록된 영문, 숫자 또는 영문과 숫자의 조합을 말합니다.</li>
        <li>“비밀번호”란 회원인지를 확인하고 비밀을 보호하기 위하여 회원이 스스로가 설정해 회사에 등록한 영문과 숫자, 특수문자의 조합을 말합니다.</li>
      </ul>
      <p>제1항에서 정의되지 않은 용어의 의미는 관련 법령 및 일반적인 거래관행을 따릅니다.</p>
      </span>
      <strong>제3조 (서비스 이용신청)</strong>
      <span>
      <p>1. 본 사이트 내 각종 서비스를 이용하고자 하는 이용자는 본 약관에 동의 하여야 하며, 본 서비스를 이용하신 경우 본 약관에 동의한 것으로 간주됩니다.</p>
      <p>2. 유비컴퍼니 비즈니스 회원 서비스의 가입 후 본 사이트를 이용하고자 하는 이용자는, 회사가 정한 절차에 따라 필요한 사항을 기입하고 회사가 요구한 자료를 제출함으로써 서비스 이용을 신청하실 수 있습니다. 회사는 회원이 운영할 숙소의 상황에 따라 회사에서 요구하는 자격에 부합하는지 심사를 진행할 수 있으며, 심사 절차를 거쳐 최종 승인된 경우에 한하여 이용 신청 절차가 종료됩니다.</p>
      <p>3. 회원가입 신청 처리는 이용자의 신청순서에 의하며, 회원가입 완료시기는 회사의 승낙이 회원에게 도달한 시점으로 하며, 이용자는 그 이후부터 해당 회원으로서 서비스 이용이 가능합니다.</p>
      <p>4. 회원가입 신청시 이용자 본인의 정보로 가입신청을 해야 하며, 본인의 정보로 가입하지 않은 사실(타인의 정보를 도용하는 경우 포함)이 확인된 경우 서비스 이용이 제한되거나 관련 법령에 따라 처벌받을 수 있습니다.</p>
      <p>5. B2B 회원은 회사와 B2B 계약 체결 절차를 진행하여 회사의 승낙 처리가 된 자에 한하며, 가입 여부는 본 사이트 해당 서비스화면에서 확인하실 수 있습니다. 회사는 승인 여부에 대해 별도로 E-mail 또는 기타 방법으로 통지합니다.</p>
      <p>6. 회사는 다음과 같은 사유가 발생한 경우 이용신청에 대한 승낙을 거부하거나 유보할 수 있습니다.</p>
      
      <ul>
        <li>실제 정보로 가입 신청하지 않은 것이 확인된 경우</li>
        <li>이미 가입된 회원 정보와 동일한 경우</li>
        <li>회사에 의하여 서비스 이용계약이 해지된 날로부터 7일 이내에 재이용신청을 하는 경우</li>
        <li>회사로부터 회원자격 정지 조치 등을 받은 회원이 그 조치기간 중에 서비스 이용계약을 임의 해지하고 재이용신청을 하는 경우</li>
        <li>기타 회사 정책에 위반 또는 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우</li>
      </ul>
      </span>
      <strong>제4조 (약관의 효력)</strong>
      <span>
      <P>1. 회사는 관계 법령, 회사 정책, 사업의 방향성 등에 따라 홈페이지 공지를 통해 별도 정책을 제.개정할 수 있으며, 이용자는 별도 정책이 본 약관 및 관련 이용계약의 일부로 구성됨을 확인하고 동의합니다.</P>
      <P>2. 회사는 관계 법령, 회사 정책, 사업의 방향성 등에 따라 일부 특정 서비스에 관한 약관(이하 “개별약관”) 을 별도로 제.개정할 수 있습니다. 회원이 개별 약관에 동의한 경우 본 약관과 함께 본 사이트 이용계약의 일부를 구성하게 되며, 개별약관과 본 약관간 상충하는 내용이 있으면 개별약관이 우선하여 적용됩니다.</P>
      <P>3. 본 약관 또는 개별약관의 변경이 있는 경우, 회사는 변경 내용의 효력발생일 7일 이전(단, 이용자에게 불리한 내용으로 변경 시 30일 이전)에 해당 변경 사항을 본 사이트에 공지하며, 변경 적용일까지 별도의 거부 의사를 표시하지 않으면 변경 약관, 개별약관에 동의한 것으로 간주합니다. 또한 회원이 변경된 약관, 개별약관에 동의하지 않을 경우 회사에 탈퇴(이용계약의 해지)를 요청할 수 있습니다.</P>
      <P>4. 변경된 약관은 그 적용일자 이전으로 소급하여 적용되지 않습니다.</P>
      </span>
      <strong>제5조 (제공 서비스)</strong>
      <span>
      <p>1. 일반 서비스</p>
      <ul>
        <li>상품의 운영 및 관련 일반 상담 서비스 (이용문의, 비용 견적, 운영 상담 등)</li>      
        <li>상품의 온라인 판매 및 운영 관련 교육 등 기타 정보 제공 서비스</li>
        <li>이벤트 제공 및 프로모션 참여 안내 서비스</li>
      </ul>
      <p>2. B2B 서비스</p>
      <ul>
        <li>일반 서비스 일체</li>
        <li>상품 예약(판매) 또는 광고 등 B2B 상품 신청 및 승인 확인 서비스</li>
        <li>온라인 상 상품의 예약(판매) 현황 확인 및 관리(상품 판매 시간, 갯수, 가격 조정 등), 구매 고객 관리 서비스</li>
        <li>온라인 상 상품의 마케팅 및 광고(정보 노출, 컨텐츠 노출 등)의 현황 확인 및 관리 서비스(이하 “파트너센터”)</li>
        <li>제휴입점 신청 및 심사 과정의 확인, 입점 조건 관리를 할 수 있는 서비스</li>
        <li>유료 정보 이용 및 별도의 상품 이용 약관 동의 절차 후 이용이 가능한 서비스</li>
        <li>비즈포인트 적립 및 사용 현황 조회 서비스</li>
      </ul>
      <p>3. 서비스 이용료</p>
      <ul>
        <li>일반 서비스는 무료로 제공되며, 별도 비용이 발생하는 일반 서비스가 있을 경우 사전에 안내 드립니다.</li>
        <li>B2B 서비스는 개별 체결한 B2B 계약에 따라 별도 비용이 발생할 수 있으며 구체적인 조건은 별도 계약에서 정하는 바에 의합니다.</li>
        <li>본 사이트의 이용료는 회원이 이용 신청하신 서비스 종류에 따라 이용료 및 정산 방식이 달라지며, 본 사이트 내 특정 페이지나 기능을 활용하여 이용 서비스의 조건과 현황, 이용료를 확인할 수 있습니다.</li>
      </ul>  
      </span>
      <strong>제6조 (이용자의 권리와 의무)</strong>
      <span>
      <p>1. 회사는 연중 무휴, 안정적으로 본 사이트를 유지하고 서비스를 제공하기 위해 노력합니다. 단, 서버 및 정보통신설비의 보수점검, 교체 또는 고장, 통신 두절 등의 사유가 발생하거나 천재지변 등 불가항력적 사유로 인하여 서비스의 전부 또는 일부를 중단할 수 있습니다.</p>
      <p>2. 이용자는 서비스 이용과 관련하여 다양한 사항들에 대해서 상담 신청이나 견적 요청을 할 수 있습니다. 이 경우, 회사는 해당 서비스 제공을 위하여 필요한 정보 등을 요청할 수 있습니다.</p>
      <p>3. 이용자는 이용하고자 하는 서비스의 종류에 따라 회사가 요청하는 정보를 제공하거나 서류를 제출해야 합니다. 이 경우 이용자는 회사에 제공하는 정보와 서류에 허위가 없어야 하고 진실됨을 보장하여야 합니다.</p>
      <p>4. 이용자는 전자상거래 등에서의 소비자보호에 관한 법률(이하 “전상법”), 전자금융거래법, 여신전문금융업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 상표법, 저작권법 등 본 사이트에서 상품의 예약(판매) 또는 서비스 이용과 관련된 모든 관계 법령이 요구하는 사항을 준수하여야 합니다.</p>
      <p>5. 이용자는 회사가 서면으로 미리 승인하지 않으면 회사의 상호나 로고 등을 사용할 수 없습니다.</p>
      </span>
      <strong>제7조 (회원의 권리와 의무)</strong>
      <span>
      <p>1. 일반 회원의 경우 일반 서비스 이용자와 동일한 권리와 의무가 적용됩니다.</p>
      <p>2. B2B 회원의 경우 일반 서비스 이용자와 동일한 권리와 의무가 적용됩니다.</p>
      <ul>
        <li>B2B 회원은 운영중인 상품의 사업자(마스터계정) 또는 근무자(근무자계정)로 온라인 마케팅 및 예약 판매 등을 위한 입점 신청 및 선택적인 상품 이용을 신청할 수 있습니다.</li>
        <li>B2B 회원이 근무자계정을 등록할 경우, 해당 근무자계정에 대한 관리 책임은 마스터계정을 보유한 B2B 회원에게 있으며 근무자계정 관리에 철저를 기하여야 합니다. 근무자의 변경, 퇴사 등의 사유가 발생한 경우 지체 없이 정보의 최신성을 유지하기 위한 조치를 취하여야 하며, 이 의무를 이행하지 않아 발생한 모든 책임은 해당 B2B 회원에게 있습니다.</li>
        <li>B2B 회원은 운영중인 상품에 문제가 생기거나, 사업자로서의 지위 상실, 소유 변경, 근무자계정 변경 등 상품 및 시설 운영에 필요한 정보가 변경된 경우 지체없이 회사에게 알리고 적절한 조치를 취하여야 합니다.</li>
        <li>B2B 회원은 본 사이트에서 상품 시설 이용권, 객실 이용 등의 상품을 예약(판매) 관리할 수 있습니다. 이를 위해서 회원은 상품에 관한 정보를 본 사이트에 직접 등록, 관리할 수 있습니다.</li>
        <li>B2B 회원은 상품의 재고 수량 등 수시로 바뀌는 사항에 대한 데이터를 적절히 관리해야 하며, 데이터를 허위로 기재할 수 없습니다.</li>
        <li>B2B 회원은 상품 및 용역에 대한 보증 서비스를 자신의 책임과 비용으로 시행하여야 합니다.</li>
        <li>B2B 회원은 상품에 필요한 인∙허가 및 자격, 신고 등을 완비하고 적법.유효한 상태로 유지하여야 하며, 이를 위반 시 회사는 B2B 회원의 상품에 대한 B2B 서비스를 일시 또는 영구히 중단 할 수 있습니다. 또한, 상기 자격을 갖추지 않은 상태에서 관련 상품을 판매하여 발생한 모든 민, 형사상의 책임은 해당 B2B 회원 본인에게 있습니다.</li>
        <li>B2B 회원은 상품 등록 후 장기간 예약(판매)가 없을 경우, 상품 판매 활성화를 위한 회사의 프로모션 제안 등에 대하여 따르기로 합니다.</li>
        <li>회사는 B2B 회원의 귀책사유로 상품에 대한 취소율, 구매 고객 민원제기율 등을 분석하여 관리할 수 있으며, 회사의 합리적 기준에 따른 일정 수준을 초과 시 서비스 품질 향상 등을 위하여 해당 B2B 회원에게 경고, 아이디(ID) 정지, B2B 서비스 중단 등 필요한 조치를 할 수 있습니다.</li>
        <li>B2B 회원은 변경 사항이 있다면 즉시 회사에 해당 내용을 통지해야 하며, 회사는 통지의 지연으로 발생한 회원의 손해에 대해 책임을 지지 않습니다.</li>
        <li>B2B 계약이 기간 만료, 해제.해지 등의 사유로 종료된 경우 회사는 B2B 회원의 회원자격을 일반회원으로 조정 할 수 있습니다.</li>
        <li>B2B 회원은 회사의 서면 동의 없이 본 약관 상 권리와 의무를 제3자에게 양도하거나 처분할 수 없습니다.</li>
      </ul>
      </span>
      <strong>제8조 (파트너센터 서비스 이용시 권리와 의무)</strong>
      <span>
      <p>1. 파트너센터는 B2B 회원이 이용할 수 있는 B2B 서비스의 일종으로 온라인 상 상품의 예약(판매) 현황 확인 및 관리(상품 판매 시간, 갯수, 가격 조정 등), 구매 고객 관리 서비스를 의미합니다.</p>
      <p>2. B2B회원은 파트너센터 서비스를 통해 숙박 등 자신의 서비스를 이용하는 고객을 편리하게 관리할 수 있습니다.</p>
      <p>3. 파트너센터에 기록, 저장, 처리, 관리되는 고객의 정보는 파트너센터를 이용하시는 해당 B2B회원의 고객정보이며, 회사는 B2B회원으로부터 이러한 고객 관리 업무를 수탁 받아 처리하게 됩니다.</p>
      <p>4. 회사는 B2B회원과의 관계에서 고객정보 처리 업무를 적법하게 수탁받은 자로서 개인정보보호법 및 관련 법령에서 정한 안전성 확보조치를 다하고 파트너센터 운영과 관련된 수탁업무 범위를 벗어나 개인정보를 이용하지 않습니다.</p>
      <p>5. B2B회원은 파트너센터 이용 종료시 파트너센터 위탁업무와 관련하여 회사가 보유한 개인정보의 파기 또는 반납을 요청할 수 있습니다.</p>
      <p>6. B2B회원은 파트너센터를 통해 기록, 저장, 처리, 관리되는 정보에 대하여 열람, 제공 등을 요청할 수 있으며, B2B회원께서 다운로드 또는 별도로 저장한 정보에 대해서는 회사는 어떠한 책임도 지지 않습니다.</p>
      </span>

      <strong>제9조 (아이디 및 비밀번호의 관리)</strong>
      <span>
      <p>1. 아이디 및 비밀번호에 대한 관리책임은 회원에게 있으며, 회원은 어떠한 경우에도 본인의 아이디 또는 비밀번호를 타인에게 양도.대여.공유.제공 해서는 안됩니다.</p>
      <p>2. 회사의 귀책사유 없이 아이디 또는 비밀번호의 유출, 양도, 대여로 인하여 발생하는 손실이나 손해에 대해서는 회원 본인이 그에 대한 모든 책임을 부담합니다.</p>
      <p>3. 회원은 아이디 또는 비밀번호를 도난 당하거나 제3자가 무단으로 이를 사용하고 있음을 인지한 경우, 이를 즉시 회사에 통보하여야 하고 회사는 이에 대한 신속한 처리를 위하여 최선의 노력을 다합니다.</p>
      </span>


      <strong>제10조 (지식재산권)</strong>
      <span>
      <p>1. 본 사이트를 통하여 제공되는 모든 정보 및 게시물 등(이하 “콘텐츠”라 합니다)의 지식재산권은 회사에 있으며 저작권법 등에 따라 보호 받는 회사의 자산으로 콘텐츠의 무단 전재, (재)배포, 복사, 2차적 저작행위 등을 엄격히 금지하고 있습니다. 이용자는 콘텐츠를 서비스 제공 목적 외로 사용할 수 없으며, 이를 위반할 경우 민, 형사적 처벌을 받을수 있습니다.</p>
      <p>2. 이용자는 사이트내 등록하거나 회사에 제공하는 상품 정보 등이 제3자의 상표권, 특허권, 저작권, 성명권, 초상권 등 제반 지식재산권을 침해하지 않았으며, 제3자의 지식재산권을 사용할 때에는 정당한 권리자로부터 사용 허락을 받았음을 보증합니다.</p>
      <p>3. 회사는 이용자가 등록한 상품 정보가 회사 정책 가이드 미 준수 또는 제3자 이의 제기 등 민원, 문제가 발생한 경우에는 이를 수정할 수 있습니다.</p>
      <p>4. 이용자가 등록하거나 제공한 상품 정보에 대하여 제3자가 지식재산권과 관련한 권리침해 이의제기 또는 소송이 발생할 경우 회사는 해당 상품 관련 모든 서비스를 중단 할 수 있습니다. 이 경우 이용자는 회사(및 사이트)를 면책시켜야 하며, 그로 인해 발생하는 회사(및 사이트)와 제3자의 모든 손해를 배상하여야 합니다.</p>
      </span>

      <strong>제11조 (개인정보의 보호)</strong>
      <span>
      <p>1. 이용자는 서비스를 이용하면서 얻은 타인(예약고객 등)의 개인정보를 본 약관에서 정한 목적 이외의 용도로 사용할 수 없으며, 이를 위반하면 관련 법령에 따라 모든 민/형사상의 법적 책임을 지고 자신의 노력과 비용으로 회사를 면책시켜야만 하며, 회사는 해당 이용자의 본 사이트 이용을 제한할 수 있습니다.</p>
      <p>2. 회사는 서비스 이행 목적으로 회원에게 제공된 타인(예약고객 등)의 개인정보에 대하여 일정 기간이 지난 후 비공개 조치할 수 있습니다.</p>
      <p>3. 회사가 개인정보를 보호하는데 주의를 하였음에도 불구하고 특정 이용자가 제1항을 위반하여 타인의 개인정보를 유출하거나 유용한 경우 회사는 그에 대하여 아무런 책임을 지지 않습니다.</p>
      <p>4. 회사는 전기통신사업법 등 관련 법령이 규정하는 적법한 절차에 따라 수사관서 등이 이용자에 관한 정보 제공을 요청받았을 경우 회사는 해당 자료를 제출할 수 있습니다.</p>
      <p>5. 회사는 이용자가 불법행위를 하였다면 회사는 이에 대한 수사 등을 의뢰하기 위하여 관련 자료를 수사관서 등에 제출할 수 있고 이용자는 이에 동의합니다.</p>
      <p>6. 이용자는 회사로부터 제공받은 정보를 제공받은 목적(상품 서비스 제공 등)의 용도로 법령 또는 회사가 정한 기간 동안 보유할 수 있으며 그 기간이 종료될 경우 즉시 파기해야 합니다. 또한 제공받은 개인정보의 주체로부터 직접 파기 요청을 받은 경우 이에 응해야 합니다.</p>
      <p>7. 이용자는 관련 법령 및 방송통신위원회 고시에 따라 회사로부터 제공받은 개인정보를 보호하기 위하여 기술적∙관리적 보호조치를 취해야 합니다.</p>
      </span>

      <strong>제12조 (쿠키 활용 등)</strong>
      <span>
      <p>1. 회사는 업무와 관련하여 사전 동의 없이 이용자 컴퓨터에 쿠키를 전송할 수 있고 이용자의 전체 혹은 일부 정보를 특정 개인으로 식별이 불가한 통계 자료 등으로 작성하여 사용할 수 있습니다.</p>
      <p>2. 이용자는 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있으며 쿠키의 설정 변경에 의해 본 서비스 이용이 변경되는 것은 이용자의 책임입니다.</p>
      </span>

      <strong>제13조 (손해배상 및 면책사항)</strong>
      <span>
      <p>1. 당사자 일방이 본 약관을 불이행함으로써 상대방 또는 제3자에게 손해가 발생한 경우, 귀책 당사자는 상대방 또는 제3자에게 발생한 손해를 배상할 책임이 있습니다.</p>
      <p>2. 이용자가 본 사이트를 이용하면서 회사의 명예, 신용 등 대외 이미지를 실추함으로써 회사에 유.무형적 손해가 발생한 경우, 이용자는 이에 따른 회사의 손해를 배상해야 합니다.</p>
      <p>3. 이용자가 본 약관에서 정한 의무를 위반하거나 회사에서 허용하지 않는 방법으로 본 서비스를 이용함에 있어 제3자의 이의 제기 또는 손해가 발생한 경우 이용자는 자신의 비용과 노력으로 이를 해결하여야 하고 법령상 허용하는 범위 내에서 회사를 면책합니다.</p>
      </span>

      <strong>제14조 (금지행위 및 이용제한)</strong>
      <span>
      <p>1. 이용자는 회사의 경영 또는 영업 활동을 방해하거나, 회사의 명예, 신용 등 대외 이미지를 실추하는 행위를 직∙간접적으로 하거나 제3자로 하여금 하도록 하여서는 안 됩니다.</p>
      <p>2. 회사는 이용자의 법령 위반, 본 약관 위반, 기타 금지행위 여부에 대한 모니터링을 실시할 수 있고, 이용자는 회사의 모니터링 업무에 대하여 협조하여야 하며 회사가 요청하는 필요 최소한의 증빙자료를 제출할 의무가 있습니다.</p>
      </span>

      <strong>제15조 (비밀유지)</strong>
      <span>
       회사와 이용자는 법령상 요구되는 경우를 제외하고는 본 사이트를 통하여 상대방으로부터 취득한 정보, 기술정보, 판매계획, 노하우 등 비밀로 관리되는 정보를 제3자에게 누설해서는 안 되며, 그 정보를 본 약관 이행 이외의 목적으로 사용해서는 안 됩니다.
      </span>

      <strong>제16조 (정보 제공 및 광고의 게재)</strong>
      <span>
      <p>1. 회사가 이용자 및 회원에게 무료로 제공하는 서비스의 일부는 광고 게재를 통한 수익으로부터 나옵니다. 이에 영리목적 광고성 정보 수신동의한 이용자 및 회원(기존, 본 약관 동의로 영리목적 광고성 정보 수신 동의로 처리된 이용자 및 회원 포함, 이하 “동의 회원”)에게 서비스 이용 시 서비스 화면 상 광고가 노출됩니다.</p>
      <p>2. 회사는 이용자의 게시물 내용, 검색 내용 뿐만 아니라 언어, 쿠키 및 기기정보, IP 주소, 브라우저 유형, 운영체제 및 요청 일시와 같은 표준 로그 정보 등을 활용한 맞춤광고를 제공합니다. 이에 대한 자세한 사항은 "개인정보처리방침"을 참고하시기 바랍니다.</p>
      <p>3. 이용자가 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 다른 상품 등을 이용하는 경우, 이는 전적으로 이용자와 광고주 간의 법률관계이므로, 그로 인해 발생한 이용자와 광고주간 분쟁 등 제반 문제는 이용자와 광고주간에 직접 해결하여야 하며, 이와 관련하여 회사는 어떠한 책임도 지지 않습니다.</p>
      <p>4. 회사는 동의 회원으로부터 수집한 개인정보를 통해 회원의 서비스 이용 중 필요하다고 판단되는 다양한 마케팅 정보를 SMS(LMS), 스마트폰 알림 (Push 알림), E-mail 등을 활용하여 발송할 수 있습니다. 다만, 회원은 거래관련정보 및 고객문의 등에 대한 답변을 제외하고 관련법에 따라 언제든지 마케팅 수신 동의를 철회하실 수 있으며, 이 경우 회사는 전단의 마케팅 정보 등을 제공하는 행위를 중단합니다(단, 시스템 반영에 시차가 있을 수 있음).</p>
      </span>

      <strong>제17조 (회원에 대한 통지)</strong>
      <span>
      <p>1. 회원에 대한 통지를 하는 경우 회사는 회원이 등록한 E-mail 주소 또는 SMS 등으로 할 수 있습니다.</p>
      <p>2. 회사는 회원이 연락처를 기재하지 않았거나 변경 후 미수정하여 개별 통지를 하기가 어렵거나, 본 약관에 따른 불특정 다수 회원에 대한 공지를 할 경우 본 사이트 화면에 3일 이상 게시 함으로써 개별 통지에 갈음할 수 있습니다.</p>
      </span>

      <strong>제18조 (회원 탈퇴, 서비스 탈퇴, 서비스 이용 중단)</strong>
      <span>
      <p>1. 회원은 비즈니스 회원 사이트를 통해 회원의 탈퇴를 신청할 수 있습니다. 회원 탈퇴 처리 시 해당 회원 계정에 부속된 정보, 게시물 및 혜택(비즈포인트, 마일리지, 쿠폰 포함) 등 모든 데이터는 소멸됨과 동시에 복구할 수 없게 되며 관련 정보는 파기 됩니다(단, 관계 법령 및 개인정보처리방침에 따라 회사가 해당 회원의 정보를 보유할 수 있는 경우는 제외)</p>
      <p>2. 회원은 회사가 정한 방식에 따라 본 사이트 서비스 이용 중단 또는 서비스 탈퇴를 신청할 수 있습니다. 단, 본 서비스 탈퇴 처리 시 본 서비스 이용과 관련된 정보, 게시물 및 혜택 (비즈포인트, 마일리지, 쿠폰 포함) 등 모든 데이터는 소멸됨과 동시에 복구할 수 없게 되며 관련 정보는 파기 됩니다(단, 관계 법령 및 개인정보처리방침에 따라 회사가 해당 회원의 정보를 보유할 수 있는 경우는 제외)</p>
      </span>

      <strong>제19조 (보칙)</strong>
      <span>
      <p>1. 본 약관과 관련하여 당사자가 서로 합의하여 추가로 작성한 계약서, 협정서, 약정서 등과 회사의 정책 변경, 법령의 제·개정 또는 공공기관의 고시·지침 등에 따라 회사가 사이트 등을 통해 회원에게 공지하는 내용도 본 약관의 일부로 구성됩니다.</p>
      <p>2. 본 약관과 개별약관, 별도 정책에서 정하지 않은 사항은 관련 법령 및 상관례를 따릅니다.</p>
      </span>


      <strong>제20조 (합의관할)</strong>
      <span>
      <p>본 약관과 관련하여 회사와 이용자간 분쟁이 발생하여 소송이 제기될 때에는 서울중앙지방법원을 전속적 합의관할로 합니다.</p>
      <p>본 사이트 서비스와 관련하여 궁금하신 사항이 있으시다면 언제든지 고객센터로 문의 주시기 바랍니다.</p>
      </span>

      <strong>부 칙</strong>
      <span>
      제1조(시행일) 본 약관은 2024년 04월 24일부터 시행합니다.
      </span>
  </div>
</section>`;

// 파트너 서비스 개인정보 수집 및 이용 동의
const M04 = `
<section>
  <div class='inner' >
  <table>
    <tbody>
      <tr>
        <th>구분</th>	 <td>파트너센터</td>
      </tr>
      <tr>  
        <th>유형</th>	 <td>필수</td>
      </tr>
      <tr>
        <th>이용 목적</th>	<td>계약의 이행 및 신청 서비스 제공, 요금 정산∙청구∙수납∙추심, 고객식별 및 본인여부 확인, 상담∙불만처리, 고지사항 전달, 상품∙서비스 이용실적 정보 통계∙분석, 상품∙서비스 개선 및 추천, 불법∙부정이용방지, 통합 로그인</td>
      </tr>
      <tr>
        <th>수집 항목</th>	
        <td>
        <p>제휴 계약 체결 시</p>
        <p>이름, 회사명(사업자/지점/시설명 포함), 상호, 사업자등록번호, 연락처, 이메일, 주소, 팩스번호, 담당자 정보(이름, 이메일, 연락처), 정산계좌정보(은행명, 계좌번호, 예금주명)</p>
        
        <p>서비스 이용 시</p>
        <p>ID(이메일), 상호, 사업자등록번호, 대표자명, 주소, 대표번호, 이메일, 휴대폰 번호, 이름</p>
        
        <p>고객상담 시</p>
        <p>상담내용 및 상담에 필요한 개인정보</p>
        </td>
      </tr>
      <tr>
        <th>보유 기간</th>	
        <td>
        <p>계약 종료 시까지 또는 회원 탈퇴 시까지.</p>
        <p>관계법령에 따라 보존할 필요가 있는 경우 해당 법령에서 요구하는 기한까지 보관</p>
        </td>
      </tr>
    </tbody>
  </table>

  <p>※ 위 개인정보 수집 · 이용에 대한 동의를 거부할 수 있으며 거부할 경우 서비스 이용이 제한됩니다.</p>
  
  </div>
</section>`;

const A04 = `
<section>
  <div class="inner">
      <span>수신 동의한 이메일, SMS로 업로드부킹이 할인 쿠폰, 이벤트 등의 특별한 혜택 및 상품 정보를 받아 볼 수 있습니다.  수신 거부 시 사용 가능한 쿠폰 및 예약 알림 등이 발송되지 않습니다.</span>
      <table>
          <tbody>
              <tr>
                  <th>수집 및 이용 목적</th>
                  <td>
                      개인맞춤형 상품·서비스 혜택에 관한 정보제공, 통계·분석·이용내역 정보와의 결합 및 분석, 회사 또는 기타 광고주의 요청에 따른 정보·안내(프로모션·이벤트) 전송
                  </td>
              </tr>
              <tr>
                  <th>수집 항목</th>
                  <td>예약내역(예약일시, 결제금액, 업체명) 예약자 및 구매자 정보(이름,휴대폰번호,이메일), 상담 내용 및 상담에 필요한 개인정보<br/>
                      그 외 수집하는 정보가 있으면 추가 할 것
                  </td>
              </tr>
              <tr>
                  <th>보유 및 이용 기간</th>
                  <td>
                      •회원 탈퇴 또는 동의 철회 요청 시 까지<br/>
                      •동의 철회 또는 이용종료일부터 *개월후 파기.
                  </td>
              </tr>
          </tbody>
      </table>
      <table>
          <tbody>
              <tr>
                  <th>전송자</th>
                  <td>주식회사 유비컴퍼니</td>
              </tr>
              <tr>
                  <th>전송자 연락처</th>
                  <td>museum@uploadbooking.com</td>
              </tr>
              <tr>
                  <th>파기 절차 및 방법</th>
                  <td>파기절차:회사는 파기 사유가 발생한 개인정보를 선정하고 개인 정보 보호책임자 승인 후 개인정보를 파기함.<br/>
                      파기방법:회사는 전자적 파일 형태로 처리된 정보는 기록을 재생 할 수 없도록 파기하며,종이 문서에 의하여 처리된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기함.
                  </td>
              </tr>
          </tbody>
      </table>
      <span>동의한 개인 정보는 업로드부킹(uploadbooking.com)에서 새로운 서비스 및 이벤트 정보 등의 안내를 하기 위해 업로드부킹 관리자 데이터 베이스에 수집되고있으며, 제3자는 광고성 정보를 보낼 수 없습니다.</span>
  </div>
</section>`;

const A03 = `
<section>
  <div class="inner">
      <table>
          <tbody>
              <tr>
                  <td>제공 받는자</td>
                  <td>정보 제공 항목</td>
                  <td>제공 목적</td>
                  <td>보유 및 이용기간</td>
              </tr>
              <tr>
                  <td>예약숙소</td>
                  <td>
                      예약 및 숙박 서비스 이용자의 정보 <br/>
                      (예약자명,투숙자명,이메일,핸드폰번호,예약번호,결제정보(결제금액,신용카드번호,유효기간)) 
                  </td>
                  <td>숙박 예약 서비스의 이용 계약 이행(서비스 제공,예약 확인,이용자 정보 확인)</td>
                  <td>5년</td>
              </tr>
              <tr>
                  <td>예약확인서 발송업체 </td>
                  <td>거래정보(투숙자명,이메일,휴대폰번호,예약번호,결제정보(결제금액))</td>
                  <td>예약관련 안내사항의 이메일 예약확인서 발송</td>
                  <td>5년</td>
              </tr>
              <tr>
                  <td>알림톡 발송업체</td>
                  <td>거래정보(성명,휴대폰번호,상품명,상품금액,예약내용)</td>
                  <td>예약관련 안내사항의 카카오 알림톡, SMS전송</td>
                  <td>5년</td>
              </tr>
              <tr>
                  <td>결제 대행 업체</td>
                  <td>결제정보(구매자명,상품명)</td>
                  <td>결제관련 결제사항의 이메일 또는 문자 발송</td>
                  <td>5년</td>
              </tr>
          </tbody>
      </table>
      <span>
          <span class="font-red">※</span> 더 나은 서비스를 위하여 고객님의 개인정보를 협력 업체 등과 공유할 필요가 있는 경우에는 해당 내용을 이용자에게 고지하여 동의를 구합니다.
      </span>
      <h3>[개인정보의 제3자 제공 거부]</h3>
      <span>이용자는 개인정보의 제3자 제공 동의를 거부할 수 있습니다.단,동의를 거부하는 경우 본 서비스 결제 및 예약이 정상적으로 이루어 질 수 없음을 알려드립니다.</span>
  </div>
</section>
`;

const A02 = `
<section>
  <div class="inner">
      <table>
          <tbody>
              <tr>
                  <th>수집 및 이용    목적</th>
                  <td>서비스 가입, 계약의 이행 및 서비스 제공, 예약·구매 내역, 고지·안내사항 전달, 상담·불만·민원처리, 상품·서비스 이용 실적 정보 통계·분석, 상품·서비스 개선 및 추천, 불법·부정 이용 방지</td>
              </tr>
              <tr>
                  <th>수집 항목</th>
                  <td>
                      서비스 이용시 <br/>
                      · 이메일(ID),비밀번호,설명,휴대폰 번호<br/>
                      예약·구매 시 <br/>
                      ·공통 , 예약 내역(예약일시, 결제금액, 업체명)<br/>
                      ·예약자 및 구매자 - 성명, 휴대폰 번호, 이메일 주소<br/>
                      상담 문의시<br/>
                      · 상담내용 및 상담에 필요한 개인 정보<br/>

                  </td>
              </tr>
              <tr>
                  <th>보유 및 이용 기간</th>
                  <td>
                  •이용 종료일로부터 상담 등 민원처리를 위해 30일 후 삭제<br/>

                  •회원 탈퇴 요청시 상담등 민원 처리를 위해 30일 후 삭제<br/>
                  (단, 법렵상 의무를 준수하기 위하여 필요한 경우 법령이 정한 기간까지)<br/>
                  </td>
                  </td>
              </tr>
          </tbody>
      </table>
      <strong>1. 예약 및 결제 서비스 제공 </strong>
      <span>
          본 예약 서비스는 주식회사 유비컴퍼니(이하 '회사'라 합니다)이 제공하는 UPLOADBOOKING 시스템에서 제공받고 있습니다. 회사가 제공하는 전자상거래 서비스 이용과 관련하여, 서비스 이용을 위한 본인 확인, 예약 및 결제, 예약 취소 및 노쇼 시 대금 지급 요청, 서비스 부정 이용 방지, 각종 불편 사항 접수 및 분쟁 조정을 위해 필요한 이용자의 최소한의 개인정보를 수집합니다. 서비스 이용과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수 있습니다. 추가로 개인정보를 수집할 경우에는 해당 개인정보 수집 시점에서 이용자에게 ‘수집하는 개인정보 항목, 개인정보의 수집 및 이용목적, 개인정보의 보관기간’에 대해 안내 드리고 동의를 받습니다.
      </span>
      <strong>2. 서비스 개선, 신규 서비스 개발 및 마케팅, 서비스 이용 통계 및 설문</strong>
      <span>
          서비스 개선, 신규 서비스 개발, 개인 맞춤서비스, 서비스 마케팅 활용을 위해 수집된 개인정보가 활용될 수 있습니다. 또한 통계적 정보를 바탕으로 서비스 유효성 확인, 광고 게재, 이벤트 정보 및 참여기회 제공, 회원의 서비스 이용에 대한 통계제작을 목적으로 개인정보가 활용될 수 있습니다.
      </span>
      <h3>[개인정보의수집항목]</h3>
      <strong>1. 예약, 결제 서비스 이용 시 </strong>
      <span>
          ㆍ(공통) 이용자 식별 정보 - 이름, 이메일주소, 연락처(휴대폰번호) (단, 예약자와 방문자가 다를 경우 방문자의 이름, 연락처, 이메일)<br/>
          ㆍ(예약 및 결제 보증 시) – 예약 비밀번호, 신용카드번호(유효기간 포함)
      </span>
      <strong>2. 자동수집 항목 및 수집방법</strong>
      <span>
          수집항목 : 서비스 이용 과정에서 IP 주소, 쿠키, 방문일시 등의 서비스 이용 기록, 기기정보(모델명, OS버전, 디바이스 ID, IMEI 등)<br/>
          수집방법 : 서비스 이용 과정에서 이용자에 관한 정보를 정보통신서비스 제공자가 자동화된 방법으로 생성하여 이를 저장(수집)
      </span>
      <h3>[개인정보의보유 및 이용기간]</h3>
      <span>
          회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고 있습니다.
          단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.
      </span>
      <table>
          <tbody>
              <tr>
                  <td>근거법률</td>
                    <td>목적</td>
                    <td>수집항목</td>
                    <td>보유기간</td>
              </tr>
              <tr>
                  <td>개인정보보호법</td>
                  <td>통계·학술·연구목적</td>
                  <td>비식별화,익명화작업을거친정보</td>
                  <td>준영구</td>
              </tr>
              <tr>
                  <td>통신비밀보호법</td>
                  <td>법원의영장을받아수사기관이요청시제공</td>
                  <td>IP등로그기록</td>
                  <td>3개월</td>
              </tr>
              <tr>
                  <td rowspan="3">전자상거래등에서의소비자보호에관한법률</td>
                  <td>소비자의불만또는분쟁처리에관한기록확인</td>
                  <td>이용자식별정보,분쟁처리기록등</td>
                  <td>3년</td>
              </tr>
              <tr>
                  <td >대금결제및재화등의공급에관한기록확인</td>
                  <td rowspan="2">이용자식별정보,계약/청약철회기록등</td>
                  <td rowspan="2">5년</td>
              </tr>
              <tr>
                  <td>계약또는청약철회등에관한기록확인</td>
              </tr>
          </tbody>
      </table>
      <span>회원의 경우 ‘개인정보 유효기간제’에 따라 1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.</span>
      <h3> [개인정보 수집 동의 거부의 권리] </h3>
      <span>
          1. 귀하는 위 개인정보의 수집이용에 대한 동의를 거부할 수 있으며, 동의 후에도 언제든지 철회할 수 있습니다.<br/>
          2. 위 개인정보의 수집이용에 동의하지 않을 경우, 본 서비스 결제 및 예약이 정상적으로 이루어질 수 없음을 알려드립니다.<br/>
      </span>
  </div>
</section>`;

const A01 = `
<section>
<h3>[제 1 장 총칙]</h3>
<div class="inner">
  <strong>제 1 조 (목적)</strong>
  <span>
    이 약관은 주식회사 유비컴퍼니(이하 '회사'라 합니다)가 제공하는 전자상거래 관련 서비스의 이용과 관련하여 회사와 이용자(개인 또는 법인) 간의 권리, 의무 및 책임사항, 이용조건 및 절차 기타 필요한 사항을 규정함을 목적으로 합니다. 이 약관은 회사가 제공하는 전자상거래 관련 서비스 일체에 적용됩니다.
  </span>
  <strong>제 2 조 (용어의 정의)</strong>
  <span>
    <p>이 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.</p>

    “서비스”라 함은 회사가 전자적 방법으로 제공하는 숙박중개 등 일체의 용역을 의미하며, 회사가 공개한 API(Application Programming Interface)를 이용하여 제 3자가 개발 또는 구축한 정보통신매체를 통하여 이용자에게 제공되는 용역을 포함합니다.
    “이용자”라 함은 회사가 제공하는 서비스를 이용하는 회원 또는 비회원을 말합니다.
    “회원”이라 함은 이 약관에 따라 회사와 이용 계약을 체결하고, 회사가 제공하는 서비스를 이용하는 사람을 말합니다.
    “비회원”이라 함은 이 약관에 따른 회원계약을 체결하지 않고 회사가 제공하는 서비스를 이용하는 사람을 말합니다. 
    “가입신청인”이라 함은 이 약관에 의하여 회원이 되고자 하는 사람을 말합니다.
    “회원계약”이라 함은 회사와 가입신청인 간에 체결되는 회원가입계약을 말하고, “구매계약”이라 함은 회사와 이용자 간에 체결되는 재화 또는 용역의 매매계약을 말합니다. 
    “비밀번호(Password)”라 함은 회원의 본인 신원 확인을 위하여 회원이 설정하여 회사에 등록한 고유의 문자와 숫자의 조합을 말합니다. 
    “할인쿠폰”이라 함은 "티켓" 또는 "상품 등"을 구매할 때 증권 상 표기된 액면가액만큼 할인 받을 수 있는 디지털 증권을 말합니다.
    “판매자”라 함은 회사가 제공하는 서비스를 이용하여 이용자에게 재화·용역 등에 관한 정보를 전달하고 그 재화·용역을 판매하는 사업자(개인 또는 법인)를 의미합니다.

  </span>

  <strong>제 3 조 (약관의 명시와 설명 및 개정) </strong>
  <span>
    회사는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소, 전화번호, 전자우편주소, 사업자등록번호 등과 함께 회사 홈페이지(서비스 초기화면)에 게시합니다. 다만, 이 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다. 
    회사는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자문서 및 전자거래 기본법, 전자금융거래법, 전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 소비자기본법 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. 
    회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 회사 홈페이지(서비스 초기화면)에 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 개정하는 경우, 회사는 개정약관 적용일자 30일 이전 서비스 초기화면 공지 외에 서면(전자문서 또는 전자우편을 포함), 서비스 접속 시의 팝업창 등의 전자적 수단을 통해 명확히 통지합니다.
    회사가 약관을 개정할 경우 그 개정약관은 관련 법령 또는 기타 부득이한 사유가 존재하지 아니하는 이상 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대하여서는 개정 전의 약관조항이 그대로 적용됩니다.
    회사가 개정약관을 공지 또는 통지하면서 회원에게 해당 기간 내에 의사표시를 하지 않으면 의사표시가 표명된다는 것으로 본다는 뜻을 명확하게 고지 또는 통지하였음에도 회원이 명시적으로 거부의사표시를 하지 아니한 경우에는 회원이 개정약관에 동의한 것으로 봅니다. 회원이 개정약관에 동의하지 아니하는 경우 언제든지 자유롭게 회원계약을 해지할 수 있습니다. 
    이 약관 또는 개별약관에서 정하지 않은 사항과 이 약관의 해석에 관하여서는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률 등 관계법령 또는 상관례에 따릅니다.  

  </span>
  <strong>제 4 조 (이용자에 대한 통지) </strong>
  <span>
    회사가 이용자에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서면(전자문서 또는 전자우편을 포함)에 의하여 이용자가 회사에 제공한 전자우편주소, 전화번호, 주소 등에 합니다.
    회사는 이용자 전체에 대한 통지의 경우 7일 이상 회사의 공지사항(고객센터)에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.  다만 이용자의 서비스 이용과 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.

  </span>
</div>
</section>
<section>
<h3>[제 2 장 회원계약의 성립과 소멸]</h3>
<div class="inner">
  <strong>제 5 조 (회원계약의 체결) </strong>
  <span>
    회원계약은 가입신청인이 이 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 회사가 이러한 가입신청에 대하여 승낙함으로써 체결됩니다. 다만, 가입신청인(이용자가 14세 미만의 아동인 경우 그 아동의 법정대리인)이 가입신청 당시 이 약관의 내용과 함께 제공한 동의 확인란에 동의를 표시한 경우, 회사는 이용자가 이 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 이를 적용하는 것에 동의한 것으로 간주합니다.
    회사는 제1항의 가입신청과 관련하여 가입신청인에게 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
    가입신청인은 회사의 요청에 따라 본인의 개인 정보 중 필수항목(성명, 생년월일, 성별, 전자우편주소, 비밀번호, 전화번호, 주소 등)을 제공하여야 합니다.
    회사는 가입신청자의 서비스 이용 신청에 대하여 승낙함을 원칙으로 합니다. 다만 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 회원계약을 해지할 수 있습니다. 
    실명이 아니거나 타인의 명의를 사용한 경우
    가입신청인이 회원가입 신청일 이전에 이 약관에 의하여 회원자격을 상실한 적이 있는 경우 단, 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함
    허위의 정보를 기재하거나 회사가 제시한 필수항목을 기재하지 않는 경우
    19세 미만 청소년이 법정대리인의 동의를 얻지 아니한 경우
    이용자가 회원탈퇴 후 30일 이내 재가입을 신청하거나 부정한 용도 또는 영리 추구를 목적으로 서비스를 이용하는 경우
    관계법령에 위배되거나 선량한 풍속 기타 사회질서를 저해할 수 있는 목적으로 신청한 경우
    기타 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 제반 사항을 위반하여 신청한 경우
    회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다. 
    제4항 또는 제5항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 가입신청인에게 그 사실을 알리도록 합니다.

    <p>
      회원계약의 체결시기는 회사가 가입신청인에게 회원계약 승낙사실(회원가입)을 표시한 시점으로 합니다.
    </p>
  </span>
  <strong>제 6 조 (회원계약의 해지) </strong>
  <span>
    회원은 언제든지 회사에게 회원계약 해지를 신청할 수 있고, 회사는 관련 법령 등이 정하는 바에 따라 3영업일 이내에 이를 처리합니다.
    회사는 회원에게 회원계약 위반 등 다음 각 호의 사유가 발생한 경우 2주 이상의 기간을 정하여 서면(전자문서 또는 전자우편을 포함)으로 회원계약의 위반의 시정을 최고할 수 있고, 회원이 그 기간 내에 회원계약 위반을 시정하지 아니할 경우, 회사는 회원에게 해지의 의사를 표시함으로써 회원계약을 해지할 수 있습니다. 다만, 회원에게 회원계약 상의 권리 행사 또는 의무의 이행을 계속할 수 없는 사유가 발생한 경우 회사는 최고 없이 회원계약을 해지할 수 있습니다.
    회원계약 체결을 전후로 회원에게 제5조 제4항의 사유가 발생한 경우
    회원이 서비스와 관련하여 회사 또는 제3자에게 직·간접적으로 재산적·비재산적 손해를 가한 경우
    회원이 회사 또는 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하여 회사의 영업을 침해한 경우
    회원이 이 약관에 위배되는 행위를 하거나 이 약관에서 정한 해지사유가 발생한 경우
    회사 또는 회원이 회원계약을 해지하는 경우, 회사는 해지의 의사를 통지하거나 통지받은 때로부터 3영업일 이내에 회원탈퇴에 관한 업무를 처리합니다.
    회사 또는 회원이 회원계약을 해지하는 경우, 관련법 및 개인정보 처리 방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지업무 처리 완료 즉시 회원의 데이터는 아래와 같은 절차와 방법에 따라 파기처리 됩니다.
    파기절차: 회사는 파기 사유가 발생한 개인정보를 선정하고, 개인정보 보호책임자의 승인 후 개인정보를 파기합니다.
    파기방법: 회사는 전자적 파일 형태로 처리된 정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 의하여 처리된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다
    회사 또는 회원이 회원계약을 해지하는 경우, 회원의 보유한 할인 쿠폰은 탈퇴와 즉시 소멸되며 회원은 쿠폰의 소멸로 인하여 발생하는 경제적 이익의 상실에 관하여 회사에게 손해배상 등 법적 청구를 할 수 없습니다.
    회원계약의 해지시기는 회사가 회원에게 회원계약 해지사실(회원탈퇴)을 표시한 때로 합니다.  

  </span>
</div>
</section>
<section>
<h3>[제 3 장 서비스의 이용]</h3>
<div class="inner">
  <strong>제 7 조 (서비스의 제공 및 변경) </strong>
  <span>
    회사는 회원에게 아래와 같은 서비스를 제공합니다. 
    숙박시설, 음식점, 기타 장소에 관한 정보 제공 서비스
    숙박시설 및 음식점 예약 서비스
    기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 제공하는 서비스
    회사는 이용 감소로 인한 원활한 서비스 제공의 곤란 및 수익성 악화, 서비스 제공과 관련한 회사 정책의 변경 등 기타 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경 또는 중단할 수 있습니다. 
    회사는 서비스의 변경 또는 서비스 중단이 예정된 경우 예정일로부터 30일 이상의 기간 전 WCRS홈페이지(서비스 초기화면)에 변경 또는 중단될 서비스의 종류, 변경 또는 중단 내용과 사유, 변경 또는 중단 일시 등을 공지합니다. 
    회사는 이용자에게 무료로 제공하는 서비스의 일부 또는 전부를 회사의 경영상 필요에 의하여 변경, 중단, 폐지할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 이용자에게 별도의 보상을 하지 않습니다. 

    않습니다.
  </span>
  <strong>제 8 조 (서비스 및 고객센터 이용시간) </strong>
  <span>
    서비스는 주중 (공휴일 제외) 9시~18시까지 제공함을 원칙으로 합니다
    회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 제4조(이용자에 대한 통지)에 정한 방법으로 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
    회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 회사 홈페이지(서비스 초기화면)에 공지한 바에 따릅니다.

  </span>
  <strong>제 9 조 (정보의 제공 및 광고의 게재)</strong>
  <span>
    회사는 회원의 서비스 이용에 필요하다고 인정되는 정보를 공지사항 또는 서면(전자문서 또는 전자우편을 포함), 문자메시지(SMS), 전화, 애플리케이션 푸쉬(Push) 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래관련정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 위 정보의 제공수신을 거절할 수 있습니다.
    회사는 서비스의 운영과 관련하여 회사 홈페이지(서비스 초기화면), 전자우편, SMS, 앱 푸쉬(App Push) 등에 광고를 게재할 수 있습니다. 다만, 전자우편, SMS, 앱 푸쉬 등 전자적 전송매체의 경우 정보통신망법에서 정한 바에 따라 원칙적으로 회사는 수신자의 명시적인 사전동의를 받아야 하고, 수신자가 수신거부의사를 표시하거나 사전 동의를 철회한 경우에는 영리목적의 광고성 정보를 전송하지 않습니다. 광고가 게재된 전자우편을 수신한 회원은 수신거절을 회사에게 할 수 있습니다.
    
  </span>
  <strong>제 10 조 (서비스 이용과 지식재산권) </strong>
  <span>
    회사가 서비스 제공과 관련하여 작성· 편집한 저작물(2차 저작물을 포함, 이하 동일)에 대한 지식재산권은 회사에 귀속되며, 이용자는 서비스 이용 과정에서 얻은 회사의 저작물을 회사의 명시적인 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 등 기타 방법에 의하여 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
    회원이 서비스를 이용하여 서비스 상에 부호, 문자, 음성, 음향, 화상, 동영상 등의 정보형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 게시한 “게시물＂의 저작권은 회원에게 귀속됩니다.
    회원 가입을 하여 윙스 부킹 서비스를 이용 하는 경우 회사는 회원의 게시물을 회원 서비스 화면에 표시할 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며, 회원은 언제든지 고객센터 또는 어플리케이션 내 관리 기능을 통해 해당 게시물에 대해 삭제, 비공개 등의 조치를 취할 수 있습니다.
    회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리 침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시 조치 등을 취할 수 있습니다.
    회사는 바람직한 게시판 문화를 활성화하기 위하여 동의 없는 타인의 신상 공개 시 특정부분을 삭제하거나 기호 등으로 수정하여 게시할 수 있습니다.
    게시물에 관련된 제반 권리와 책임은 작성자 개인에게 있습니다. 또 게시물을 통해 자발적으로 공개된 정보는 보호받기 어려우므로 정보 공개전에 심사숙고 하시기 바랍니다.
    가입 해지 시 고객께서 작성하신 게시물은 자동으로 파기됩니다.
    
  </span>
  <strong>제 11 조 (게시물의 관리)</strong>
  <span>
    회원의 게시물이 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 저작권법 등 관련법에 위반되는 내용을 포함함으로 인하여, 지식재산권 등의 권리를 보유한 자가 회사에게 관련법이 정한 절차에 따라 해당 게시물의 게시중단 또는 삭제 등을 요청하는 경우, 회사는 관련법에 따라 조치를 취합니다.
    회사는 전항에 따른 권리자의 요청이 없는 경우라도 회사 또는 제3자의 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.

  </span>
  <strong>제 12 조 (할인쿠폰) </strong>
  <span>
    회원의 게시물이 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 저작권법 등 관련법에 위반되는 내용을 포함함으로 인하여, 지식재산권 등의 권리를 보유한 자가 회사에게 관련법이 정한 절차에 따라 해당 게시물의 게시중단 또는 삭제 등을 요청하는 경우, 회사는 관련법에 따라 조치를 취합니다.
    회사는 전항에 따른 권리자의 요청이 없는 경우라도 회사 또는 제3자의 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.

  </span>
  <strong>제 13 조 (서비스 이용제한) </strong>
  <span>
    회사는 이용자가 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지, 회원계약 해지 등으로 서비스 이용을 단계적으로 제한할 수 있습니다. 
    회사는 전항에도 불구하고, 이용자가 주민등록법을 위반한 명의도용 및 결제도용, 전화번호 도용, 저작권법 및 컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및 서비스 운영방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다.
    제1항 또는 제2항에 따른 영구이용정지시 할인 쿠폰 등 서비스 이용과 관련한 이용자의 일체 혜택은 전부 소멸되고, 회사는 그 소멸된 혜택에 대하여 별도로 보상하지 않습니다. 
    회사가 이용자의 서비스 이용을 제한하는 경우에는 회사는 서비스 이용제한의 사유와 이용제한의 내용을 명시하여 이용제한 30일 전 제4조(이용자에 대한 통지)에 따라 통지합니다. 
    회원은 이용제한의 통지를 받은 때로부터 2주 이내에 제1항 또는 제2항에 따른 이용제한 등에 대하여 회사에 따라 이의신청을 할 수 있습니다. 회사는 이용자의 이의가 정당하다고 판단되는 경우 즉시 이용자에 대한 서비스 이용제한을 해제합니다.

  </span>
</div>
</section>
<section>
<h3>[제 4 장 구매계약]</h3>
<div class="inner">
  <strong>제 14 조 (구매신청) </strong>
  <span>
    이용자는 서비스 상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, 회사는 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
    재화 등의 검색 및 선택
    받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 전화번호) 등의 입력
    약관 내용, 계약취소· 변경· 환불의 제한, 미성년자의 투숙 조건, 추가 발생 비용 항목 등의 게시
    이 약관에 동의하고 위 3. 호의 사항을 확인하거나 거부하는 표시
    재화 등의 구매신청 및 이에 관한 확인 또는 회사의 확인에 대한 동의
    결제방법의 선택
  </span>
  <br />
  <strong> 제 15 조 (구매계약의 성립) </strong>
  <span>
    이용자는 이 약관 및 기타 규정에 따라 다음 각 호의 절차를 거쳐 구매계약을 체결할 수 있습니다. <br/>
    성명, 연락처의 입력<br/>
    재화 또는 용역 및 이용시간 선택<br/>
    아래 계약조건, 약관 내용, 서비스 내용(포함사항 및 불포함사항), 추가 비용 부담 등의 게시<br/>
      ① 계약취소, 변경 및 환불 불가 상품 고지<br/>
      ② 보호자 동반 시 미성년자 투숙 가능 내용 고지<br/>
      ③ 기타 서비스 관련 내용<br/>
    이용자의 동의(계약체결) 표시<br/>
    결제방법의 선택 및 결제<br/>
    이용자가 제1항 상의 절차에 따라 구매계약 체결 확인란에 동의(예약체결)를 표시하고 재화 또는 물품 구입대금을 결제한 경우, 회사는 이용자가 이 약관 및 서비스 내용을 모두 읽고 이를 충분히 이해하였으며, 이를 적용하는 것에 동의한 것으로 간주합니다.
    이용자의 숙박시설 및 음식점 이용과 관련한 일체의 권리 및 의무는 숙박시설 및 음식점에 귀속되므로, 회사는 이용자의 숙박시설 및 음식점 이용과 관련하여 이용자에게 발생하는 재산적·비재산적 손해 일체에 관하여 어떠한 책임도 부담하지 아니합니다.
    회사는 이용자의 구매계약에 있어 필요로 하는 각종 서비스를 제공하고 회사의 내부정책에 따라 그에 대한 이용료(서비스이용료)를 부과할 수 있습니다.

  </span>
  <strong>제 16 조 (구매계약 기록의 보관) </strong>
  <span>
    구매계약의 거래기록은 전자상거래 등에서의 소비자보호에 관한 법률에 의하여 일정기간 보존합니다.
  </span>
  <strong> 제 17 조 (구매계약의 결제 수단) </strong>
  <span>
    이용자는 회사에서 구매한 재화 또는 용역에 대한 대금지급을 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다. 단, 회사는 이용자의 지급방법 선택과 관련 없이 어떠한 명목의 수수료도 징수하지 아니합니다.<br/>

    폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체<br/>
    신용카드, 직불카드, 선불카드 등의 각종 카드<br/>
    회사와 계약을 맺었거나 회사가 인정한 상품권<br/>
    할인쿠폰<br/>
    기타 전자적 지급 방법

  </span>
  <strong>제 18 조 (구매계약의 취소와 환불) </strong>
  <span>
    회사는 구매계약의 취소 및 환불과 관련하여 전자상거래 등의 소비자보호에 관한 법률 등 관련법을 준수합니다. <br/>
    구매계약시 재화 또는 용역의 성격에 따라 회사는 별도의 취소 및 환불 관련 규정을 정할 수 있으며, 회사는 이러한 경우 개별 취소 및 환불 규정을 적용할 수 있습니다.<br/>
    회사는 사업자의 확인 후 이용자의 구매계약의 취소 및 환불 신청을 처리하며, 회사 및 판매자의 취소 및 환불 규정에 따라 취소수수료가 발생할 수 있습니다.<br/>
    구매 취소 시점과 결제수단별 환불 처리기준에 따라 취소금액의 환급 방법과 환급일은 다소 차이가 있을 수 있습니다.<br/>
    회사는 선량한 이용자의 보호를 위하여 다음에 해당하는 고객에 대하여 일정한 기간을 정하여 예약제한 또는 해당 예약 건에 대한 예약취소 조치를 취할 수 있습니다.<br/>
    부정한 방법을 통한 예약의 경우 (예: 매크로 등을 사용한 예약 시도)<br/>
    반복적으로 다량의 재화 또는 용역을 구매 후 취소하는 경우(예: 재판매를 목적으로 한 구매 후 취소)<br/>
    기타 이에 준하는 부정한 이용으로 판단되는 경우<br/>
    미성년자 또는 미성년자의 법정대리인은 계약에 동의하지 아니할 경우 미성년자의 구매를 취소할 수 있으나, 미성년자가 속임수로써 회사로 하여금 법정대리인의 동의가 있는 것으로 믿게 한 경우에는 그 취소가 제한됩니다.
    회원이 예약상품을 구매하는 경우 대금결제는 예약과 동시에 이루어집니다. 만일, 일반예약 상품의 잔여객실 또는 좌석이 없을 경우 결제가 취소되므로 회원에게 별도의 요금이 청구되지 않습니다. 다만, 회원의 사정으로 인하여 예약상품의 결제를 취소하는 경우에는 서비스에 명시된 별도의 특별약관에서 정한 바에 따릅니다.
    기타 이 약관 및 서비스의 이용안내에 규정되지 않은 취소 및 환불에 대한 사항에 대해서는 소비자기본법 등 관련법에서 정한 바에 따릅니다.

  </span>
</div>
</section>
<section>
<h3>[제 5 장 회사와 이용자의 의무]</h3>
<div class="inner">
  <strong>제 19 조(회사의 의무)</strong>
  <span>
    회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화 및 용역을 제공하는데 최선을 다하여야 합니다.
    회사는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
    회사가 상품이나 용역에 대하여 표시·광고의 공정화에 관한 법률 제3조 소정의 부당한 표시·광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
    회사는 이용자가 동의하지 않은 영리목적의 광고성 전자우편을 발송하지 않습니다.

  </span>
  <strong> 제 20 조 (이용자의 의무) </strong>
  <span>
    이용자는 서비스 이용에 필요한 전자우편주소, 비밀번호, 결제수단에 관한 정보 등의 관리책임을 부담하며, 서비스의 이용과 관련하여 위 정보를 제3자가 사용하도록 하여서는 아니됩니다.<br/>
    이용자는 관계법령, 이 약관의 규정, 서비스 이용안내 등 회사가 고지하는 사항을 준수하여야 하며, 기타 회사 업무를 방해하거나 저해하는 행위를 하여서는 안됩니다. <br/>
    이용자는 다음 각 호의 행위를 하여서는 안됩니다. <br/>
    서비스 신청 또는 변경 시 허위내용의 등록행위<br/>
    타인의 정보 도용 행위<br/>
    회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시행위<br/>
    회사 기타 제3자의 저작권 등 지식재산권 침해행위<br/>
    회사 기타 제3자의 명예 ·신용 훼손 또는 업무방해행위<br/>
    외설 또는 폭력적인 메시지, 화상, 음성 기타 공서양속에 반하는 정보를 공개하거나 게시하는 행위<br/>
    회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위<br/>
    가공행위를 통하여 서비스를 복제, 분해 또는 모방 기타 변형하는 행위<br/>
    자동 접속 프로그램(매크로) 등에 의하여 비정상적인 방법으로 서비스를 이용하여 회사의 업무 및 서비스제공을 방해하는 행위<br/>
    할인쿠폰을 제3자에게 처분하거나 제3자로부터 양수하는 행위.<br/>
    회사가 이용자를 위해 시행하는 각종의 이벤트 서비스를 회사 서비스정책에 맞지 않도록 사용하는 행위<br/>
    회사의 서비스 이용 과정(서비스에 대한 이의 제기 과정을 포함)에서 회사의 임· 직원(시간제근로자 및 파견근로자 포함)에 대하여 폭행, 협박, 위력 행사, 명예훼손, 모욕, 성추행(언어에 의한 것을 포함) 등 심히 부당한 대우를 하는 행위
    이용자는 회사에서 공식적으로 인정한 경우를 제외하고는 서비스 이용을 통하여 영리를 추구할 수 없으며, 회사의 정보통신설비에 대한 해킹, 광고· 음란정보(음란사이트의 링크를 포함) 등의 게시 행위, 음원· 동영상· 소프트웨어 무단 배포 등의 지식재산권침해 행위를 할 수 없습니다.
    기타 관계 법령이나 회사에서 정한 규정에 위배되는 경우<br/>

    이용자가 위 의무를 위반함으로 인하여 회사 또는 제3자에게 재산적· 비재산적 손해가 발생한 경우 회사는 그로 인하여 발생한 손해로부터 면책되고, 해당 이용자에 대하여 민사상 손해배상의 청구, 형사 고발, 행정기관에 대한 신고 등 일체의 법적 조치를 취할 수 있습니다. 

  </span>
</div>
</section>
<section>
<h3>[제 6 장 기타]</h3>
<div class="inner">
  <span>
    회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. <br/>
    회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다. <br/>
    회사는 이용자가 게재한 이용후기, 업소 평가 등 정보/자료/사실의 신뢰도, 정확성에 대해서는 책임을 지지 않습니다. <br/>
    회사는 회사의 서비스를 이용하지 않고 이용자와 제휴업주가 직접거래를 한 경우 발생하는 피해에 대하여 책임을 지지 않습니다. <br/>
    회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다. <br/>
    회사는 이용자가 자신의 개인정보를 타인에게 유출하거나 제공하여 발생하는 피해에 대하여 책임을 지지 않습니다.<br/>
    회사 및 회사의 임직원 그리고 대리인의 고의 또는 중대한 과실이 없는 한 다음과 같은 사항으로부터 발생하는 손해에 대해 책임을 지지 아니합니다. <br/>
    이용자가 회사에 제공한 정보의 허위 또는 부정확성에 기인하는 손해<br/>
    서비스에 대한 접속 및 서비스의 이용과정에서 발생하는 개인적인 손해<br/>
    서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인 이용으로부터 발생하는 손해<br/>
    서버에 대한 전송 또는 서버로부터의 전송에 대한 제3자의 모든 불법적인 방해 또는 중단행위로부터 발생하는 손해<br/>
    제3자가 서비스를 이용하여 불법적으로 전송, 유포하거나 또는 전송, 유포되도록 한 모든 바이러스, 스파이웨어 및 기타 악성 프로그램으로 인한 손해<br/>
    전송된 데이터의 오류 및 생략, 누락, 파괴 등으로 발생되는 손해<br/>
    회원 간의 이용자 정보 등록 및 서비스 이용 과정에서 발생하는 명예훼손 기타 불법행위로 인한 각종 민형사상 책임<br/>
    회사는 이용자가 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖의 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다. <br/>
    회사는 보호자가 동반하지 않은 미성년자의 숙박시설 이용으로 발생하는 숙박시설로부터의 입실거부, 예약취소, 환불불가 등의 불이익에 관하여 책임지지 않습니다.

  </span>
  <strong> 제 21 조 (연결서비스와 피연결서비스 간의 관계) </strong>
  <span>
    상위 서비스와 하위 서비스가 하이퍼링크 (예: 하이퍼 링크의 대상에는 문자, 그림 및 동영상 등이 포함됨) 방식 등으로 연결된 경우, 전자를 연결서비스라고 하고 후자를 피연결서비스라고 합니다. <br/>
    .연결서비스는 피연결서비스가 독자적으로 제공하는 재화 등에 의하여 회원과 행하는 거래에 대하여 책임을 지지 않습니다. 
    
  </span>
  <strong> 제 22 조 (준거법 및 중재조항) </strong>
  <span>
    회사와 이용자 간에 제기된 전자상거래 분쟁에는 대한민국 법을 적용합니다.
    회사와 이용자 간에 서비스 이용과 직·간접적으로 관련한 이 계약과 관련하여 당사자간에 발생하는 모든 분쟁은 회사의 관할지방법원에서 최종적으로 해결합니다.

  </span>
</div>
</section>
<strong> [부칙] </strong>
<strong>제 1 조 (시행일)</strong>
<strong
>이 약관은 <span class="font-red">2023.12.01</span> 부터
시행됩니다.</strong
>

`;
