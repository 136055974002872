import RoomBookCancelListView from "components/view/room/book/cancel";
import { rmResvCnlSearchProps } from "interface/room";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch, setMessage } from "store/commonSlice";
import { afterDay } from "util/common";
import { dateTypOptions } from "util/option";

const RoomBookCancelListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  let lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
      : "";
  const { sDt, eDt } = afterDay(14);
  const [searchInfo, setSearchInfo] = useState<rmResvCnlSearchProps>({
    lodGrpNo: lodGrpInfo?.lodGrpNo,
    lodGrpNm: lodGrpInfo?.lodGrpNm,
    lodNm: lodGrpInfo?.lodNm,
    lodNo: lodGrpInfo?.lodNo,
    lodGrpCd: state?.loGrpCd ?? "",
    rmNo: state?.rmNo ?? "",
    rmNm: state?.rmNm ?? "",
    rmTypCd: state?.rmTypCd ?? "",
    rmOlkCd: state?.rmOlkCd ?? "",
    minPerCt: state?.minPerCt ?? "",
    maxPerCt: state?.maxPerCt ?? "",
    rmPdNo: state?.rmPdNo ?? "",
    lodChlSeq: state?.locChlSeq ?? "",
    rmResvNo: state?.rmResvNo ?? "",
    resvCstMblCntyCd: state?.resvCstMblCntCd ?? "",
    resvCstMblNo: state?.resvCstMblNo ?? "",
    resvCstnm: state?.resvCstnm ?? "",
    mobCntyCd: state?.mobCntCd ?? "",
    memMobNo: state?.memMobNo ?? "",
    memFstnm: state?.memFstnm ?? "",
    resvCstFstnm: state?.resvCstFstnm ?? "",
    promCd: state?.memSurnm ?? "",
    promNm: state?.promNm ?? "",
    sellSDt: state?.sellSDt ?? "",
    sellEDt: state?.sellEDt ?? "",
    cnlSDt: state?.cnlSDt ?? "",
    cnlEDt: state?.cnlEDt ?? "",
    resvSDt: state?.resvSDt ?? "",
    resvEDt: state?.resvEDt ?? "",
    dateTyp: state?.dateTyp || dateTypOptions[0]?.value,
  });

  const handleChange = (name: string, value: string) => {
    if (name === "SDt" || name === "EDt") {
      if (searchInfo?.dateTyp === "rmPdDt") {
        setSearchInfo({
          ...searchInfo,
          [`sell${name}`]: value,
        });

        return;
      }
      if (searchInfo?.dateTyp === "rmResvDt") {
        setSearchInfo({
          ...searchInfo,
          [`resv${name}`]: value,
        });
        return;
      }
      if (searchInfo?.dateTyp === "rmCnlResvDt") {
        setSearchInfo({
          ...searchInfo,
          [`cnl${name}`]: value,
        });
        return;
      }
    }

    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;
    if (name && value) {
      if (name === "dateTyp") {
        setSearchInfo({
          ...searchInfo,
          [name]: value,
          sellEDt: "",
          sellSDt: "",
          resvEDt: "",
          resvSDt: "",
          cnlEDt: "",
          cnlSDt: "",
        });
        return;
      }
      if (name === "lodGrpNo") {
        setSearchInfo({
          ...searchInfo,
          lodNo: "",
          rmNo: "",
          rmOlkCd: "",
          rmTypCd: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }

      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: lodGrpInfo?.lodGrpNo,
      lodGrpNm: lodGrpInfo?.lodGrpNm,
      lodNm: lodGrpInfo?.lodNm,
      lodNo: lodGrpInfo?.lodNo,
      lodGrpCd: "",
      rmNo: "",
      rmNm: "",
      rmTypCd: "",
      rmOlkCd: "",
      minPerCt: "",
      maxPerCt: "",
      rmPdNo: "",
      lodChlSeq: "",
      rmResvNo: "",
      resvCstMblCntyCd: "",
      resvCstMblNo: "",
      resvCstnm: "",
      mobCntyCd: "",
      memMobNo: "",
      memFstnm: "",
      resvCstFstnm: "",
      promCd: "",
      promNm: "",
      sellSDt: "",
      sellEDt: "",
      cnlSDt: "",
      cnlEDt: "",
      resvSDt: "",
      resvEDt: "",
      dateTyp: dateTypOptions[0]?.value,
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };

  const handleSearch = () => {
    if (
      !(
        (searchInfo.sellEDt && searchInfo.sellSDt) ||
        (searchInfo.cnlSDt && searchInfo.cnlEDt) ||
        (searchInfo.resvSDt && searchInfo.resvEDt)
      )
    ) {
      dispatch(
        setMessage({
          message: "조회시작일자와 조회종료일자를 선택하여주세요!",
          isConfirm: true,
          handleClick() {
            dispatch(setMessage({ message: null }));
          },
        })
      );
      return;
    }

    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));
    navigate(`?page=1`);
  };

  return (
    <>
      <RoomBookCancelListView
        memType={memType}
        searchInfo={searchInfo}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleReset={handleReset}
        handleSearch={handleSearch}
      />
    </>
  );
};

export default RoomBookCancelListPage;
