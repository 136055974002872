import { useMutation, useQuery } from "@tanstack/react-query";
import { bedtypListApi } from "components/api/comn";
import Select from "components/select";
import { optionProps } from "interface";
import { cdList } from "interface/lod";
import { useEffect, useState } from "react";

type IProps = {
  option: string;
  onSelect(target: EventTarget & HTMLLIElement): void;
};

const BedTypeSelect = ({ option, onSelect }: IProps) => {
  const [options, setOptions] = useState<optionProps[]>([
    { value: "A", kr: "선택하세요", en: "select" },
  ]);

  const listMutation = useMutation({
    mutationKey: ["bedtypList"],
    mutationFn: bedtypListApi,
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          const options = body.cdList.map((li: cdList) => {
            const option = {
              value: li.lodCd,
              kr: li.codNm,
              en: li.lodCd,
            };
            return option;
          });

          setOptions([...options]);
        }
        if (code === -1) {
          setOptions([{ value: "A", kr: "선택하세요", en: "select" }]);
        }
      }
    },
  });

  useEffect(() => {
    listMutation.mutate();

    return () => {
      listMutation.mutate();
    };
  }, []);

  return (
    <Select
      // wrapStyle="flex-1 w-full h-[2.75rem]"
      id="bedTypCd"
      name="bedTypCd"
      option={option}
      options={[{ value: "A", kr: "선택하세요", en: "select" }, ...options]}
      onSelect={onSelect}
    />
  );
};

export default BedTypeSelect;
