import { Link } from "react-router-dom";
import "./signup.scss";

const SingupEntryView = () => {
  return (
    <div className="card-form ">
      <h2 className="text-center">회원 등록 방법을 선택해 주세요</h2>

      <div className="flex justify-center gap-10 py-20">
        <Link to="/signup/admin" className="entry-card">
          <span>관리자 등록</span>
        </Link>
        <Link to="/signup/manager" className="entry-card">
          <span>매니저 등록</span>
        </Link>
      </div>
    </div>
  );
};

export default SingupEntryView;
