import { lodChlHead } from "util/option";
import { IlodGrpList, lodChlSearchProps, lodSearchProps } from "interface/lod";
import { useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import Modal from "components/modal/modal";
import { useState } from "react";
import { lodStatusApi } from "components/api/lod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import NotData from "../notData";
import { useSelector } from "react-redux";
import { isSearchSelector } from "store/memberSlice";

type IProps = {
  isLoading: boolean;
  memType: "admin" | "manager";
  searchInfo: lodChlSearchProps;
  list?: IlodGrpList[] | null;
};

const LodChlList = ({ list = null, memType, isLoading }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSearch = useSelector(isSearchSelector);

  const [isOpen, setIsOpen] = useState(false);

  const statusMutation = useMutation(lodStatusApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          setIsOpen(false);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleStatus = () => {
    const params = {
      uYn: "",
      lodNo: "",
    };
    statusMutation.mutate(params);
  };

  const headList = lodChlHead.map((head) => {
    return head;
  });

  const [No, lodGrpNm, lodGrpCd, lodNm, ...res] = headList;

  // const { data } = useQuery({
  //   queryKey: ['rmList', isSearch],
  //   queryFn: () => {
  //     return rmListApi('')
  //   },
  //   initialData: null
  // })

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          {list && (
            <h3>
              검색결과
              <span>
                총<b>{list && list.length}</b>개
              </span>
            </h3>
          )}
        </div>
        <div>
          <table>
            <thead>
              {/* {memType === "admin" && ( */}
              <tr>
                {headList.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
              {/* )} */}

              {/* {memType === "manager" && (
                <tr>
                  {[No, ...res].map((li) => (
                    <th key={li}>{li}</th>
                  ))}
                </tr>
              )} */}
            </thead>

            <tbody>
              {list &&
                list?.map((li: IlodGrpList, index: number) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <button
                          onClick={() => {
                            navigate(`?lodGrpNo=${li.lodGrpNo}`);
                          }}
                        >
                          {li.lodGrpNo}
                        </button>
                      </td>
                      <td>{li.lodGrpNm}</td>
                      <td>{li.lodGrpCntyCd}</td>
                      <td>{li.lodGrpAddr1}</td>
                      <td>{li.lodGrpAddr2}</td>
                      <td>
                        <span
                          className={li.uYn === "Y" ? "uyn" : ""}
                          onClick={() => setIsOpen(true)}
                        >
                          {li.uYn === "Y" ? "정상" : "미 정상"}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              <tr></tr>
            </tbody>
          </table>
        </div>
        <div className={list ? "" : "hidden"}>
          <NotData
            isLoading={isLoading}
            isNotData={Boolean(list)}
            styles=""
            txt="Loading ..."
          />
        </div>
      </div>
      {/* {rmList && !isLoading && <Pagenation total={1} />} */}
      {/* <AgGrid rowData={rowData} columnDefs={columnDefs} pagination={true} /> */}
      {list && list.length > 0 && !isLoading && <Pagenation total={1} />}
      <Modal
        title="상태 변경"
        isOpen={isOpen}
        isLoading={statusMutation.isLoading}
        disabled={statusMutation.isLoading}
        handleCloseModal={() => setIsOpen(!isOpen)}
        onClick={handleStatus}
      >
        <p>
          해당 숙소 그룹을 <b>사용</b> 하시겠습니까?
        </p>
      </Modal>
    </div>
  );
};

export default LodChlList;
