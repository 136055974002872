import "../signup.scss";
import { signupInfoProps } from "interface/member";
import { setOpenPhoneSelect } from "store/commonSlice";
import { useDispatch } from "react-redux";
import { regEN, regEmail, regNum, regPhone } from "util/common";

import PhoneSelect from "components/select/phone";
import Select from "components/select";
import { authOptions } from "util/option";
import Button from "components/button/createButtonWrap";
import i18n from "locales/i18n";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";

type IProps = {
  info: signupInfoProps;
  type: "admin" | "manager";
  isLoading: boolean;
  handleChange(target: { name: string; value: string }): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleSignup(): void;
};

const Step1View = ({
  info,
  type,
  isLoading,
  handleChange,
  handleSelect,
  handleSignup,
}: IProps) => {
  const dispath = useDispatch();

  const lng = i18n.language;

  const baseAble = !!(
    info.emlId &&
    info.fstnm &&
    info.surnm &&
    info.mobCntyCd &&
    info.telNo &&
    regEmail.test(info.emlId) &&
    regPhone.test(info.telNo)
  );

  const admAble =
    baseAble && info.authGrpCod !== "all" && info.authGrpCod !== "";
  const manIsAble =
    type === "admin"
      ? admAble
      : baseAble && info.lodGrpNo !== "all" && info.lodGrpNo !== "";

  const typeName = type === "admin" ? " 관리자" : "매니저";

  return (
    <>
      <div
        className="no-logged-wrap"
        onClick={() => dispath(setOpenPhoneSelect(false))}
      >
        <h2>{typeName} 회원가입</h2>

        <div className={type === "manager" ? "lod-slt" : "hidden"}>
          <label role="form" htmlFor="">
            숙박 그룹
          </label>
          {type === "manager" && (
            <LodGrpNmSelect
              option={info.lodGrpNo ?? ""}
              onSelect={handleSelect}
            />
          )}
        </div>

        <div className="signup-form">
          <div>
            <label className={info.fstnm ? "on" : ""}>
              {typeName} 영문이름
            </label>
            <input
              type="text"
              name="fstnm"
              value={info.fstnm}
              onChange={(e) => {
                const value = regEN.test(e.currentTarget.value)
                  ? e.currentTarget.value
                  : "";
                handleChange({ name: "fstnm", value });
              }}
            />
          </div>
          <div>
            <label className={info.surnm ? "on" : ""}>{typeName} 영문성</label>
            <input
              type="text"
              name="surnm"
              value={info.surnm}
              onChange={(e) => {
                const value = regEN.test(e.currentTarget.value)
                  ? e.currentTarget.value
                  : "";
                handleChange({ name: "surnm", value });
              }}
            />
          </div>
          <div className="email">
            <label className={info.emlId ? "on" : ""}>{typeName} 이메일</label>
            <input
              type="text"
              name="emlId"
              value={info?.emlId}
              onChange={(e) => {
                const value = e.currentTarget.value;
                handleChange({ name: "emlId", value });
              }}
            />
          </div>
          {!regEmail.test(info.emlId) && !!info.emlId && (
            <span
              className={"err"} //regEmail.test(info.emlId) || !info.emlId ? "opacity-0" :
            >
              이메일 정규식을 확인하세요.
            </span>
          )}

          <div className="relative">
            <label className={info.telNo ? "on" : ""}>
              {typeName} 전화번호
            </label>
            <PhoneSelect
              wrapStyle="me-[0.25rem]"
              optionStyle="ms-[12.5rem] "
              mobCd={info.mobCntyCd}
              handleSelect={handleSelect}
            />

            <input
              type="text"
              name="telNo"
              value={info.telNo}
              maxLength={11}
              onChange={(e) => {
                const value = regNum.test(e.currentTarget.value)
                  ? e.currentTarget.value
                  : "";

                handleChange({ name: "telNo", value });
              }}
            />
          </div>
          {!regPhone.test(info.telNo) && !!info.telNo && (
            <span
              className={
                regPhone.test(info.telNo) || !info.telNo ? "opacity-0" : "err"
              }
            >
              전화번호를 확인하세요.
            </span>
          )}

          {type === "admin" && (
            <div>
              <label className={!info.authGrpCod ? "on" : ""}>
                관리자 권한 선택
              </label>
              <Select
                id="authGrpCod"
                name="authGrpCod"
                wrapStyle="w-full h-[2.75rem] max-w-[26.25rem]"
                option={info.authGrpCod}
                options={authOptions}
                onSelect={handleSelect}
              />
            </div>
          )}
        </div>

        <Button
          txt="회원가입"
          isLoading={isLoading}
          disabled={!manIsAble || isLoading}
          onClick={handleSignup}
        />
      </div>
      {/* <LoadingPage isLoading={isLoading} /> */}
    </>
  );
};

export default Step1View;
