import CalculatePromView from "components/view/calculate/promotion";
import { clcPromProps } from "interface/calcutate";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";

const CalculatePromPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchInfo, setSearchInfo] = useState<clcPromProps>({
    promCd: "",
    rmResvNo: "",
    resvCnlYn: false,
    sDt: "",
    eDt: "",
  });

  const handleChange = (name: string, value: string) => {
    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {};

  const handleReset = () => {
    setSearchInfo({
      promCd: "",
      rmResvNo: "",
      resvCnlYn: false,
      sDt: "",
      eDt: "",
    });
  };
  const handleSearch = () => {
    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));
    navigate(`?page=1`);
  };
  return (
    <>
      <CalculatePromView
        searchInfo={searchInfo}
        handleChange={handleChange}
        handleReset={handleReset}
        handleSearch={handleSearch}
        handleSelect={handleSelect}
      />
    </>
  );
};

export default CalculatePromPage;
