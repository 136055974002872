import RmHstView from "components/view/room/hst/list";
import { rmHstSearhInfo } from "interface/room";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch, setMessage } from "store/commonSlice";
import { afterDay } from "util/common";

const RmHstListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  let lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
      : "";

  const { sDt, eDt } = afterDay(14);
  const [searchInfo, setSearchInfo] = useState<Omit<rmHstSearhInfo, "pageNo">>({
    lodGrpNo: state?.lodGrpNo || lodGrpInfo?.lodGrpNo,
    lodGrpNm: state?.lodGrpNm || lodGrpInfo?.lodGrpNm,
    lodNo: state?.lodNo || lodGrpInfo?.lodNo,
    lodNm: state?.lodNm || lodGrpInfo?.lodNm,
    lodGrdCd: state?.lodGrdCd ?? "",
    lodChlCd: state?.lodChlCd ?? "",
    opRgSeq: state?.opRgSeq ?? 0,
    rmNo: state?.rmNo ?? "",
    rmOpNm: state?.rmOpNm ?? "",
    rmOlkCd: state?.rmOlkcd ?? "",
    rmPdNo: state?.rmPdNo ?? "",
    rmTypCd: state?.rmTypCd ?? "",
    sellEDt: state?.sellEDt ?? "",
    sellSDt: state?.sellSDt ?? "",
    uYn: state?.uYn ?? "",
  });

  const handleChange = (name: string, value: string) => {
    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      if (name === "lodGrpNo") {
        setSearchInfo({
          ...searchInfo,
          lodNo: "",
          rmNo: "",

          [name]: value === "A" ? "" : value,
        });
        return;
      }
      if (name === "lodNo") {
        setSearchInfo({
          ...searchInfo,
          rmNo: "",
          [name]: value === "A" ? "" : value,
        });
        return;
      }
      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleSearch = () => {
    if (!(searchInfo.sellSDt && searchInfo.sellEDt)) {
      dispatch(
        setMessage({
          message: "조회시작일자와 조회종료일자를 선택하여주세요!",
          isConfirm: true,
          handleClick() {
            dispatch(setMessage({ message: null }));
          },
        })
      );
      return;
    }
    // dispatch(
    //   setMessage({
    //     message: `기본 조회기간은 14일입니다. / 다른 일자로 조회하시려면 조회일자를 선택하여 주세요. / 조회하시겠습니까?`,
    //     isCancel: true,
    //     isConfirm: true,
    //     handleClick() {
    //       dispatch(setIsLoading(true));
    //       dispatch(setIsSearch(true));
    //       navigate(`?page=1`);
    //       dispatch(setMessage({ message: null }));
    //     },
    //   })
    // );
    dispatch(setIsSearch(true));
    dispatch(setIsLoading(true));
    navigate("?page=1");
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: lodGrpInfo?.lodGrpNo ?? "",
      lodGrpNm: lodGrpInfo?.lodGrpNm ?? "",
      lodNo: lodGrpInfo?.lodNo ?? "",
      lodNm: lodGrpInfo?.lodNm ?? "",
      lodGrdCd: "",
      lodChlCd: "",
      rmNo: "",
      rmOpNm: "",
      opRgSeq: 0,
      rmOlkCd: "",
      rmPdNo: "",
      rmTypCd: "",
      sellEDt: "",
      sellSDt: "",
      uYn: "A",
    });
    dispatch(setIsSearch(false));
    dispatch(setIsLoading(false));
  };

  return (
    <RmHstView
      memType={memType}
      searchInfo={searchInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleSearch={handleSearch}
      handleReset={handleReset}
    />
  );
};

export default RmHstListPage;
