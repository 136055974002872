import SubHeader from "components/layout/header/subHeader";
import { useLocation, useNavigate } from "react-router-dom";

type IProps = {
  promInfo: any;
};

const PromInfoView = ({ promInfo }: IProps) => {
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();
  return (
    <div className="main-cnt">
      <SubHeader title="프로모션 상세" close />
      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>프로모션 코드</th>
              <td>
                <input
                  type="text"
                  name="promCd"
                  value={promInfo?.promCd}
                  readOnly
                />
              </td>
              <th>인풀루언서명</th>
              <td>
                <input
                  type="text"
                  name="influNm"
                  value={promInfo?.influNm}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>인플루언서 휴대폰국가번호</th>
              <td>
                <input type="text" value={promInfo?.mobCd} readOnly />
              </td>
              <th>인플루언서 휴대폰번호</th>
              <td>
                <input
                  type="text"
                  name="influMobNo"
                  value={promInfo?.influMobNo}
                  maxLength={11}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>인플루언서 이메일</th>
              <td>
                <input
                  type="text"
                  name-="influEml"
                  value={promInfo?.influEml}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>인풀루언서 국가</th>
              <td>
                <input type="text" value={promInfo?.influCnty} readOnly />
              </td>
              <th>인풀루언서 언어코드</th>
              <td>
                <input type="text" value={promInfo?.langCd} readOnly />
              </td>
            </tr>
            <tr>
              <th>최초생성사용자</th>
              <td>
                <input type="text" value={``} readOnly />
              </td>
              <th>최초수정사용자</th>
              <td>
                <input type="text" value={``} readOnly />
              </td>
            </tr>
            <tr>
              <th>최초생성일</th>
              <td>
                <input type="text" value={``} readOnly />
              </td>
              <th>최초수정일</th>
              <td>
                <input type="text" value={``} readOnly />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-form-wrap">
        <button
          className="btn-search"
          onClick={() => navigate(pathname.replace("info", "modify"))}
        >
          수정
        </button>
      </div>
    </div>
  );
};

export default PromInfoView;
