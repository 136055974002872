import FormSearchBtn from "components/button/formSearch";
import SubHeader from "components/layout/header/subHeader";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import CurrentList from "components/tableList/room/crr";
import Loading from "components/view/loading";
import { rmCrrProps } from "interface/room";
import { Suspense } from "react";

type Iprops = {
  searchInfo: Omit<rmCrrProps, "sellSDt" | "sellEDt">;
  memType: "admin" | "manager";
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleSearch(): void;
  handleReset(): void;
};

const RoomCrrView = ({
  searchInfo,
  memType,
  handleSelect,
  handleSearch,
  handleReset,
}: Iprops) => {
  return (
    <>
      <div className="search-form">
        <SubHeader title="객실상품현황" />
        <div className="table-form">
          <table>
            <tbody>
              {memType === "admin" && (
                <tr>
                  <th>숙소그룹번호</th>
                  <td>
                    <LodGrpSelect
                      option={searchInfo.lodGrpNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>

                  <th>숙소그룹명</th>
                  <td>
                    <input type="text" value={searchInfo.lodGrpNm} />
                  </td>
                </tr>
              )}
              {memType === "admin" && (
                <tr>
                  <th>숙소번호</th>
                  <td>
                    <LodNoSelect
                      params={searchInfo.lodGrpNo}
                      disabled={!searchInfo.lodGrpNo}
                      option={searchInfo.lodNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소명</th>
                  <td>
                    <input type="text" value={searchInfo.lodNm} />
                  </td>
                </tr>
              )}
              {memType === "manager" && (
                <tr>
                  <th>숙소그룹명</th>
                  <td>
                    <input type="text" value={searchInfo?.lodGrpNm} readOnly />
                  </td>
                  <th>숙소명</th>
                  <td>
                    <input type="text" value={searchInfo?.lodNm} readOnly />
                  </td>
                </tr>
              )}
              {/* {memType === "admin" && (
                <tr>
                  <th>숙소등급</th>
                  <td>
                    <LodGrdSelect
                      option={searchInfo.lodGrpCd}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                </tr>
              )} */}
              {/* <tr>
                <th>상태</th>
                <td>
                  <Select
                    id="sellYn"
                    name="sellYn"
                    option={searchInfo.sellYn}
                    options={rmPrdStatus}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr> */}
            </tbody>
          </table>
          <FormSearchBtn
            disabled={!searchInfo.lodNo && !searchInfo.lodGrpNo}
            handleReset={handleReset}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <Suspense fallback={<Loading />}>
        <CurrentList searchInfo={searchInfo} />
      </Suspense>
    </>
  );
};

export default RoomCrrView;
