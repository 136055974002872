import { rmBkCnlListHead } from "util/option";
import { IlodGrpList } from "interface/lod";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import Modal from "components/modal/modal";
import {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { lodStatusApi } from "components/api/lod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoadingSelector,
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import NotData from "../notData";
import { rmResvCnlSearchProps } from "interface/room";
import { pageNavProps } from "interface";
import { rmResvCnlListApi } from "components/api/room";
import { dateFormat, inCom } from "util/common";

type IListProps = {
  searchInfo: rmResvCnlSearchProps;
  memType: "admin" | "manager";
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const rmResvMutation = useMutation(rmResvCnlListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setPageNav(body?.pageNav);
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          setList(body?.rmResvCnlList);
        }
        if (code === -1) {
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          dispatch(
            setMessage({
              message,
            })
          );
        }
      }
    },
  });

  let page = search?.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        sellSDt: searchInfo?.sellSDt?.replaceAll("-", "") ?? "",
        sellEDt: searchInfo?.sellEDt?.replaceAll("-", "") ?? "",
        cnlSDt: searchInfo?.cnlSDt?.replaceAll("-", "") ?? "",
        cnlEDt: searchInfo?.cnlEDt?.replaceAll("-", "") ?? "",
        resvSDt: searchInfo?.resvSDt?.replaceAll("-", "") ?? "",
        resvEDt: searchInfo?.resvEDt?.replaceAll("-", "") ?? "",
        resvCstFstnm: searchInfo?.resvCstFstnm,
        resvCstMblNo: searchInfo?.resvCstMblNo,
        memMobNo: searchInfo?.memMobNo,
        memFstnm: searchInfo?.memFstnm,
        pageNo: search?.replace("?page=", "") || state.pageNo,
      };
      rmResvMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = rmResvMutation.data?.body?.pageNav?.pageRows;

  return (
    <>
      {list &&
        list?.map((li: any, index: number) => {
          return (
            <tr
              key={index}
              onClick={() => {
                navigate(`info?rmResvNo=${li.rmResvNo}`, {
                  state: {
                    rmNo: li.rmNo,
                    rmPdNo: li.rmPdNo,
                    rmResvNo: li.rmResvNo,
                    searchInfo: searchInfo,
                    url: pathname + search,
                  },
                });
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              {memType === "admin" && <td>{li.lodGrpNo}</td>}
              {memType === "admin" && <td>{li.lodGrpNm}</td>}
              {memType === "admin" && <td>{li.lodNo}</td>}
              {memType === "admin" && (
                <td>
                  <span className="w-[12.5rem]">{li.lodNm}</span>
                </td>
              )}
              {/* {memType === "admin" && <td>{li.lodGrdNm}</td>} */}
              <td>{li.rmNo}</td>
              <td>
                <span className="w-[12.5rem]">{li.rmNm}</span>
              </td>
              <td>
                <span className="w-[7.5rem]">{li.rmTypNm}</span>
              </td>
              <td>{li.rmOlkNm}</td>
              {/* <td>{li.maxPerCt}</td> */}
              <td>{li.rmPdNo}</td>
              <td>{li.lodChlNm}</td>
              <td>{li.rmOpNm}</td>
              <td>{dateFormat(li.sellDt)}</td>
              <td>
                <span>{dateFormat(li?.resvDtti?.slice(0, 8))}</span>
                <span>{dateFormat(li?.cnlDtti?.slice(0, 8))}</span>
              </td>
              <td>{li.rmResvNo}</td>
              <td>{li.resvCstSurnm + li.resvCstFstnm}</td>
              <td>{li.resvCstMblNo}</td>
              <td>{li.resvCstSurnm + li.resvCstFstnm}</td>
              <td>{li.resvCstMblNo}</td>
              <td>
                <span>{inCom(li.payAmt)}</span>
                <span>{inCom(li.refAmt)}</span>
              </td>
            </tr>
          );
        })}
    </>
  );
};

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmResvCnlSearchProps;
};

const RoomBookCancelList = ({ memType, searchInfo }: IProps) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const statusMutation = useMutation(lodStatusApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          setIsOpen(false);
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
        }
      }
    },
  });

  const handleStatus = () => {
    const params = {
      lodNo: "",
      uYn: "",
    };
    statusMutation.mutate(params);
  };

  const headList = rmBkCnlListHead.map((head) => {
    return head;
  });

  const [No, lodGrpNo, lodGrpNm, lodNo, lodNm, ...res] = headList;

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>
        <div>
          <table>
            <thead>
              {memType === "admin" && (
                <tr>
                  {headList.map((li) => (
                    <th key={li}>{li}</th>
                  ))}
                </tr>
              )}
              {memType === "manager" && (
                <tr>
                  {[No, ...res].map((li) => (
                    <th key={li}>{li}</th>
                  ))}
                </tr>
              )}
            </thead>

            <tbody>
              <Suspense>
                <List
                  searchInfo={searchInfo}
                  memType={memType}
                  setPageNav={setPageNav}
                />
              </Suspense>
            </tbody>
          </table>
        </div>
        <NotData
          isLoading={isLoading}
          isNotData={!pageNav?.totalRows}
          isSuccess={!!pageNav}
          styles=""
          txt="Loading ..."
        />
      </div>
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav?.totalPage} />
      )}
      <Modal
        title="상태 변경"
        isOpen={isOpen}
        isLoading={statusMutation.isLoading}
        disabled={statusMutation.isLoading}
        handleCloseModal={() => setIsOpen(!isOpen)}
        onClick={handleStatus}
      >
        <p>
          해당 숙소 그룹을 <b>사용</b> 하시겠습니까?
        </p>
      </Modal>
    </div>
  );
};

export default RoomBookCancelList;
