import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type IProps = {
  id: string;
  content: any;
  handleChange(html: string, value: string): void;
};

const Editor = ({ id, content, handleChange }: IProps) => {
  const modules = {
    toolbar: [
      // [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],

      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [{ color: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"], //"image", "video"
      ["clean"],
    ],

    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
    // "image",
    // "video",
  ];

  return (
    <>
      <ReactQuill
        id={id}
        className=""
        theme="snow"
        // value={content[id as keyof string]}
        placeholder="내용을 작성하여 주세요."
        modules={modules}
        formats={formats}
        onChange={(content, delta, source, editor) => {
          handleChange(id, editor.getText());
        }}
        // readOnly
      />
    </>
  );
};

export default Editor;
