import { prvProps } from "interface/term";
import api from "./api";

export const termListAPi = async (params: {
  langCd: string;
  userType: string;
}) => {
  const { langCd, userType } = params;
  const res = await api({
    method: "get",
    url: `comn/prvList/${langCd}/${userType}`,
  });

  return res;
};

export const trmDtlApi = async ({
  langCd,
  prvCd,
}: {
  langCd: string;
  prvCd: string;
}) => {
  const res = await api({
    method: "get",
    url: `comn/prvDtl/${langCd}/${prvCd}`,
  });

  return res;
};

export const termCreateApi = async (params: prvProps) => {
  const res = await api({
    method: "post",
    url: "comn/registerPrv",
    data: params,
  });

  return res;
};

export const termModifyApi = async (params: Partial<prvProps>) => {
  const res = await api({
    method: "post",
    url: `comn/modifyPrvDtl`,
    data: params,
  });

  return res;
};
