import SingupEntryView from "components/view/signup";

const SignupPage = () => {
  return (
    <>
      <SingupEntryView />
    </>
  );
};

export default SignupPage;
