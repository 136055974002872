import { Fragment, useEffect, useRef, useState } from "react";
import "./radio.scss";
import { useDispatch } from "react-redux";
import { setSelectLng } from "store/commonSlice";
import { useSelector } from "react-redux";
import { isSuccessSelector } from "store/roomSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./editor.scss";
import i18n from "locales/i18n";
import { useLocation } from "react-router-dom";

export type langProps = {
  kr: string;
  us: string;
};

type IProps = {
  options: { id: string; label: string }[];
  name: string;
  contStyle?: string;
  placeholder?: string;
  rows?: number;
  inpDisabled?: boolean;
  rdoDisabled?: boolean;
  readOnly?: boolean;
  info: any;
  onChange(name: string, value: string): void;
};

const RadioEditor = ({
  options,
  name,
  rdoDisabled = false,
  readOnly = false,
  info,
  onChange,
}: IProps) => {
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "indent",
    "link",
    // "image",
    // "video",
  ];

  const modules = {
    toolbar: [
      // [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [{ color: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"], //"image", "video"
      ["clean"],
    ],

    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isSuccess = useSelector(isSuccessSelector);

  const quillRef = useRef<ReactQuill>(null);

  const [lng, setLng] = useState(i18n.language);
  const [txt, setTxt] = useState<any>({});

  let id = `${name}_${lng}`;

  const handleChange = (content: string, editor: string) => {
    setTxt({
      ...txt,
      [id]: content,
    });
  };

  useEffect(() => {
    if (quillRef.current) {
      let html = info[id as keyof string] ?? "";
      quillRef.current.editor?.pasteHTML(html);
      if (isSuccess) {
        quillRef.current.editor?.pasteHTML(`<></>`);
      }
    }
  }, [lng, isSuccess]);

  useEffect(() => {
    if (quillRef.current) {
      if (pathname.includes("create")) {
        let html = txt[`${name}_${lng}` as keyof string] ?? "";
        quillRef.current.editor?.pasteHTML(html);
        return;
      }
      if (pathname.includes("info") || pathname.includes("modify")) {
        let html =
          txt[`${name}_${lng}` as keyof string] || info[`${name}_${lng}`]
            ? info[`${name}_${lng}`]
            : "";
        quillRef.current.editor?.pasteHTML(html ?? "");
        return;
      }
    }
  }, [lng]);

  useEffect(() => {
    if (quillRef.current) {
      let html = info[`${name}_${lng}` as keyof string] ?? "";
      quillRef.current.editor?.pasteHTML(html);
    }
  }, []);

  // useEffect(() => {
  //   if (quillRef.current) {
  //     quillRef.current.editor?.setText("");
  //     setContent({});
  //   }
  // }, [isSuccess]);

  return (
    <div className="radio-content">
      {options.map((op) => {
        return (
          <Fragment key={op.id}>
            <input
              type="radio"
              id={name + "_" + op.id}
              name={name}
              value={op.id}
              readOnly={readOnly}
              onChange={(e) => {
                const value = e.currentTarget.value;
                dispatch(setSelectLng(value ?? ""));
                setLng(value ?? "");

                // if (quillRef.current) {
                // }

                // if (quillRef.current && !!info[id]) {
                //   const html = info[id as keyof string] ?? "";
                //   quillRef.current.editor?.pasteHTML(html);
                // }
              }}
              disabled={rdoDisabled}
              defaultChecked={lng === op.id}
            />
            <label htmlFor={name + "_" + op.id} data-label={op.label}></label>
          </Fragment>
        );
      })}

      <ReactQuill
        id={id}
        className=""
        theme="snow"
        placeholder="내용을 작성하여 주세요."
        ref={quillRef}
        modules={modules}
        formats={formats}
        readOnly={readOnly}
        onChange={(content, delta, source, editor) => {
          handleChange(content, editor.getText());
          onChange(id, content);
        }}
      />
    </div>
  );
};

export default RadioEditor;
