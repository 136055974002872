import RadioButtonWrap from "./radioButtonWrap";
import {
  facilityOptions,
  lngLabel,
  otherFacilityOptions,
  roomFacilityOptions,
} from "util/option";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SubHeader from "components/layout/header/subHeader";
import RadioContent from "components/radio/radiocontent";
import Button from "components/button/createButtonWrap";

const LodPrdInfoView = () => {
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();
  return (
    <div className="main-cnt">
      <SubHeader
        title="숙소제공정보 상세"
        close
        url={state?.url}
        state={state?.searchInfo}
      />
      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹 명</th>
              <td>
                <input type="text" disabled readOnly />
              </td>
              <th>숙소 등급</th>
              <td>
                <input type="text" disabled readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소명</th>
              <td colSpan={1}>
                <div className="max-w-[25rem]">
                  <RadioContent
                    options={lngLabel}
                    name={"lodNm"}
                    readOnly
                    onChange={(target) => {}}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>공용시설</th>
              <td colSpan={3} className="py-2">
                {facilityOptions.map((radio, index) => (
                  <div className="radiobtn-wrap" key={index}>
                    <RadioButtonWrap
                      label={radio.label1}
                      radio1={false}
                      radio2
                      handleChange={() => {}}
                    />
                    {radio.label2 && (
                      <RadioButtonWrap
                        label={radio.label2}
                        radio1={false}
                        radio2
                        handleChange={() => {}}
                      />
                    )}
                  </div>
                ))}
              </td>
            </tr>
            <tr>
              <th>객실시설</th>
              <td colSpan={3} className="py-2">
                {roomFacilityOptions.map((radio, index) => (
                  <div className="radiobtn-wrap" key={index}>
                    <RadioButtonWrap
                      label={radio.label1}
                      radio1={false}
                      radio2
                      handleChange={() => {}}
                    />
                    {radio.label2 && (
                      <RadioButtonWrap
                        label={radio.label2}
                        radio1={false}
                        radio2
                        handleChange={() => {}}
                      />
                    )}
                  </div>
                ))}
              </td>
            </tr>
            <tr>
              <th>기타</th>
              <td colSpan={3} className="py-2">
                {otherFacilityOptions.map((radio, index) => (
                  <div className="radiobtn-wrap" key={index}>
                    <RadioButtonWrap
                      label={radio.label1}
                      radio1={false}
                      radio2
                      handleChange={() => {}}
                    />
                    {radio.label2 && (
                      <RadioButtonWrap
                        label={radio.label2}
                        radio1={false}
                        radio2
                        handleChange={() => {}}
                      />
                    )}
                  </div>
                ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button txt="수정" isLoading={false} onClick={() => {}} />
    </div>
  );
};

export default LodPrdInfoView;
