import Button from "components/button/createButtonWrap";
import SubHeader from "components/layout/header/subHeader";
import RadioEditor from "components/radio/radioEditor";
import RadioContent from "components/radio/radiocontent";
import { useLocation } from "react-router-dom";
import { lngLabel } from "util/option";

type IProps = {
  lodGrpInfo: any;
};

const LodGroupInfoView = ({ lodGrpInfo }: IProps) => {
  const { pathname, search, state } = useLocation();
  let lodGrpNmInfo = {};
  let lodGrpDescInfo = {};
  return (
    <div className="main-cnt">
      <SubHeader
        title="숙소그룹 상세"
        close
        url={state?.url}
        state={state?.searchInfo}
      />

      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹번호</th>
              <td>
                <input type="text" value={lodGrpInfo?.lodGrpNo} readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소그룹 명</th>
              <td colSpan={3}>
                <RadioContent
                  options={lngLabel}
                  name={"lodGrpNm"}
                  info={lodGrpNmInfo}
                  readOnly={true}
                  inpDisabled={true}
                  onChange={(target) => {}}
                />
              </td>
            </tr>
            <tr>
              <th>숙소그룹 설명</th>
              <td colSpan={3}>
                <RadioEditor
                  options={lngLabel}
                  name={"lodGrpDesc"}
                  info={lodGrpDescInfo}
                  readOnly={true}
                  onChange={(name, value) => {}}
                />
              </td>
            </tr>
            <tr>
              <th>숙소그룹 주소국가</th>
              <td>
                <input type="text" value={""} readOnly />
              </td>
              <th>숙소그룹 우편번호</th>
              <td>
                <input type="text" value={""} readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소그룹 주소1</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    options={lngLabel}
                    name={"addr1"}
                    info={lodGrpNmInfo}
                    readOnly={true}
                    inpDisabled={true}
                    onChange={(target) => {}}
                  />
                </div>
              </td>
              <th>숙소그룹 주소2</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    options={lngLabel}
                    name={"addr2"}
                    info={lodGrpNmInfo}
                    readOnly={true}
                    inpDisabled={true}
                    onChange={(target) => {}}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <th>숙소그룹 주소3</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    options={lngLabel}
                    name={"addr3"}
                    info={lodGrpNmInfo}
                    readOnly={true}
                    inpDisabled={true}
                    onChange={(target) => {}}
                  />
                </div>
              </td>
              <th>숙소그룹 주소4</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    options={lngLabel}
                    name={"addr4"}
                    info={lodGrpNmInfo}
                    readOnly={true}
                    inpDisabled={true}
                    onChange={(target) => {}}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>숙소그룹 이메일</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
              <th>숙소그룹 전화번호</th>
              <td>
                <input type="text" value="" readOnly />
              </td>
            </tr>
            <tr>
              <th>상태</th>
              <td>
                <input type="text" value={""} readOnly />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button txt="수정" isLoading={false} onClick={() => {}} />
    </div>
  );
};

export default LodGroupInfoView;
