import LodProvideView from "components/view/lod/prd/list";
import { lodPrdSearchProps } from "interface/lod";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const LodPrdPage = () => {
  const { pathname } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  const [searchInfo, setSearchInfo] = useState<lodPrdSearchProps>({
    lodGrpNo: "",
    lodGrdCd: "",
    lodNo: "",
    uYn: "N",
  });
  const handleChange = (name: string, value: string) => {};
  const handleSelect = (target: EventTarget & HTMLLIElement) => {};
  const handleReset = () => {};
  const handleSearch = () => {};
  return (
    <LodProvideView
      memType={memType}
      searchInfo={searchInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleReset={handleReset}
      handleSearch={handleSearch}
    />
  );
};

export default LodPrdPage;
