import "./radio.scss";

type IProps = {
  id: string;
  name: string;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  lableStyle?: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
};

const Radio = ({
  id,
  name,
  label,
  checked,
  disabled,
  lableStyle,
  onChange,
}: IProps) => {
  return (
    <div className="radio-wrap">
      <input
        type="radio"
        id={id}
        name={name}
        defaultChecked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <label htmlFor={id} className={lableStyle}>
        {label}
      </label>
    </div>
  );
};

export default Radio;
