import axios from "axios";
import { ResponseProps } from "interface";

type IProps = {
  method: "post" | "get" | "patch" | "delete" | "put";
  url: string;
  data?: object;
  params?: URLSearchParams | string | object;
};

const baseUrl = "/app";

const api = async ({ method, url, data }: IProps) => {
  const headers: any = {
    "Content-Type": "application/json; charset=UTF-8",
  };

  try {
    axios.defaults.withCredentials = true;
    const response = await axios({
      method,
      url: `${baseUrl}/${url}`,
      data,
      headers,
    });

    const res: ResponseProps = response.data;

    const {
      header: { code, message },
      body,
    } = response.data;

    if (
      message?.includes(`"admEtt" is null`) ||
      message?.includes(`"adminEtt" is null`)
    ) {
      // alert(message);
      alert("session time out");
      sessionStorage.removeItem("login");
      window.location.replace("/");
      localStorage.removeItem("memType");
      localStorage.removeItem("lodGrpInfo");
      localStorage.removeItem("sltLodNo");
    }

    if (code === -200) {
      alert(message);
      sessionStorage.removeItem("login");
      window.location.replace("/");
      localStorage.removeItem("memType");
      localStorage.removeItem("lodGrpInfo");
      localStorage.removeItem("sltLodNo");
    }

    return res;
  } catch (error) {
    console.error(error);
    console.log("api error", error);
  }
};

export default api;
