import { useMutation, useQuery } from "@tanstack/react-query";
import { trmDtlApi } from "components/api/term";
import TrmDtlView from "components/view/terms/dtl";
import i18n from "locales/i18n";
import { useEffect } from "react";

import { useLocation, useParams } from "react-router-dom";

const TrmDtlPage = () => {
  const { pathname, search, state } = useLocation();
  const { data } = useQuery({
    queryKey: ["termDtl"],
    queryFn: () =>
      trmDtlApi({
        langCd: state?.langCd ?? i18n.language,
        prvCd: search.replace("?prvCd=", ""),
      }),
  });

  const trmDtlMutation = useMutation(trmDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
      }
    },
  });
  useEffect(() => {
    trmDtlMutation.mutate({
      langCd: state?.langCd ?? i18n.language,
      prvCd: search.replace("?prvCd=", ""),
    });
  }, []);

  return <TrmDtlView trmDtl={trmDtlMutation?.data?.body?.prvDtl} />;
};

export default TrmDtlPage;
