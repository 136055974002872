import Button from "components/button/createButtonWrap";
import SubHeader from "components/layout/header/subHeader";
import Select from "components/select";
import { lodChlCreateProps } from "interface/lod";
import { useNavigate } from "react-router";
import { regNum } from "util/common";
import { uYnOptions } from "util/option";

type IProps = {
  info: lodChlCreateProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleModify(): void;
};

const LodChlModifyView = ({
  info,
  handleChange,
  handleModify,
  handleSelect,
}: IProps) => {
  const navigate = useNavigate();
  return (
    <div className="main-cnt">
      <SubHeader title="숙소채널수정" close />

      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹명</th>
              <td>
                <input
                  type="text"
                  value=""
                  onChange={(e) =>
                    handleChange("lodGrpNm", e.currentTarget.value)
                  }
                />
              </td>
              <th>숙소명</th>
              <td>
                <input
                  type="text"
                  value=""
                  onChange={(e) => handleChange("lodNm", e.currentTarget.value)}
                />
              </td>
            </tr>
            <tr>
              <th>숙소등급</th>
              <td>
                <input
                  type="text"
                  value=""
                  onChange={(e) =>
                    handleChange("lodGrd", e.currentTarget.value)
                  }
                />
              </td>
              <th>숙소채널명</th>
              <td>
                <input
                  type="text"
                  value=""
                  onChange={(e) =>
                    handleChange("lodChlNm", e.currentTarget.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <th>숙소 판매수수료율</th>
              <td>
                <input
                  type="text"
                  name="sellFeeRti"
                  value={info?.sellFeeRti}
                  onChange={(e) => {
                    const value = e.currentTarget.value;
                    if (!regNum.test(value)) {
                      return;
                    }
                    if (regNum.test(value)) {
                      handleChange("sellFeeRti", e.currentTarget.value);
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>적용 시작일자</th>
              <td>
                <input
                  type="date"
                  name="aplySDt"
                  onChange={(e) =>
                    handleChange("aplySDt", e.currentTarget.value)
                  }
                />
              </td>
              <th>적용 종료일자</th>
              <td>
                <input
                  type="date"
                  value={info?.aplyEDt}
                  onChange={(e) =>
                    handleChange("aplySDt", e.currentTarget.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <th>상태</th>
              <td>
                <Select
                  id={"uyn"}
                  name={"uyn"}
                  options={uYnOptions}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th>등록일시</th>
              <td>
                <input type="text" value="" />
              </td>
              <th>등록회원명</th>
              <td>
                <input
                  type="text"
                  value=""
                  onChange={(e) => handleChange("memNm", e.currentTarget.value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button
        txt="수정"
        isLoading={false}
        disabled={false}
        onClick={handleModify}
      />
    </div>
  );
};

export default LodChlModifyView;
