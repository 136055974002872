import { lodDefaultHead, lodHead } from "util/option";
import { IlodGrpList, lodDefaultSearchProps } from "interface/lod";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import Modal from "components/modal/modal";
import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { isLoadingSelector, setMessage } from "store/commonSlice";
import NotData from "../notData";
import { useSelector } from "react-redux";
import { isSearchSelector } from "store/memberSlice";
import { pageNavProps } from "interface";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: lodDefaultSearchProps;
};

type IListProps = {
  searchInfo: lodDefaultSearchProps;
  memType: "admin" | "manager";
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const [info, setInfo] = useState({
    isOpen: false,
    rmNo: "",
    uyn: "",
  });
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const handleStatus = () => {};

  return (
    <>
      {list &&
        list?.map((li: IlodGrpList, index: number) => {
          return (
            <tr>
              <td>{index + 1}</td>
              <td>
                <button
                  onClick={() => {
                    navigate(`?lodGrpNo=${li.lodGrpNo}`);
                  }}
                >
                  {li.lodGrpNo}
                </button>
              </td>
              <td>{li.lodGrpNm}</td>
              <td>{li.lodGrpCntyCd}</td>
              <td>{li.lodGrpAddr1}</td>
              <td>{li.lodGrpAddr2}</td>
              <td>
                <span
                  className={li.uYn === "Y" ? "uyn" : ""}
                  // onClick={() => setIsOpen(true)}
                >
                  {li.uYn === "Y" ? "정상" : "미 정상"}
                </span>
              </td>
            </tr>
          );
        })}
      <Modal
        title="상태 변경"
        isOpen={info.isOpen}
        cnlText="취소"
        isLoading={false}
        disabled={false}
        handleCloseModal={() => {
          setInfo({
            isOpen: false,
            rmNo: "",
            uyn: "",
          });
        }}
        onClick={handleStatus}
      >
        <div>객실 상태를 변경 하시겠습니까?</div>
      </Modal>
    </>
  );
};

const LodDefaultList = ({ memType, searchInfo }: IProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = lodDefaultHead.map((head) => {
    return head;
  });

  const [No, lodGrpNm, lodGrpCd, lodNm, ...res] = headList;

  // const { data } = useQuery({
  //   queryKey: ['rmList', isSearch],
  //   queryFn: () => {
  //     return rmListApi('')
  //   },
  //   initialData: null
  // })

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                {headList.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              <List
                searchInfo={searchInfo}
                memType={memType}
                setPageNav={setPageNav}
              />
            </tbody>
          </table>
        </div>
        <NotData
          isLoading={isLoading}
          isNotData={!pageNav?.totalRows}
          isSuccess={!!pageNav}
          styles=""
          txt="Loading ..."
        />
      </div>
      {/* {rmList && !isLoading && <Pagenation total={1} />} */}
      {/* <AgGrid rowData={rowData} columnDefs={columnDefs} pagination={true} /> */}
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav?.totalPage} />
      )}
    </div>
  );
};

export default LodDefaultList;
