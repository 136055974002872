import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface roomState {
  rmList: any[];
  rmOpList: any[];
  isSuccess: boolean;
  isSearch: boolean;
}

const initialState: roomState = {
  rmList: [],
  rmOpList: [],
  isSuccess: false,
  isSearch: false,
};

export const roomSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    setRmList: (state, { payload }: PayloadAction<any[]>) => {
      state.rmList = payload;
    },

    setRmOpList: (state, { payload }: PayloadAction<any>) => {
      state.rmOpList = payload;
    },

    setIsSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isSuccess = payload;
    },

    setIsSearch: (state, { payload }: PayloadAction<boolean>) => {
      state.isSearch = payload;
    },
  },
});

export const rmListSelector = createSelector(
  [(state: RootState) => state.room.rmList],
  (rmList) => rmList
);

export const rmOpListSelector = createSelector(
  [(state: RootState) => state.room.rmOpList],
  (rmOpList) => rmOpList
);

export const isSuccessSelector = createSelector(
  [(state: RootState) => state.room.isSuccess],
  (isSuccess) => isSuccess
);

export const isSearchSeloctor = createSelector(
  [(state: RootState) => state.room.isSearch],
  (isSearch) => isSearch
);

export const { setRmList, setRmOpList, setIsSuccess, setIsSearch } =
  roomSlice.actions;

export default roomSlice.reducer;
